import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetRefrigerantAndFireDataDocument,
  GetRefrigerantAndFireDataQuery,
  GetRefrigerantAndFireDataQueryVariables,
  SubmitRefrigerantAndFireDocument,
  SubmitRefrigerantAndFireMutation,
  SubmitRefrigerantAndFireMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getRefrigerantAndFireData: (
    variables: GetRefrigerantAndFireDataQueryVariables
  ) => Promise<GetRefrigerantAndFireDataQuery["project"]["refrigerantAndFireEx"]>;
  submitRefrigerantAndFire: (
    variables: SubmitRefrigerantAndFireMutationVariables
  ) => Promise<SubmitRefrigerantAndFireMutation["submitRefrigerantAndFire"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getRefrigerantAndFireData = useCallback(
    async (variables: GetRefrigerantAndFireDataQueryVariables) => {
      const response = await client.query<GetRefrigerantAndFireDataQuery, GetRefrigerantAndFireDataQueryVariables>({
        query: GetRefrigerantAndFireDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.refrigerantAndFireEx;
    },
    [client]
  );

  const submitRefrigerantAndFire = useCallback(
    async (variables: SubmitRefrigerantAndFireMutationVariables) => {
      const response = await client.mutate<SubmitRefrigerantAndFireMutation, SubmitRefrigerantAndFireMutationVariables>(
        {
          mutation: SubmitRefrigerantAndFireDocument,
          variables,
        }
      );

      return response.data?.submitRefrigerantAndFire ?? false;
    },
    [client]
  );

  return {
    getRefrigerantAndFireData,
    submitRefrigerantAndFire,
  };
};
