import React from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { QueryUser } from "../types";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    padding: spacing(3, 2),
    height: 550,
    display: "flex",
    flexDirection: "column",
  },

  container: { height: "100%" },
  font: { height: "10%" },
  list: {
    width: "100%",
    height: "90%",
    overflowY: "auto",
    backgroundColor: palette.background.paper,
  },
  title: {
    padding: spacing(1),
  },
}));

export type UserListProps = {
  users: QueryUser[];
  selectedUser: string;
  onSelectUser: (userId: string) => void;
};

export const UserList: React.FC<UserListProps> = (props) => {
  const { users, selectedUser, onSelectUser } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  const handleSelectUser = (id: string) => {
    onSelectUser(id);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h5" className={classes.font}>
          {intl.formatMessage({ id: "header.userPermission.userList" })}
        </Typography>
        <List className={classes.list}>
          {users.length > 0 ? (
            users.map((u) => (
              <React.Fragment key={u.id}>
                <Divider />
                <ListItemButton
                  alignItems="flex-start"
                  selected={selectedUser === u.id}
                  onClick={() => handleSelectUser(u.id)}
                >
                  <ListItemText primary={u.name} secondary={u.loginId} />
                </ListItemButton>
              </React.Fragment>
            ))
          ) : (
            <Typography variant="h6" color="primary">
              <Link to="/project/user">{intl.formatMessage({ id: "button.userPermission.addUser" })}</Link>
            </Typography>
          )}
        </List>
      </div>
    </Paper>
  );
};
