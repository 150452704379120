export const title = {
  "/stepper": "layout.menu.admin.iesg",
  "/stepper/company": "excel.title.company",
  "/stepper/nf1": "excel.title.nf1",
  "/stepper/nf2": "excel.title.nf2",
  "/stepper/nf3": "excel.title.nf3",
  "/stepper/energy": "excel.title.energy",
  "/stepper/water": "excel.title.water",
  "/stepper/paper": "excel.title.paper",
  "/stepper/non-hazardous-waste": "excel.title.non-hazardous",
  "/stepper/hazardous-waste": "excel.title.hazardous-waste",
  "/stepper/fuel-mobile": "excel.title.fuel-mobile",
  "/stepper/fuel-stationary": "excel.title.fuel-stationary",
  "/stepper/other-mobile-machinery": "excel.title.other-mobile-machinery",
  "/stepper/packaging-material": "excel.title.packaging-material",
  "/stepper/refrigerant-and-fire": "excel.title.refrigerant-and-fire",
  "/stepper/others": "excel.title.others",
  "/stepper/tree": "excel.title.tree",
  "/project/user": "layout.menu.admin.account",
  "/project/user-permission": "layout.menu.admin.user_permission",
  "/project-list": "layout.menu.systemAdmin.projects",
  "/project/document": "header.document.title",
  "/stepper/workforce": "excel.title.workforce",
  "/stepper/turnover": "excel.title.turnover",
  "/stepper/injury-and-fatalities": "excel.title.injuryAndFatalities",
  "/stepper/development-and-training": "excel.title.developmentAndTraining",
  "/stepper/training-ratio": "excel.title.trainingRatio",
  "/stepper/supply-chain-management": "excel.title.supplyChainManagement",
  "/stepper/product-responsibility": "excel.title.productResponsibility",
  "/stepper/community-investment": "excel.title.communityInvestment",
  "/stepper/product-responsibility-policy": "excel.title.productResponsibilityPolicy",
  "/stepper/labour-responsibility-policy": "excel.title.labourResponsibilityPolicy",
};
