import React from "react";

import { Route } from "react-router-dom";

import { Role } from "generated/graphql";
import { Login } from "login";
import { LandingPage } from "pages/landing";
import { LogoutPage } from "pages/logout";
import { NoProjectPage } from "pages/no-project";
import { AdminRoute } from "router/admin";
import { PrivateRoute } from "../components/private-route";
import { EmissionFactorRoute } from "./emission-factor";
import { ProjectRoute } from "./project";
import { Scope3Route } from "./scope3";
import { StepperRoute } from "./stepper";

export const AppRouter: React.FC = () => (
  <>
    <PrivateRoute path="/" exact component={LandingPage} />
    <PrivateRoute path="/stepper" component={StepperRoute} />
    <PrivateRoute path="/scope3" component={Scope3Route} />
    <PrivateRoute path="/project" component={ProjectRoute} permission={Role.ClientAdmin} />
    <PrivateRoute path="/admin" component={AdminRoute} permission={Role.Admin} />
    <PrivateRoute path="/admin/emission-factor" component={EmissionFactorRoute} permission={Role.Admin} />
    <PrivateRoute path="/logout" exact component={LogoutPage} />
    <Route path="/login" exact component={Login} />
    <Route path="/no-project" exact component={NoProjectPage} />
  </>
);
