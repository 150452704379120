import { PASSWORD_SCHEMA } from "utils/const";
import { Yup } from "utils/yup";
import { LoginFormField, ResetPasswordFormField } from "./types";

export const loginSchema = Yup.object().shape<LoginFormField>({
  loginId: Yup.string()
    .trim("Can not include space 不能包含空格")
    .strict(true)
    .required("Login Id is required 帐户名称是必需的"),
  password: Yup.string().required("Password is required 密码是必需的"),
});

export const resetPasswordSchema = Yup.object().shape<ResetPasswordFormField>({
  password: Yup.string()
    .required("Password is required 密码是必需的")
    .min(8, "Password must be at least 8 characters. 密码长度最少八个字符​")
    .matches(
      PASSWORD_SCHEMA,
      `Password must contain both alphabetic and numeric characters, and only supports the following symbols:
      密码必须包含英文字母及阿拉伯数字​，並只支援以下符号：
      !@#$%^&*`
    ),
  password2: Yup.string()
    .required("Password is required 密码是必需的")
    .test("check", "Password is not correct 密码不正确", function () {
      return this.parent.password === this.parent.password2;
    }),
});
