import { Scope3DataPage } from "pages/scope3";
import { DynamicRoute } from "router/dynamic-route";

const route = [
  {
    path: "/",
    component: Scope3DataPage,
    exact: true,
  },
];

export const Scope3Route = DynamicRoute(route);
