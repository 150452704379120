import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import {
  machineryTypeOptions,
  newOtherMobileMachineryRow,
  OtherMobileMachineryFuelType,
  otherMobileMachineryFuelTypeOptions,
  otherMobileMachineryFuelUnitOptions,
  petrolEngingOptions,
  tips,
} from "./consts";
import { useActions } from "./hook/action";
import { OtherMobileMachineryRecordRow } from "./types";

const source = IndexType.OtherMobileMachinery;

export const OtherMobileMachineryPage: React.FC = () => {
  const [initRows, setInitRows] = useState<OtherMobileMachineryRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);
  const { getData, submitData } = useActions();
  const { openDialog, Dialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 250,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "machineryType",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.machinery-type" }),
        width: 280,
        resizable: true,
        editor: SelectEditor,
        options: machineryTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(machineryTypeOptions), "machineryType"),
        isCreatable: true,
      },
      {
        key: "fuelType",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.fuel-type" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: otherMobileMachineryFuelTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(otherMobileMachineryFuelTypeOptions), "fuelType"),
        isCreatable: true,
      },
      {
        key: "petrolEngine",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.petrol-engine" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: petrolEngingOptions,
        formatter: getFormatter(createSelectSingleFormatter(petrolEngingOptions), "petrolEngine"),
        isDisable: (row) => row.rowData.fuelType !== OtherMobileMachineryFuelType.UnleadedPetrol,
      },
      {
        key: "fuelConsumption",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.fuel-consumption" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "fuelConsumption"),
      },
      {
        key: "fuelUnit",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.fuel-unit" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: otherMobileMachineryFuelUnitOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(otherMobileMachineryFuelUnitOptions), "fuelUnit"),
      },
      {
        key: "enginePower",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.engine-power" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "enginePower"),
      },
      {
        key: "numberOfHour",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.number-of-hour" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "numberOfHour"),
      },
      {
        key: "energy",
        name: intl.formatMessage({ id: "excel.other-mobile-machinery.energy" }),
        width: 280,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "energy"),
      },
    ],
    [companyOptions, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<OtherMobileMachineryRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as OtherMobileMachineryRecordRow[]);

    return Yup.array(
      Yup.object<Partial<OtherMobileMachineryRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        machineryType: Yup.string().required("errors.common.require"),
        fuelType: Yup.string().required("errors.common.require"),
        fuelConsumption: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        fuelUnit: Yup.string().required("errors.common.require"),
        enginePower: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        numberOfHour: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        petrolEngine: Yup.string().test("isRequired", "errors.common.require", function (value) {
          return this.parent.fuelType === OtherMobileMachineryFuelType.UnleadedPetrol ? !!value : true;
        }),
      }).required()
    ).defined();
  });

  const handleUpdateRows: OnUpdateDataGridRows<OtherMobileMachineryRecordRow> = (rows, updatedItem) => {
    const { fuelType, enginePower, numberOfHour } = updatedItem.updated;
    if (fuelType === OtherMobileMachineryFuelType.UnleadedPetrol) {
      openDialog({
        content: intl.formatMessage({ id: "errors.other-mobile-machinery.unleaded-petrol" }),
        type: DialogType.ALERT,
      });
    } else if (isAnyNotNullOrUndefined(enginePower, numberOfHour)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        rows[i].energy = +(+rows[i].enginePower * +rows[i].numberOfHour);
      }
    }

    return rows;
  };

  const introContent = intl.formatMessage({ id: "excel.other-mobile-machinery.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newOtherMobileMachineryRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "fuelConsumption", value: 0 },
          { property: "enginePower", value: 0 },
          { property: "numberOfHour", value: 0 },
          { property: "energy", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
