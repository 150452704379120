import React from "react";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { Breadcrumbs } from "components/breadcrumbs";
import { useHistory } from "react-router-dom";
import { EmissionFactorStepper } from "./components/stepper";
import { categories } from "./data";
import { useUrlParser } from "./hook/url-parser";

const useStyles = makeStyles()(({ spacing }) => ({
  paperRoot: {
    padding: spacing(3),
  },
  breadcrumbs: {
    padding: spacing(1, 2),
  },
}));

const ITEM_PER_COLUMN = 5;
const COLUMN_WIDTH = 3;

export const EmissionFactorEditPage: React.FC = () => {
  const { classes } = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { profile, url } = useUrlParser();

  const renderSteppersByColumn = (itemsPerColumn: number) => {
    const steppers: JSX.Element[] = [];
    let count = 0;

    while (count < categories.length) {
      steppers.push(
        <EmissionFactorStepper
          key={steppers.length}
          items={categories.slice(count, Math.min(count + itemsPerColumn, categories.length))}
          onClick={(category) => history.push(url.profileEditData(category))}
        />
      );
      count += itemsPerColumn;
    }

    return steppers;
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Paper className={classes.breadcrumbs}>
          <Breadcrumbs
            separator={">"}
            items={[
              {
                content: intl.formatMessage({ id: "layout.menu.systemAdmin.emissionFactors" }),
                link: url.profile,
              },
              { content: profile },
            ]}
          />
        </Paper>
      </Grid>
      <Grid xs={12}>
        <Paper className={classes.paperRoot}>
          <Grid container spacing={1} justifyContent="space-between">
            {renderSteppersByColumn(ITEM_PER_COLUMN).map((stepperElement, index) => (
              <Grid key={index} xs={COLUMN_WIDTH}>
                {stepperElement}
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
