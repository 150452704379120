import React, { useContext, useEffect } from "react";

import { LanguageContext } from "provider/language";

export type LangProps = {
  setLang: (locale: string) => void;
};

export const Lang: React.FC<LangProps> = ({ setLang }) => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    setLang(locale);
  }, [setLang, locale]);

  return <></>;
};
