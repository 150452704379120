import React, { useState } from "react";

import AddCircle from "@mui/icons-material/AddCircle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { FastField, Field, Form, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DialogMode } from "utils/dialog";
import { generatePassword } from "utils/helper";
import { Yup } from "utils/yup";
import { IClientGroup, IUser } from "../types";
import { AdminUsersTable } from "./admin-users-table";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "60%",
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    margin: "100px 0 0 0",
  },
  fastField: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginTop: spacing(2),
    width: 200,
  },
  errorMsg: {
    color: "red",
  },
  formTitle: {
    margin: spacing(),
  },
  divider: {
    mirgin: spacing(2),
  },
  paper: {
    margin: spacing(1),
    padding: spacing(2),
  },
  button: {
    margin: spacing(1),
  },
}));

type AdminUserForm = {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
  loginId: string;
};

const initAdminUser: AdminUserForm = {
  loginId: "",
  lastName: "",
  firstName: "",
  title: "",
  phone: "",
  email: "",
};

export type ClientGroupFormProps = FormikProps<IClientGroup> & {
  mode: DialogMode;
} & any;

export const ClientGroupForm: React.FC<ClientGroupFormProps> = (props) => {
  const { classes } = useStyles();

  const { values, touched, errors, handleChange, setFieldValue, mode, children, setFieldError, setErrors } = props;
  const [newAdminUser, setNewAdminUser] = useState<IUser>(initAdminUser);
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    loginId: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .matches(/^([a-zA-Z])+(\.|_|[0-9]+)?([a-zA-Z0-9]+)$/, intl.formatMessage({ id: "errors.common.value-invalid" }))
      .required(intl.formatMessage({ id: "errors.common.value-invalid" }))
      .notExist(intl.formatMessage({ id: "errors.common.record-existed" }), "loginId", values.adminUsers),
    firstName: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    lastName: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    phone: Yup.string()
      .min(8, intl.formatMessage({ id: "errors.common.value-invalid" }))
      .matches(/^[0-9]{8,}$/, intl.formatMessage({ id: "errors.common.value-invalid" }))
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    title: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    email: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .email(intl.formatMessage({ id: "errors.common.value-invalid" }))
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
  });

  const addAdminUser = (formValues) => {
    const newList = values.adminUsers.concat(formValues);
    setFieldValue("adminUsers", newList, false);
  };

  const removeAdminUser = (targetLoginId: string) => {
    const targetIndex = values.adminUsers.findIndex((x: IUser) => x.loginId === targetLoginId);
    values.adminUsers.splice(targetIndex, 1);
    setFieldValue("adminUsers", values.adminUsers, false);
  };

  const handleChangeAdminUser = (key, value) => {
    setNewAdminUser({ ...newAdminUser, [key]: value });
  };

  return (
    <Form autoComplete="off">
      <Paper className={classes.paper} elevation={2}>
        <Grid container spacing={3} className={classes.root} alignItems="center">
          <Grid xs={4}>
            <Typography variant="h3" className={classes.formTitle}>
              Client Group Info:
            </Typography>
          </Grid>
          <Grid xs={12} sm={4}>
            <FormControl className={classes.fastField}>
              <FastField
                required
                id="groupCode"
                name="groupCode"
                label="Group Code"
                component={TextField}
                variant="outlined"
                fullWidth={true}
                value={values.groupCode}
                onChange={handleChange}
                error={errors.groupCode && touched.groupCode}
                disabled={mode === DialogMode.EDIT}
              />
              {errors.groupCode && touched.groupCode && <div className={classes.errorMsg}>{errors.groupCode}</div>}
            </FormControl>
          </Grid>
          <Grid xs={12} sm={4}>
            <FormControl className={classes.fastField}>
              <FastField
                required
                id="groupName"
                name="groupName"
                label="Group Name"
                component={TextField}
                variant="outlined"
                fullWidth={true}
                value={values.groupName}
                onChange={handleChange}
                error={errors.groupName && touched.groupName}
              />
              {errors.groupName && touched.groupName && <div className={classes.errorMsg}>{errors.groupName}</div>}
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper} elevation={2}>
        {errors.adminUsers && <div className={classes.errorMsg}>{errors.adminUsers}</div>}
        <Typography variant="h3" className={classes.formTitle}>
          Admin Users:
        </Typography>
        <Grid xs={12}>
          <AdminUsersTable adminUsers={values.adminUsers} removeAdminUser={removeAdminUser} />
        </Grid>
        <Grid container className={classes.form} spacing={1} alignItems="center">
          <Grid xs={1}>
            <IconButton
              color="primary"
              className={classes.button}
              aria-label="add"
              onClick={() => {
                validationSchema
                  .validate(newAdminUser)
                  .then((valid) => {
                    if (valid) {
                      setErrors({});
                      addAdminUser({ ...newAdminUser, password: generatePassword(6) });
                      setNewAdminUser(initAdminUser);
                    }
                  })
                  .catch((err) => {
                    setErrors({});
                    setFieldError(err.path, err.message);
                  });
              }}
              size="large"
            >
              <AddCircle />
            </IconButton>
          </Grid>
          <Grid xs={11}>
            <Grid container spacing={1}>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.loginId}
                    name={newAdminUser.loginId}
                    label="Login ID"
                    value={newAdminUser.loginId}
                    onChange={(e) => {
                      handleChangeAdminUser("loginId", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    required
                    error={!!errors.loginId}
                  />
                  {errors.loginId && <div className={classes.errorMsg}>{errors.loginId}</div>}
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.lastName}
                    name={newAdminUser.lastName}
                    label="Last Name"
                    value={newAdminUser.lastName}
                    onChange={(e) => {
                      handleChangeAdminUser("lastName", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    required
                    error={!!errors.lastName}
                  />
                  {errors.lastName && <div className={classes.errorMsg}>{errors.lastName}</div>}
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.firstName}
                    name={newAdminUser.firstName}
                    label="First Name"
                    value={newAdminUser.firstName}
                    onChange={(e) => {
                      handleChangeAdminUser("firstName", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    required
                    error={!!errors.firstName}
                  />
                  {!!errors.firstName && <div className={classes.errorMsg}>{errors.firstName}</div>}
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.title}
                    name={newAdminUser.title}
                    label="Title"
                    value={newAdminUser.title}
                    onChange={(e) => {
                      handleChangeAdminUser("title", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    error={!!errors.title}
                  />
                  {errors.title && <div className={classes.errorMsg}>{errors.title}</div>}
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.phone}
                    name={newAdminUser.phone}
                    label="Phone"
                    value={newAdminUser.phone}
                    onChange={(e) => {
                      handleChangeAdminUser("phone", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    error={!!errors.phone}
                  />
                  {errors.phone && <div className={classes.errorMsg}>{errors.phone}</div>}
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <FormControl>
                  <Field
                    id={newAdminUser.email}
                    name={newAdminUser.email}
                    label="Email"
                    value={newAdminUser.email}
                    onChange={(e) => {
                      handleChangeAdminUser("email", e.target.value);
                    }}
                    component={TextField}
                    variant="outlined"
                    error={!!errors.email}
                  />
                  {errors.email && <div className={classes.errorMsg}>{errors.email}</div>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {children}
    </Form>
  );
};
