import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";
import { NumberSchema, StringSchema } from "yup";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnPreValidateRows,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { usePermission } from "hook/permission";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import {
  Availability,
  availabilityOptions,
  grossFloorUnitOptions,
  newNf1Row,
  propertyTypeOptions,
  propertyUsageOptions,
  tips,
} from "./consts";
import { useActions } from "./hook/action";
import { Nf1RecordRow } from "./types";

const source = IndexType.NormalizationFactor1;

export const Nf1Page: React.FC = () => {
  const [initRows, setInitRows] = useState<Nf1RecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate, includedInCompanyIndexes } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();
  const editable = usePermission();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: editable ? SelectEditor : undefined,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
        editable,
      },
      {
        key: "typeOfProperty",
        name: intl.formatMessage({ id: "excel.nf1.type-of-property" }),
        resizable: true,
        width: 200,
        editor: editable ? SelectEditor : undefined,
        options: propertyTypeOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(propertyTypeOptions), "typeOfProperty"),
        editable,
      },
      {
        key: "useOfProperty",
        name: intl.formatMessage({ id: "excel.nf1.use-of-property" }),
        resizable: true,
        width: 200,
        editor: editable ? SelectEditor : undefined,
        options: propertyUsageOptions,
        formatter: getFormatter(createSelectSingleFormatter(propertyUsageOptions), "useOfProperty"),
        isCreatable: true,
        editable,
      },
      {
        key: "address",
        name: intl.formatMessage({ id: "excel.common.address" }),
        resizable: true,
        width: 200,
        editor: editable ? InputEditor : undefined,
        formatter: getFormatter(Formatter, "address"),
        editable,
      },
      {
        key: "grossFloorArea",
        name: intl.formatMessage({ id: "excel.nf1.gross-floor-area" }),
        resizable: true,
        width: 200,
        editor: editable ? InputEditor : undefined,
        type: "number",
        formatter: getFormatter(Formatter, "grossFloorArea"),
        isDisable: (row: { rowData: Nf1RecordRow }) => !includedInCompanyIndexes[row.rowData.companyId],
        editable,
      },
      {
        key: "grossFloorUnit",
        name: intl.formatMessage({ id: "excel.common.unit" }),
        resizable: true,
        width: 200,
        editor: editable ? SelectEditor : undefined,
        options: grossFloorUnitOptions,
        formatter: getFormatter(createSelectSingleFormatter(grossFloorUnitOptions), "grossFloorUnit"),
        isDisable: (row: { rowData: Nf1RecordRow }) => !includedInCompanyIndexes[row.rowData.companyId],
        editable,
      },
      {
        key: "leaseStartDate",
        name: intl.formatMessage({ id: "excel.nf1.lease-start-date" }),
        resizable: true,
        width: 200,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "leaseStartDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "leaseStartDate"),
        editable,
      },
      {
        key: "leaseEndDate",
        name: intl.formatMessage({ id: "excel.nf1.lease-end-date" }),
        resizable: true,
        width: 200,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "leaseStartDate",
          endDateProperty: "leaseEndDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "leaseEndDate"),
        editable,
      },
      {
        key: "energy",
        name: intl.formatMessage({ id: "excel.nf1.energy-record" }),
        resizable: true,
        width: 200,
        editor: editable ? SelectEditor : undefined,
        options: availabilityOptions,
        formatter: getFormatter(createSelectSingleFormatter(availabilityOptions), "energy"),
        editable,
      },
      {
        key: "water",
        name: intl.formatMessage({ id: "excel.nf1.water-record" }),
        resizable: true,
        width: 200,
        editor: editable ? SelectEditor : undefined,
        options: availabilityOptions,
        formatter: getFormatter(createSelectSingleFormatter(availabilityOptions), "water"),
        editable,
      },
    ],
    [companyOptions, intl, maxDate, minDate, includedInCompanyIndexes]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOptions]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<Nf1RecordRow> = (rows, updatedItem) => {
    const { water, energy } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(water || energy)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        if (
          (isAnyNotNullOrUndefined(water) && rows[i].water === Availability.Unavailable) ||
          (isAnyNotNullOrUndefined(energy) && rows[i].energy === Availability.Unavailable)
        ) {
          openDialog({ content: intl.formatMessage({ id: "errors.nf1.energy-water" }), type: DialogType.ALERT });
        }
      }
    }

    return rows;
  };

  const handlePreValidate: OnPreValidateRows<Nf1RecordRow> = (rows) => {
    rows.forEach((r) => {
      if (!includedInCompanyIndexes[r.companyId]) {
        r.grossFloorUnit = "";
        r.grossFloorArea = 0;
      }
    });
    return rows;
  };

  const basicSchema = Yup.lazy<Partial<Nf1RecordRow>[]>((values) => {
    return Yup.array(
      Yup.object<Partial<Nf1RecordRow>>({
        address: Yup.string().required("errors.common.require").unique("errors.unique.address", "address", values),
        leaseStartDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "leaseStartDate", "leaseEndDate"),
        leaseEndDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "leaseStartDate", "leaseEndDate"),
      }).required()
    ).defined();
  });

  const fullSchema = Yup.lazy<Partial<Nf1RecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as Nf1RecordRow[]);

    return Yup.array(
      Yup.object<Partial<Nf1RecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        typeOfProperty: Yup.string().required("errors.common.require"),
        useOfProperty: Yup.string().required("errors.common.require"),
        address: Yup.string().required("errors.common.require").unique("errors.unique.address", "address", values),
        grossFloorArea: Yup.number()
          .required("errors.common.require")
          .when("companyId", {
            is: (companyId) => includedInCompanyIndexes[companyId],
            then: (schema: NumberSchema) => schema.positive("errors.common.more-zero"),
          }),
        grossFloorUnit: Yup.string().when("companyId", {
          is: (companyId) => includedInCompanyIndexes[companyId],
          then: (schema: StringSchema) => schema.required("errors.common.require"),
        }),
        leaseStartDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "leaseStartDate", "leaseEndDate"),
        leaseEndDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "leaseStartDate", "leaseEndDate"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.nf1.guide" });
  const steps = useMemo(() => getSteps(intl), [intl]);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newNf1Row}
        source={source}
        basicSchema={basicSchema}
        fullSchema={fullSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "leaseStartDate", value: "" },
          { property: "leaseEndDate", value: "" },
          { property: "grossFloorArea", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
        onPreValidate={handlePreValidate}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
