import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { KeyArray } from "utils/types";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newDevelopmentTrainingRow, TrainingType } from "./consts";
import { useActions } from "./hook/action";
import { DevelopmentTrainingRecordRow } from "./types";

const source = IndexType.DevelopmentAndTraining;

export const DevelopmentTrainingPage: React.FC = () => {
  const [rows, setRows] = useState<DevelopmentTrainingRecordRow[]>([]);
  const [defaultNotice, setDefaultNotice] = useState(false);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);

  const trainingTypeOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "excel.developmentTraining.trainingType.internal" }),
        value: TrainingType.Internal,
      },
      {
        label: intl.formatMessage({ id: "excel.developmentTraining.trainingType.external" }),
        value: TrainingType.External,
      },
    ],
    [intl]
  );

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "courseName",
        name: intl.formatMessage({ id: "excel.developmentTraining.courseName" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "courseName"),
      },
      {
        key: "type",
        name: intl.formatMessage({ id: "excel.developmentTraining.trainingType" }),
        editor: SelectEditor,
        options: trainingTypeOptions,
        resizable: true,
        width: 200,
        formatter: getFormatter(createSelectSingleFormatter(trainingTypeOptions), "type"),
      },
      {
        key: "host",
        name: intl.formatMessage({ id: "excel.developmentTraining.host" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "host"),
      },
      {
        key: "date",
        name: intl.formatMessage({ id: "excel.developmentTraining.date" }),
        width: 150,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "date"),
      },
      {
        key: "numberOfHours",
        name: intl.formatMessage({ id: "excel.developmentTraining.numberOfHours" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "numberOfHours"),
      }, //
      {
        key: "numberOfEmployees",
        name: intl.formatMessage({ id: "excel.developmentTraining.numberOfEmployees" }),
        editable: true,
        resizable: true,
        width: 200,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "numberOfEmployees"),
      },
      {
        key: "male",
        name: intl.formatMessage({ id: "excel.workforce.male" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "male"),
      },
      {
        key: "female",
        name: intl.formatMessage({ id: "excel.workforce.female" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "female"),
      },
      {
        key: "seniorManagement",
        name: intl.formatMessage({ id: "excel.workforce.seniorManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "seniorManagement"),
      },
      {
        key: "middleLevelManagement",
        name: intl.formatMessage({ id: "excel.workforce.middleLevelManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "middleLevelManagement"),
      },
      {
        key: "generalAndTechnicalEmployees",
        name: intl.formatMessage({ id: "excel.workforce.generalAndTechnicalEmployees" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "generalAndTechnicalEmployees"),
      },
    ],
    [intl, companyOptions, trainingTypeOptions, minDate, maxDate]
  );

  const introContent = intl.formatMessage({ id: "excel.developmentTraining.guide" });
  const steps = getSteps(intl);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) {
      return;
    }
    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setDefaultNotice(true);
    setRows(rows);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<DevelopmentTrainingRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as DevelopmentTrainingRecordRow[]);

    const gender: KeyArray<DevelopmentTrainingRecordRow, "male" | "female"> = ["male", "female"];
    const grade: KeyArray<
      DevelopmentTrainingRecordRow,
      "seniorManagement" | "middleLevelManagement" | "generalAndTechnicalEmployees"
    > = ["seniorManagement", "middleLevelManagement", "generalAndTechnicalEmployees"];

    const validateByCategory = (keys: string[]) =>
      Yup.number()
        .required("errors.common.require")
        .sumEqual("error.workforce.sumOfTotal", keys, "numberOfEmployees")
        .nonNegativeInteger("error.common.positiveInteger");

    return Yup.array(
      Yup.object<Partial<DevelopmentTrainingRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        courseName: Yup.string().required("errors.common.require"),
        type: Yup.string().required("errors.common.require"),
        host: Yup.string().required("errors.common.require"),
        date: Yup.string().required("errors.common.require"),
        numberOfHours: Yup.number().required("errors.common.require").moreThan(0, "errors.common.more-zero"),
        numberOfEmployees: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),

        male: validateByCategory(gender),
        female: validateByCategory(gender),

        seniorManagement: validateByCategory(grade),
        middleLevelManagement: validateByCategory(grade),
        generalAndTechnicalEmployees: validateByCategory(grade),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        openNotice={defaultNotice}
        columns={columns}
        initRows={rows}
        newRowData={newDevelopmentTrainingRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "date", value: "" },
          { property: "numberOfHours", value: 0 },
          { property: "numberOfEmployees", value: 0 },
          { property: "male", value: 0 },
          { property: "female", value: 0 },
          { property: "seniorManagement", value: 0 },
          { property: "middleLevelManagement", value: 0 },
          { property: "generalAndTechnicalEmployees", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
