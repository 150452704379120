import React, { useCallback, useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { ProgressBlock } from "components/loading/progress-block";
import { useSnackbar } from "hook/snackbar";
import { IndexIntl } from "../consts";
import { ProjectTableRow, QueryProjectCompany } from "../types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    padding: spacing(3, 2),
    height: 550,
    display: "flex",
    flexDirection: "column",
  },
  tableRoot: {
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    minWidth: 650,
  },
  expandedContent: {},
  hideExpandedContent: {
    borderBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  button: {
    margin: 0,
    padding: 0,
  },
  chipRoot: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  chip: {
    margin: spacing(1),
  },
  submitButton: {
    margin: spacing(1),
  },
  title: {
    padding: spacing(1),
  },
  titleRoot: {
    height: "20%",
  },
}));

export type CompanyListProps = {
  isLoading: boolean;
  isSubmitting: boolean;
  selectedUserId: string;
  projectCompanies: QueryProjectCompany[];
  /** companies that user has permission */
  permittedCompanies: string[];
  onSubmit: (userId: string, projectCompanies: string[]) => Promise<boolean>;
};

export const CompanyList: React.FC<CompanyListProps> = (props) => {
  const { isLoading, isSubmitting, projectCompanies, permittedCompanies, selectedUserId, onSubmit } = props;

  const [companyPermissionMap, setCompanyPermissionMap] = useState<Record<string, boolean>>({});
  // row index which is expanding, -1 if no row is expanded
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);

  const { classes } = useStyles();
  const intl = useIntl();
  const { Snackbar, openSnackbar } = useSnackbar();

  const setPermissions = useCallback(
    (projectCompanies: QueryProjectCompany[], value: boolean | ((id: string) => boolean)) => {
      const object: Record<string, boolean> = {};

      if (typeof value === "boolean") {
        // Set all project companies w/ same value
        projectCompanies.forEach((pc) => (object[pc.id] = value));
      } else {
        // Judge project company permission 1 by 1
        projectCompanies.forEach((pc) => (object[pc.id] = value(pc.id)));
      }

      return object;
    },
    []
  );

  useEffect(() => {
    if (projectCompanies.length > 0) {
      setCompanyPermissionMap(setPermissions(projectCompanies, (id) => permittedCompanies.includes(id)));
    }
  }, [projectCompanies, setCompanyPermissionMap, permittedCompanies, setPermissions]);

  const projectTables = projectCompanies.map<ProjectTableRow>(
    (pc) =>
      ({
        ...pc,
        indexes: pc.indexes.map((i) => ({ name: i, label: intl.formatMessage({ id: IndexIntl[i] }) })),
      } as ProjectTableRow)
  );

  const isSelectedAll = () => {
    const permissions = Object.values(companyPermissionMap);
    return permissions.length === 0 ? false : permissions.every((isTrue) => isTrue);
  };

  const handleSelectAll = () => {
    setCompanyPermissionMap(setPermissions(projectCompanies, !isSelectedAll()));
  };

  const handleSelectCompany = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyPermissionMap((value) => ({ ...value, [id]: event.target.checked }));
  };

  const handleClickExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex !== index ? index : -1);
  };

  const handleSubmit = async () => {
    try {
      const isSubmitSuccess = await onSubmit(
        selectedUserId,
        Object.keys(companyPermissionMap).filter((x) => companyPermissionMap[x])
      );

      if (isSubmitSuccess) {
        openSnackbar(intl.formatMessage({ id: "message.common.update-success" }));
      } else {
        throw new Error();
      }
    } catch (e) {
      openSnackbar(intl.formatMessage({ id: "message.common.update-error" }), { variant: "error" });
    }
  };

  const columns = [
    { name: intl.formatMessage({ id: "excel.common.company-name" }) },
    { name: intl.formatMessage({ id: "excel.common.company-short-name" }) },
  ];

  return (
    <Paper className={classes.root}>
      <div className={classes.titleRoot}>
        <Typography variant="h5" className={classes.title}>
          {intl.formatMessage({ id: "header.userPermission.projectCompanyList" })}
        </Typography>
        {isLoading ? (
          <ProgressBlock width={50} height={50} />
        ) : (
          <Button
            id="submit-button"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "button.submit" })}
          </Button>
        )}
      </div>
      {isLoading ? (
        <ProgressBlock width={50} height={50} />
      ) : (
        <div className={classes.tableRoot}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={isSelectedAll()}
                    onChange={handleSelectAll}
                    value="checkedAll"
                    color="secondary"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                </TableCell>
                {columns.map((col, index) => (
                  <TableCell key={index}>{col.name}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {projectTables.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={!!companyPermissionMap[row.id]}
                        onChange={handleSelectCompany(row.id)}
                        value={"checked" + row.id}
                        color="primary"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.fullName ? row.fullName : "---"}</TableCell>
                    <TableCell>{row.shortName ? row.shortName : "---"}</TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        size="medium"
                        className={classes.button}
                        aria-label="expand"
                        onClick={() => handleClickExpand(index)}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      className={expandedRowIndex === index ? classes.expandedContent : classes.hideExpandedContent}
                    >
                      <Collapse in={expandedRowIndex === index}>
                        <div className={classes.chipRoot}>
                          {row.indexes.length > 0
                            ? row.indexes.map((item, index) => (
                                <Chip
                                  key={index}
                                  size="small"
                                  label={item.label}
                                  className={classes.chip}
                                  color="primary"
                                />
                              ))
                            : "Please assign indexes for this project company"}
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {!isSubmitting && <Snackbar />}
    </Paper>
  );
};
