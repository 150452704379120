import React, { useContext } from "react";

import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLabelProps, VictoryTheme } from "victory";

import { useDimensions } from "hook/dimension";
import { LanguageContext } from "provider/language";
import { DashboardColor } from "../../consts";
import { ChartInputWithLabelColor } from "../types";
import { getBarChartXAxisProps, getBarChartYAxisProps } from "./utils";

export type BarChartProps = {
  height?: number;
  names: string[];
  data: ChartInputWithLabelColor[];
  bound: number[];
  chartProps?: {
    axisLabel?: string;
  };
};

const AXIS_LABEL_DELTA = 45;

export const BarChart: React.FC<BarChartProps> = (props) => {
  const { height, names, data, bound, chartProps } = props;
  const { axisLabel } = chartProps ?? {};

  const { locale } = useContext(LanguageContext);
  const { ref, dimensions } = useDimensions();

  // By default, horizontal bar charts show data from bottom to top
  // To let them display from top to bottom, need to reverse the order of names
  const orderedNames = [...names].reverse();

  const getColorByXName = (datum?: { xName?: string }) => {
    if (datum && datum.xName) {
      const targetDatum = data.find((d) => d.name === datum.xName);
      if (targetDatum && targetDatum.color) return targetDatum.color;
    }

    return DashboardColor.chart.grey1;
  };

  const getLabelByXName = (datum?: { xName?: string }) => {
    if (datum && datum.xName) {
      const targetDatum = data.find((d) => d.name === datum.xName);
      if (targetDatum && targetDatum.label) return targetDatum.label;
    }

    return "";
  };

  return (
    <div ref={ref}>
      <VictoryChart
        theme={VictoryTheme.material}
        domain={{ x: [1, data.length], y: [bound[0], bound[bound.length - 1]] }}
        domainPadding={{ x: 24, y: 0 }}
        /**
         * Left Padding = 80
         * ~ 8 English characters / 4 Chinese characters (font size 16)
         */
        padding={{ left: 80, right: 60, top: 20, bottom: 40 + (axisLabel ? AXIS_LABEL_DELTA : 0) }}
        width={dimensions.width}
        height={(height ?? dimensions.height) + (axisLabel ? AXIS_LABEL_DELTA : 0)}
      >
        <VictoryAxis {...getBarChartXAxisProps()} />
        <VictoryAxis
          {...getBarChartYAxisProps(bound, locale)}
          label={axisLabel}
          axisLabelComponent={<VictoryLabel dy={AXIS_LABEL_DELTA} />}
        />
        <VictoryBar
          horizontal
          barWidth={24}
          categories={{ x: orderedNames }}
          data={data.map(({ name, value }) => ({ x: name, y: value }))}
          style={{
            data: { fill: ({ datum }) => getColorByXName(datum) },
            labels: {
              fill: ({ datum }: VictoryLabelProps) => getColorByXName(datum!),
              fontSize: 16,
              fontWeight: "bold",
            },
          }}
          labels={({ datum }: VictoryLabelProps) => getLabelByXName(datum)}
        />
      </VictoryChart>
    </div>
  );
};
