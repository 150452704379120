import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  CreateEmissionFactorProfileDocument,
  CreateEmissionFactorProfileMutation,
  CreateEmissionFactorProfileMutationVariables,
  GetEmissionFactorProfilesDocument,
  GetEmissionFactorProfilesQuery,
  GetEmissionFactorTemplatesDocument,
  GetEmissionFactorTemplatesQuery,
  UpdateEmissionFactorProfileDocument,
  UpdateEmissionFactorProfileMutation,
  UpdateEmissionFactorProfileMutationVariables,
} from "generated/graphql";

export type APIs = {
  getEmissionFactorProfiles: () => Promise<GetEmissionFactorProfilesQuery["emissionFactorProfiles"]>;
  createEmissionFactorProfile: (
    variables: CreateEmissionFactorProfileMutationVariables
  ) => Promise<CreateEmissionFactorProfileMutation["createEmissionFactorProfile"]>;
  updateEmissionFactorProfile: (
    variables: UpdateEmissionFactorProfileMutationVariables
  ) => Promise<UpdateEmissionFactorProfileMutation["updateEmissionFactorProfile"]>;
  getEmissionFactorTemplates: () => Promise<GetEmissionFactorTemplatesQuery["emissionFactorTemplates"]>;
};

export const useAPI = (): APIs => {
  const client = useApolloClient();

  const getEmissionFactorProfiles = useCallback(async () => {
    const queryResponse = await client.query<GetEmissionFactorProfilesQuery>({
      query: GetEmissionFactorProfilesDocument,
    });

    return queryResponse.data.emissionFactorProfiles;
  }, [client]);

  const createEmissionFactorProfile = useCallback(
    async (variables: CreateEmissionFactorProfileMutationVariables) => {
      const response = await client.mutate<
        CreateEmissionFactorProfileMutation,
        CreateEmissionFactorProfileMutationVariables
      >({
        mutation: CreateEmissionFactorProfileDocument,
        variables,
      });

      return response.data?.createEmissionFactorProfile ?? false;
    },
    [client]
  );

  const updateEmissionFactorProfile = useCallback(
    async (variables: UpdateEmissionFactorProfileMutationVariables) => {
      const response = await client.mutate<
        UpdateEmissionFactorProfileMutation,
        UpdateEmissionFactorProfileMutationVariables
      >({
        mutation: UpdateEmissionFactorProfileDocument,
        variables,
      });

      return response.data?.updateEmissionFactorProfile ?? false;
    },
    [client]
  );

  const getEmissionFactorTemplates = useCallback(async () => {
    const queryResponse = await client.query<GetEmissionFactorTemplatesQuery>({
      query: GetEmissionFactorTemplatesDocument,
    });

    return queryResponse.data.emissionFactorTemplates;
  }, [client]);

  return {
    getEmissionFactorProfiles,
    createEmissionFactorProfile,
    updateEmissionFactorProfile,
    getEmissionFactorTemplates,
  };
};
