import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { VictoryPie, VictoryTheme } from "victory";

import { ChartInputWithLabelColor } from "../types";

export type PieChartProps = {
  names: string[];
  data: ChartInputWithLabelColor[];
  labelComponent: React.ReactNode;
};

export const PIE_CHART_WIDTH = 400;
export const PIE_CHART_HEIGHT = 400;

export const PieChart: React.FC<PieChartProps> = (props) => {
  const { names, data, labelComponent } = props;

  return (
    <Grid xs={12} height="100%">
      <svg height="100%" viewBox={`0 0 ${PIE_CHART_WIDTH} ${PIE_CHART_HEIGHT}`}>
        <VictoryPie
          standalone={false}
          theme={VictoryTheme.material}
          categories={{ x: names }}
          data={data.map(({ name, value }) => ({ x: name, y: value }))}
          width={PIE_CHART_WIDTH}
          height={PIE_CHART_HEIGHT}
          innerRadius={120}
          // Override labels sticked to each datum by default
          labelComponent={<></>}
          colorScale={data.map(({ color }) => color!)}
          padAngle={2}
        />
        {labelComponent}
      </svg>
    </Grid>
  );
};
