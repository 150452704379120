import { RecordStatus } from "generated/graphql";
import { SupplyChainManagementRecordRow } from "./types";

export const newSupplyChainManagementRow: SupplyChainManagementRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  asOfDate: "",
  numberOfSuppliers: 0,
  hk: 0,
  china: 0,
  otherRegions: 0,
  engagingDesc: "",
  numberOfEngaged: 0,
  identifyDesc: "",
  promoteDesc: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
