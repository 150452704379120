import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetNf3DataDocument,
  GetNf3DataQuery,
  GetNf3DataQueryVariables,
  SubmitNf3Document,
  SubmitNf3Mutation,
  SubmitNf3MutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getNf3Data: (variables: GetNf3DataQueryVariables) => Promise<GetNf3DataQuery["project"]["normalizationFactor3"]>;
  submitNf3: (variables: SubmitNf3MutationVariables) => Promise<SubmitNf3Mutation["submitNF3"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getNf3Data = useCallback(
    async (variables: GetNf3DataQueryVariables) => {
      const response = await client.query<GetNf3DataQuery, GetNf3DataQueryVariables>({
        query: GetNf3DataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.normalizationFactor3;
    },
    [client]
  );

  const submitNf3 = useCallback(
    async (variables: SubmitNf3MutationVariables) => {
      const response = await client.mutate<SubmitNf3Mutation, SubmitNf3MutationVariables>({
        mutation: SubmitNf3Document,
        variables,
      });

      return response.data?.submitNF3 ?? false;
    },
    [client]
  );

  return {
    getNf3Data,
    submitNf3,
  };
};
