import React from "react";

import FormatterDisplayValue, { FormatterDisplayValueProps } from "./formatter-display-value";

export type DisabledFormatterProps = Omit<FormatterDisplayValueProps, "answer" | "isDisable"> & {
  value: string | number;
};

const DisabledFormatter: React.FC<DisabledFormatterProps> = (props) => {
  return <FormatterDisplayValue answer={props.value} isDisable={true} {...props} />;
};

export default DisabledFormatter;
