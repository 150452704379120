import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newTreeRow, yesNoOptions } from "./consts";
import { useActions } from "./hook/action";
import { TreeRecordRow } from "./types";

const source = IndexType.Tree;

export const TreePage: React.FC = () => {
  const [initRows, setInitRows] = useState<TreeRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);
  const { getData, submitData } = useActions();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "numberOfTree",
        name: intl.formatMessage({ id: "excel.tree.number-tree" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "numberOfTree"),
      },
      {
        key: "isPlantedWithinOrAfter",
        name: intl.formatMessage({ id: "excel.tree.is-planted" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: yesNoOptions,
        formatter: getFormatter(createSelectSingleFormatter(yesNoOptions), "isPlantedWithinOrAfter"),
      },
      {
        key: "isWithinCompanySiteBoundary",
        name: intl.formatMessage({ id: "excel.tree.is-within" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: yesNoOptions,
        formatter: getFormatter(createSelectSingleFormatter(yesNoOptions), "isWithinCompanySiteBoundary"),
      },
      {
        key: "isHigherThan5",
        name: intl.formatMessage({ id: "excel.tree.is-highter" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: yesNoOptions,
        formatter: getFormatter(createSelectSingleFormatter(yesNoOptions), "isHigherThan5"),
      },
    ],
    [companyOptions, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<TreeRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as TreeRecordRow[]);

    return Yup.array(
      Yup.object<Partial<TreeRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        numberOfTree: Yup.number().required("errors.common.require"),
        isPlantedWithinOrAfter: Yup.string().required("errors.common.require"),
        isWithinCompanySiteBoundary: Yup.string().required("errors.common.require"),
        isHigherThan5: Yup.string().required("errors.common.require"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.tree.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newTreeRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[{ property: "numberOfTree", value: 0 }]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
