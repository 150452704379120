import { ClientGroupPage } from "pages/client-group";
import { EmissionFactorProfilePage } from "pages/emission-factor-profile";
import { ProjectPage } from "pages/project-list";
import { DynamicRoute } from "../dynamic-route";

const route = [
  {
    path: "/project-list",
    component: ProjectPage,
    exact: true,
  },
  {
    path: "/client-group",
    component: ClientGroupPage,
    exact: true,
  },
  {
    path: "/emission-factor",
    component: EmissionFactorProfilePage,
    exact: true,
  },
];

export const AdminRoute = DynamicRoute(route);
