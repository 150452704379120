import { RecordStatus } from "generated/graphql";
import { WorkforceRecordRow } from "./hook/types";

export const newWorkforceRow: WorkforceRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  asOfDate: "",
  numberOfEmployees: 0,
  male: 0,
  female: 0,
  fullTime: 0,
  partTime: 0,
  seniorManagement: 0,
  middleLevelManagement: 0,
  generalAndTechnicalEmployees: 0,
  ageGroup1: 0,
  ageGroup2: 0,
  ageGroup3: 0,
  ageGroup4: 0,
  ageGroup5: 0,
  ageGroup6: 0,
  hk: 0,
  china: 0,
  otherRegions: 0,
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
