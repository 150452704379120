import { Reducer } from "react";

import { GridApi, RowNode } from "ag-grid-community";

import cuid from "cuid";
import {
  EmissionFactor,
  EmissionFactorCategory,
  EmissionFactorCode,
  EmissionFactorParam,
  EventAction,
} from "generated/graphql";
import { EmissionFactorDataRow } from "../types";
import { getDefaultRowValue, sortEmissionFactors } from "../utils";
import { getUsedCode } from "./update-used-code";

export enum Actions {
  Init = "INIT",
  CreateRow = "CREATE_ROW",
  CloneRow = "CLONE_ROW",
  UpdateRow = "UPDATE_ROW",
  DeleteRow = "DELETE_ROW",
  SetUnsaved = "SET_UNSAVED",
}

type State = {
  code: EmissionFactorCode[];
  usedCode: string[];
  factors: EmissionFactorDataRow[];
  data: Record<string, EmissionFactorParam>;
  unsaved: boolean;
};

export const initState: State = {
  code: [],
  usedCode: [],
  factors: [],
  data: {},
  unsaved: false,
};

export type ActionType =
  | { type: Actions.Init; emissionFactor: EmissionFactor[]; code: EmissionFactorCode[]; gridApi: GridApi }
  | { type: Actions.CreateRow; gridApi: GridApi; category: EmissionFactorCategory }
  | { type: Actions.CloneRow; gridApi: GridApi; row: EmissionFactorDataRow }
  | { type: Actions.UpdateRow; gridApi: GridApi; field?: string; row: EmissionFactorDataRow; node: RowNode }
  | { type: Actions.DeleteRow; gridApi: GridApi; row: EmissionFactorDataRow }
  | { type: Actions.SetUnsaved; unsaved: boolean };

export const reducer: Reducer<State, ActionType> = (state, action) => {
  switch (action.type) {
    case Actions.Init: {
      const sortedFactors = sortEmissionFactors(action.emissionFactor, action.code);

      const usedCode = sortedFactors.map((factor) => factor.codeId);
      const rows = sortedFactors.map<EmissionFactorDataRow>((ef) => ({
        ...ef,
        rowId: cuid(),
      }));

      action.gridApi.setRowData(rows);

      return { code: action.code, usedCode, factors: rows, data: {}, unsaved: false };
    }

    case Actions.CreateRow: {
      const { gridApi, category } = action;
      const newData = { ...state.data };
      const firstUnusedCode = state.code.find(({ id }) => !state.usedCode.includes(id))?.id;

      if (!firstUnusedCode) return state;

      const newRowData = getDefaultRowValue(category, firstUnusedCode);

      /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
      const { rowId, error, ...rest } = newRowData;
      newData[rowId] = { data: rest, action: EventAction.Create };

      const createdRow = gridApi.applyTransaction({ add: [newRowData] });
      if (createdRow) gridApi.ensureIndexVisible(createdRow.add[0].rowIndex);

      return { ...state, data: newData, usedCode: getUsedCode(gridApi), unsaved: true };
    }

    case Actions.CloneRow: {
      const { gridApi, row: data } = action;
      const newData = { ...state.data };
      const firstUnusedCode = state.code.find(({ id }) => !state.usedCode.includes(id))?.id;

      if (!firstUnusedCode) return state;

      delete data.id;
      const newRowData: EmissionFactorDataRow = { ...data, rowId: cuid(), codeId: firstUnusedCode };
      /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
      const { rowId, id, error, ...rest } = newRowData;
      newData[rowId] = { data: { ...rest }, action: EventAction.Create };

      const clonedRow = gridApi.applyTransaction({ add: [newRowData] });
      if (clonedRow) gridApi.ensureIndexVisible(clonedRow.add[0].rowIndex);

      return { ...state, data: newData, usedCode: getUsedCode(gridApi), unsaved: true };
    }

    case Actions.UpdateRow: {
      const { gridApi, field, node, row } = action;

      const newData = { ...state.data };

      /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
      const { rowId, error, ...rest } = row;

      let eventAction = EventAction.Update;

      if (newData[rowId]) {
        eventAction = newData[rowId].action;
      }

      newData[rowId] = { data: rest, action: eventAction };

      let usedCode = [...state.usedCode];

      if (field) {
        if (field === "codeId") usedCode = getUsedCode(gridApi);

        if (row.error && row.error[field]) {
          delete row.error[field];
          gridApi.refreshCells({ force: true, rowNodes: [node] });
        }
      }

      return { ...state, data: newData, usedCode, unsaved: true };
    }

    case Actions.DeleteRow: {
      const { gridApi, row: data } = action;
      const newData = { ...state.data };

      if (data.id) newData[data.rowId] = { data: { id: data.id }, action: EventAction.Delete };
      else delete newData[data.rowId];

      gridApi.applyTransaction({ remove: [data] });

      return { ...state, data: newData, usedCode: getUsedCode(gridApi), unsaved: true };
    }

    case Actions.SetUnsaved: {
      return { ...state, unsaved: action.unsaved };
    }

    default:
      return state;
  }
};
