import { IndexType } from "generated/graphql";

export const IndexIntl: Record<IndexType, string> = {
  [IndexType.NormalizationFactor1]: "excel.title.nf1",
  [IndexType.NormalizationFactor2]: "excel.title.nf2",
  [IndexType.NormalizationFactor3]: "excel.title.nf3",
  [IndexType.EnergyConsumption]: "excel.title.energy",
  [IndexType.MobileFuel]: "excel.title.fuel-mobile",
  [IndexType.StationaryFuel]: "excel.title.fuel-stationary",
  [IndexType.OtherMobileMachinery]: "excel.title.other-mobile-machinery",
  [IndexType.WaterConsumption]: "excel.title.water",
  [IndexType.OfficePaperUsage]: "excel.title.paper",
  [IndexType.NonHazardousWaste]: "excel.title.non-hazardous",
  [IndexType.HazardousWaste]: "excel.title.hazardous-waste",
  [IndexType.PackagingMaterial]: "excel.title.packaging-material",
  [IndexType.RefrigerantAndFireEx]: "excel.title.refrigerant-and-fire",
  [IndexType.Tree]: "excel.title.tree",
  [IndexType.CommunityInvestment]: "excel.title.communityInvestment",
  [IndexType.DevelopmentAndTraining]: "excel.title.developmentAndTraining",
  [IndexType.InjuryAndFatalities]: "excel.title.injuryAndFatalities",
  [IndexType.LabourResponsibilityPolicy]: "excel.title.labourResponsibilityPolicy",
  [IndexType.Others]: "excel.title.others",
  [IndexType.ProductResponsibility]: "excel.title.productResponsibility",
  [IndexType.ProductResponsibilityPolicy]: "excel.title.productResponsibilityPolicy",
  [IndexType.SupplyChainManagement]: "excel.title.supplyChainManagement",
  [IndexType.TrainingRatio]: "excel.title.trainingRatio",
  [IndexType.Turnover]: "excel.title.turnover",
  [IndexType.Workforce]: "excel.title.tree",
};
