const graphqlServer = process.env.REACT_APP_GRAPHQL_HOST;
const sseServer = process.env.REACT_APP_SSE_HOST;
const downloadServer = process.env.REACT_APP_DL_HOST;
const stepperDocumentServer = String(downloadServer) + String(process.env.REACT_APP_STEPPER_DOC_URL);
const host = process.env.REACT_APP_HOST;
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const jwtCookieName = process.env.REACT_APP_JWT_COOKIE_NAME;
const version = process.env.REACT_APP_VERSION;
const uploadFileSize = parseInt(process.env.REACT_APP_FILE_UPLOAD_SIZE_IN_MB || "") * 1024 * 1024;

export const appName = "αvista";

export const SERVER = {
  GRAPHQL: graphqlServer,
  SSE: sseServer,
  DL: downloadServer,
  STEPPER_DOC: stepperDocumentServer,
  HOST: host,
};

export const COOKIES = {
  domain: cookieDomain,
  name: {
    jwt: jwtCookieName as string,
  },
};

export const MIME_TYPES = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const EXCEL_MIME_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const VERSION = version;

export const FILE_SIZE_LIMIT = uploadFileSize;

export const DATE_FORMAT = "DD/MM/YYYY";

export const PASSWORD_SCHEMA = /^(?=.*[A-Za-z]+)(?=.*[\d]+)[A-Za-z\d!@#$%^&*]*$/;
