import React from "react";

import IconButton from "@mui/material/IconButton";

import { ButtonTooltip } from "components/button-tooltip";

export type DownloadButtonProps = {
  tooltip: string;
  ariaLabel?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  onClick: () => void;
};

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const { tooltip, ariaLabel = "", onClick, disabled, disabledTooltip = "", children } = props;

  return (
    <ButtonTooltip title={disabled ? disabledTooltip : tooltip} arrow>
      {/* Wrap children by span (wrapper element) to make sure tooltip shows even button is disabled */}
      <span style={{ height: "fit-content" }}>
        <IconButton aria-label={ariaLabel} onClick={onClick} disabled={disabled} size="large">
          {children}
        </IconButton>
      </span>
    </ButtonTooltip>
  );
};
