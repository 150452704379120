import React, { useCallback, useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Form, FormikProps } from "formik";
import Select from "react-select";
import { makeStyles } from "tss-react/mui";

import { SwitchField } from "components/formik/switch-field";
import { EmissionFactorProfile, EmissionFactorTemplate } from "generated/graphql";
import { DialogMode } from "utils/dialog";
import { OptionType } from "utils/interfaces";
import { useActions } from "../hook/actions";

export interface IProfileForm {
  id?: string;
  name: string;
  templateId: string;
  cloneFromProfileId?: string;
  isSwitchActiveProjects?: boolean;
}

export const initProfile: IProfileForm = {
  name: "",
  templateId: "",
  cloneFromProfileId: "",
  isSwitchActiveProjects: false,
};

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  errorMsg: {
    color: "red",
  },
  field: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    marginTop: spacing(3),
  },
  fieldHeader: {
    marginBottom: spacing(1),
  },
  textField: {
    minWidth: 300,
  },
  switchText: {
    fontSize: 16,
  },
}));

type ProfileFormProps = FormikProps<IProfileForm> & {
  mode: DialogMode;
  profiles: EmissionFactorProfile[];
};

export const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const { errors, setFieldValue, touched, values, profiles, mode } = props;

  const [templates, setTemplates] = useState<Pick<EmissionFactorTemplate, "id" | "name">[]>([]);

  const { classes } = useStyles();
  const { getTemplates } = useActions();

  const initData = useCallback(async () => {
    // Only create mode needs templates for dropdown selection
    if (mode === DialogMode.CREATE) {
      const result = await getTemplates();

      setTemplates(result);
    }
  }, [mode, getTemplates]);

  useEffect(() => {
    initData();
  }, [initData]);

  const isError = (key: keyof IProfileForm): boolean => !!errors[key] && !!touched[key];

  const renderErrorMessage = (isError: boolean, message: string) => {
    return isError && <div className={classes.errorMsg}>{message}</div>;
  };

  return (
    <Form autoComplete="off">
      <Grid container spacing={3} className={classes.root} alignItems="center">
        <Grid xs={12} className={classes.field}>
          <Typography variant="h4" className={classes.fieldHeader}>
            Profile Name*
          </Typography>
          <FormControl>
            <Field
              required
              id="name"
              name="name"
              value={values.name}
              component={TextField}
              className={classes.textField}
              onChange={(e) => setFieldValue("name", e.target.value)}
              error={isError("name")}
            />
            {renderErrorMessage(isError("name"), errors.name!)}
          </FormControl>
        </Grid>
        {mode === DialogMode.CREATE && (
          <>
            <Grid xs={12} className={classes.field}>
              <Typography variant="h4" className={classes.fieldHeader}>
                Template*
              </Typography>
              <FormControl>
                <Field
                  required
                  id="templateId"
                  name="templateId"
                  value={values.templateId}
                  error={isError("templateId")}
                >
                  {() => (
                    <Select
                      className={classes.textField}
                      options={templates.map((template) => ({ label: template.name, value: template.id }))}
                      onChange={(option) => {
                        setFieldValue("templateId", option ? (option as OptionType).value : "");
                      }}
                    />
                  )}
                </Field>
              </FormControl>
            </Grid>

            <Grid xs={12} className={classes.field}>
              <Typography variant="h4" className={classes.fieldHeader}>
                Clone Data from Profile
              </Typography>
              <FormControl>
                <Field
                  required
                  id="cloneFromProfileId"
                  name="cloneFromProfileId"
                  value={values.cloneFromProfileId}
                  error={isError("cloneFromProfileId")}
                >
                  {() => (
                    <Select
                      className={classes.textField}
                      options={profiles.map((profile) => ({ label: profile.name, value: profile.id }))}
                      onChange={(option) => {
                        if (option) {
                          setFieldValue("cloneFromProfileId", (option as OptionType).value);
                        } else {
                          setFieldValue("cloneFromProfileId", "");
                          setFieldValue("isSwitchActiveProjects", false);
                        }
                      }}
                      isClearable
                    />
                  )}
                </Field>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl className={classes.field}>
                <SwitchField
                  id="isSwitchActiveProjects"
                  name="isSwitchActiveProjects"
                  value={values.isSwitchActiveProjects}
                  text="Switch projects using old profile to new profile"
                  disabled={values.cloneFromProfileId === ""}
                  onChange={(checked) => setFieldValue("isSwitchActiveProjects", checked)}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );
};
