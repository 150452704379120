import React, { createContext, useState } from "react";

import { AlertColor } from "@mui/material/Alert";
import { GlobalSnackbar } from "components/global-snackbar";
import { useIntl } from "react-intl";

export type MessageOptions = {
  message: string;
  /** Default: "success" */
  variant?: AlertColor;
  /** Default: 2000 ms (2s) */
  autoHideDuration?: number;
};

export type ShowMessage = (message: MessageOptions) => void;

const defaultMessageOptions: Required<MessageOptions> = {
  message: "",
  variant: "success",
  autoHideDuration: 2000,
};

export const MessageContext = createContext<{
  showMessage: ShowMessage;
  showUpdateSuccessMessage: () => void;
  showUpdateFailedMessage: (message?: string) => void;
  hideMessage: () => void;
}>({
  showMessage: () => undefined,
  showUpdateSuccessMessage: () => undefined,
  showUpdateFailedMessage: () => undefined,
  hideMessage: () => undefined,
});

export const MessageProvider: React.FC = ({ children }) => {
  const [messageOptions, setMessageOptions] = useState<Required<MessageOptions> & { open: boolean }>({
    ...defaultMessageOptions,
    open: false,
  });

  const intl = useIntl();

  const showMessage = (options: MessageOptions) =>
    setMessageOptions({
      message: options.message,
      variant: options.variant ?? "success",
      autoHideDuration: options.autoHideDuration ?? defaultMessageOptions.autoHideDuration,
      open: true,
    });
  const hideMessage = () => setMessageOptions({ ...messageOptions, open: false });

  const showUpdateSuccessMessage = () =>
    showMessage({ message: intl.formatMessage({ id: "message.common.update-success" }) });
  const showUpdateFailedMessage = (message?: string) =>
    showMessage({ message: message ?? intl.formatMessage({ id: "message.common.update-error" }), variant: "error" });

  return (
    <MessageContext.Provider value={{ showMessage, showUpdateSuccessMessage, showUpdateFailedMessage, hideMessage }}>
      <GlobalSnackbar {...messageOptions} onClose={hideMessage} />
      {children}
    </MessageContext.Provider>
  );
};
