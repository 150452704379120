import React, { useCallback, useState } from "react";

import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  SvgIconComponent,
  Warning as WarningIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  AlertColor,
  IconButton,
  Paper,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { amber, blue, green } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";
import { white } from "theme/color";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: palette.error.dark,
  },
  info: {
    backgroundColor: blue[500],
  },
  warning: {
    backgroundColor: amber[700],
  },
  snackbarContent: {
    minWidth: 288,
    padding: spacing(1.5, 2),
    color: white,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    padding: spacing(0),
  },
}));

type OpenSnackbarOptions = {
  variant?: AlertColor;
  autoHideDuration?: number;
  alwaysOnScreen?: boolean;
  closable?: boolean;
};

type UseSnackbarParams = {
  autoHideDuration?: number;
};

export type UseSnackbarReturns = {
  openSnackbar: (snackbarMessage: string, options?: OpenSnackbarOptions) => void;
  closeSnackbar: () => void;
  Snackbar: React.VFC<SnackbarProps>;
};

export type SnackbarProps = Pick<MuiSnackbarProps, "anchorOrigin">;

const messageIcon: Record<AlertColor, SvgIconComponent> = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export const useSnackbar = (params?: UseSnackbarParams): UseSnackbarReturns => {
  const { autoHideDuration = 3000 } = params ?? {};

  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState<number | null>(autoHideDuration);
  const [variant, setVariant] = useState<AlertColor>("success");
  const [isClosable, setIsClosable] = useState(false);

  const { classes, cx } = useStyles();

  const openSnackbar = useCallback((snackbarMessage: string, options?: OpenSnackbarOptions) => {
    const { alwaysOnScreen: isStayOnScreen, autoHideDuration: oneTimeDurration, variant, closable } = options ?? {};

    if (isStayOnScreen) {
      setDuration(null);
      setIsClosable(true);
    } else if (oneTimeDurration && oneTimeDurration > 0) {
      setDuration(oneTimeDurration);
    }

    if (closable) {
      setIsClosable(closable);
    }

    if (variant) {
      setVariant(variant);
    }

    setMessage(snackbarMessage);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setDuration(autoHideDuration);
      setVariant("success");
      setIsClosable(false);
      setIsOpen(false);
    },
    [autoHideDuration]
  );

  const closeSnackbar = useCallback(() => handleClose(), [handleClose]);

  const Snackbar: React.VFC<SnackbarProps> = useCallback(
    (props) => {
      const Icon = messageIcon[variant];

      return (
        <MuiSnackbar
          open={isOpen}
          onClose={handleClose}
          autoHideDuration={duration}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          {...props}
        >
          <div>
            <Paper className={cx(classes.snackbarContent, classes[variant])}>
              <Grid container alignItems="center">
                <Grid flexGrow={1}>
                  <span id="client-snackbar" className={classes.message}>
                    <Icon className={cx(classes.icon, classes.iconVariant)} />
                    {message}
                  </span>
                </Grid>
                {isClosable && (
                  <Grid>
                    <IconButton size="small" onClick={handleClose} className={classes.closeButton}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </div>
        </MuiSnackbar>
      );
    },
    [classes, cx, duration, handleClose, isOpen, message, variant, isClosable]
  );

  return { openSnackbar, closeSnackbar, Snackbar };
};
