import React, { useContext } from "react";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { Role } from "generated/graphql";
import { AuthContext } from "provider/auth";
import avatar from "static/images/avatar.jpg";

const useStyles = makeStyles()(({ spacing }) => ({
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    margin: spacing(2),
  },
  avatar: {
    width: "100px",
    height: "100px",
  },
  nameText: {
    marginTop: spacing(2),
  },
  bioText: {},
}));

export const Profile: React.FC = () => {
  const { classes } = useStyles();
  const { me } = useContext(AuthContext);
  const intl = useIntl();
  return (
    <div className={classes.profile}>
      <Avatar alt="avatar" className={classes.avatar} src={avatar} />
      <Typography className={classes.nameText} variant="h6">
        {me.user && me.user.name}
      </Typography>
      <Typography className={classes.bioText} variant="caption">
        {me.role === Role.ClientAdmin && intl.formatMessage({ id: "layout.role.administrator" })}
        {me.role === Role.ClientStaff && intl.formatMessage({ id: "layout.role.normal-user" })}
        {me.role === Role.Admin && "System Admin"}
      </Typography>
    </div>
  );
};
