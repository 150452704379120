import React from "react";

import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { StepperStyleList } from "components/stepper-style-list";
import { EmissionFactorCategory } from "generated/graphql";
import { CategoryLabels } from "../data";

const useStyles = makeStyles()(({ spacing }) => ({
  button: {
    marginTop: spacing(),
    marginRight: spacing(),
  },
  actionsContainer: {
    marginBottom: spacing(2),
  },
}));

type EmissionFactorStepperProps = {
  items: EmissionFactorCategory[];
  onClick: (category: EmissionFactorCategory) => void;
};

export const EmissionFactorStepper: React.FC<EmissionFactorStepperProps> = (props) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <StepperStyleList
      items={props.items.map((category) => ({
        labelProps: { StepIconProps: { completed: true, active: false } },
        labelContent: <span>{intl.formatMessage({ id: CategoryLabels[category] })}</span>,
        content: (
          <div className={classes.actionsContainer}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.onClick(category)}
                className={classes.button}
              >
                {intl.formatMessage({ id: "button.stepper.go" })}
              </Button>
            </div>
          </div>
        ),
      }))}
    />
  );
};
