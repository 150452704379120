import React from "react";

import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, FieldConfig } from "formik";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  inlineTypography: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

export type SwitchFieldProps = FieldConfig & {
  id: string;
  text: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const SwitchField: React.FC<SwitchFieldProps> = (props) => {
  const { text, disabled, onChange, ...fieldProps } = props;

  const { classes } = useStyles();

  return (
    <Field {...fieldProps}>
      {() => (
        <Grid container alignContent="center">
          <Switch
            checked={fieldProps.value}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled || false}
          />
          <Typography variant="body1" className={classes.inlineTypography}>
            {text}
          </Typography>
        </Grid>
      )}
    </Field>
  );
};
