import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { materialTypeOptions, newPackagingMaterialRow, tips } from "./consts";
import { useActions } from "./hook/action";
import { PackagingMaterialRecordRow } from "./types";

const source = IndexType.PackagingMaterial;

export const PackagingMaterialPage: React.FC = () => {
  const [initRows, setInitRows] = useState<PackagingMaterialRecordRow[]>([]);

  const { openDialog, Dialog, DialogType } = useDialog();
  const { setLoading } = useContext(LoadingContext);
  const intl = useIntl();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { getData, submitData } = useActions();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "packagingMaterialType",
        name: intl.formatMessage({ id: "excel.material.type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: materialTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(materialTypeOptions), "packagingMaterialType"),
        isCreatable: true,
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.material.start-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "startDate"),
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.material.end-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "endDate"),
      },
      {
        key: "openingBalance",
        name: intl.formatMessage({ id: "excel.material.opening-balance" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "openingBalance"),
      },
      {
        key: "quantityPurchased",
        name: intl.formatMessage({ id: "excel.material.quantity-purchased" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityPurchased"),
      },
      {
        key: "endingBalance",
        name: intl.formatMessage({ id: "excel.material.ending-balance" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "endingBalance"),
      },
      {
        key: "quantityUsed",
        name: intl.formatMessage({ id: "excel.material.quantity-used" }),
        width: 200,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "quantityUsed"),
      },
    ],
    [companyOptions, maxDate, minDate, intl]
  );

  const validationSchema = Yup.lazy<Partial<PackagingMaterialRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as PackagingMaterialRecordRow[]);

    return Yup.array(
      Yup.object<Partial<PackagingMaterialRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        packagingMaterialType: Yup.string().required("errors.common.require"),
        startDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        endDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        openingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityPurchased: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        endingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityUsed: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
      }).required()
    ).defined();
  });

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<PackagingMaterialRecordRow> = (rows, updatedItem) => {
    const { openingBalance, quantityPurchased, endingBalance } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(openingBalance, quantityPurchased, endingBalance)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        rows[i].quantityUsed = +(+rows[i].openingBalance + +rows[i].quantityPurchased - +rows[i].endingBalance);

        if (+rows[i].quantityUsed < 0) {
          openDialog({ content: intl.formatMessage({ id: "errors.material.quantity-used" }), type: DialogType.ALERT });
        }
      }
    }

    return rows;
  };

  const introContent = intl.formatMessage({ id: "excel.packaging-material.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newPackagingMaterialRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          ...[
            "openingBalance",
            "quantityPurchased",
            "endingBalance",
            "quantityUsed",
            "recyclingMaterialUsageAmount",
            "recyclingAmount",
            "quantityUsedAfterRecycling",
          ].map((l) => ({ property: l, value: 0 })),
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
