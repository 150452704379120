import React from "react";

import ErrorOutline from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { ButtonTooltip } from "components/button-tooltip";

const useStyles = makeStyles()(({ spacing }) => ({
  button: {
    padding: spacing(0.9),
  },
  tooltip: {
    whiteSpace: "pre-wrap",
  },
}));

interface ErrorMessagePopupProps {
  message: string;
}

export const ErrorMessagePopup = React.memo((props: ErrorMessagePopupProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <div>
      <ButtonTooltip
        title={
          <div className={classes.tooltip}>
            {intl.formatMessage({ id: props.message, defaultMessage: props.message })}
          </div>
        }
      >
        <IconButton classes={{ root: classes.button }} size="large">
          <ErrorOutline fontSize="small" />
        </IconButton>
      </ButtonTooltip>
    </div>
  );
});
