import React from "react";

import TablePagination from "@mui/material/TablePagination";
import { useIntl } from "react-intl";

export type PaginationProps = {
  pageOptions: number[];
  count: number;
  pageSize: number;
  pageIndex: number;
  gotoPage: (pageNumber: number) => void;
  setPageSize: (size: number) => void;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const intl = useIntl();
  return (
    <TablePagination
      labelRowsPerPage={intl.formatMessage({ id: "data-table.rows-per-page" })}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} , ${intl.formatMessage({ id: "data-table.total" })} ${count}`
      }
      rowsPerPageOptions={props.pageOptions}
      component={"div"}
      count={props.count}
      rowsPerPage={props.pageSize}
      page={props.pageIndex}
      backIconButtonProps={{
        "aria-label": "Previous Page",
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page",
      }}
      onPageChange={(event, newPage) => {
        props.gotoPage(newPage);
      }}
      onRowsPerPageChange={(event) => {
        props.setPageSize(parseInt(event.target.value, 10));
        props.gotoPage(0);
      }}
    />
  );
};
