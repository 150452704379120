import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import {
  FuelType,
  fuelTypeOptions,
  fuelUnitOptions,
  newMobileFuelRow,
  tips,
  transportationOwnershipOptions,
  TransportationType,
  transportationTypeOptions,
} from "./consts";
import { useActions } from "./hook/action";
import { MobileFuelRecordRow } from "./types";

const source = IndexType.MobileFuel;

export const MobileFuelPage: React.FC = () => {
  const [initRows, setInitRows] = useState<MobileFuelRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "transportationLicense",
        name: intl.formatMessage({ id: "excel.fuel-mobile.license" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        frozen: true,
        formatter: getFormatter(Formatter, "transportationLicense"),
      },
      {
        key: "transportationOwnership",
        name: intl.formatMessage({ id: "excel.fuel-mobile.ownership" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: transportationOwnershipOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(transportationOwnershipOptions), "transportationOwnership"),
      },
      {
        key: "transportationType",
        name: intl.formatMessage({ id: "excel.fuel-mobile.transportation-type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: transportationTypeOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(transportationTypeOptions), "transportationType"),
      },
      {
        key: "fuelType",
        name: intl.formatMessage({ id: "excel.fuel-mobile.fuel-type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: fuelTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(fuelTypeOptions), "fuelType"),
        isCreatable: true,
      },
      {
        key: "fuelConsumption",
        name: intl.formatMessage({ id: "excel.fuel-mobile.fuel-consumption" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "fuelConsumption"),
      },
      {
        key: "fuelUnit",
        name: intl.formatMessage({ id: "excel.fuel-mobile.fuel-unit" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: fuelUnitOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(fuelUnitOptions), "fuelUnit"),
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.fuel-mobile.start-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "startDate"),
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.fuel-mobile.end-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "endDate"),
      },
      {
        key: "yearBeginDistanceTravelled",
        name: intl.formatMessage({ id: "excel.fuel-mobile.year-begin" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "yearBeginDistanceTravelled"),
      },
      {
        key: "yearEndDistanceTravelled",
        name: intl.formatMessage({ id: "excel.fuel-mobile.year-end" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "yearEndDistanceTravelled"),
      },
      {
        key: "estimatedDistanceTravelled",
        name: intl.formatMessage({ id: "excel.fuel-mobile.distance" }),
        width: 200,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "estimatedDistanceTravelled"),
      },
    ],
    [companyOptions, maxDate, minDate, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<MobileFuelRecordRow> = (rows, updatedItem) => {
    const { yearBeginDistanceTravelled, yearEndDistanceTravelled, transportationType, fuelType } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(yearBeginDistanceTravelled, yearEndDistanceTravelled, transportationType, fuelType)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        if (rows[i].fuelType === FuelType.Electric) rows[i].estimatedDistanceTravelled = 0;
        else
          rows[i].estimatedDistanceTravelled = +(
            +rows[i].yearEndDistanceTravelled - +rows[i].yearBeginDistanceTravelled
          );
        if (rows[i].transportationType === TransportationType.Ship)
          openDialog({ content: intl.formatMessage({ id: "errors.fuel-mobile.ship" }), type: DialogType.ALERT });
      }
    }

    return rows;
  };

  const validationSchema = Yup.lazy<Partial<MobileFuelRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as MobileFuelRecordRow[]);

    return Yup.array(
      Yup.object<Partial<MobileFuelRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        startDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        endDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        transportationLicense: Yup.string().required("errors.common.require"),
        transportationType: Yup.string().required("errors.common.require"),
        fuelType: Yup.string().required("errors.common.require"),
        fuelConsumption: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        fuelUnit: Yup.string().required("errors.common.require"),
        yearBeginDistanceTravelled: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        yearEndDistanceTravelled: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        estimatedDistanceTravelled: Yup.number()
          .required("errors.common.require")
          .test("more-zero", "errors.common.more-zero", function (value) {
            const row = this.parent as MobileFuelRecordRow;
            if (row.transportationType === TransportationType.Ship || row.fuelType === FuelType.Electric) {
              return true;
            }

            return value! > 0;
          }),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.fuel-mobile.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newMobileFuelRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "fuelConsumption", value: 0 },
          { property: "yearBeginDistanceTravelled", value: 0 },
          { property: "yearEndDistanceTravelled", value: 0 },
          { property: "estimatedDistanceTravelled", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
