import Cookies from "js-cookie";

import { COOKIES } from "./const";

export const isLogin = (): boolean => {
  return !!Cookies.get(COOKIES.name.jwt);
};

export const generatePassword = (number: number): string => {
  const password = Math.random()
    .toString(36)
    .slice(number * -1);
  return password;
};
