import React from "react";

import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { SearchInput } from "./serach-input";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    padding: spacing(3),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: spacing(),
  },
  searchInput: {
    marginRight: spacing(),
  },
}));

export type ToolbarProps = {
  value: string;
  onChange: (value: string) => void;
};

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { value, onChange } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <SearchInput
          placeholder={intl.formatMessage({ id: "data-table.search" }) + "..."}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};
