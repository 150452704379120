import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newProductResponsibilityRow } from "./consts";
import { useActions } from "./hook/action";
import { ProductResponsibilityRecordRow } from "./types";

const source = IndexType.ProductResponsibility;

export const ProductResponsibilityPage: React.FC = () => {
  const [initRows, setInitRows] = useState<ProductResponsibilityRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { Dialog, openDialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "asOfDate",
        name: intl.formatMessage({ id: "excel.workforce.asOfDate" }),
        width: 150,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "asOfDate"),
      },
      {
        key: "numberOfProducts",
        name: intl.formatMessage({ id: "excel.productResponsibility.numberOfProducts" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "numberOfProducts"),
      },
      {
        key: "unitOfProduct",
        name: intl.formatMessage({ id: "excel.productResponsibility.unitOfProduct" }),
        editable: true,
        resizable: true,
        width: 200,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "unitOfProduct"),
      },
      {
        key: "numberOfRetrieved",
        name: intl.formatMessage({ id: "excel.productResponsibility.numberOfRetrieved" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "numberOfRetrieved"),
      },
      {
        key: "numberOfComplaints",
        name: intl.formatMessage({ id: "excel.productResponsibility.numberOfComplaints" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "numberOfComplaints"),
      },
    ],
    [intl, companyOptions, minDate, maxDate]
  );

  const introContent = intl.formatMessage({ id: "excel.productResponsibility.guide" });
  const steps = getSteps(intl);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<ProductResponsibilityRecordRow> = (rows, updatedItem) => {
    const { numberOfComplaints } = updatedItem.updated;
    if (numberOfComplaints && numberOfComplaints > 0) {
      openDialog({
        content: intl.formatMessage({ id: "error.productResponsibility.numberOfComplaints" }),
        type: DialogType.ALERT,
      });
    }

    return rows;
  };

  const validationSchema = Yup.lazy<Partial<ProductResponsibilityRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as ProductResponsibilityRecordRow[]);

    return Yup.array(
      Yup.object<Partial<ProductResponsibilityRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        asOfDate: Yup.string(),
        numberOfProducts: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),
        unitOfProduct: Yup.string().test("not-null", "error.productResponsibility.unitOfProduct", function (value) {
          return this.parent.numberOfProducts > 0 ? !!value : true;
        }),
        numberOfComplaints: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),
        numberOfRetrieved: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newProductResponsibilityRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "asOfDate", value: "" },
          { property: "numberOfProducts", value: 0 },
          { property: "numberOfComplaints", value: 0 },
          { property: "numberOfRetrieved", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
