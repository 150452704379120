import React, { useContext, useEffect, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { IntroModal } from "components/intro-modal";
import {
  DataTable,
  HeaderAddButton,
  StyledTableCell,
  useDebounceSearch,
  usePaginationMisc,
  useSort,
} from "components/react-table";
import {
  useCreateUserMutation,
  useGetUserListLazyQuery,
  useResetUserPasswordMutation,
  useUpdateUserMutation,
} from "generated/graphql";
import { useDialog } from "hook";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { DateFormat, toDateString } from "utils/date-time";
import { DialogMode } from "utils/dialog";
import { getLang } from "utils/local-storage";
import { Yup } from "utils/yup";
import { Notes } from "./components/notes";
import { UserForm } from "./components/user-form";
import { defaultFormValue } from "./consts";
import { UserFormFields, UserRowItem } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: spacing(1),
  },
  snackBar: {
    margin: spacing(1),
    width: "15%",
  },
  inlineFlex: {
    display: "inline-flex",
  },
}));

export const UserPage: React.FC = () => {
  const { classes } = useStyles();
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState(DialogMode.CREATE);
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [data, setData] = useState<UserRowItem[]>([]);

  const { openDialog, Dialog, DialogType } = useDialog();

  const client = useApolloClient();
  const { me } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showUpdateSuccessMessage, showUpdateFailedMessage } = useContext(MessageContext);

  const {
    state: { pageSize, pageIndex, totalCount },
    setPageSize,
    setPageIndex,
    setTotalCount,
  } = usePaginationMisc();
  const { sortBy, setSortBy, orderBy } = useSort();
  const [search, setSearch] = useDebounceSearch(() => {
    setPageIndex(0);
    getUser({ variables: { clientGroupId: me.clientGroup!.id, pageSize, pageIndex, keyword: search, orderBy } });
  });

  const [getUser, getUserState] = useGetUserListLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ userList: { data, count } }) => {
      const rows = data.map<UserRowItem>((user) => ({
        id: user.id,
        loginId: user.loginId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        title: user.title,
        createdAt: toDateString(user.createdAt, DateFormat.Date),
        updatedAt: toDateString(user.updatedAt, DateFormat.Date),
      }));

      setData(rows);
      setTotalCount(count);
    },
  });

  const [createUser, createUserState] = useCreateUserMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ createUser: { loginId, password } }) => {
      handleDialogClose();
      getUser({ variables: { clientGroupId: me.clientGroup!.id, pageSize, pageIndex, keyword: search, orderBy } });
      showUpdateSuccessMessage();
      showResetPasswordDialog(loginId, password);
    },
    onError: () => showUpdateFailedMessage(),
  });

  const [updateUser, updateUserState] = useUpdateUserMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      getUser({ variables: { clientGroupId: me.clientGroup!.id, pageSize, pageIndex, keyword: search, orderBy } });
      showUpdateSuccessMessage();
    },
    onError: () => showUpdateFailedMessage(),
  });

  const [resetPassword, resetPasswordState] = useResetUserPasswordMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ resetUserPassword: { loginId, password } }) => {
      handleDialogClose();
      showResetPasswordDialog(loginId, password);
    },
    onError: () => showUpdateFailedMessage(),
  });

  useEffect(() => {
    const isLoading =
      getUserState.loading || createUserState.loading || updateUserState.loading || resetPasswordState.loading;
    setLoading(isLoading);
  }, [setLoading, getUserState.loading, createUserState.loading, updateUserState.loading, resetPasswordState.loading]);

  useEffect(() => {
    if (!me.clientGroup?.id) return;
    getUser({ variables: { clientGroupId: me.clientGroup.id, pageSize, pageIndex, keyword: search, orderBy } });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [me.clientGroup, pageSize, pageIndex, sortBy]);

  const showResetPasswordDialog = (loginId: string, password: string) => {
    const message = `${intl.formatMessage({ id: "user.login-id" })}: ${loginId}\n${intl.formatMessage({
      id: "user.password",
    })}: ${password}`;
    openDialog({ content: message, type: DialogType.ALERT });
  };

  const handleDialogOpen = (row?: UserRowItem) => {
    if (row) {
      setDialogMode(DialogMode.EDIT);
      setFormValue({ ...row });
    } else {
      setDialogMode(DialogMode.CREATE);
      setFormValue(defaultFormValue);
    }
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setFormValue(defaultFormValue);
    setIsOpen(false);
  };

  const handleResetPassword = (userId: string) => resetPassword({ variables: { userId } });

  const handleSubmit = (data: UserFormFields) => {
    if (dialogMode === DialogMode.EDIT) {
      updateUser({
        variables: {
          userId: data.id!,
          data: {
            loginId: data.loginId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            title: data.title,
            password: data.password1 && data.password1 === data.password2 ? data.password1 : undefined,
          },
        },
      });
    } else {
      createUser({
        variables: {
          clientGroupId: me.clientGroup!.id,
          data: {
            loginId: data.loginId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            title: data.title,
          },
        },
      });
    }
  };

  const columns = useMemo(() => {
    const isEn = getLang() === "en";

    const firstName = {
      accessor: "firstName",
      Header: intl.formatMessage({ id: "user.first-name" }),
    };
    const lastName = {
      accessor: "lastName",
      Header: intl.formatMessage({ id: "user.last-name" }),
    };

    return [
      {
        accessor: "loginId",
        Header: intl.formatMessage({ id: "user.login-id" }),
      },
      // English: display first name -> last name
      // Chinese, display last name (姓) -> first name (名)
      isEn ? firstName : lastName,
      isEn ? lastName : firstName,
      {
        accessor: "email",
        Header: intl.formatMessage({ id: "user.email" }),
      },
      {
        accessor: "phone",
        Header: intl.formatMessage({ id: "user.phone" }),
      },
      {
        accessor: "title",
        Header: intl.formatMessage({ id: "user.title" }),
      },
    ];
  }, [intl]);

  const validationSchema = Yup.object<UserFormFields>({
    loginId: Yup.string()
      .trim(intl.formatMessage({ id: "errors.user-login-id-format" }))
      .strict(true)
      .matches(
        /^([a-zA-Z\d])+((\.|_|[0-9]+)?([a-zA-Z0-9]+))+$/,
        intl.formatMessage({ id: "errors.user-login-id-format" })
      )
      .required(intl.formatMessage({ id: "errors.user-login-id-format" })),
    firstName: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    lastName: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    phone: Yup.string()
      .min(8, intl.formatMessage({ id: "errors.common.value-invalid" }))
      .matches(/^[0-9]{8,}$/, intl.formatMessage({ id: "errors.common.value-invalid" }))
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    title: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    email: Yup.string()
      .trim(intl.formatMessage({ id: "errors.common.include-space" }))
      .strict(true)
      .email(intl.formatMessage({ id: "errors.common.value-invalid" }))
      .required(intl.formatMessage({ id: "errors.common.value-invalid" })),
    password1: Yup.string()
      .test(
        "password-length",
        intl.formatMessage({ id: "errors.common.password-length" }),
        (password) => !password || password.length >= 8
      )
      .test(
        "password-format",
        intl.formatMessage({ id: "errors.common.password-format" }),
        (password) => !password || /^(?=.*[A-Za-z]+)(?=.*[\d]+)[A-Za-z\d]{8,}$/.test(password)
      ),
    password2: Yup.string().test("check", intl.formatMessage({ id: "errors.common.value-invalid" }), function () {
      return this.parent.password1 ? this.parent.password1 === this.parent.password2 : true;
    }),
  }).required();

  const renderedRow = (row, rowProps) => (
    <TableRow {...rowProps}>
      <TableCell>
        <div className={classes.inlineFlex}>
          <IconButton
            aria-label="Edit"
            className={classes.fab}
            onClick={() => handleDialogOpen(row.original)}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </div>
      </TableCell>
      {row.cells.map((cell) => {
        const Formatter = cell.render("Cell");
        return (
          <StyledTableCell key={cell.column.id} {...cell.getCellProps()}>
            <div>{Formatter}</div>
          </StyledTableCell>
        );
      })}
    </TableRow>
  );

  return (
    <>
      <IntroModal>
        {intl.formatMessage(
          { id: "user.guide" },
          {
            br: <br />,
            menuIcon: <MenuIcon />,
            deleteIcon: <DeleteIcon />,
            editIcon: <EditIcon />,
          }
        )}
      </IntroModal>
      <Notes />

      <DataTable
        data={data}
        columns={columns}
        state={{ pageSize, pageIndex, totalCount }}
        goToPage={setPageIndex}
        setPageSize={setPageSize}
        renderRow={renderedRow}
        HeaderActionComponent={<HeaderAddButton onClick={() => handleDialogOpen()} />}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSortByChange={setSortBy}
      />

      <UserForm
        mode={dialogMode}
        isOpen={isOpen}
        data={formValue}
        schema={validationSchema}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        onResetPassword={handleResetPassword}
      />
      <Dialog />
    </>
  );
};
