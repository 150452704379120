import React, { useEffect } from "react";

import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import { COOKIES } from "utils/const";

export const LogoutPage: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    Cookies.remove(COOKIES.name.jwt, { path: "/", domain: COOKIES.domain });
    history.push("/login");
  }, [history]);

  return <></>;
};
