import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { CompactSelect } from "components/compact-select";
import { formatNumberToPercentage } from "utils/format";
import { OptionType } from "utils/interfaces";
import { TitleValueLayout } from "../blocks/title-value-layout";
import { DashboardColor, TurnoverRateKey } from "../consts";
import { StyledContainer } from "../containers/styled-container";

export type TurnoverRateBlockProps = {
  title: string;
  data: Record<TurnoverRateKey, number>;
  rateMode: TurnoverRateKey;
  rateModeOptions: OptionType[];
  onChangeRateMode: (rateMode: TurnoverRateKey) => void;
};

export const TurnoverRateBlock: React.FC<TurnoverRateBlockProps> = (props) => {
  const { title, data, rateMode, rateModeOptions, onChangeRateMode } = props;

  const intl = useIntl();

  return (
    <StyledContainer>
      <Grid container height="100%" direction="column" spacing={0} alignItems="center" wrap="nowrap">
        <Grid xs={12} flexGrow={3}>
          <TitleValueLayout
            title={title}
            value={formatNumberToPercentage(data[rateMode], 1)}
            footer={intl.formatMessage({ id: "dashboard.social.turnover.rate.select.description" })}
            style={{
              align: "center",
              title: {
                color: DashboardColor.text.grey,
                bold: true,
              },
              subTitle: {
                color: DashboardColor.text.grey,
              },
              value: {
                color: DashboardColor.text.grey,
                bold: true,
              },
              footer: {
                color: DashboardColor.text.grey,
                variant: "body2",
              },
            }}
          />
        </Grid>
        <Grid xs={12} paddingY={1}>
          <CompactSelect
            height={26}
            value={rateModeOptions.find((o) => o.value === rateMode)}
            options={rateModeOptions}
            onChange={(option: OptionType) => onChangeRateMode(option.value as TurnoverRateKey)}
          />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
