import { useCallback, useContext } from "react";

import { Scope3Category } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { DateFormat, toDateString } from "utils/date-time";
import { DataRecordRowItem, Scope3DataFormFields } from "../types";
import { useAPI } from "./api";

type Actions = {
  getData: () => Promise<{
    permission: Scope3Category[];
    data: DataRecordRowItem[];
  }>;
  uploadData: (data: Scope3DataFormFields) => Promise<boolean>;
};

export const useActions = (): Actions => {
  const { me } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const { getScope3Data, uploadScope3Data } = useAPI();

  const getData = useCallback(async () => {
    const defaultReturn = { permission: [], data: [] };
    if (!me.project?.scope3Permission) return defaultReturn;

    try {
      setLoading(true);

      const permission = me.project.scope3Permission.categories;
      const result = await getScope3Data({ projectId: me.project.id });

      const data = result.map<DataRecordRowItem>(({ id, category, file, updatedAt }) => ({
        id,
        category,
        name: file.originalName,
        updatedAt: toDateString(updatedAt, DateFormat.Date),
        download: file.storageName,
      }));

      return { permission, data };
    } catch (e) {
      return defaultReturn;
    } finally {
      setLoading(false);
    }
  }, [getScope3Data, me, setLoading]);

  const uploadData = useCallback(
    async ({ category, file }: Scope3DataFormFields) => {
      if (!me.project) return false;

      try {
        setLoading(true);

        await uploadScope3Data({ projectId: me.project.id, category, file });

        return true;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [uploadScope3Data, me, setLoading]
  );

  return { getData, uploadData };
};
