import React from "react";

import { VictoryLabelStyleObject, VictoryLabel } from "victory";
import { PIE_CHART_HEIGHT, PIE_CHART_WIDTH } from "./pie-chart";

export type PieChartLabelProps = {
  xByRatio: number;
  yByRatio: number;
  text: string;
  style?: Pick<VictoryLabelStyleObject, "fontSize" | "fill">;
};

export const PieChartLabel: React.FC<PieChartLabelProps> = (props) => {
  const { xByRatio, yByRatio, text, style } = props;

  return (
    <VictoryLabel
      textAnchor="middle"
      verticalAnchor="middle"
      x={PIE_CHART_WIDTH * xByRatio}
      y={PIE_CHART_HEIGHT * yByRatio}
      text={text}
      style={style}
    />
  );
};
