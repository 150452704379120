import { useMemo } from "react";

import { useIntl } from "react-intl";
import { Property } from "csstype";

import { PercentageDatum } from "generated/graphql";
import { DashboardColor } from "../components/consts";
import { PieChartWithLargestValueLabelProps } from "../components/charts/pie-chart/pie-chart-with-largest-value-label";

type BreakdownHelperParams = {
  data?: PercentageDatum[];
  color: Property.Fill[];
  remain?: number;
};

type BreakdownHelperReturns = {
  groupedData: PieChartWithLargestValueLabelProps["data"];
  isGroupedDataVisible: boolean;
};

export const useBreakdownHelper = (params: BreakdownHelperParams): BreakdownHelperReturns => {
  const { data = [], color, remain = 3 } = params;

  const intl = useIntl();

  const groupedData = useMemo<PieChartWithLargestValueLabelProps["data"]>(() => {
    data.sort((a, b) => b.value - a.value);

    let convertedData = data;
    if (data.length > remain) {
      const dataInOthers = convertedData.slice(remain);
      const othersPieChartDatum: PercentageDatum = {
        name: intl.formatMessage({ id: "dashboard.common.pie.legend.others" }),
        value: dataInOthers.map((d) => d.value).reduce((a, b) => a + b, 0),
        percentage: dataInOthers.map((d) => d.percentage).reduce((a, b) => a + b, 0),
      };

      convertedData = convertedData.slice(0, remain);
      convertedData.push(othersPieChartDatum);
    }

    return convertedData.map(({ name, value }, i) => ({
      name,
      value,
      color: color[i] ?? DashboardColor.chart.grey1,
    }));
  }, [color, data, intl, remain]);

  const isGroupedDataVisible = groupedData.length > 0 && groupedData.some((d) => d.value > 0);
  return { groupedData, isGroupedDataVisible };
};
