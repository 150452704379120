import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import { IndexType, NormalizationFactor1Record, ProjectCompany, Role } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, defaultMoment, toDate, toMoment } from "utils/date-time";
import { CommonReportRecordRow, OptionType } from "utils/interfaces";
import { useAPI } from "..";

type Nf1Data = {
  company: ProjectCompany;
  record: NormalizationFactor1Record[];
};

type ProjectPeriod = {
  startDate: Date;
  endDate: Date;
};

type AddressPeriodItem = {
  address: string;
  minDate: string;
  maxDate: string;
};

type CommonReportRecordRowWithAddress = CommonReportRecordRow & { address: string };

export type ValidateCompanyResult = { isValid: boolean; error: string };

type UseProjectCompanyReturns = {
  companyOptions: OptionType[];
  addressOptions: Record<string, OptionType[]>;
  addressPeriod: Record<string, AddressPeriodItem[]>;
  projectPeriod: ProjectPeriod;
  minDate: Date;
  maxDate: Date;
  isValidAllCompanies: (records: CommonReportRecordRow[]) => ValidateCompanyResult;
  isValidAllCompaniesAddress: (
    records: CommonReportRecordRowWithAddress[],
    selfRow: CommonReportRecordRowWithAddress
  ) => ValidateCompanyResult;
  includedInCompanyIndexes: Record<string, boolean>;
};

export const useProjectCompany = (indexType: IndexType): UseProjectCompanyReturns => {
  const { getProjectInfo } = useAPI();
  const intl = useIntl();
  const { me } = useContext(AuthContext);
  const [projectPeriod, setProjectPeriod] = useState<ProjectPeriod>({
    startDate: defaultMoment.toDate(),
    endDate: defaultMoment.toDate(),
  });
  const [nf1Data, setNf1Data] = useState<Nf1Data[]>([]);

  const initData = useCallback(async () => {
    if (!me.project || !me.user) return;

    const { project, permission } = await getProjectInfo({ projectId: me.project.id, userId: me.user.id });

    setProjectPeriod({
      startDate: toMoment(project.startDate).startOf("day").toDate(),
      endDate: toMoment(project.endDate).startOf("day").toDate(),
    });

    let companies: ProjectCompany[] = [];
    if (me.role === Role.ClientAdmin) {
      companies = project.projectCompany as ProjectCompany[];
    } else if (permission?.projectCompanies) {
      companies = permission.projectCompanies as ProjectCompany[];
    }

    if (companies.length === 0) return;

    const newNf1Data = companies
      .filter((i) => {
        switch (indexType) {
          case IndexType.EnergyConsumption:
          case IndexType.WaterConsumption:
            const nf1 = project.normalizationFactor1.find((n) => n.projectCompanyId === i.id);
            if (!nf1) return false;
            return nf1.records.some((x) => (indexType === IndexType.EnergyConsumption ? x.energy : x.water));
          default:
            return i.indexes.includes(indexType);
        }
      })
      .map<Nf1Data>((i) => {
        const nf1Nodes = project.normalizationFactor1!.filter((n) => n.projectCompanyId === i.id);
        return {
          company: { ...i },
          record: nf1Nodes.length > 0 ? nf1Nodes.flatMap((r) => r.records) : [],
        } as Nf1Data;
      });

    setNf1Data(newNf1Data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  const companyOptions = useMemo(
    () =>
      nf1Data.map((i) => ({
        label: i.company.shortName || "",
        value: i.company.id,
      })),
    [nf1Data]
  );

  const addressPeriod = useMemo(
    () =>
      nf1Data.reduce((t, c) => {
        t[c.company.id] = c.record.map((a) => ({
          address: a.address,
          minDate: a.leaseStartDate,
          maxDate: a.leaseEndDate,
        }));
        return t;
      }, {}),
    [nf1Data]
  );

  const addressOptions = useMemo(() => {
    if (indexType !== IndexType.EnergyConsumption && indexType !== IndexType.WaterConsumption) return {};
    return nf1Data.reduce((t, c) => {
      t[c.company.id] = c.record
        .filter((a) => (indexType === IndexType.EnergyConsumption ? a.energy : a.water))
        .map((a) => ({ label: a.address, value: a.address }));
      return t;
    }, {});
  }, [nf1Data, indexType]);

  const includedInCompanyIndexes = useMemo(() => {
    return nf1Data.reduce((t, c) => {
      t[c.company.id] = c.company.indexes.includes(indexType);
      return t;
    }, {});
  }, [nf1Data, indexType]);

  const isValidAllCompanies = useCallback(
    (records: CommonReportRecordRow[]) => {
      const distinctCompanies = records.filter(
        (r, index) => records.findIndex((x) => x.companyId === r.companyId) === index
      );

      const differenceCompanies = records.filter((r) => !companyOptions.find((c) => c.value === r.companyId));
      const missingCompanies = companyOptions.filter((r) => !distinctCompanies.find((c) => c.companyId === r.value));

      if (distinctCompanies.length === 0 || distinctCompanies.length < companyOptions.length) {
        return {
          isValid: false,
          error:
            intl.formatMessage({ id: "errors.common.missing" }) +
            ": " +
            missingCompanies.map((x) => x.label).join(", "),
        };
      } else if (differenceCompanies.length !== 0) {
        return { isValid: false, error: "Please type in correct companies information" };
      }

      return { isValid: true, error: "" };
    },
    [companyOptions, intl]
  );

  const isValidAllCompaniesAddress = useCallback(
    (
      records: Array<CommonReportRecordRow & { address: string }>,
      selfRow: CommonReportRecordRow & { address: string }
    ) => {
      if (!selfRow.companyId) {
        return { isValid: false, error: "Please Fill in the company Name" };
      }
      const distinctCompanyAddress = records.filter((r, index, self) => {
        return (
          self.findIndex(
            (x) => x.companyId === r.companyId && x.address === r.address && x.companyId === selfRow.companyId
          ) === index
        );
      });
      const flattenAddress: { companyId: string; address: string }[] = [];

      addressOptions[selfRow.companyId].forEach((address) => {
        flattenAddress.push({ companyId: selfRow.companyId, address: address.value });
      });

      const differenceCompaniesAddress = distinctCompanyAddress.filter((r) => {
        return !flattenAddress.find((c) => c.companyId === r.companyId && c.address === r.address);
      });

      const missingCompaniesAddress = flattenAddress.filter((r) => {
        return !distinctCompanyAddress.find((c) => c.address === r.address);
      });

      if (distinctCompanyAddress.length === 0 || distinctCompanyAddress.length < flattenAddress.length) {
        return {
          isValid: false,
          error:
            intl.formatMessage({ id: "errors.common.missing" }) +
            ": " +
            missingCompaniesAddress.map((x) => x.address).join(", "),
        };
      } else if (differenceCompaniesAddress.length !== 0) {
        return {
          isValid: false,
          error:
            differenceCompaniesAddress.map((x) => x.address).join(", ") +
            intl.formatMessage({ id: "error.common.unregister-address" }),
        };
      }
      return { isValid: true, error: "" };
    },
    [addressOptions, intl]
  );

  const minDate = useMemo(() => toDate(projectPeriod.startDate, DateFormat.Date), [projectPeriod.startDate]);
  const maxDate = useMemo(() => toDate(projectPeriod.endDate, DateFormat.Date), [projectPeriod.endDate]);

  return {
    companyOptions,
    addressOptions,
    addressPeriod,
    projectPeriod,
    minDate,
    maxDate,
    isValidAllCompanies,
    isValidAllCompaniesAddress,
    includedInCompanyIndexes,
  };
};
