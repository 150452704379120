import React from "react";

import { OptionType } from "utils/interfaces";
import FormatterDisplayValue, { FormatterDisplayValueProps } from "../components/formatter/formatter-display-value";
import SelectFormatter, { SelectFormatterProps } from "../components/formatter/select-formatter";

export const createSelectSingleFormatter =
  (options: OptionType[]): React.FC<Omit<SelectFormatterProps, "options">> =>
  (props) => {
    return <SelectFormatter options={options} {...props} />;
  };

export const createSelectGroupedFormatter =
  (options: Array<{ options: OptionType[] }>): React.FC<Omit<SelectFormatterProps, "options">> =>
  (props) => {
    const result = options.map((x) => x.options).flat();
    return <SelectFormatter options={result} {...props} />;
  };

export type CreateSelectMultiFormatterProps = Omit<FormatterDisplayValueProps, "answer"> & {
  value: string | OptionType[];
};

export const createSelectMultiFormatter: React.FC<CreateSelectMultiFormatterProps> = (props) => {
  if (props.value === "" || !Array.isArray(props.value)) {
    return <FormatterDisplayValue answer={props.value} {...props} />;
  } else {
    const values = props.value.map((e) => e.label).join(", ");
    return <FormatterDisplayValue answer={values} {...props} />;
  }
};
