import { RecordStatus } from "generated/graphql";
import { GroupedOptionType, OptionType } from "utils/interfaces";
import { EnergyConsumptionRecordRow } from "./types";

export const newEnergyConsumptionRow: EnergyConsumptionRecordRow = {
  recordId: "",
  companyId: "",
  address: "",
  remarks: "",
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
  powerSource: "",
  powerSourceSupplierLocation: "",
  startDate: "",
  endDate: "",
  energyConsumptionAmount: 0,
  energyConsumptionUnit: "",
  maxDate: new Date(),
  meterNumber: "N/A",
  minDate: new Date(),
  errors: {},
};

export enum EnergyConsumptionUnit {
  MJ = "MJ",
  KWH = "kWh",
}

export const energyConsumptionUnitOptions: OptionType[] = [
  { label: "MJ 兆焦耳", value: EnergyConsumptionUnit.MJ },
  { label: "kWh 千瓦时", value: EnergyConsumptionUnit.KWH },
];

/** Power Source */

export enum PowerSource {
  // Citygrid
  CitygridHMT = "citygridHMT",
  CitygridMainland = "citygridMainland",
  CitygridOtherRegion = "citgridOtherRegion",

  // Solar Power
  PurchasedSolarPower = "solarPower",
  SelfSolarPower = "selfSolarPower",
  SoldSolarPower = "soldSolarPower",

  // Other
  Other = "other",
  SelfOther = "selfOther",
  SoldOther = "soldOther",
}

export const purchasedPowerOptions: OptionType[] = [
  {
    label: "购电 (台港澳) Citygrid (HK, Macau, & Taiwan)",
    value: PowerSource.CitygridHMT,
  },
  {
    label: "购电 (国网) Citygrid (Mainland)",
    value: PowerSource.CitygridMainland,
  },
  {
    label: "购电 (其他地区) Citygrid (Other Countries)",
    value: PowerSource.CitygridOtherRegion,
  },
  { label: "(购电) 太阳能 Solar power", value: PowerSource.PurchasedSolarPower },
  { label: "其他，请注明 Other, pls specify", value: PowerSource.Other },
];

export const selfGeneratedPowerOptions: OptionType[] = [
  { label: "(自用) 太阳能 Solar power (internal use)", value: PowerSource.SelfSolarPower },
  { label: "(外售) 太阳能 Solar power (sold)", value: PowerSource.SoldSolarPower },
  { label: "(自用) 其他，请注明 Other (internal use), pls specify", value: PowerSource.SelfOther },
  { label: "(外售) 其他，请注明 Other (sold), pls specify", value: PowerSource.SoldOther },
];

export const powerSourceOptions: GroupedOptionType[] = [
  {
    label: "购电 Puchased Power",
    options: purchasedPowerOptions,
  },
  {
    label: "自有发电 Self generated",
    options: selfGeneratedPowerOptions,
  },
];

/** Location of Electricity Supplier */

export enum PowerSupplierLocation {
  // Citygrid (Hong Kong / Macau / Taiwan)
  HkElectric = "hkElectric",
  CLP = "clp",
  Macau = "macau",
  Taiwan = "taiwan",

  // Citygrid (Mainland)
  Beijing = "beijing",
  Tianjin = "tianjin",
  Hebei = "hebei",
  Shanxi = "shanxi",
  Shandong = "shandong",
  Mengxi = "mengxi",
  Liaoning = "liaoning",
  Monton = "monton",
  Shanghai = "shanghai",
  Jiangsu = "jiangsu",
  Zhejiang = "zhejiang",
  Anhui = "anhui",
  Fujian = "fujian",
  Henan = "henan",
  Hubei = "hubei",
  Hunan = "hunan",
  Jiangxi = "jiangxi",
  Sichuan = "sichuan",
  Chongqing = "chongqing",
  Shaanxi = "shaanxi",
  Gansu = "gansu",
  Qinghai = "qinghai",
  Ningxia = "ningxia",
  Xinjiang = "xinjiang",
  Guangdong = "guangdong",
  Guangxi = "guangxi",
  Yunnan = "yunnan",
  Guizhou = "guizhou",
  Hainan = "hainan",

  // Citygrid (other regions)
  PlnIndonesia = "plnIndonesia",
  VictoriaAustralia = "victoriaAustralia",
  QueenslandAustralia = "queenslandAustralia",
  Singapore = "singapore",
  France = "france",
  Malaysia = "malaysia",
  Thailand = "thailand",
  Vietnam = "vietnam",
  Bangladesh = "bangladesh",
  Germany = "germany",
  Japan = "japan",
  Morocco = "morocco",
  Myanmar = "myanmar",
  CaliforniaUS = "californiaUs",
  UK = "uk",
  Cambodia = "cambodia",
  Turkey = "turkey",
  Korea = "korea",
  Switzerland = "switzerland",
  ChugokuElectricPower = "chugokuElectricPower",
  TokyoElectricPower = "tokyoElectricPower",
  KansaiElectricPower = "kansaiElectricPower",
  TayNinhProvinceVietnam = "tayNinhProvinceVietnam",
  SriLanka = "sriLanka",
  India = "india",
  Spain = "spain",
  Portugal = "portugal",
  DominicanRepublic = "dominicanRepublic",
  Mexico = "mexico",
  Peru = "peru",
  ElSalvador = "elSalvador",
  REC = "rec",

  // Solar Power
  PurchasedSolarPower = "solarPower",
  SelfSolarPower = "selfSolarPower",
  SoldSolarPower = "soldSolarPower",

  // Other
  Other = "other",
  SelfOther = "selfOther",
  SoldOther = "soldOther",

  // Citygrid (deprecated, for displaying value only)
  Citygrid = "citygrid",
}

// Hong Kong, Macau & Taiwan
export const citygridHMTOptions: OptionType[] = [
  { label: "香港电灯 HK Electric", value: PowerSupplierLocation.HkElectric },
  { label: "中电 CLP", value: PowerSupplierLocation.CLP },
  { label: "澳门 Macau", value: PowerSupplierLocation.Macau },
  { label: "台湾 Taiwan", value: PowerSupplierLocation.Taiwan },
];

export const citygridMainlandOptions: OptionType[] = [
  { label: "北京市 (华北) Beijing", value: PowerSupplierLocation.Beijing },
  { label: "天津市 (华北) Tianjin", value: PowerSupplierLocation.Tianjin },
  { label: "河北省 (华北) Hebei", value: PowerSupplierLocation.Hebei },
  { label: "山西省 (华北) Shanxi", value: PowerSupplierLocation.Shanxi },
  { label: "山东省 (华北) Shandong", value: PowerSupplierLocation.Shandong },
  { label: "蒙西 (华北) Mengxi", value: PowerSupplierLocation.Mengxi },
  { label: "辽宁省 (东北) Liaoning", value: PowerSupplierLocation.Liaoning },
  { label: "蒙东 (东北) Monton", value: PowerSupplierLocation.Monton },
  { label: "上海市 (华东) Shanghai", value: PowerSupplierLocation.Shanghai },
  { label: "江苏省 (华东) Jiangsu", value: PowerSupplierLocation.Jiangsu },
  { label: "浙江省 (华东) Zhejiang", value: PowerSupplierLocation.Zhejiang },
  { label: "安徽省 (华东) Anhui", value: PowerSupplierLocation.Anhui },
  { label: "福建省 (华东) Fujian", value: PowerSupplierLocation.Fujian },
  { label: "河南省 (华中) Henan", value: PowerSupplierLocation.Henan },
  { label: "湖北省 (华中) Hubei", value: PowerSupplierLocation.Hubei },
  { label: "湖南省 (华中) Hunan", value: PowerSupplierLocation.Hunan },
  { label: "江西省 (华中) Jiangxi", value: PowerSupplierLocation.Jiangxi },
  { label: "四川省 (华中) Sichuan", value: PowerSupplierLocation.Sichuan },
  { label: "重庆市 (华中) Chongqing", value: PowerSupplierLocation.Chongqing },
  { label: "陕西省 (西北) Shaanxi", value: PowerSupplierLocation.Shaanxi },
  { label: "甘肃省 (西北) Gansu", value: PowerSupplierLocation.Gansu },
  { label: "青海省 (西北) Qinghai", value: PowerSupplierLocation.Qinghai },
  { label: "宁夏自治区 (西北) Ningxia", value: PowerSupplierLocation.Ningxia },
  { label: "新疆自治区 (西北) Xinjiang", value: PowerSupplierLocation.Xinjiang },
  { label: "广东省 (南方) Guangdong", value: PowerSupplierLocation.Guangdong },
  { label: "广西自治区 (南方) Guangxi", value: PowerSupplierLocation.Guangxi },
  { label: "云南省 (南方) Yunnan", value: PowerSupplierLocation.Yunnan },
  { label: "贵州省 (南方) Guizhou", value: PowerSupplierLocation.Guizhou },
  { label: "海南省 (南方) Hainan", value: PowerSupplierLocation.Hainan },
];

export const citygridOtherRegionOptions: OptionType[] = [
  { label: "印尼 PLN Indonesia", value: PowerSupplierLocation.PlnIndonesia },
  { label: "澳洲 (维多利亚) Victoria, Australia", value: PowerSupplierLocation.VictoriaAustralia },
  { label: "澳洲 (昆士兰) Queensland, Australia", value: PowerSupplierLocation.QueenslandAustralia },
  { label: "新加坡 Singapore", value: PowerSupplierLocation.Singapore },
  { label: "法国 France", value: PowerSupplierLocation.France },
  { label: "马来西亚 Malaysia", value: PowerSupplierLocation.Malaysia },
  { label: "泰国 Thailand", value: PowerSupplierLocation.Thailand },
  { label: "越南 Vietnam", value: PowerSupplierLocation.Vietnam },
  { label: "孟加拉 Bangladesh", value: PowerSupplierLocation.Bangladesh },
  { label: "德国 Germany", value: PowerSupplierLocation.Germany },
  { label: "日本 Japan", value: PowerSupplierLocation.Japan },
  { label: "摩洛哥 Morocco", value: PowerSupplierLocation.Morocco },
  { label: "缅甸 Myanmar", value: PowerSupplierLocation.Myanmar },
  { label: "美国(加利福尼亚州) California (U.S)", value: PowerSupplierLocation.CaliforniaUS },
  { label: "英国 UK", value: PowerSupplierLocation.UK },
  { label: "柬埔寨 Cambodia", value: PowerSupplierLocation.Cambodia },
  { label: "土耳其 Turkey", value: PowerSupplierLocation.Turkey },
  { label: "韩国 Korea", value: PowerSupplierLocation.Korea },
  { label: "瑞士 Switzerland", value: PowerSupplierLocation.Switzerland },
  { label: "日本中国电力 Chugoku Electric Power", value: PowerSupplierLocation.ChugokuElectricPower },
  { label: "日本东京电力 Tokyo Electric Power", value: PowerSupplierLocation.TokyoElectricPower },
  { label: "日本关西电力 Kansai Electric Power", value: PowerSupplierLocation.KansaiElectricPower },
  { label: "越南西宁省 Tay Ninh Province, Vietnam", value: PowerSupplierLocation.TayNinhProvinceVietnam },
  { label: "斯里兰卡 Sri Lanka", value: PowerSupplierLocation.SriLanka },
  { label: "印度 India", value: PowerSupplierLocation.India },
  { label: "西班牙 Spain", value: PowerSupplierLocation.Spain },
  { label: "葡萄牙 Portugal", value: PowerSupplierLocation.Portugal },
  { label: "多米尼加共和国 Dominican Republic", value: PowerSupplierLocation.DominicanRepublic },
  { label: "墨西哥 Mexico", value: PowerSupplierLocation.Mexico },
  { label: "秘鲁 Peru", value: PowerSupplierLocation.Peru },
  { label: "萨尔瓦多 El Salvador", value: PowerSupplierLocation.ElSalvador },
  { label: "可再生能源证书 Renewable Energy Certificate", value: PowerSupplierLocation.REC },
];

export const purchasedSolarPowerOptions: OptionType[] = [
  { label: "(购电) 太阳能 Solar power", value: PowerSupplierLocation.PurchasedSolarPower },
];

export const selfSolarPowerOptions: OptionType[] = [
  { label: "(自用) 太阳能 Solar power (internal use)", value: PowerSupplierLocation.SelfSolarPower },
];

export const soldSolarPowerOptions: OptionType[] = [
  { label: "(外售) 太阳能 Solar power (sold)", value: PowerSupplierLocation.SoldSolarPower },
];

export const powerSupplierOptionsMapping: Record<string, OptionType[]> = {
  [PowerSource.CitygridHMT]: citygridHMTOptions,
  [PowerSource.CitygridMainland]: citygridMainlandOptions,
  [PowerSource.CitygridOtherRegion]: citygridOtherRegionOptions,
  [PowerSource.PurchasedSolarPower]: purchasedSolarPowerOptions,
  [PowerSource.SelfSolarPower]: selfSolarPowerOptions,
  [PowerSource.SoldSolarPower]: soldSolarPowerOptions,
  [PowerSource.Other]: [],
  [PowerSource.SelfOther]: [],
  [PowerSource.SoldOther]: [],
};

export const flattenPowerSupplierOptions = Object.values(powerSupplierOptionsMapping).reduce((a, b) => a.concat(b));
