import { useCallback, useContext, useState } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import {
  EventAction,
  GetTailorMadeIndicesAndFieldsQuery,
  SuccessfulRecord,
  TailorMadeIndexFieldType,
  TailorMadeIndexParam,
} from "generated/graphql";
import { AuthContext } from "provider/auth";
import { getApolloError } from "utils/apollo-error";
import { newOthersRow } from "../consts";
import { OthersRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyIds: string[]) => Promise<{
    tailorMadeIndexFields: GetTailorMadeIndicesAndFieldsQuery["project"]["tailorMadeIndexFields"];
    rows: OthersRecordRow[];
    newRow: OthersRecordRow;
  }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const [tailorMadeIndexField, setTailorMadeIndexField] = useState<string[]>([]);
  const { me } = useContext(AuthContext);

  const { getTailorMadeIndicesAndFields, submitTailorMadeIndex } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { tailorMadeIndexFields: [], rows: [], newRow: newOthersRow };

      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const { tailorMadeIndex, tailorMadeIndexFields } = await getTailorMadeIndicesAndFields({
          projectId: me.project.id,
          companies: companyIds,
        });

        setTailorMadeIndexField(tailorMadeIndexFields.map((f) => f.fieldName));

        const newRowData = tailorMadeIndexFields.reduce<OthersRecordRow>(
          (acc, field) => {
            if (!field.fieldName) return acc;

            switch (field.fieldType) {
              case TailorMadeIndexFieldType.Number:
                acc[field.fieldName] = 0;
                break;
              case TailorMadeIndexFieldType.DateTime:
              case TailorMadeIndexFieldType.String:
              default:
                acc[field.fieldName] = "";
                break;
            }

            return acc;
          },
          { ...newOthersRow }
        );

        const rows = tailorMadeIndex.flatMap((index) =>
          index.records.map<OthersRecordRow>((r) => ({
            ...newRowData,
            ...r.tailorMadeField,
            remarks: r.remarks,
            recordId: r.id,
            companyId: index.projectCompany.id,
            originalCompanyId: index.projectCompany.id,
            fileInfo: r.file,
          }))
        );

        return { tailorMadeIndexFields, rows, newRow: newRowData };
      } catch (e) {
        return defaultReturn;
      }
    },
    [me, getTailorMadeIndicesAndFields]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<TailorMadeIndexParam>((e) => {
          const data = e.data as DataGridRow<OthersRecordRow>;

          const { companyId, recordStatus, fileToUpload, fileToDelete, remarks, ...rest } = data;

          // filter out tailor made index field data from row data
          const fieldData = tailorMadeIndexField.reduce((data, field) => {
            if (rest[field] !== null && rest[field] !== undefined) data[field] = rest[field];
            return data;
          }, {});

          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId,
              recordStatus,
              remarks,
              fileToUpload,
              fileToDelete,
              tailorMadeField: fieldData,
            },
          } as TailorMadeIndexParam;
        });

        return await submitTailorMadeIndex({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        const err = getApolloError(e);
        throw err;
      }
    },
    [submitTailorMadeIndex, tailorMadeIndexField]
  );

  return { getData, submitData };
};
