// Material helpers
import { createTheme } from "@mui/material/styles";

import components from "./components";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  palette,
  typography,
  components,
  zIndex: {
    appBar: 1200,
    drawer: 1300,
  },
});

export default theme;
