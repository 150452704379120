import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  titleWrapper: {
    margin: 0,
    padding: spacing(2),
  },
  title: {
    fontSize: "18px",
  },
  contentWrapper: {
    padding: spacing(2),
  },
  content: {
    fontSize: "16px",
  },
  cancelBtn: {
    color: palette.error.main,
  },
  actionWrapper: {
    margin: 0,
    padding: spacing(1),
  },
}));

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  );
});

export type ConfirmDialogProps = {
  isOpen: boolean;
  title: string;
  content: React.ReactNode;
  handleCloseConfirm: () => void;
  handleClose: () => void;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <div>
      <Dialog
        open={props.isOpen}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleWrapper}>
          <span className={classes.title}>{props.title}</span>
        </DialogTitle>
        <DialogContent className={classes.contentWrapper}>
          <DialogContentText id="alert-dialog-description" className={classes.content}>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <MuiDialogActions>
          <Button onClick={props.handleClose} className={classes.cancelBtn}>
            {intl.formatMessage({ id: "button.cancel" })}
          </Button>

          <Button
            onClick={() => {
              props.handleCloseConfirm();
              props.handleClose();
            }}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({ id: "button.ok" })}
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};
