import { RecordStatus } from "generated/graphql";
import { DevelopmentTrainingRecordRow } from "./types";

export const newDevelopmentTrainingRow: DevelopmentTrainingRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  courseName: "",
  type: "",
  host: "",
  date: "",
  numberOfHours: 0,
  numberOfEmployees: 0,
  male: 0,
  female: 0,
  seniorManagement: 0,
  middleLevelManagement: 0,
  generalAndTechnicalEmployees: 0,
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export enum TrainingType {
  Internal = "interal",
  External = "external",
}
