import React, { createContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { Me, Role, useGetMeLazyQuery, useRefetchTokenMutation } from "generated/graphql";
import { isLogin } from "utils/helper";

export const AuthContext = createContext<{ me: Me; refetchToken: () => void }>({
  me: {},
  refetchToken: () => undefined,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [me, setMe] = useState<Me>({});

  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();

  const [getMe] = useGetMeLazyQuery({
    onCompleted: (d) => {
      setMe(d.getMe as Me);
      if (d.getMe.role !== Role.Admin) refetchToken();
    },
    onError: (e) => {
      if (e.message.toLowerCase().includes("user doesn't have project")) {
        history.push("/no-project");
      }
    },
  });

  const [refetchToken] = useRefetchTokenMutation({ client, notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (isLogin() && Object.keys(me).length === 0) getMe();
    else if (!isLogin() && Object.keys(me).length > 0) setMe({});
  }, [me, location.pathname]);

  return <AuthContext.Provider value={{ me, refetchToken }}>{children}</AuthContext.Provider>;
};
