type InputBase = { value: number };

export type UseSocialPercentageDataHelperParams<T extends InputBase> = {
  data: T[];
};

export type UseSocialPercentageDataHelperReturns<T extends InputBase> = {
  data: T[];
  isVisible: boolean;
};

export const useSocialPercentageDataHelper = <T extends InputBase>(
  params: UseSocialPercentageDataHelperParams<T>
): UseSocialPercentageDataHelperReturns<T> => {
  const { data } = params;

  const filteredData = data.filter((d) => d.value > 0);

  const isVisible = filteredData.length > 0;

  return { data: filteredData, isVisible };
};
