import React, { useEffect, useState } from "react";

import { EventSourcePolyfill, NativeEventSource } from "event-source-polyfill";
import { SERVER, VERSION } from "utils/const";
import { VersionUpdateDialog } from "./components/dialog";

const instance = NativeEventSource || EventSourcePolyfill;

export const SSEProvider: React.FC = ({ children }) => {
  const [reminder, setReminder] = useState(false);

  useEffect(() => {
    const es = new instance(`${SERVER.SSE}/sse/version`);
    es.onmessage = function (e) {
      if (e.data !== VERSION) setReminder(true);
    };
  }, []);

  return (
    <>
      <VersionUpdateDialog reminder={reminder} />
      {children}
    </>
  );
};
