import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, IndexType, ProjectCompanyParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { Applicability, indexTypeColumnRef, newCompanyRow } from "../consts";
import { CompanyRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: () => Promise<{ segment: string[]; rows: CompanyRecordRow[] }>;
  submitData: (events: DataGridEvents) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);

  const { getProjectCompanyData, submitProjectCompany } = useAPI();

  const getData = useCallback(async () => {
    const defaultReturn = { segment: [], rows: [] };
    try {
      if (!me.project?.id) return defaultReturn;

      const { segment, projectCompany, tailorMadeIndexFields } = await getProjectCompanyData({
        projectId: me.project.id,
      });

      const rows = projectCompany.map<CompanyRecordRow>((pc) => {
        const row: CompanyRecordRow = {
          ...newCompanyRow,
          recordId: pc.id,
          // the companyId and originalCompanyId is same to identify event type is update
          companyId: pc.id,
          originalCompanyId: pc.id,
          fullName: pc.fullName ?? "",
          shortName: pc.shortName ?? "",
          segment: pc.segment ?? "",
          remarks: pc.remarks ?? "",
          isDisableOthersColumn: tailorMadeIndexFields?.length === 0,
        };

        for (const [index, { key }] of Object.entries(indexTypeColumnRef)) {
          row[key] = pc.indexes.includes(index as IndexType) ? Applicability.Applicable : Applicability.Unapplicable;
        }

        return row;
      });

      return { segment, rows };
    } catch (e) {
      return defaultReturn;
    }
  }, [getProjectCompanyData, me]);

  const submitData = useCallback(
    async (events: DataGridEvents) => {
      const params = Object.values(events).map<ProjectCompanyParam>((e) => {
        const data = e.data as DataGridRow<CompanyRecordRow>;
        return {
          event: formSubmitDataEvent(e.action, data),
          data: {
            id: e.action === EventAction.Create ? undefined : data.recordId,
            fullName: data.fullName,
            shortName: data.shortName,
            remarks: data.remarks,
            segment: data.segment,
            indexes: Object.keys(indexTypeColumnRef).filter((index) => {
              switch (index as IndexType) {
                // Energy Consumption & Water Consumption always available in datasheet
                case IndexType.EnergyConsumption:
                case IndexType.WaterConsumption:
                  return true;
                default:
                  return data[indexTypeColumnRef[index as IndexType].key] === Applicability.Applicable;
              }
            }) as IndexType[],
          },
        };
      });

      return await submitProjectCompany({ data: params });
    },
    [submitProjectCompany]
  );

  return { getData, submitData };
};
