import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetTrainingRatioDataDocument,
  GetTrainingRatioDataQuery,
  GetTrainingRatioDataQueryVariables,
  SubmitTrainingRatioDocument,
  SubmitTrainingRatioMutation,
  SubmitTrainingRatioMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getTrainingRatioData: (
    variables: GetTrainingRatioDataQueryVariables
  ) => Promise<GetTrainingRatioDataQuery["project"]["trainingRatio"]>;
  submitTrainingRatio: (
    variables: SubmitTrainingRatioMutationVariables
  ) => Promise<SubmitTrainingRatioMutation["submitTrainingRatio"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getTrainingRatioData = useCallback(
    async (variables: GetTrainingRatioDataQueryVariables) => {
      const response = await client.query<GetTrainingRatioDataQuery, GetTrainingRatioDataQueryVariables>({
        query: GetTrainingRatioDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.trainingRatio;
    },
    [client]
  );

  const submitTrainingRatio = useCallback(
    async (variables: SubmitTrainingRatioMutationVariables) => {
      const response = await client.mutate<SubmitTrainingRatioMutation, SubmitTrainingRatioMutationVariables>({
        mutation: SubmitTrainingRatioDocument,
        variables,
      });

      return response.data?.submitTrainingRatio ?? false;
    },
    [client]
  );

  return {
    getTrainingRatioData,
    submitTrainingRatio,
  };
};
