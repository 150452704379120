import React, { useCallback, useContext, useMemo, useState } from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Formik, FormikActions } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { FullScreenFormDialog } from "components/react-table";
import { Scope3Category } from "generated/graphql";
import { useFetch } from "hook";
import { MessageContext } from "provider/message";
import { EXCEL_MIME_TYPES } from "utils/const";
import { DialogMode } from "utils/dialog";
import { Scope3CategoryIntl } from "utils/intl";
import { Yup } from "utils/yup";
import { Scope3DataForm } from "./components/form";
import { Scope3Stepper, Scope3StepperItem } from "./components/stepper";
import { defaultFormFields, orderedBusinessTravelCategories, orderedUseOfSoldProductsCategories } from "./data";
import { useActions } from "./hook/action";
import { DataRecordRowItem, Scope3DataFormFields } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  paperRoot: {
    padding: spacing(2, 3),
  },
  sectionTitle: {
    padding: spacing(1, 2.5),
  },
  snackBar: {
    margin: spacing(1),
    width: "15%",
  },
}));

export const Scope3DataPage: React.FC = () => {
  const { classes } = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<Scope3DataFormFields>(defaultFormFields);
  const [permission, setPermission] = useState<Scope3Category[]>([]);
  const [scope3Data, setScope3Data] = useState<DataRecordRowItem[]>([]);

  const currentData = useMemo(
    () => scope3Data.filter((d) => d.category === initialValues.category),
    [scope3Data, initialValues]
  );

  const intl = useIntl();
  const { refetch } = useFetch(() => fetchData());
  const { showUpdateSuccessMessage, showUpdateFailedMessage } = useContext(MessageContext);

  const { getData, uploadData } = useActions();

  const fetchData = useCallback(async () => {
    const { permission, data } = await getData();

    setPermission(permission);
    setScope3Data(data);
  }, [getData, setPermission, setScope3Data]);

  const handleDialogOpen = (category: Scope3Category) => {
    setInitialValues({
      category,
      file: undefined,
    });
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSubmitForm = async (
    values: Scope3DataFormFields,
    { setSubmitting, resetForm }: FormikActions<Scope3DataFormFields>
  ) => {
    try {
      await uploadData(values);

      showUpdateSuccessMessage();
      resetForm({ category: values.category, file: undefined });
      refetch();
    } catch (e) {
      showUpdateFailedMessage();
    } finally {
      setSubmitting(false);
    }
  };

  const businessTravelSteppers = useMemo(
    () =>
      orderedBusinessTravelCategories.map<Scope3StepperItem>((category) => ({
        category,
        visible: permission.includes(category),
      })),
    [permission]
  );

  const useOfSoldProductsSteppers = useMemo(
    () =>
      orderedUseOfSoldProductsCategories.map<Scope3StepperItem>((category) => ({
        category,
        visible: permission.includes(category),
      })),
    [permission]
  );

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required(intl.formatMessage({ id: "errors.document.file-required" }))
      .test("isValidExtension", "errors.document.file-format", function (value) {
        if (!value) return false;
        const fileMimeType = value.type;
        return EXCEL_MIME_TYPES.includes(fileMimeType);
      }),
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Paper className={classes.paperRoot}>
            <Grid container>
              <Grid xs={6}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  {intl.formatMessage({ id: "scope3.category.section.business-travel" })}
                </Typography>
                <Scope3Stepper items={businessTravelSteppers} onClick={handleDialogOpen} />
              </Grid>
              <Grid xs={6}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  {intl.formatMessage({ id: "scope3.category.section.use-of-sold-products" })}
                </Typography>
                <Scope3Stepper items={useOfSoldProductsSteppers} onClick={handleDialogOpen} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Formik<Scope3DataFormFields>
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
        validationSchema={validationSchema}
        render={(formikProps) => (
          <FullScreenFormDialog
            isOpen={isDialogOpen}
            handleDialogClose={() => {
              handleDialogClose();
              formikProps.resetForm();
            }}
            handleSubmit={formikProps.submitForm}
            mode={DialogMode.MANAGE}
            title={intl.formatMessage({ id: Scope3CategoryIntl[formikProps.values.category] })}
          >
            <Scope3DataForm {...formikProps} data={currentData} />
          </FullScreenFormDialog>
        )}
      />
    </>
  );
};
