import React from "react";

import { useBeforeunload } from "react-beforeunload";
import { Prompt, PromptProps } from "react-router-dom";

export const BeforeExitPrompt: React.FC<PromptProps> = (props) => {
  // NOTE: useBeforeUnload handles closing tab / browser
  useBeforeunload((event) => {
    if (props.when) {
      event.preventDefault();
      return props.message;
    }
  });

  // NOTE: Prompt handles navigating away from current path
  return <Prompt {...props} />;
};
