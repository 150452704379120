import * as Excel from "pages/excel";
import { DynamicRoute } from "../dynamic-route";

const route = [
  {
    path: "",
    component: Excel.Layer,
  },
  {
    path: "",
    component: Excel.StepperPage,
    exact: true,
  },
  {
    path: "/company",
    component: Excel.CompanyPage,
    exact: true,
  },
  {
    path: "/nf1",
    component: Excel.Nf1Page,
    exact: true,
  },
  {
    path: "/nf2",
    component: Excel.Nf2Page,
    exact: true,
  },
  {
    path: "/nf3",
    component: Excel.Nf3Page,
    exact: true,
  },
  {
    path: "/energy",
    component: Excel.EnergyConsumptionPage,
    exact: true,
  },
  {
    path: "/water",
    component: Excel.WaterConsumptionPage,
    exact: true,
  },
  {
    path: "/paper",
    component: Excel.OfficePaperUsagePage,
    exact: true,
  },
  {
    path: "/non-hazardous-waste",
    component: Excel.NonHazardousWastePage,
    exact: true,
  },
  {
    path: "/packaging-material",
    component: Excel.PackagingMaterialPage,
    exact: true,
  },
  {
    path: "/refrigerant-and-fire",
    component: Excel.RefrigerantAndFirePage,
    exact: true,
  },
  {
    path: "/others",
    component: Excel.OthersPage,
    exact: true,
  },
  {
    path: "/tree",
    component: Excel.TreePage,
    exact: true,
  },
  {
    path: "/fuel-mobile",
    component: Excel.MobileFuelPage,
    exact: true,
  },
  {
    path: "/fuel-stationary",
    component: Excel.StationaryFuelPage,
    exact: true,
  },
  {
    path: "/other-mobile-machinery",
    component: Excel.OtherMobileMachineryPage,
    exact: true,
  },
  {
    path: "/hazardous-waste",
    component: Excel.HazardousWastePage,
    exact: true,
  },
  {
    path: "/workforce",
    component: Excel.WorkforcePage,
    exact: true,
  },
  {
    path: "/turnover",
    component: Excel.TurnoverPage,
    exact: true,
  },
  {
    path: "/product-responsibility",
    component: Excel.ProductResponsibilityPage,
    exact: true,
  },
  {
    path: "/development-and-training",
    component: Excel.DevelopmentTrainingPage,
    exact: true,
  },
  {
    path: "/supply-chain-management",
    component: Excel.SupplyChainManagementPage,
    exact: true,
  },
  {
    path: "/training-ratio",
    component: Excel.TrainingRatioPage,
    exact: true,
  },
  {
    path: "/community-investment",
    component: Excel.CommunityInvestmentPage,
    exact: true,
  },
  {
    path: "/injury-and-fatalities",
    component: Excel.InjuryFatalitiesPage,
    exact: true,
  },
  {
    path: "/labour-responsibility-policy",
    component: Excel.LabourResponsibilityPolicyPage,
    exact: true,
  },
  {
    path: "/product-responsibility-policy",
    component: Excel.ProductResponsibilityPolicyPage,
    exact: true,
  },
];

export const StepperRoute = DynamicRoute(route);
