import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { LayoutContext, LayoutContextProps } from "../layout-context";
import { NestedListItem } from "./nested-list-item";
import { MenuItem } from "./types";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  listSubheader: {
    color: palette.text.secondary,
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.primary.light,
      borderLeft: `4px solid ${palette.primary.main}`,
      borderRadius: "4px",
      "& $listItemIcon": {
        color: palette.primary.main,
        marginLeft: "-4px",
      },
    },
    "& + &": {
      marginTop: spacing(),
    },
  },
  activeListItem: {
    borderLeft: `4px solid ${palette.primary.main}`,
    borderRadius: "4px",
    backgroundColor: palette.primary.light,
    "& $listItemText": {
      color: palette.text.primary,
    },
    "& $listItemIcon": {
      color: palette.primary.main,
      marginLeft: "-4px",
    },
  },
  listItemIcon: {
    marginRight: 0,
  },
  listItemText: {
    fontWeight: 500,
    color: palette.text.primary,
  },
}));

export type MenuListProps = {
  menu: MenuItem[];
};

export const MenuList: React.FC<MenuListProps> = ({ menu }) => {
  const { handleDrawerClose } = React.useContext<LayoutContextProps>(LayoutContext);
  const intl = useIntl();

  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();

  const goTo = (link: string) => {
    handleDrawerClose();
    history.push(link);
  };

  return (
    <List component="div" disablePadding>
      {menu.map((i) => {
        const match = location.pathname === i.path;
        return (
          <div key={i.name}>
            {i.child.length === 0 && (
              <ListItem
                className={`${match ? classes.activeListItem : classes.listItem}`}
                onClick={() => {
                  if (!match) goTo(i.path);
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <i.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={intl.formatMessage({ id: i.name })}
                />
              </ListItem>
            )}
            {i.child.length !== 0 && <NestedListItem menuItem={i} goTo={goTo} match={match} />}
          </div>
        );
      })}
    </List>
  );
};
