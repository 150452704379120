import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DialogMode } from "utils/dialog";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: spacing(2),
    color: palette.common.white,
    flex: 1,
  },
}));

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  );
});

export type FullScreenFormDialogProps = {
  isOpen: boolean;
  title: string;
  mode: DialogMode;
  handleDialogClose: () => void;
  handleSubmit: () => void;
};

export const FullScreenFormDialog: React.FC<FullScreenFormDialogProps> = (props) => {
  const { isOpen, title, mode, handleDialogClose, handleSubmit, children } = props;
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleDialogClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="Close" size="large">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" className={classes.title}>
              {intl.formatMessage(
                { id: mode === DialogMode.CREATE ? "dialog.title.common.add" : "dialog.title.common.edit" },
                { title }
              )}
            </Typography>
            <Button color="inherit" onClick={handleSubmit}>
              {intl.formatMessage({ id: "button.data-grid.save" })}
            </Button>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </div>
  );
};
