import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { GridWithLoading, ValueWithPieChartBlock } from "..";
import { useSocialPercentageDataHelper } from "../../hook/social-percentage-data-helper";
import { DASHBOARD_BLOCK_HEIGHT_100, SocialDashboardRegionIntl, SupplyChainDashboardRegionColor } from "../consts";
import { SectionHeader } from "../section-header";

export type SupplyChainDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  previousYear: string;
  isLoading: boolean;
};

export const SupplyChainDashboard: React.FC<SupplyChainDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current } = data;

  const intl = useIntl();

  const regionHelper = useSocialPercentageDataHelper({ data: current.supplyChain.region });

  return (
    <>
      {(isLoading || regionHelper.isVisible) && (
        <Grid xs={5}>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.social.supplyChain.title" })}
              description={intl.formatMessage(
                { id: "dashboard.social.supplyChain.description" },
                { year: currentYear }
              )}
            />
          </Grid>

          <GridWithLoading
            xs={12}
            height={DASHBOARD_BLOCK_HEIGHT_100}
            isLoading={isLoading}
            isVisible={regionHelper.isVisible}
          >
            <ValueWithPieChartBlock
              data={regionHelper.data.map(({ name, value }) => ({
                name: intl.formatMessage({ id: SocialDashboardRegionIntl[name] }),
                value,
                color: SupplyChainDashboardRegionColor[name],
              }))}
              compareValue={
                data.previous
                  ? {
                      name: previousYear,
                      value: data.previous.supplyChain.total.value,
                    }
                  : undefined
              }
            />
          </GridWithLoading>
        </Grid>
      )}
    </>
  );
};
