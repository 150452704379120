import React from "react";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: "18px",
  },
  content: {
    fontSize: "16px",
  },
}));

enum AlertDialogType {
  ALERT = "ALERT",
  ERROR = "ERROR",
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  );
});

export type AlertDialogProps = {
  title: string;
  type?: AlertDialogType;
  isOpen: boolean;
  content: React.ReactNode;
  handleClose: () => void;
};

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const { classes } = useStyles();
  const isError = props.type === AlertDialogType.ERROR;

  return (
    <div>
      <Dialog
        open={props.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          <span className={classes.title}>{props.title}</span>
        </DialogTitle>
        <DialogContent>
          <div>{isError && <AnnouncementIcon />}</div>
          <DialogContentText id="alert-dialog-description" className={classes.content}>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
