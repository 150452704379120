import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetLabourResponsibilityPolicyDataDocument,
  GetLabourResponsibilityPolicyDataQuery,
  GetLabourResponsibilityPolicyDataQueryVariables,
  SubmitLabourResponsibilityPolicyDocument,
  SubmitLabourResponsibilityPolicyMutation,
  SubmitLabourResponsibilityPolicyMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getLabourResponsibilityPolicyData: (
    variables: GetLabourResponsibilityPolicyDataQueryVariables
  ) => Promise<GetLabourResponsibilityPolicyDataQuery["project"]["labourResponsibilityPolicy"]>;
  submitLabourResponsibilityPolicy: (
    variables: SubmitLabourResponsibilityPolicyMutationVariables
  ) => Promise<SubmitLabourResponsibilityPolicyMutation["submitLabourResponsibilityPolicy"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getLabourResponsibilityPolicyData = useCallback(
    async (variables: GetLabourResponsibilityPolicyDataQueryVariables) => {
      const response = await client.query<
        GetLabourResponsibilityPolicyDataQuery,
        GetLabourResponsibilityPolicyDataQueryVariables
      >({
        query: GetLabourResponsibilityPolicyDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.labourResponsibilityPolicy;
    },
    [client]
  );

  const submitLabourResponsibilityPolicy = useCallback(
    async (variables: SubmitLabourResponsibilityPolicyMutationVariables) => {
      const response = await client.mutate<
        SubmitLabourResponsibilityPolicyMutation,
        SubmitLabourResponsibilityPolicyMutationVariables
      >({
        mutation: SubmitLabourResponsibilityPolicyDocument,
        variables,
      });

      return response.data?.submitLabourResponsibilityPolicy ?? false;
    },
    [client]
  );

  return {
    getLabourResponsibilityPolicyData,
    submitLabourResponsibilityPolicy,
  };
};
