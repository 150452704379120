import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetNf1DataDocument,
  GetNf1DataQuery,
  GetNf1DataQueryVariables,
  SubmitNf1Document,
  SubmitNf1Mutation,
  SubmitNf1MutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getNf1Data: (variables: GetNf1DataQueryVariables) => Promise<GetNf1DataQuery["project"]["normalizationFactor1"]>;
  submitNf1: (variables: SubmitNf1MutationVariables) => Promise<SubmitNf1Mutation["submitNF1"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getNf1Data = useCallback(
    async (variables: GetNf1DataQueryVariables) => {
      const response = await client.query<GetNf1DataQuery, GetNf1DataQueryVariables>({
        query: GetNf1DataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.normalizationFactor1;
    },
    [client]
  );

  const submitNf1 = useCallback(
    async (variables: SubmitNf1MutationVariables) => {
      const response = await client.mutate<SubmitNf1Mutation, SubmitNf1MutationVariables>({
        mutation: SubmitNf1Document,
        variables,
      });

      return response.data?.submitNF1 ?? false;
    },
    [client]
  );

  return {
    getNf1Data,
    submitNf1,
  };
};
