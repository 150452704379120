import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing }) => ({
  leftIcon: {
    marginRight: spacing(1),
  },
  popper: {
    overflowY: "auto",
    maxHeight: "300px",
  },
}));

export type AddButtonProps = {
  addRow: (cloneFromIndex: number) => void;
  rowsLength: number;
};

export const AddButton: React.FC<AddButtonProps> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [options] = React.useState<string[]>([]);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { classes } = useStyles();

  const handleMenuItemClick = (index: number) => {
    props.addRow(index);
    setOpen(false);
  };

  const handleClick = () => {
    props.addRow(-1);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="outlined" ref={anchorRef} color={"primary"}>
        <Button onClick={handleClick} id="add-row-button">
          <AddIcon className={classes.leftIcon} />
          {intl.formatMessage({ id: "button.data-grid.add-row" })}
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition={true} disablePortal={true} style={{ zIndex: 3 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper id="menu-list-grow" className={classes.popper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem key={option} onClick={handleMenuItemClick.bind({}, index)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
