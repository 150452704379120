import { EmissionFactorCategory, EmissionFactorUnit } from "generated/graphql";

export const CategoryLabels: Record<EmissionFactorCategory, string> = {
  [EmissionFactorCategory.NoxMobileCombustion]: "emission-factor.title.nox-mobile-combustion",
  [EmissionFactorCategory.NoxStationaryCombustion]: "emission-factor.title.nox-stationary-combustion",
  [EmissionFactorCategory.SoxMobileCombustion]: "emission-factor.title.sox-mobile-combustion",
  [EmissionFactorCategory.SoxStationaryCombustion]: "emission-factor.title.sox-stationary-combustion",
  [EmissionFactorCategory.PmMobileCombustion]: "emission-factor.title.pm-mobile-combustion",
  [EmissionFactorCategory.PmStationaryCombustion]: "emission-factor.title.pm-stationary-combustion",
  [EmissionFactorCategory.Co2EmissionStationary]: "emission-factor.title.co2-emission-stationary",
  [EmissionFactorCategory.Ch4EmissionStationary]: "emission-factor.title.ch4-emission-stationary",
  [EmissionFactorCategory.N2OEmissionStationary]: "emission-factor.title.n2o-emission-stationary",
  [EmissionFactorCategory.Co2EmissionMobile]: "emission-factor.title.co2-emission-mobile",
  [EmissionFactorCategory.Ch4EmissionMobile]: "emission-factor.title.ch4-emission-mobile",
  [EmissionFactorCategory.N2OEmissionMobile]: "emission-factor.title.n2o-emission-mobile",
  [EmissionFactorCategory.GhgRemoval]: "emission-factor.title.ghg-removal",
  [EmissionFactorCategory.PurchasedTowngas]: "emission-factor.title.purchased-towngas",
  [EmissionFactorCategory.PurchasedElectricityEmission]: "emission-factor.title.purchased-electricity-emission",
  [EmissionFactorCategory.PurchasedWater]: "emission-factor.title.purchased-water",
  [EmissionFactorCategory.PurchasedChillingSystem]: "emission-factor.title.purchased-chilling-system",
  [EmissionFactorCategory.PurchasedSteam]: "emission-factor.title.purchased-steam",
  [EmissionFactorCategory.Ghg]: "emission-factor.title.ghg",
  [EmissionFactorCategory.IndustrialDesignation]: "emission-factor.title.industrial-designation"
};

export const categories = Object.keys(CategoryLabels) as EmissionFactorCategory[];

export const UnitLabels: Record<EmissionFactorUnit, string> = {
  [EmissionFactorUnit.None]: "",
  [EmissionFactorUnit.G]: "emission-factor.unit.g",
  [EmissionFactorUnit.Gj]: "emission-factor.unit.gj",
  [EmissionFactorUnit.Kg]: "emission-factor.unit.kg",
  [EmissionFactorUnit.Kgco2E]: "emission-factor.unit.kgco2e",
  [EmissionFactorUnit.Km]: "emissionfactor.unit.km",
  [EmissionFactorUnit.Kwh]: "emissionfactor.unit.kwh",
  [EmissionFactorUnit.L]: "emissionfactor.unit.l",
  [EmissionFactorUnit.M3]: "emissionfactor.unit.m3",
  [EmissionFactorUnit.Mj]: "emissionfactor.unit.mj",
  [EmissionFactorUnit.TonneOfSteam]: "emissionfactor.unit.tonne-of-steam",
  [EmissionFactorUnit.Tree]: "emissionfactor.unit.tree"
};

export const units = Object.keys(UnitLabels).filter((unit) => unit !== EmissionFactorUnit.None) as EmissionFactorUnit[];
