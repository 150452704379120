import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newNonHazardousWasteRow, nonHazardousWasteTypeOptions, tips } from "./consts";
import { useActions } from "./hook/action";
import { NonHazardousWasteRecordRow } from "./types";

const source = IndexType.NonHazardousWaste;

export const NonHazardousWastePage: React.FC = () => {
  const [initRows, setInitRows] = useState<NonHazardousWasteRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "wasteSource",
        name: intl.formatMessage({ id: "excel.non-hazardous.waster-source" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "wasteSource"),
      },
      {
        key: "wasteType",
        name: intl.formatMessage({ id: "excel.non-hazardous.waster-type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: nonHazardousWasteTypeOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(nonHazardousWasteTypeOptions), "wasteType"),
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.non-hazardous.start-date" }),
        resizable: true,
        width: 100,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "startDate"),
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.non-hazardous.end-date" }),
        resizable: true,
        width: 100,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "endDate"),
      },
      {
        key: "wasteManagementDisposeAmount",
        name: intl.formatMessage({ id: "excel.non-hazardous.dispose" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "wasteManagementDisposeAmount"),
      },
      {
        key: "wasteManagementRecyclingAmount",
        name: intl.formatMessage({ id: "excel.non-hazardous.recycling" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "wasteManagementRecyclingAmount"),
      },
      {
        key: "otherUsageAmount",
        name: intl.formatMessage({ id: "excel.non-hazardous.other" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "otherUsageAmount"),
      },
    ],
    [companyOptions, maxDate, minDate, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<NonHazardousWasteRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as NonHazardousWasteRecordRow[]);

    return Yup.array(
      Yup.object<Partial<NonHazardousWasteRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        startDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        endDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        wasteSource: Yup.string().required("errors.common.require"),
        wasteType: Yup.string().required("errors.common.require"),
        wasteManagementDisposeAmount: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        wasteManagementRecyclingAmount: Yup.number()
          .required("errors.common.require")
          .min(0, "errors.common.more-zero"),
        wasteManagementCollectedAmount: Yup.number()
          .required("errors.common.require")
          .min(0, "errors.common.more-zero"),
        otherUsageAmount: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.non-hazardous-waste.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newNonHazardousWasteRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "wasteManagementDisposeAmount", value: 0 },
          { property: "wasteManagementRecyclingAmount", value: 0 },
          { property: "wasteManagementCollectedAmount", value: 0 },
          { property: "otherUsageAmount", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
