import { Property } from "csstype";

import { placeholderIntlKey } from "utils/intl";
import { ChartInput } from "./components/charts/types";
import { DashboardColor, DashboardUnitIntl } from "./components/consts";

export const getChartInput = (args: { name?: string; datum?: { value: number } | null }): ChartInput | undefined => {
  const { name, datum } = args;
  if (!(name && datum)) return undefined;

  return { name, value: datum.value };
};

export const getColorByValueDiff = (mainValue?: number, subValue?: number): Property.Fill => {
  if (mainValue && subValue) {
    if (mainValue > subValue) return DashboardColor.chart.red;
    if (mainValue < subValue) return DashboardColor.chart.green;
  }

  return DashboardColor.chart.grey1;
};

export const getUnitIntlKey = (value: string): string => {
  if (!value) return placeholderIntlKey;
  const key = value.toLowerCase().replaceAll(" ", "-");

  return DashboardUnitIntl[key] ?? value;
};
