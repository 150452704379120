import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

export type FileDialogRowProps = {
  index: number;
  fileName: string;
  onDelete: (index: number) => void;
  downloadUrl?: string;
};

export const FileDialogRow: React.FC<FileDialogRowProps> = (props) => {
  const { index, fileName, onDelete, downloadUrl } = props;
  return (
    <ListItem key={index}>
      <ListItemText>
        {downloadUrl ? (
          <a target="_blank" rel="noopener noreferrer" href={downloadUrl}>
            {fileName}
          </a>
        ) : (
          <>[{fileName}]</>
        )}
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(index)} size="large">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
