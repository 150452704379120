import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newSupplyChainManagementRow } from "./consts";
import { useActions } from "./hook/action";
import { SupplyChainManagementRecordRow } from "./types";

const source = IndexType.SupplyChainManagement;

export const SupplyChainManagementPage: React.FC = () => {
  const [initRows, setInitRows] = useState<SupplyChainManagementRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { Dialog, openDialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "asOfDate",
        name: intl.formatMessage({ id: "excel.workforce.asOfDate" }),
        width: 150,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "asOfDate"),
      },
      {
        key: "numberOfSuppliers",
        name: intl.formatMessage({ id: "excel.supplyChainManagement.numberOfSuppliers" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "numberOfSuppliers"),
      },
      {
        key: "hk",
        name: intl.formatMessage({ id: "excel.workforce.hk" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "hk"),
      },
      {
        key: "china",
        name: intl.formatMessage({ id: "excel.workforce.china" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "china"),
      },
      {
        key: "otherRegions",
        name: intl.formatMessage({ id: "excel.workforce.otherRegions" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "otherRegions"),
      },
      {
        key: "engagingDesc",
        name: intl.formatMessage({ id: "excel.supplyChainManagement.engagingDesc" }),
        width: 330,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "engagingDesc"),
      },
      {
        key: "numberOfEngaged",
        name: intl.formatMessage({ id: "excel.supplyChainManagement.numberOfEngaged" }),
        width: 330,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "numberOfEngaged"),
      },
      {
        key: "identifyDesc",
        name: intl.formatMessage({ id: "excel.supplyChainManagement.identifyDesc" }),
        width: 400,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "identifyDesc"),
      },
      {
        key: "promoteDesc",
        name: intl.formatMessage({ id: "excel.supplyChainManagement.promoteDesc" }),
        width: 450,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "promoteDesc"),
      },
    ],
    [intl, companyOptions, minDate, maxDate]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<SupplyChainManagementRecordRow> = (rows, updatedItem) => {
    const { otherRegions, engagingDesc, identifyDesc, promoteDesc } = updatedItem.updated;
    if (otherRegions && otherRegions > 0) {
      openDialog({ content: intl.formatMessage({ id: "error.workforce.otherRegions" }), type: DialogType.ALERT });
    }

    if (isAnyNotNullOrUndefined(engagingDesc, identifyDesc, promoteDesc)) {
      openDialog({ content: intl.formatMessage({ id: "excel.supplyChainManagement.upload" }), type: DialogType.ALERT });
    }

    return rows;
  };

  const introContent = intl.formatMessage({ id: "excel.supplyChainManagement.guide" });
  const steps = getSteps(intl);

  const validationSchema = Yup.lazy<Partial<SupplyChainManagementRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as SupplyChainManagementRecordRow[]);

    const region = ["hk", "china", "otherRegions"];

    const validateByCategory = (keys: string[]) =>
      Yup.number()
        .sumEqual("error.supplyChain.sumOfTotal", keys, "numberOfSuppliers")
        .required("errors.common.require")
        .nonNegativeInteger("error.common.positiveInteger");

    return Yup.array(
      Yup.object<Partial<SupplyChainManagementRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        asOfDate: Yup.string().required("errors.common.require"),
        numberOfSuppliers: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),

        hk: validateByCategory(region),
        china: validateByCategory(region),
        otherRegions: validateByCategory(region),

        engagingDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
        identifyDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
        promoteDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newSupplyChainManagementRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "asOfDate", value: "" },
          { property: "numberOfSuppliers", value: 0 },
          { property: "hk", value: 0 },
          { property: "china", value: 0 },
          { property: "otherRegions", value: 0 },
          { property: "numberOfEngaged", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
