import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { DateFormat, isValidDateFormat, toMoment } from "utils/date-time";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { NumberSchema } from "yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newInjuryAndFatalitiesRow } from "./consts";
import { useActions } from "./hook/action";
import { InjuryAndFatalitiesRecordRow } from "./types";

const source = IndexType.InjuryAndFatalities;

export const InjuryFatalitiesPage: React.FC = () => {
  const [initRows, setInitRows] = useState<InjuryAndFatalitiesRecordRow[]>([]);
  const [defaultNotice, setDefaultNotice] = useState(false);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, projectPeriod, maxDate } = useProjectCompany(source);

  const minDate = useMemo(
    () => toMoment(projectPeriod.startDate).subtract("2", "year").toDate(),
    [projectPeriod.startDate]
  );

  const columns = useMemo<IColumnItem[]>(() => {
    const isDisableDateColumn = ({ rowData: { occurrenceDate } }) => {
      if (!isValidDateFormat(occurrenceDate, DateFormat.Date)) return true;
      return toMoment(occurrenceDate, DateFormat.Date).isBefore(projectPeriod.startDate);
    };
    return [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "occurrenceDate",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.occurrenceDate" }),
        width: 150,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "occurrenceDate"),
      },
      {
        key: "numberOfInjuries",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.numberOfInjuries" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        isDisable: isDisableDateColumn,
        formatter: getFormatter(Formatter, "numberOfInjuries"),
      },
      {
        key: "daysOfInjury",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.daysOfInjury" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        type: "number",
        isDisable: isDisableDateColumn,
        formatter: getFormatter(Formatter, "daysOfInjury"),
      },
      {
        key: "numberOfFatalities",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.numberOfFatalities" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "numberOfFatalities"),
      },
      {
        key: "incidentDescription",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.incidentDescription" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        formatter: getFormatter(Formatter, "incidentDescription"),
      },
      {
        key: "status",
        name: intl.formatMessage({ id: "excel.injuryAndFatalities.status" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "status"),
      },
    ];
  }, [intl, companyOptions, minDate, maxDate, projectPeriod.startDate]);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);
    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setDefaultNotice(true);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<InjuryAndFatalitiesRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as InjuryAndFatalitiesRecordRow[]);

    return Yup.array(
      Yup.object<Partial<InjuryAndFatalitiesRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        occurrenceDate: Yup.string()
          .required("errors.common.require")
          .test("verify-date", "errors.common.invalid", function (value) {
            return isValidDateFormat(value, DateFormat.Date);
          }),
        numberOfInjuries: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger")
          .when("occurrenceDate", {
            is: (occurrenceDate) => toMoment(occurrenceDate, DateFormat.Date).isBefore(projectPeriod.startDate),
            then: (schema: NumberSchema) => schema.equals([0], "errors.common.invalid"),
          }),
        daysOfInjury: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger")
          .when("occurrenceDate", {
            is: (occurrenceDate) => toMoment(occurrenceDate, DateFormat.Date).isBefore(projectPeriod.startDate),
            then: (schema: NumberSchema) => schema.equals([0], "errors.common.invalid"),
          }),
        numberOfFatalities: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),
        incidentDescription: Yup.string().required("errors.common.require"),
        status: Yup.string().required("errors.common.require"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.injuryAndFatalities.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        openNotice={defaultNotice}
        columns={columns}
        initRows={initRows}
        newRowData={newInjuryAndFatalitiesRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "occurrenceDate", value: "" },
          { property: "numberOfInjuries", value: 0 },
          { property: "daysOfInjury", value: 0 },
          { property: "numberOfFatalities", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
