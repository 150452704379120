import { ActionParam, EventAction } from "generated/graphql";
import { defaultMoment, toMoment } from "utils/date-time";
import { DataGridRow } from "..";

export const formSubmitDataEvent = (action: EventAction, row: DataGridRow): ActionParam => {
  return {
    id: row.rowId,
    action,
    projectCompanyId: row.companyId,
  };
};

export const checkIsSameMinDate = (minDate: Date, maxDate: Date): boolean => {
  return toMoment(minDate).isSame(defaultMoment) && toMoment(maxDate).isSame(defaultMoment);
};
