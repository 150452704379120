import React from "react";

interface IInputProps {
  column: {
    key: string;
    type?: string;
    isDisable?: (props) => boolean;
    inputDataMeta?: {
      positive?: boolean;
      integer?: boolean;
    };
  };
  onCommit: () => void;
}
class InputEditor extends React.Component<IInputProps, { value: string }> {
  static defaultProps;
  private readonly inputRef;
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    if (this.props.column.type === "number" && isNaN(props.value)) {
      this.state = { value: "" };
    }
    this.inputRef = React.createRef();
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.isDisable = this.isDisable.bind(this);
  }

  getValue() {
    const result = {};
    result[this.props.column.key] = this.state.value;
    if (this.props.column.type === "number") {
      result[this.props.column.key] = +this.state.value;
    }
    return result;
  }

  getInputNode() {
    this.inputRef.current.focus();
    return this.inputRef.current;
    // return undefined;
  }

  handleChangeComplete(e) {
    const value = e.target.value;
    let checking = /^[+-]?\d*[.]?(\d+)?$/;
    if (this.props.column.inputDataMeta && this.props.column.inputDataMeta.positive) {
      checking = /^\d*[.]?(\d+)?$/;
    }
    if (
      this.props.column.inputDataMeta &&
      this.props.column.inputDataMeta.integer &&
      this.props.column.inputDataMeta.positive
    ) {
      checking = /^\d*$/;
    } else if (
      this.props.column.inputDataMeta &&
      this.props.column.inputDataMeta.integer &&
      !this.props.column.inputDataMeta.positive
    ) {
      checking = /^[+-]?\d*$/;
    }
    if (!checking.test(e.target.value) && this.props.column.type === "number") return;
    this.setState({ value });
    // () => this.props.onCommit()f
  }

  handleOnBlur() {
    this.props.onCommit();
  }

  isDisable() {
    if (!this.props.column.isDisable) {
      return false;
    }
    return this.props.column.isDisable(this.props);
  }

  render() {
    return (
      <input
        ref={this.inputRef}
        onChange={this.handleChangeComplete}
        value={this.state.value}
        className="data-editor"
        onBlur={this.handleOnBlur}
        style={{ width: "100%", background: "#eee" }}
        disabled={this.isDisable()}
      />
    );
  }
}

InputEditor.defaultProps = {
  column: {
    type: "text",
    key: ""
  },
  value: ""
};

export default InputEditor;
