import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, ProductResponsibilityParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { useCallback, useContext } from "react";
import { newProductResponsibilityPolicyRow } from "../consts";
import { useAPI } from "../hook/api";
import { ProductResponsibilityPolicyRecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: ProductResponsibilityPolicyRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getProductResponsibilityPolicyData, submitProductResponsibilityPolicy } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const productResponsibilityPolicy = await getProductResponsibilityPolicyData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = productResponsibilityPolicy.flatMap((p) =>
          p.records.map<ProductResponsibilityPolicyRecordRow>((r) => ({
            ...newProductResponsibilityPolicyRow,
            recordId: r.id,
            originalCompanyId: p.projectCompany.id,
            companyId: p.projectCompany.id,
            remarks: r.remarks,
            practicesDesc: r.practicesDesc,
            qualityDesc: r.qualityDesc,
            dataDesc: r.dataDesc,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getProductResponsibilityPolicyData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<ProductResponsibilityParam>((e) => {
          const data = e.data as DataGridRow<ProductResponsibilityPolicyRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              practicesDesc: data.practicesDesc,
              qualityDesc: data.qualityDesc,
              dataDesc: data.dataDesc,
            },
          };
        });

        return await submitProductResponsibilityPolicy({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitProductResponsibilityPolicy]
  );

  return { getData, submitData };
};
