import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetEnvDashboardDataQuery } from "generated/graphql";
import {
  GridWithLoading,
  IntensityBlock,
  MultiColumnBreakdownBlock,
  TotalBlock,
  WasteBlock,
  YearlyComparisonBlock,
} from "..";
import { useBreakdownHelper } from "../../hook/breakdown-helper";
import { useDashboardHelper } from "../../hook/dashboard-helper";
import { getChartInput, getUnitIntlKey } from "../../utils";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100, DASHBOARD_BLOCK_HEIGHT_50, TabPosition } from "../consts";
import { SectionHeader } from "../section-header";

const breakdownPieChartColors = [
  DashboardColor.chart.blue2,
  DashboardColor.chart.blue1,
  DashboardColor.chart.blue3,
  DashboardColor.chart.blue4,
];

export type WasteDashboardProps = {
  data: GetEnvDashboardDataQuery;
  currentYear: string;
  previousYear?: string;
  isLoading: boolean;
};

export const WasteDashboard: React.FC<WasteDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data.dashboardEnv;
  const {
    wasteTotal,
    wasteIntensity,
    hazardousWasteTotal,
    hazardousWasteIntensity,
    nonHazardousWasteTotal,
    nonHazardousWasteIntensity,
  } = data.dashboardTarget ?? {};

  const intl = useIntl();

  /** Waste */

  const { total: wasteTotalHelper, intensity: wasteIntensityHelper } = useDashboardHelper({
    current: current.waste,
    previous: previous?.waste,
    nf1Label: "unit",
  });

  const targetWasteIntensity = wasteIntensity?.find(({ option }) => option === wasteIntensityHelper.current.option);

  const currentWasteTotalInput = getChartInput({ name: currentYear, datum: wasteTotalHelper.current })!;
  const previousWasteTotalInput = getChartInput({ name: previousYear, datum: wasteTotalHelper.previous });
  const targetWasteTotalInput =
    wasteTotal?.value && wasteTotal.value > 0
      ? getChartInput({ name: intl.formatMessage({ id: "dashboard.common.target" }), datum: wasteTotal })
      : undefined;

  const currentWasteIntensityInput = getChartInput({ name: currentYear, datum: wasteIntensityHelper.current })!;
  const previousWasteIntensityInput = getChartInput({ name: previousYear, datum: wasteIntensityHelper.previous });
  const targetWasteIntensityInput =
    targetWasteIntensity?.value && targetWasteIntensity.value > 0
      ? getChartInput({
          name: intl.formatMessage({ id: "dashboard.common.target" }),
          datum: targetWasteIntensity,
        })
      : undefined;

  /** Hazardous Waste */

  const { total: hazardousTotalHelper, intensity: hazardousIntensityHelper } = useDashboardHelper({
    current: current.hazardousWaste,
    previous: previous?.hazardousWaste,
  });

  const targetHazardousIntensity = hazardousWasteIntensity?.find(
    ({ option }) => option === hazardousIntensityHelper.current.option
  );

  const currentHazardousTotalInput = getChartInput({ name: currentYear, datum: hazardousTotalHelper.current })!;
  const previousHazardousTotalInput = getChartInput({ name: previousYear, datum: hazardousTotalHelper.previous });
  const targetHazardousTotalInput =
    hazardousWasteTotal?.value && hazardousWasteTotal.value > 0
      ? getChartInput({
          name: intl.formatMessage({ id: "dashboard.common.target" }),
          datum: hazardousWasteTotal,
        })
      : undefined;

  const currentHazardousIntensityInput = getChartInput({
    name: currentYear,
    datum: hazardousIntensityHelper.current,
  })!;
  const previousHazardousIntensityInput = getChartInput({
    name: previousYear,
    datum: hazardousIntensityHelper.previous,
  });
  const targetHazardousIntensityInput =
    targetHazardousIntensity?.value && targetHazardousIntensity.value > 0
      ? getChartInput({
          name: intl.formatMessage({ id: "dashboard.common.target" }),
          datum: targetHazardousIntensity,
        })
      : undefined;

  /** Non-hazardous Waste */

  const { breakdown } = current.nonHazardousWaste;
  const { breakdown: pBreakdown } = previous?.nonHazardousWaste ?? {};

  const { total: nonHazardousTotalHelper, intensity: nonHazardousIntensityHelper } = useDashboardHelper({
    current: current.nonHazardousWaste,
    previous: previous?.nonHazardousWaste,
  });

  const { groupedData, isGroupedDataVisible } = useBreakdownHelper({
    data: breakdown.data.map(({ name, ...rest }) => ({
      name: intl.formatMessage({ id: `dashboard.env.waste.non-hazardous.breakdown.${name}` }),
      ...rest,
    })),
    color: breakdownPieChartColors,
  });

  const { groupedData: pGroupedData, isGroupedDataVisible: isPGroupedDataVisible } = useBreakdownHelper({
    data: pBreakdown?.data.map(({ name, ...rest }) => ({
      name: intl.formatMessage({ id: `dashboard.env.waste.non-hazardous.breakdown.${name}` }),
      ...rest,
    })),
    color: breakdownPieChartColors,
  });

  const targetNonHazardousIntensity = nonHazardousWasteIntensity?.find(
    ({ option }) => option === nonHazardousIntensityHelper.current.option
  );

  const currentNonHazardousTotalInput = getChartInput({ name: currentYear, datum: nonHazardousTotalHelper.current })!;
  const previousNonHazardousTotalInput = getChartInput({
    name: previousYear,
    datum: nonHazardousTotalHelper.previous,
  });
  const targetNonHazardousTotalInput =
    nonHazardousWasteTotal?.value && nonHazardousWasteTotal.value > 0
      ? getChartInput({
          name: intl.formatMessage({ id: `dashboard.common.target` }),
          datum: nonHazardousWasteTotal,
        })
      : undefined;

  const currentNonHazardousIntensityInput = getChartInput({
    name: currentYear,
    datum: nonHazardousIntensityHelper.current,
  })!;
  const previousNonHazardousIntensityInput = getChartInput({
    name: previousYear,
    datum: nonHazardousIntensityHelper.previous,
  });
  const targetNonHazardousIntensityInput =
    targetNonHazardousIntensity?.value && targetNonHazardousIntensity.value > 0
      ? getChartInput({
          name: intl.formatMessage({ id: `dashboard.common.target` }),
          datum: targetNonHazardousIntensity,
        })
      : undefined;

  return (
    <>
      {(isLoading ||
        hazardousTotalHelper.isVisible ||
        hazardousIntensityHelper.isVisible ||
        nonHazardousTotalHelper.isVisible ||
        nonHazardousIntensityHelper.isVisible ||
        isGroupedDataVisible) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.env.waste.title" })}
              description={intl.formatMessage({ id: "dashboard.env.waste.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              {(wasteTotalHelper.isVisible || wasteIntensityHelper.isVisible) && (
                <>
                  <Grid xs={2}>
                    <Grid container spacing={2}>
                      <GridWithLoading
                        xs={12}
                        height={DASHBOARD_BLOCK_HEIGHT_50}
                        isLoading={isLoading}
                        isVisible={wasteTotalHelper.isVisible}
                      >
                        <TotalBlock
                          title={intl.formatMessage({ id: "dashboard.env.waste.total.title" })}
                          value={wasteTotalHelper.current.value}
                          unit={intl.formatMessage({ id: getUnitIntlKey(wasteTotalHelper.current.unit) })}
                        />
                      </GridWithLoading>

                      <GridWithLoading
                        xs={12}
                        height={DASHBOARD_BLOCK_HEIGHT_50}
                        isLoading={isLoading}
                        isVisible={wasteIntensityHelper.isVisible}
                      >
                        <IntensityBlock
                          title={intl.formatMessage({ id: "dashboard.env.waste.intensity.title" })}
                          value={wasteIntensityHelper.current.value}
                          valueColor={wasteIntensityHelper.color}
                          unit={intl.formatMessage({ id: getUnitIntlKey(wasteTotalHelper.current.unit) })}
                          selectorProps={{
                            ...wasteIntensityHelper.nf1Select,
                            options: wasteIntensityHelper.nf1Select.options.map(({ label, value }) => ({
                              label: intl.formatMessage({ id: getUnitIntlKey(label) }),
                              value,
                            })),
                          }}
                        />
                      </GridWithLoading>
                    </Grid>
                  </Grid>

                  <GridWithLoading
                    xs={7}
                    height={DASHBOARD_BLOCK_HEIGHT_100}
                    isLoading={isLoading}
                    isVisible={wasteTotalHelper.isVisible || wasteIntensityHelper.isVisible}
                  >
                    <YearlyComparisonBlock
                      title={intl.formatMessage({ id: "dashboard.common.yearly-comparison" })}
                      left={
                        wasteTotalHelper.isVisible
                          ? {
                              title: intl.formatMessage({ id: "dashboard.env.waste.total.title" }),
                              main: currentWasteTotalInput,
                              mainColor: wasteTotalHelper.color,
                              sub: previousWasteTotalInput,
                              manual: targetWasteTotalInput,
                              manualColor: DashboardColor.chart.blue1,
                              truncateBaseline: true,
                            }
                          : undefined
                      }
                      right={
                        wasteIntensityHelper.isVisible
                          ? {
                              title: intl.formatMessage({ id: "dashboard.env.waste.intensity.title" }),
                              main: currentWasteIntensityInput,
                              mainColor: wasteIntensityHelper.color,
                              sub: previousWasteIntensityInput,
                              manual: targetWasteIntensityInput,
                              manualColor: DashboardColor.chart.blue1,
                            }
                          : undefined
                      }
                    />
                  </GridWithLoading>
                </>
              )}

              <GridWithLoading
                xs={3}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={isGroupedDataVisible}
              >
                <MultiColumnBreakdownBlock
                  mains={{
                    tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                    charts: [
                      {
                        title: intl.formatMessage(
                          { id: "dashboard.env.waste.non-hazardous.breakdown.title" },
                          { year: currentYear }
                        ),
                        data: groupedData,
                        unit: intl.formatMessage({ id: getUnitIntlKey(breakdown.unit) }),
                        labelType: "total",
                      },
                    ],
                  }}
                  subs={
                    isPGroupedDataVisible
                      ? {
                          tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                          charts: [
                            {
                              title: intl.formatMessage(
                                { id: "dashboard.env.waste.non-hazardous.breakdown.title" },
                                { year: previousYear }
                              ),
                              data: pGroupedData,
                              unit: intl.formatMessage({ id: getUnitIntlKey(breakdown.unit) }),
                              labelType: "total",
                            },
                          ],
                        }
                      : undefined
                  }
                  tabPosition={TabPosition.Right}
                  legendProps={{
                    position: "right",
                    showPercentage: true,
                    showValue: true,
                  }}
                />
              </GridWithLoading>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <GridWithLoading
                xs={6}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={hazardousTotalHelper.isVisible || hazardousIntensityHelper.isVisible}
              >
                <WasteBlock
                  header={intl.formatMessage({ id: "dashboard.env.waste.hazardous.title" })}
                  total={
                    hazardousTotalHelper.isVisible
                      ? {
                          main: currentHazardousTotalInput,
                          mainColor: hazardousTotalHelper.color,
                          sub: previousHazardousTotalInput,
                          manual: targetHazardousTotalInput,
                          manualColor: DashboardColor.chart.blue1,
                          description: intl.formatMessage({ id: "dashboard.env.waste.hazardous.waste-produced" }),
                          unit: intl.formatMessage({ id: getUnitIntlKey(hazardousTotalHelper.current.unit) }),
                        }
                      : undefined
                  }
                  intensity={
                    hazardousIntensityHelper.isVisible
                      ? {
                          main: currentHazardousIntensityInput,
                          mainColor: hazardousIntensityHelper.color,
                          sub: previousHazardousIntensityInput,
                          manual: targetHazardousIntensityInput,
                          manualColor: DashboardColor.chart.blue1,
                          description: intl.formatMessage({
                            id: "dashboard.env.waste.hazardous.waste-produced-intensity",
                          }),
                          unit: `${intl.formatMessage({
                            id: getUnitIntlKey(hazardousTotalHelper.current.unit),
                          })} / ${intl.formatMessage({ id: getUnitIntlKey(hazardousIntensityHelper.current.unit) })}`,
                          selectProps: {
                            options: hazardousIntensityHelper.nf1Select.options,
                            value: hazardousIntensityHelper.nf1Select.nf1,
                            onChangeValue: hazardousIntensityHelper.nf1Select.onChangeNf1,
                          },
                        }
                      : undefined
                  }
                />
              </GridWithLoading>

              <GridWithLoading
                xs={6}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={nonHazardousTotalHelper.isVisible || nonHazardousIntensityHelper.isVisible}
              >
                <WasteBlock
                  header={intl.formatMessage({ id: "dashboard.env.waste.non-hazardous.title" })}
                  total={
                    nonHazardousTotalHelper.isVisible
                      ? {
                          main: currentNonHazardousTotalInput,
                          mainColor: nonHazardousTotalHelper.color,
                          sub: previousNonHazardousTotalInput,
                          manual: targetNonHazardousTotalInput,
                          manualColor: DashboardColor.chart.blue1,
                          description: intl.formatMessage({ id: "dashboard.env.waste.non-hazardous.waste-produced" }),
                          unit: intl.formatMessage({ id: getUnitIntlKey(nonHazardousIntensityHelper.current.unit) }),
                        }
                      : undefined
                  }
                  intensity={
                    nonHazardousIntensityHelper.isVisible
                      ? {
                          main: currentNonHazardousIntensityInput,
                          mainColor: nonHazardousIntensityHelper.color,
                          sub: previousNonHazardousIntensityInput,
                          manual: targetNonHazardousIntensityInput,
                          manualColor: DashboardColor.chart.blue1,
                          description: intl.formatMessage({
                            id: "dashboard.env.waste.non-hazardous.waste-produced-intensity",
                          }),
                          unit: `${intl.formatMessage({
                            id: getUnitIntlKey(nonHazardousTotalHelper.current.unit),
                          })} / ${intl.formatMessage({
                            id: getUnitIntlKey(nonHazardousIntensityHelper.current.unit),
                          })}`,
                          selectProps: {
                            options: nonHazardousIntensityHelper.nf1Select.options,
                            value: nonHazardousIntensityHelper.nf1Select.nf1,
                            onChangeValue: nonHazardousIntensityHelper.nf1Select.onChangeNf1,
                          },
                        }
                      : undefined
                  }
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
