import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DataGridRow,
  DateRangeEditor,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnPreValidateRows,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { changeDateFormat, DateFormat, toMoment } from "utils/date-time";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { hazardousWasteTypeOptions, newHazardousWasteRow, tips } from "./consts";
import { useActions } from "./hook/action";
import { HazardousWasteRecordRow } from "./types";

const source = IndexType.HazardousWaste;

export const HazardousWastePage: React.FC = () => {
  const [initRows, setInitRows] = useState<HazardousWasteRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { getData, submitData } = useActions();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 250,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "hazardousWasteName",
        name: intl.formatMessage({ id: "excel.hazardous.name" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "hazardousWasteName"),
      },
      {
        key: "hazardousWasteSource",
        name: intl.formatMessage({ id: "excel.hazardous.source" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "hazardousWasteSource"),
      },
      {
        key: "hazardousWasteTypes",
        name: intl.formatMessage({ id: "excel.hazardous.type" }),
        width: 300,
        resizable: true,
        editor: SelectEditor,
        options: hazardousWasteTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(hazardousWasteTypeOptions), "hazardousWasteTypes"),
        isCreatable: true,
      },
      {
        key: "handlingDate",
        name: intl.formatMessage({ id: "excel.hazardous.date" }),
        width: 300,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "handlingDate"),
      },
      {
        key: "previousQuantityRemaining",
        name: intl.formatMessage({ id: "excel.hazardous.previous-quantity" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "previousQuantityRemaining"),
      },
      {
        key: "quantityHandled",
        name: intl.formatMessage({ id: "excel.hazardous.quantity-handled" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityHandled"),
      },
      {
        key: "quantityRemaining",
        name: intl.formatMessage({ id: "excel.hazardous.quantity-remaining" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityRemaining"),
      },
      {
        key: "quantityGenerated",
        name: intl.formatMessage({ id: "excel.hazardous.quantity-generated" }),
        width: 300,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "quantityGenerated"),
      },
    ],
    [companyOptions, intl, maxDate, minDate]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows(rows);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<HazardousWasteRecordRow> = (rows, updatedItem) => {
    const { quantityHandled, quantityRemaining, previousQuantityRemaining } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(quantityHandled, quantityRemaining, previousQuantityRemaining)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        rows[i].quantityGenerated = +(
          +rows[i].quantityHandled +
          +rows[i].quantityRemaining -
          +rows[i].previousQuantityRemaining
        );
      }
    }

    return rows;
  };

  const handlePreValidate: OnPreValidateRows<HazardousWasteRecordRow> = (rows) => {
    return [...rows].map((r, i) => ({
      ...r,
      id: (i + 1).toString(),
      sortingHandlingDate: changeDateFormat(r.handlingDate, DateFormat.Date, DateFormat.DigitOnlyDate),
    }));
  };

  const validationSchema = Yup.lazy<Partial<DataGridRow<HazardousWasteRecordRow>>[]>((values) => {
    const rows = values as DataGridRow<HazardousWasteRecordRow>[];
    const result = isValidAllCompanies(rows);

    // rows grouped by company & waste type, and sorted by handling date ascending
    const mapping: { [CompanyId: string]: { [WasteType: string]: DataGridRow<HazardousWasteRecordRow>[] } } =
      rows.reduce((obj, r) => {
        if (!r.companyId || !r.hazardousWasteTypes || !r.handlingDate) return obj;

        if (!obj[r.companyId]) obj[r.companyId] = {};
        if (!obj[r.companyId][r.hazardousWasteTypes]) obj[r.companyId][r.hazardousWasteTypes] = [];

        obj[r.companyId][r.hazardousWasteTypes].push(r);
        return obj;
      }, {});

    for (const companyId in mapping) {
      for (const wasteType in mapping[companyId]) {
        mapping[companyId][wasteType].sort((a, b) => {
          if (a.handlingDate === b.handlingDate) {
            return 0;
          } else {
            return toMoment(a.handlingDate).isBefore(b.handlingDate) ? -1 : 1;
          }
        });
      }
    }

    return Yup.array(
      Yup.object<Partial<HazardousWasteRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        hazardousWasteName: Yup.string().required("errors.common.require"),
        hazardousWasteSource: Yup.string().required("errors.common.require"),
        hazardousWasteTypes: Yup.string().required("errors.common.require"),
        handlingDate: Yup.string().required("errors.common.require"),
        quantityHandled: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityRemaining: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        previousQuantityRemaining: Yup.number()
          .required("errors.common.require")
          .test("consistent quantity remaining", "errors.hazardous.validate-quantity", function (value) {
            const row = this.parent as DataGridRow<HazardousWasteRecordRow>;
            if (!row.companyId || !row.hazardousWasteTypes || !row.handlingDate) return true;

            // compare current row's previousQuantityRemaining w/ previous rows' quantityRemaining
            const recordGroup = mapping[row.companyId][row.hazardousWasteTypes];
            const rowIndex = recordGroup.findIndex(({ rowId }) => rowId === row.rowId);
            // if row index is 0 (no previous row) or -1 (not exist), no validation needed
            if (rowIndex <= 0) return true;

            return value === recordGroup[rowIndex - 1].quantityRemaining;
          }),
        quantityGenerated: Yup.number().required("errors.common.require").min(0, "errors.hazardous.quantityGenerated"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.hazardous-waste.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newHazardousWasteRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "handlingDate", value: "" },
          { property: "quantityHandled", value: 0 },
          { property: "quantityRemaining", value: 0 },
          { property: "previousQuantityRemaining", value: 0 },
          { property: "quantityGenerated", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
        onPreValidate={handlePreValidate}
      />
    </DatasheetPaper>
  );
};
