import { UserFormFields } from "./types";

export const defaultFormValue: UserFormFields = {
  loginId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  title: "",
};
