import { createContext } from "react";

export type LayoutContextProps = {
  isDrawerOpen: boolean;
  layoutClasses: Record<string, string>;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};

export const LayoutContext = createContext<LayoutContextProps>({
  isDrawerOpen: false,
  layoutClasses: {},
  handleDrawerClose: () => undefined,
  handleDrawerOpen: () => undefined,
});
