import { RecordStatus } from "generated/graphql";
import { ProductResponsibilityRecordRow } from "./types";

export const newProductResponsibilityRow: ProductResponsibilityRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  asOfDate: "",
  unitOfProduct: "",
  numberOfComplaints: 0,
  numberOfProducts: 0,
  numberOfRetrieved: 0,
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
