import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newCommunityInvestmentRow } from "./consts";
import { useActions } from "./hook/action";
import { CommunityInvestmentRecordRow } from "./types";

const source = IndexType.CommunityInvestment;

export const CommunityInvestmentPage: React.FC = () => {
  const [initRows, setInitRows] = useState<CommunityInvestmentRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "policyDescription",
        name: intl.formatMessage({ id: "excel.communityInvestment.policyDescription" }),
        width: 250,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "policyDescription"),
      },
      {
        key: "organizer",
        name: intl.formatMessage({ id: "excel.communityInvestment.organizer" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "organizer"),
      },
      {
        key: "date",
        name: intl.formatMessage({ id: "excel.communityInvestment.date" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "date"),
      },
      {
        key: "eventDescription",
        name: intl.formatMessage({ id: "excel.communityInvestment.eventDescription" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "eventDescription"),
      },
      {
        key: "resources",
        name: intl.formatMessage({ id: "excel.communityInvestment.resources" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "resources"),
      },
    ],
    [intl, companyOptions]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows(rows);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<CommunityInvestmentRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as CommunityInvestmentRecordRow[]);

    return Yup.array(
      Yup.object<Partial<CommunityInvestmentRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.communityInvestment.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newCommunityInvestmentRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[{ property: "date", value: "" }]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
