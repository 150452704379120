import React from "react";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { FastField, Form, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { UploadPanel } from "components/upload-panel";
import { DocFormFields } from "../types";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: 0,
    width: "100%",
  },
  field: {
    width: "100%",
  },
  errorMsg: {
    color: "red",
  },
}));

export const DocumentForm: React.FC<FormikProps<DocFormFields>> = (props) => {
  const { values, touched, errors, handleChange, setFieldTouched, setFieldValue, children } = props;

  const fileName = values.file && values.file.name;
  const { classes } = useStyles();
  const intl = useIntl();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    setFieldTouched("file", true);
    setFieldValue("file", event.target.files[0]);
  };

  return (
    <>
      <Form autoComplete="off">
        <Grid container spacing={3} className={classes.root}>
          <Grid xs={12}>
            <FormControl variant="standard" className={classes.field}>
              <FastField
                required
                id="desc"
                name="desc"
                label={intl.formatMessage({ id: "header.document.doc-desc" })}
                component={TextField}
                variant="standard"
                fullWidth={true}
                value={values.desc}
                onChange={handleChange}
                error={errors.desc && touched.desc}
              />
            </FormControl>
            {errors.desc && touched.desc && <div className={classes.errorMsg}>{errors.desc}</div>}
          </Grid>
          <Grid xs={12}>
            <UploadPanel
              fileName={fileName || ""}
              onFileChange={handleFileChange}
              error={!!errors.file && touched.file}
              errorMessage={errors.file ? intl.formatMessage({ id: errors.file }) : ""}
              formatTips={intl.formatMessage({ id: "data-grid.tools.file-format" })}
            />
          </Grid>
        </Grid>
      </Form>
      {children}
    </>
  );
};
