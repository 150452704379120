import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetPackagingMaterialDataDocument,
  GetPackagingMaterialDataQuery,
  GetPackagingMaterialDataQueryVariables,
  SubmitPackagingMaterialDocument,
  SubmitPackagingMaterialMutation,
  SubmitPackagingMaterialMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getPackagingMaterialData: (
    variables: GetPackagingMaterialDataQueryVariables
  ) => Promise<GetPackagingMaterialDataQuery["project"]["packagingMaterial"]>;
  submitPackagingMaterial: (
    variables: SubmitPackagingMaterialMutationVariables
  ) => Promise<SubmitPackagingMaterialMutation["submitPackagingMaterial"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getPackagingMaterialData = useCallback(
    async (variables: GetPackagingMaterialDataQueryVariables) => {
      const response = await client.query<GetPackagingMaterialDataQuery, GetPackagingMaterialDataQueryVariables>({
        query: GetPackagingMaterialDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.packagingMaterial;
    },
    [client]
  );

  const submitPackagingMaterial = useCallback(
    async (variables: SubmitPackagingMaterialMutationVariables) => {
      const response = await client.mutate<SubmitPackagingMaterialMutation, SubmitPackagingMaterialMutationVariables>({
        mutation: SubmitPackagingMaterialDocument,
        variables,
      });

      return response.data?.submitPackagingMaterial ?? false;
    },
    [client]
  );

  return {
    getPackagingMaterialData,
    submitPackagingMaterial,
  };
};
