import React from "react";

import Button from "@mui/material/Button";
import { useIntl } from "react-intl";

export type HeaderAddButtonProps = {
  onClick: () => void;
};

export const HeaderAddButton: React.FC<HeaderAddButtonProps> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Button color="primary" variant="outlined" onClick={onClick}>
      {intl.formatMessage({ id: "button.add" })}
    </Button>
  );
};
