import React from "react";

import { DrawerContainer } from "./drawer-container";
import { Header } from "./header";
import { Sidebar } from "./sidebar";

export const NoRoleLayout: React.FC = () => (
  <>
    <Header />
    <DrawerContainer>
      <Sidebar menu={[]} />
    </DrawerContainer>
  </>
);
