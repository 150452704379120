import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { Nf2RecordRow } from "./types";

export const newNf2Row: Nf2RecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  unit: "",
  value: 0,
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<Nf2RecordRow, string> = {
  unit: "tips.nf2.unit",
};

export enum PaymentUnit {
  HKD = "HKD",
  RMB = "RMB",
  USD = "USD",
}

export const paymentUnitOptions: OptionType[] = [
  { label: "港元 HKD", value: PaymentUnit.HKD },
  { label: "人民币 RMB", value: PaymentUnit.RMB },
  { label: "美元 USD", value: PaymentUnit.USD },
];
