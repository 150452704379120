import React, { ElementRef, useEffect, useImperativeHandle, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { ICellEditorParams } from "ag-grid-community";
import { ICellEditorReactComp } from "ag-grid-react";
import Select from "react-select";

import { OptionType } from "utils/interfaces";

type SelectEditorProps = {
  options: OptionType[];
};

export const SelectEditor = React.forwardRef((props: ICellEditorParams & SelectEditorProps, ref) => {
  const [value, setValue] = useState<OptionType | undefined>();

  useEffect(() => {
    if (props.value) {
      const targetOption = props.options.filter((option) => option.value === props.value);

      if (targetOption.length > 0) {
        setValue(targetOption[0]);
        return;
      }
    }

    setValue(undefined);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useImperativeHandle<ElementRef<typeof SelectEditor>, ICellEditorReactComp>(ref, () => ({
    getValue() {
      return value ? value.value : "";
    },
  }));

  return (
    <Grid style={{ width: props.eGridCell.style.width }}>
      <Select
        value={value}
        options={props.options}
        onChange={(option) => setValue(option as OptionType)}
        menuPlacement="auto"
      />
    </Grid>
  );
});
