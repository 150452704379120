import React from "react";

import zhCN from "date-fns/locale/zh-CN";
import zhTW from "date-fns/locale/zh-TW";
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";

import { DateFormat, isValidDateFormat, toDate, toDateString } from "utils/date-time";
import { Lang } from "../lang";

registerLocale("zh-cn", zhCN);
registerLocale("zh-tw", zhTW);

const CustomInput = React.forwardRef<HTMLInputElement>((props: React.HTMLProps<HTMLInputElement>, ref) => (
  <input ref={ref} onClick={props.onClick} value={props.value} type="text" readOnly />
));

interface IDateRangeEditorProps {
  column: {
    key: string;
    dateRangeMetaData: {
      minDate: string;
      maxDate: string;
      startDateProperty: string;
      endDateProperty: string;
      selectsStart: boolean;
      handleExcludeDays: (props) => [];
    };
  };
  rowData: object;
  value: string;
  onCommit: () => void;
}

class DateRangeEditor extends React.Component<IDateRangeEditorProps, { value: string; locale: string }> {
  private readonly inputRef: React.RefObject<DatePicker & { input: HTMLInputElement }>;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { value: props.value, locale: "en" };
    if (props.value === "") {
      if (props.rowData["minDate"]) {
        this.state = { value: toDateString(props.rowData["minDate"], DateFormat.Date), locale: "en" };
      } else {
        this.state = { value: toDateString(props.column.dateRangeMetaData.minDate, DateFormat.Date), locale: "en" };
      }
    }
    this.setLang = this.setLang.bind(this);
  }

  getValue() {
    const result = {};
    result[this.props.column.key] = this.state.value;
    if (!isValidDateFormat(this.state.value, DateFormat.Date)) {
      result[this.props.column.key] = "";
    }
    return result;
  }

  getInputNode() {
    const elem = this.inputRef.current?.input;
    if (elem) {
      elem.setAttribute(
        "style",
        `${elem.style.cssText};width: 100%;text-align: center;background-color: #fff;cursor: pointer`
      );
    }
    return elem;
  }

  handleChangeComplete = (val: Date) => {
    if (!val) {
      this.setState({ value: "" }, () => {
        this.props.onCommit();
      });
    } else {
      this.setState({ value: toDateString(val, DateFormat.Date) }, () => {
        this.props.onCommit();
      });
    }
  };

  getSelectedValue() {
    if (isValidDateFormat(this.state.value, DateFormat.Date)) {
      return toDate(this.state.value, DateFormat.Date);
    } else {
      return toDate(this.props.column.dateRangeMetaData.minDate, DateFormat.Date);
    }
  }

  setLang(lang: string) {
    this.setState({ locale: lang });
  }

  getDatePicker() {
    const { rowData } = this.props;
    const {
      startDateProperty,
      endDateProperty,
      selectsStart,
      handleExcludeDays,
      minDate: colMetaMinDate,
      maxDate: colMetaMaxDate,
    } = this.props.column.dateRangeMetaData;
    let excludeDays = [];
    if (typeof handleExcludeDays === "function") {
      excludeDays = handleExcludeDays(this.props);
    } else {
      excludeDays = handleExcludeDays;
    }

    const startDate = toDate(rowData[startDateProperty], DateFormat.Date);
    const endDate = toDate(rowData[endDateProperty], DateFormat.Date);

    const minDate = toDate(rowData["minDate"] ?? colMetaMinDate, DateFormat.Date);
    const maxDate = toDate(rowData["maxDate"] ?? colMetaMaxDate, DateFormat.Date);

    const dateRangeSelectProps: Pick<ReactDatePickerProps, "selectsStart" | "selectsEnd" | "minDate" | "maxDate"> = {
      selectsStart,
      selectsEnd: !selectsStart,
      minDate: selectsStart || startDate.toString() === "Invalid Date" ? minDate : startDate,
      maxDate: !selectsStart || endDate.toString() === "Invalid Date" ? maxDate : endDate,
    };

    return (
      <DatePicker
        customInput={<CustomInput />}
        locale={this.state.locale}
        ref={this.inputRef}
        autoFocus
        onChange={this.handleChangeComplete}
        selected={this.getSelectedValue()}
        isClearable
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        startDate={startDate}
        endDate={endDate}
        open
        excludeDates={excludeDays}
        {...dateRangeSelectProps}
      />
    );
  }

  render() {
    return (
      <>
        <Lang setLang={this.setLang} />
        {this.getDatePicker()}
      </>
    );
  }
}

export default DateRangeEditor;
