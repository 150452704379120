import React, { ReactElement } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { TableCellProps } from "@mui/material/TableCell";
import { TableRowProps } from "@mui/material/TableRow";
import {
  useColumns,
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useRows,
  useSortBy,
  useTable,
  useTableState,
} from "react-table";
import { makeStyles } from "tss-react/mui";

import { Body } from "./components/body";
import { Pagination } from "./components/pagination";
import { TableHeader } from "./components/table-header";
import { Toolbar } from "./components/toolbar";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
}));

export type DataTableColumn = {
  id: string;
  Header: string;
  show: boolean;
  grouped?: boolean;
  canGroupBy?: boolean;
  canFilter?: boolean;
  canSortBy?: boolean;
  sortedIndex?: number;
  sorted?: { id: string; desc?: boolean };
  visible?: boolean;
};

export type DataTableCell<T extends object = object> = {
  getCellProps: () => TableCellProps;
  column: DataTableColumn;
  row: DataTableRow<T>;
  value: T;
  render: (value: any) => ReactElement;
};

// Just defined useful properties
export type DataTableRow<T extends object = object> = {
  index: number;
  original: T;
  cells: DataTableCell<T>[];
};

interface Props<T extends object = object> {
  data: T[];
  columns: any[];
  state: any;
  goToPage: (pageIndex) => void;
  setPageSize: (size) => void;
  renderRow: (row: DataTableRow<T>, rowProps: TableRowProps) => ReactElement;
  HeaderActionComponent: React.ReactNode | null;
  isOnlyTable?: boolean;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  onSortByChange?: (sortBy?: SortByItem) => void;
}

export type SortByItem = {
  id: string;
  desc: boolean | undefined;
};

const DataTableWithGenerics = <T extends object = object>(props: Props<T>) => {
  const {
    data,
    columns,
    searchValue,
    onSearchValueChange,
    onSortByChange,
    renderRow,
    HeaderActionComponent,
    state,
    goToPage,
    setPageSize,
    isOnlyTable,
  } = props;
  const { classes } = useStyles();

  const tableState = useTableState({
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
    totalCount: state.totalCount,
  });
  const [{ pageIndex, pageSize, totalCount }, setTableState] = tableState;

  React.useEffect(() => {
    setTableState((old) => ({
      ...old,
      ...state,
    }));
  }, [setTableState, state, data]);

  const instance = useTable(
    {
      data,
      columns,
      state: tableState,
      manualPagination: true,
      manualSortBy: true,
    },
    useColumns,
    useRows,
    useGroupBy,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { getTableProps, headerGroups, page, prepareRow } = instance;

  React.useEffect(() => {
    const sortByArray = instance.state[0].sortBy as SortByItem[];

    if (sortByArray && onSortByChange) {
      onSortByChange(sortByArray.length ? sortByArray[0] : undefined);
    }
  }, [onSortByChange, instance.state]);

  const printRow = (row) => {
    prepareRow(row);
    const rowProps = row.getRowProps();
    return renderRow(row, rowProps);
  };

  const tableBody = page && page.length ? page.map((row) => printRow(row)) : null;

  return (
    <Paper className={classes.root}>
      {!isOnlyTable && <Toolbar value={searchValue} onChange={onSearchValueChange} />}

      <Table className={classes.table} {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} HeaderActionComponent={HeaderActionComponent} />
        <Body tableBody={tableBody} />
      </Table>

      {!isOnlyTable && (
        <Pagination
          pageIndex={pageIndex}
          count={totalCount}
          pageSize={pageSize}
          pageOptions={[5, 10, 25]}
          gotoPage={goToPage}
          setPageSize={setPageSize}
        />
      )}
    </Paper>
  );
};

export const DataTable = React.memo(DataTableWithGenerics) as typeof DataTableWithGenerics;
