import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { OtherMobileMachineryRecordRow } from "./types";

export const newOtherMobileMachineryRow: OtherMobileMachineryRecordRow = {
  recordId: "",
  companyId: "",
  machineryType: "",
  fuelType: "",
  petrolEngine: "",
  fuelConsumption: 0,
  fuelUnit: "",
  enginePower: 0,
  numberOfHour: 0,
  energy: 0,
  forkliftClassification: "",
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<OtherMobileMachineryRecordRow, string> = {
  machineryType: "tips.other-mobile-machinery.machinery-type",
  fuelType: "tips.other-mobile-machinery.fuel-type",
  fuelUnit: "tips.other-mobile-machinery.fuel-unit",
  petrolEngine: "tips.other-mobile-machinery.petrol-engine",
  numberOfHour: "tips.other-mobile-machinery.number-hour",
};

export enum OtherMobileMachineryFuelType {
  DieselOil = "Diesel Oil",
  UnleadedPetrol = "Unleaded Petrol",
  Electric = "Electric",
  LPG = "LPG (Liquid)",
  CNG = "CNG",
  LNG = "LNG",
}

export const otherMobileMachineryFuelTypeOptions: OptionType[] = [
  { label: "Diesel oil 柴油", value: OtherMobileMachineryFuelType.DieselOil },
  { label: "Unleaded petrol 无铅汽油", value: OtherMobileMachineryFuelType.UnleadedPetrol },
  { label: "Electric 电动", value: OtherMobileMachineryFuelType.Electric },
  { label: "LPG (Liquid) 液化石油气 (液态)", value: OtherMobileMachineryFuelType.LPG },
  { label: "CNG 压缩天然气/管道天然气", value: OtherMobileMachineryFuelType.CNG },
  { label: "LNG 液化天然气", value: OtherMobileMachineryFuelType.LNG },
];

export enum OtherMobileMachineryFuelUnit {
  Litre = "Litre",
  KG = "kg",
  M3 = "m3",
  KWH = "kWh",
}

export const otherMobileMachineryFuelUnitOptions: OptionType[] = [
  { label: "Litre 升", value: OtherMobileMachineryFuelUnit.Litre },
  { label: "kg 公斤", value: OtherMobileMachineryFuelUnit.KG },
  { label: "m3 立方米", value: OtherMobileMachineryFuelUnit.M3 },
  { label: "kWh 千瓦時", value: OtherMobileMachineryFuelUnit.KWH },
];

export enum MachineryType {
  Forklift = "Forklift",
  ReachStacker = "Reach Stacker",
  ShovelLoader = "Shovel Loader",
  Crane = "Crane",
  Bulldozer = "Bulldozer",
  Excavator = "Excavator",
  LawnMowers = "Lawn Mowers",
  SnowRemovalEquipment = "Snow Removal Equipment",
}

export const machineryTypeOptions: OptionType[] = [
  { label: "Forklift 叉车", value: MachineryType.Forklift },
  { label: "Reach Stacker 正面吊", value: MachineryType.ReachStacker },
  { label: "Shovel Loader 装载机", value: MachineryType.ShovelLoader },
  { label: "Crane 起重机​", value: MachineryType.Crane },
  { label: "Bulldozer 推土机​", value: MachineryType.Bulldozer },
  { label: "Excavator 掘土机​", value: MachineryType.Excavator },
  { label: "Lawn Mowers 割草机​", value: MachineryType.LawnMowers },
  { label: "Snow Removal Equipment 铲雪机", value: MachineryType.SnowRemovalEquipment },
];

export enum PetrolEngine {
  Two = "2",
  Four = "4",
}

export const petrolEngingOptions: OptionType[] = [
  { label: "二衝程 2-stroke", value: PetrolEngine.Two },
  { label: "四衝程 4-stroke", value: PetrolEngine.Four },
];
