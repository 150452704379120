import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "tss-react/mui";

import { DashboardColor } from "./consts";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    paddingBottom: spacing(1),
  },
  title: {
    color: DashboardColor.text.grey,
    fontWeight: "bold",
  },
  description: {
    color: DashboardColor.text.grey,
  },
}));

export type SectionHeaderProps = {
  title: string;
  description?: string;
};

export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  const { title, description: subTitle } = props;

  const { classes } = useStyles();

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid xs={12}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h4" className={classes.description}>
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};
