import TableCell from "@mui/material/TableCell";
import { withStyles } from "@mui/styles";

export const StyledTableCell = withStyles(() => ({
  root: {
    "& span": {
      width: "max-content",
    },
  },
}))(TableCell);
