import React from "react";

import Select, { Props as SelectProps } from "react-select";
import { OptionType } from "utils/interfaces";

const MIN_WIDTH = 60;
const MIN_HEIGHT = 20;

export type CompactSelectProps = Omit<SelectProps<OptionType>, "styles"> & {
  /**
   * Component width
   *
   * minimum: 60
   */
  width?: number;
  /**
   * Component height
   *
   * minimum: 20
   */
  height?: number;
};

/**
 * Styled React-Select component with less padding & controllable width/height
 */
export const CompactSelect: React.FC<CompactSelectProps> = (props) => {
  const { width, height, ...selectProps } = props;

  const getWidth = () => (width ? Math.max(width, MIN_WIDTH) : undefined);
  const getHeight = () => (height ? Math.max(height, MIN_HEIGHT) : undefined);

  return (
    <Select
      {...selectProps}
      styles={{
        // style reference: https://stackoverflow.com/a/60912805
        container: (base) => ({ ...base, width: getWidth() ?? base.width }),
        control: (base) => ({ ...base, minHeight: getHeight() ?? base.minHeight, height: getHeight() ?? base.height }),
        valueContainer: (base) => ({ ...base, paddingTop: 0, paddingBottom: 0, height: getHeight() ?? base.height }),
        dropdownIndicator: (base) => ({ ...base, padding: 0 }),
        input: (base) => ({ ...base, margin: 0, height: getHeight() ?? base.height }),
      }}
    />
  );
};
