import React from "react";

import { makeStyles } from "tss-react/mui";

import { ProgressBlock } from "./progress-block";

const useStyles = makeStyles()(() => ({
  progressBlockWrap: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 2200,
    background: "#cccccca3",
  },
  progressBlock: {
    transform: "translate(-50%,-50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 10,
  },
}));

export type LoadingProps = {
  loading: boolean;
};

export const Loading: React.FC<LoadingProps> = ({ loading }) => {
  const { classes } = useStyles();

  return (
    <>
      {loading && (
        <div className={classes.progressBlockWrap}>
          <div className={classes.progressBlock}>
            <ProgressBlock width={50} height={50} />
          </div>
        </div>
      )}
    </>
  );
};
