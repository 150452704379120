import React, { useMemo } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { SwitchField } from "components/formik/switch-field";
import { Scope3Category } from "generated/graphql";
import { orderedBusinessTravelCategories, orderedUseOfSoldProductsCategories } from "pages/scope3/data";
import { Scope3CategoryIntl } from "utils/intl";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    padding: spacing(1, 1.5),
  },
}));

export type Scope3PermissionListProps = {
  permissions: Scope3Category[];
  setPermissions: (permissions: Scope3Category[]) => void;
};

export const Scope3PermissionList: React.FC<Scope3PermissionListProps> = (props) => {
  const { permissions, setPermissions } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  const permissionListMapping = useMemo(() => {
    const mapping = {};

    for (const category of Object.values(Scope3Category)) {
      mapping[category] = permissions.includes(category as Scope3Category);
    }

    return mapping as Record<Scope3Category, boolean>;
  }, [permissions]);

  const onChangePermission = (category: Scope3Category, checked: boolean) => {
    const newPermissions = [...permissions];

    if (checked && !newPermissions.includes(category)) {
      newPermissions.push(category);
    } else if (!checked) {
      const index = newPermissions.indexOf(category);

      if (index !== -1) {
        newPermissions.splice(index, 1);
      }
    }

    setPermissions(newPermissions);
  };

  const renderSwitchField = (category: Scope3Category, index: number) => (
    <Grid key={index} xs={12} sm={6} md={4}>
      <SwitchField
        id={category}
        name={category}
        value={permissionListMapping[category]}
        text={intl.formatMessage({ id: Scope3CategoryIntl[category] })}
        onChange={(value) => onChangePermission(category, value)}
      />
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h5" className={classes.title}>
          {intl.formatMessage({ id: "scope3.category.section.business-travel" })}
        </Typography>
      </Grid>
      {orderedBusinessTravelCategories.map(renderSwitchField)}

      <Grid xs={12}>
        <Typography variant="h5" className={classes.title}>
          {intl.formatMessage({ id: "scope3.category.section.use-of-sold-products" })}
        </Typography>
      </Grid>
      {orderedUseOfSoldProductsCategories.map(renderSwitchField)}
    </Grid>
  );
};
