import { useEffect, useRef, useState } from "react";

export type Dimensions = { width: number; height: number };

export type UseDimensionsReturns = {
  dimensions: Dimensions;
  ref: React.RefObject<HTMLDivElement>;
};
export const useDimensions = (): UseDimensionsReturns => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current)
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
    };

    if (ref.current)
      setDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { dimensions, ref };
};
