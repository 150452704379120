import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Unstable_Grid2";
import { ICellRendererParams } from "ag-grid-community";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { ButtonTooltip } from "components/button-tooltip";
import { EmissionFactorProfile } from "generated/graphql";

export type EditRendererProps = {
  onClickEdit: (profile: EmissionFactorProfile) => void;
  onClickEditEmissionFactors: (profile: EmissionFactorProfile) => void;
};

const useStyles = makeStyles()(({ typography }) => ({
  buttonTooltip: {
    toolTip: {
      fontSize: typography.pxToRem(15),
    },
  },
}));

export const EditRenderer: React.FC<ICellRendererParams & EditRendererProps> = (props) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Grid container spacing={1} alignItems="center">
      <ButtonTooltip
        className={classes.buttonTooltip}
        title={intl.formatMessage({ id: "emission-factor-profile.tooltip.edit" })}
      >
        <IconButton aria-label="Edit" onClick={() => props.onClickEdit(props.value)} size="large">
          <EditIcon />
        </IconButton>
      </ButtonTooltip>
      <ButtonTooltip
        className={classes.buttonTooltip}
        title={intl.formatMessage({ id: "emission-factor-profile.tooltip.edit-emission-factor" })}
      >
        <IconButton
          aria-label="Edit Emission Factors"
          onClick={() => props.onClickEditEmissionFactors(props.value.name)}
          size="large"
        >
          <FormatListBulletedIcon />
        </IconButton>
      </ButtonTooltip>
    </Grid>
  );
};
