import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { getApolloError } from "utils/apollo-error";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { extinguisherTypeOptions, ghgTypeOptions, newRefrigerantAndFireRow, tips } from "./consts";
import { useActions } from "./hook/action";
import { RefrigerantAndFireRecordRow } from "./types";

const source = IndexType.RefrigerantAndFireEx;

export const RefrigerantAndFirePage: React.FC = () => {
  const [initRows, setInitRows] = useState<RefrigerantAndFireRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();
  const { getData, submitData } = useActions();

  const columns = useMemo<IColumnItem[]>(
    () => [
      {
        key: "count",
        name: "",
        width: 80,
        frozen: true,
        isAction: true,
        formatter: Formatter,
      },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "refrigerantFireExtinguisher",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.refrigerant-fire-extinguisher" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: extinguisherTypeOptions,
        formatter: getFormatter(createSelectSingleFormatter(extinguisherTypeOptions), "refrigerantFireExtinguisher"),
        isCreatable: true,
      },
      {
        key: "ghgType",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.ghg-type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: ghgTypeOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(ghgTypeOptions), "ghgType"),
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.start-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "startDate"),
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.end-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "endDate"),
      },
      {
        key: "openingBalance",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.opening-balance" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "openingBalance"),
      },
      {
        key: "quantityPurchased",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.quantity-purchased" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityPurchased"),
      },
      {
        key: "quantityDisposed",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.quantity-disposed" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityDisposed"),
      },
      {
        key: "endingBalance",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.ending-balance" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "endingBalance"),
      },
      {
        key: "quantityUsed",
        name: intl.formatMessage({ id: "excel.refrigerant-and-fire.quantity-used" }),
        width: 200,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "quantityUsed"),
      },
    ],
    [companyOptions, intl, maxDate, minDate]
  );

  const initData = useCallback(async () => {
    try {
      if (companyOptions.length === 0) return;

      setLoading(true);

      const { rows } = await getData(companyOptions.map((i) => i.value));

      setLoading(false);
      setInitRows([...rows]);
    } catch (e) {
      const err = getApolloError(e);
      console.error(err.message);
    }
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<RefrigerantAndFireRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as RefrigerantAndFireRecordRow[]);

    return Yup.array(
      Yup.object<Partial<RefrigerantAndFireRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        refrigerantFireExtinguisher: Yup.string().required("errors.common.require"),
        ghgType: Yup.string().required("errors.common.require"),
        startDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        endDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        openingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityPurchased: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        endingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityDisposed: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityUsed: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
      }).required()
    ).defined();
  });

  const handleUpdateRows: OnUpdateDataGridRows<RefrigerantAndFireRecordRow> = (rows, updatedItem) => {
    const { openingBalance, quantityPurchased, endingBalance, quantityDisposed } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(openingBalance, quantityPurchased, endingBalance, quantityDisposed)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        rows[i].quantityUsed = +(
          +rows[i].openingBalance +
          +rows[i].quantityPurchased -
          +rows[i].endingBalance -
          +rows[i].quantityDisposed
        );

        if (+rows[i].quantityUsed < 0) {
          openDialog({
            content: intl.formatMessage({ id: "errors.refrigerant-and-fire.quantity-used" }),
            type: DialogType.ALERT,
          });
        }
      }
    }

    return rows;
  };

  const introContent = intl.formatMessage({ id: "excel.refrigerant-and-fire.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newRefrigerantAndFireRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "openingBalance", value: 0 },
          { property: "quantityPurchased", value: 0 },
          { property: "endingBalance", value: 0 },
          { property: "quantityDisposed", value: 0 },
          { property: "quantityUsed", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
