import React from "react";

import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { StepperStyleList } from "components/stepper-style-list";
import { Scope3Category } from "generated/graphql";
import { Scope3CategoryIntl } from "utils/intl";

const useStyles = makeStyles()(({ spacing }) => ({
  button: {
    marginTop: spacing(),
    marginRight: spacing(),
  },
  actionsContainer: {
    marginBottom: spacing(2),
  },
}));

export type Scope3StepperItem = {
  category: Scope3Category;
  visible?: boolean;
};

export type Scope3StepperProps = {
  items: Scope3StepperItem[];
  onClick: (category: Scope3Category) => void;
};

export const Scope3Stepper: React.FC<Scope3StepperProps> = (props) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const visibleItems = props.items.filter(({ visible }) => (visible === undefined ? true : visible));

  return (
    <StepperStyleList
      items={visibleItems.map((item) => ({
        labelProps: { StepIconProps: { completed: true, active: false } },
        labelContent: (
          <span style={{ fontSize: "14px" }}>{intl.formatMessage({ id: Scope3CategoryIntl[item.category] })}</span>
        ),
        content: (
          <div className={classes.actionsContainer}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.onClick(item.category)}
                className={classes.button}
              >
                {intl.formatMessage({ id: "button.stepper.manage" })}
              </Button>
            </div>
          </div>
        ),
      }))}
    />
  );
};
