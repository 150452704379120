import { Language } from "i18n";
import { roundValue } from "./math";

/** Format value as "x,xxx,xxx.yyy" */
export const formatNumberWithComma = (value: number, maximumFractionDigits = 3): string => {
  // To make sure value is formatted as "x,xxx,xxx.yyy"
  return value.toLocaleString("en-US", { maximumFractionDigits });
};

/**
 * Format value with compact notation
 *
 * (e.g. K for thousand, M for million, etc.)
 */
export const formatNumberWithNotation = (
  lang: Language,
  value: number,
  maximumFractionDigits = 5,
  maximumSignificantDigits = 3
): string => {
  let locale: string;
  switch (lang) {
    case Language.Eng:
    default:
      locale = "en-US";
      break;
    case Language.SimChi:
      locale = "zh-Hans";
      break;
    case Language.TradChi:
      locale = "zh-Hant";
      break;
  }

  return value.toLocaleString(locale, { notation: "compact", maximumFractionDigits, maximumSignificantDigits });
};

export const formatNumberToPercentage = (value: number, fractionalDigits = 0): string => {
  const percentage = roundValue(value * 100, fractionalDigits);

  // Special case:
  // after calculation, percentage maybe 0. To distinguish w/ true 0 (i.e. value = 0),
  // use "<x%" for display
  return percentage === 0 && value > 0 ? `<${10 ** fractionalDigits}%` : `${percentage}%`;
};

export const formatDateWithTZ = (date: Date): string => {
  // en-GB date format: DD/MM/YYYY, hh:mm:ss (24 hours by default)
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return date.toLocaleString("en-GB", { timeZone: browserTimezone, hourCycle: "h12" });
};
