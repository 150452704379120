import { useCallback, useContext } from "react";

import sortBy from "lodash/sortBy";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, HazardousWasteParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, toMoment, tryParseDateString } from "utils/date-time";
import { HazardousWasteType, newHazardousWasteRow } from "../consts";
import { HazardousWasteRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: HazardousWasteRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getHazardousWasteData, submitHazardousWaste } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const hazardousWaste = await getHazardousWasteData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = hazardousWaste.flatMap((h) => {
          const sortedRecords = sortBy(h.records, [
            (r) => Object.values(HazardousWasteType).findIndex((t) => t === r.hazardousWasteTypes),
            (r) => toMoment(r.handlingDate),
          ]);

          return sortedRecords.map<HazardousWasteRecordRow>((r) => ({
            ...newHazardousWasteRow,
            recordId: r.id,
            originalCompanyId: h.projectCompany.id,
            companyId: h.projectCompany.id,
            remarks: r.remarks,
            hazardousWasteName: r.hazardousWasteName,
            hazardousWasteSource: r.hazardousWasteSource,
            hazardousWasteTypes: r.hazardousWasteTypes,
            handlingDate: tryParseDateString(r.handlingDate, DateFormat.Date),
            quantityHandled: r.quantityHandled,
            quantityRemaining: r.quantityRemaining,
            previousQuantityRemaining: r.previousQuantityRemaining,
            quantityGenerated: r.quantityGenerated,
            sortingHandlingDate: "",
            fileInfo: r.file,
          }));
        });

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getHazardousWasteData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<HazardousWasteParam>((e) => {
          const data = e.data as DataGridRow<HazardousWasteRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              hazardousWasteName: data.hazardousWasteName,
              hazardousWasteSource: data.hazardousWasteSource,
              hazardousWasteTypes: data.hazardousWasteTypes,
              handlingDate: toDate(data.handlingDate, DateFormat.Date),
              quantityHandled: data.quantityHandled,
              quantityRemaining: data.quantityRemaining,
              quantityGenerated: data.quantityGenerated,
              previousQuantityRemaining: data.previousQuantityRemaining,
              recordStatus: data.recordStatus,
            },
          };
        });

        return await submitHazardousWaste({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitHazardousWaste]
  );

  return { getData, submitData };
};
