import { IndexType } from "generated/graphql";
import { IUpdatedItem, CommonReportRecordRow } from "utils/interfaces";

export enum CompanyType {
  Company = "COMPANY",
}

export type DataGridSourceType = IndexType | CompanyType;

export type DataGridRow<T extends CommonReportRecordRow = CommonReportRecordRow> = {
  rowId: string;
} & T;

export type OnUpdateDataGridRows<T extends CommonReportRecordRow> = (
  rows: DataGridRow<T>[],
  updatedItem: IUpdatedItem<T>
) => DataGridRow<T>[];

export type OnPreValidateRows<T extends CommonReportRecordRow> = (rows: T[]) => T[];
