import { VictoryAxisProps } from "victory";

import { DashboardColor } from "pages/dashboard/components/consts";
import { formatNumberWithNotation } from "utils/format";
import { Language } from "i18n";

type GetBarChartBoundaryParams = {
  lower: number;
  upper: number;
  ticker: 3 | 5;
};

const TAKE_LEFTMOST_DIGIT = 3;

export const getBarChartBoundary = (params: GetBarChartBoundaryParams): number[] => {
  const { lower, upper, ticker } = params;

  if (upper === 0) return [0, 0];

  const upperValue = roundValue(upper, true);
  const lowerValue = roundValue(lower, false);

  const bound: number[] = [];

  for (let i = 0; i < ticker; i++) {
    // Divide bound intervals evenly
    bound.push(lowerValue + ((upperValue - lowerValue) * i) / (ticker - 1));
  }

  return bound;
};

const roundValue = (value: number, isUpper: boolean) => {
  // get no. of digit of value
  const digit = Math.floor(Math.log10(value));
  const digitToRound = getDigitToRound(digit);
  const roundFactor = 10 ** digitToRound;

  // if isUpper, use ceil to ensure rounded value > actual value
  // else, use floor to ensure rounded value < actual value
  // so that bar chart can display value correctly
  if (isUpper) return Math.ceil(value / roundFactor) * roundFactor;
  else return Math.floor(value / roundFactor) * roundFactor;
};

/**
 * Get digit to round based on value's digit
 * Used for rounding lowest & highest value of bar chart
 * @param digit no. of digit of number (e.g. 100 has 3 digits)
 * @returns
 */
const getDigitToRound = (digit: number) => {
  if (digit === Number.NEGATIVE_INFINITY) {
    // if digit = -Infinity (i.e., 0), return 0
    return 0;
  } else if (digit < 2) {
    // if 0 <= digit < 2 (i.e., 0-99, 0 exclusive), no need to round
    return digit;
  } else if (digit < 4) {
    // if 2 <= digit < 4 (i.e., 100-9999), round 2 digits
    // e.g., 1234 -> 1200, 456 -> 400, 78 -> 80
    return 2;
  } else {
    // if digit >= 4 (i.e., >=10000), round to leftmost 3 digits
    // e.g., 12345 -> 12300, 234567 -> 235000, 3456789 -> 3460000
    return digit + 1 - TAKE_LEFTMOST_DIGIT;
  }
};

export const getBarChartXAxisProps = (): VictoryAxisProps => ({
  style: {
    axis: { stroke: "none" },
    tickLabels: { fill: DashboardColor.text.grey, fontSize: 16 },
    grid: { stroke: "none" },
    axisLabel: { fill: DashboardColor.text.grey, fontSize: 16 },
  },
});

export const getBarChartYAxisProps = (bound: number[], locale: Language): VictoryAxisProps => ({
  dependentAxis: true,
  crossAxis: false,
  tickValues: bound,
  tickFormat: (tick) => formatNumberWithNotation(locale, tick),
  style: {
    axis: { stroke: "none" },
    grid: { stroke: DashboardColor.text.grey, strokeWidth: 1, strokeDasharray: "1, 0" },
    tickLabels: { fill: DashboardColor.text.grey, fontSize: 14 },
    axisLabel: { fill: DashboardColor.text.grey, fontSize: 16 },
  },
});
