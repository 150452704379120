import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { SectionHeader } from "../section-header";
import { GetEnvDashboardDataQuery } from "generated/graphql";
import { GridWithLoading, IntensityBlock, TotalBlock, YearlyComparisonBlock } from "..";
import { useDashboardHelper } from "../../hook/dashboard-helper";
import { getChartInput, getUnitIntlKey } from "../../utils";
import { DASHBOARD_BLOCK_HEIGHT_100, DASHBOARD_BLOCK_HEIGHT_50 } from "../consts";

export type PackagingMaterialDashbaordProps = {
  data: GetEnvDashboardDataQuery["dashboardEnv"];
  currentYear: string;
  previousYear?: string;
  isLoading: boolean;
};

export const PackagingMaterialDashbaord: React.FC<PackagingMaterialDashbaordProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data;

  const intl = useIntl();

  const { total: totalHelper, intensity: intensityHelper } = useDashboardHelper({
    current: current.packagingMaterial,
    previous: previous?.packagingMaterial,
    nf1Label: "unit",
  });

  const currentTotal = getChartInput({ name: currentYear, datum: totalHelper.current })!;
  const previousTotal = getChartInput({ name: previousYear, datum: totalHelper.previous });

  const currentIntensityInput = getChartInput({ name: currentYear, datum: intensityHelper.current })!;
  const previousIntensityInput = getChartInput({ name: previousYear, datum: intensityHelper.previous });

  return (
    <>
      {(isLoading || totalHelper.isVisible || intensityHelper.isVisible) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.env.packaging-material.title" })}
              description={intl.formatMessage(
                { id: "dashboard.env.packaging-material.description" },
                { year: currentYear }
              )}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid xs={2}>
                <Grid container spacing={2}>
                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={totalHelper.isVisible}
                  >
                    <TotalBlock
                      title={intl.formatMessage({ id: "dashboard.env.packaging-material.total.title" })}
                      value={totalHelper.current.value}
                      unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                    />
                  </GridWithLoading>

                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={intensityHelper.isVisible}
                  >
                    <IntensityBlock
                      title={intl.formatMessage({ id: "dashboard.env.packaging-material.intensity.title" })}
                      value={intensityHelper.current.value}
                      unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                      valueColor={intensityHelper.color}
                      selectorProps={intensityHelper.nf1Select}
                    />
                  </GridWithLoading>
                </Grid>
              </Grid>

              <GridWithLoading
                xs={7}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={totalHelper.isVisible || intensityHelper.isVisible}
              >
                <YearlyComparisonBlock
                  title={intl.formatMessage({ id: "dashboard.common.yearly-comparison" })}
                  left={
                    totalHelper.isVisible
                      ? {
                          title: intl.formatMessage({ id: "dashboard.env.packaging-material.total.title" }),
                          main: currentTotal,
                          mainColor: totalHelper.color,
                          sub: previousTotal,
                          truncateBaseline: true,
                        }
                      : undefined
                  }
                  right={
                    intensityHelper.isVisible
                      ? {
                          title: intl.formatMessage({ id: "dashboard.env.packaging-material.intensity.title" }),
                          main: currentIntensityInput,
                          mainColor: intensityHelper.color,
                          sub: previousIntensityInput,
                        }
                      : undefined
                  }
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
