import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

import { MenuItem } from "../components/sidebar/types";

export const menu: MenuItem[] = [
  {
    name: "excel.title.company",
    path: "/stepper/company",
    icon: BusinessOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.account",
    path: "/project/user",
    icon: AccountCircleOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.user_permission",
    path: "/project/user-permission",
    icon: SupervisorAccountOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.iesg",
    path: "/stepper",
    icon: ListAltOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.scope3",
    path: "/scope3",
    icon: ListAltOutlinedIcon,
    child: [],
  },
  {
    name: "header.document.title",
    path: "/project/document",
    icon: NoteAddOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.dashboard",
    path: "/project/dashboard",
    icon: DashboardIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.dashboard-target",
    path: "/project/dashboard/target",
    icon: DashboardIcon,
    child: [],
  },
];

// TODO
// FIXME: Remove this menu when the dashboard issue is fixed
export const menuWithoutDashboard: MenuItem[] = [
  {
    name: "excel.title.company",
    path: "/stepper/company",
    icon: BusinessOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.account",
    path: "/project/user",
    icon: AccountCircleOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.user_permission",
    path: "/project/user-permission",
    icon: SupervisorAccountOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.iesg",
    path: "/stepper",
    icon: ListAltOutlinedIcon,
    child: [],
  },
  {
    name: "layout.menu.admin.scope3",
    path: "/scope3",
    icon: ListAltOutlinedIcon,
    child: [],
  },
  {
    name: "header.document.title",
    path: "/project/document",
    icon: NoteAddOutlinedIcon,
    child: [],
  },
];
