import { isAnyNotNullOrUndefined } from "pages/excel/utils/data-type";
import { IColumnItem, OptionType, GroupedOptionType } from "utils/interfaces";
import { DataGridState } from ".";

const isGroupedOptionType = (option: OptionType | GroupedOptionType): option is GroupedOptionType =>
  !!(option as GroupedOptionType).options;

export type RowWithDisplayValue = { rowId: string; [ColumnName: string]: string };

export const getRowsDisplayValues = (
  rows: DataGridState["rowsDisplay"],
  columns: IColumnItem[]
): RowWithDisplayValue[] => {
  return rows.map((r) => {
    const rowsWithDisplayValue: RowWithDisplayValue = { rowId: r.rowId };

    columns.forEach((c) => {
      if (c.isAction) return;

      if (!isAnyNotNullOrUndefined(r[c.key])) {
        rowsWithDisplayValue[c.key] = "";
      } else if (c.options) {
        // Value editor is dropdown type, while value is storing option ID
        // return option's label (i.e. what user sees on screen)
        const options = c.options;
        const flattenedOptions = options.flatMap((o) => (isGroupedOptionType(o) ? o.options : o));
        rowsWithDisplayValue[c.key] = flattenedOptions.find((o) => o.value === r[c.key])?.label ?? r[c.key];
      } else {
        rowsWithDisplayValue[c.key] = r[c.key].toString();
      }
    });

    return rowsWithDisplayValue;
  });
};
