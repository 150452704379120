import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, Nf2Param, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { useCallback, useContext } from "react";
import { newNf2Row } from "../consts";
import { useAPI } from "../hook/api";
import { Nf2RecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: Nf2RecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getNf2Data, submitNf2 } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const nf2 = await getNf2Data({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = nf2.flatMap((n) =>
          n.records.map<Nf2RecordRow>((r) => ({
            ...newNf2Row,
            recordId: r.id,
            originalCompanyId: n.projectCompany.id,
            companyId: n.projectCompany.id,
            remarks: r.remarks,
            unit: r.unit,
            value: r.value,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getNf2Data]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<Nf2Param>((e) => {
          const data = e.data as DataGridRow<Nf2RecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              unit: data.unit,
              value: data.value,
            },
          };
        });

        return await submitNf2({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitNf2]
  );

  return { getData, submitData };
};
