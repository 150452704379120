import React, { useMemo } from "react";

import { Property } from "csstype";
import { useIntl } from "react-intl";

import { formatNumberToPercentage } from "utils/format";
import { getAbsDiff } from "utils/math";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100 } from "../../consts";
import { ChartInput, ChartInputWithLabelColor } from "../types";
import { BarChart } from "./bar-chart";
import { LOWER_BOUNDARY_FACTOR, UPPER_BOUNDARY_FACTOR } from "./consts";
import { getBarChartBoundary } from "./utils";

export type ComparableBarChartProps = {
  main: ChartInput;
  mainColor?: Property.Fill;
  sub?: ChartInput;
  subColor?: Property.Fill;
  manual?: ChartInput;
  manualColor?: Property.Fill;
  truncateBaseline?: boolean;
};

export const ComparableBarChart: React.FC<ComparableBarChartProps> = (props) => {
  const {
    main,
    mainColor = DashboardColor.chart.grey1,
    sub,
    subColor = DashboardColor.chart.grey1,
    manual,
    manualColor = DashboardColor.chart.grey1,
    truncateBaseline = false,
  } = props;

  const intl = useIntl();

  const parsedData = useMemo(() => {
    const mainChartInput: ChartInputWithLabelColor = { ...main, color: mainColor };
    const subChartInput: ChartInputWithLabelColor | undefined = sub ? { ...sub, color: subColor } : undefined;
    const manualChartInput: ChartInputWithLabelColor | undefined = manual
      ? { ...manual, color: manualColor }
      : undefined;

    if (subChartInput) {
      if (mainChartInput.value > subChartInput.value) {
        mainChartInput.label = intl.formatMessage(
          { id: "dashboard.common.bar.label.up" },
          { percentage: formatNumberToPercentage(getAbsDiff(mainChartInput.value, subChartInput.value)) }
        );
      } else if (main.value < subChartInput.value) {
        mainChartInput.label = intl.formatMessage(
          { id: "dashboard.common.bar.label.down" },
          { percentage: formatNumberToPercentage(getAbsDiff(mainChartInput.value, subChartInput.value)) }
        );
      } else {
        mainChartInput.label = intl.formatMessage({ id: "dashboard.common.bar.label.no-change" });
      }
    }

    if (manualChartInput) {
      manualChartInput.label = intl.formatMessage(
        { id: "dashboard.common.bar.label.target" },
        { percentage: formatNumberToPercentage(getAbsDiff(main.value, manualChartInput.value)) }
      );
    }

    // Order: [manual, main, sub]
    const data = [mainChartInput];
    if (manualChartInput) data.unshift(manualChartInput);
    if (subChartInput) data.push(subChartInput);

    return data;
  }, [intl, main, sub, manual, mainColor, subColor, manualColor]);

  const lowerBoundary = useMemo(() => {
    return truncateBaseline ? Math.min(...parsedData.map((d) => d.value)) * LOWER_BOUNDARY_FACTOR : 0;
  }, [truncateBaseline, parsedData]);

  const upperBoundary = useMemo(() => {
    return Math.max(...parsedData.map((d) => d.value)) * UPPER_BOUNDARY_FACTOR;
  }, [parsedData]);

  const bound = getBarChartBoundary({
    lower: lowerBoundary,
    upper: upperBoundary,
    ticker: lowerBoundary === 0 ? 3 : 5,
  });

  return (
    <BarChart
      height={DASHBOARD_BLOCK_HEIGHT_100 * (0.3 + 0.06 * parsedData.length)}
      names={parsedData.map((d) => d.name)}
      data={parsedData}
      bound={bound}
    />
  );
};
