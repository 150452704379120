import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { AlertColor } from "@mui/material/Alert";
import amber from "@mui/material/colors/amber";
import green from "@mui/material/colors/green";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { makeStyles } from "tss-react/mui";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    flexWrap: "nowrap",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: palette.error.dark,
  },
  info: {
    backgroundColor: palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

export type GlobalSnackbarProps = {
  open: boolean;
  message: string;
  variant: AlertColor;
  autoHideDuration: number;
  onClose: () => void;
};

export const GlobalSnackbar: React.FC<GlobalSnackbarProps> = (props) => {
  const { open, message, variant, autoHideDuration, onClose } = props;

  const { classes, cx } = useStyles();
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ClickAwayListenerProps={{ mouseEvent: false }}
    >
      <div>
        <SnackbarContent
          className={cx(classes[variant], classes.root)}
          aria-describedby="client-snackbar"
          message={
            <span className={classes.message}>
              <Icon className={cx(classes.icon, classes.iconVariant)} />
              <div>{message}</div>
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </div>
    </Snackbar>
  );
};
