import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { GetProjectInfoDocument, GetProjectInfoQuery, GetProjectInfoQueryVariables } from "generated/graphql";

type UseAPIReturns = {
  getProjectInfo: (variables: GetProjectInfoQueryVariables) => Promise<GetProjectInfoQuery>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getProjectInfo = useCallback(
    async (variables: GetProjectInfoQueryVariables) => {
      const result = await client.query<GetProjectInfoQuery, GetProjectInfoQueryVariables>({
        query: GetProjectInfoDocument,
        variables,
      });

      return result.data;
    },
    [client]
  );

  return { getProjectInfo };
};
