import { RecordStatus } from "generated/graphql";
import { CommunityInvestmentRecordRow } from "./types";

export const newCommunityInvestmentRow: CommunityInvestmentRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  policyDescription: "",
  organizer: "",
  date: "",
  eventDescription: "",
  resources: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
