import React from "react";

import { useIntl } from "react-intl";
import { ApolloError, ServerError } from "@apollo/client";

import { SuccessfulRecord } from "generated/graphql";
import { DialogType, UseDialogReturns } from "hook";
import { getApolloError } from "utils/apollo-error";
import { DataGridEvents } from "..";
import { ErrorMessageWithDetails } from "../components/dialog/error-message-details";
import { SubmitDataErrorCallback, SubmitDataErrorParams } from "./types";

export type SubmitData = {
  (events: DataGridEvents, successIds?: SuccessfulRecord[]): Promise<unknown>;
};

export type UseActionsParams = {
  submitData: SubmitData;
  openDialog: UseDialogReturns["openDialog"];
};

type UseActionsReturns = {
  saveStore: (events: DataGridEvents, errorCallback: SubmitDataErrorCallback) => Promise<boolean>;
  dispatchStore: (
    events: DataGridEvents,
    errorCallback: SubmitDataErrorCallback,
    successIds?: SuccessfulRecord[]
  ) => Promise<boolean>;
};

export const useActions = (params: UseActionsParams): UseActionsReturns => {
  const { submitData, openDialog } = params;

  const intl = useIntl();

  const handleSubmitDataError = React.useCallback(
    (error: ApolloError, errorCallback: SubmitDataErrorCallback) => {
      let details: string = error.message;
      try {
        if (error.networkError) {
          const networkError = error.networkError as ServerError;
          const errorDetails = (networkError.result as Record<string, unknown>).errors as Array<{ message: string }>;

          details = errorDetails.map(({ message }) => message).join("\n---\n");
        } else {
          const { success, failed, message } = JSON.parse(error.graphQLErrors[0].message) as SubmitDataErrorParams;

          errorCallback(success);

          details = JSON.stringify({ message, failed }, null, 2);
        }
      } catch (_) {
        details = error.message;
      }

      const message = intl.formatMessage({
        id: error.networkError ? "error.submit.data.network" : "error.submit.data.server",
      });

      openDialog({ content: <ErrorMessageWithDetails message={message} details={details} />, type: DialogType.ERROR });
    },
    [intl, openDialog]
  );

  const saveStore = React.useCallback(
    async (events: DataGridEvents, errorCallback: SubmitDataErrorCallback) => {
      try {
        await submitData(events);

        return true;
      } catch (e) {
        const error = getApolloError(e);
        handleSubmitDataError(error, errorCallback);

        return false;
      }
    },
    [submitData, handleSubmitDataError]
  );

  const dispatchStore = React.useCallback(
    async (events: DataGridEvents, errorCallback: SubmitDataErrorCallback, successIds?: SuccessfulRecord[]) => {
      try {
        await submitData(events, successIds);

        return true;
      } catch (e) {
        const err = getApolloError(e);
        handleSubmitDataError(err, errorCallback);
        return false;
      }
    },
    [submitData, handleSubmitDataError]
  );

  return {
    saveStore,
    dispatchStore,
  };
};
