import React from "react";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Property } from "csstype";
import { makeStyles } from "tss-react/mui";

import { DashboardColor } from "../consts";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    height: "100%",
    padding: spacing(2, 2),
    borderRadius: 16,
    display: "flex",
    overflow: "visible",
  },
  titleContainer: {
    pdding: spacing(0.5, 0),
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  greyText: {
    color: DashboardColor.text.grey,
  },
}));

export type BlockWithTitleAndValueContainerProps = {
  title: string;
  value: number;
  valueColor: Property.Fill;
};

export const BlockWithTitleAndValueContainer: React.FC<BlockWithTitleAndValueContainerProps> = (props) => {
  const { title, value, valueColor, children } = props;

  const { classes } = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid>
              <Typography variant="h4" fontWeight={"bold"} color={DashboardColor.text.grey}>
                {title}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h2" fontWeight={"bold"} color={valueColor}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>{children}</Grid>
      </Grid>
    </Card>
  );
};
