import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";

import { MenuItem } from "../components/sidebar/types";

export const menu: MenuItem[] = [
  {
    name: "layout.menu.admin.iesg",
    path: "/stepper",
    icon: ListAltOutlinedIcon,
    child: [],
  },
];
