import React from "react";

import Grid from "@mui/material/Unstable_Grid2";

import {
  ComparableStackedBarChart,
  ComparableStackedBarChartProps,
} from "../charts/stacked-bar-chart/comparable-stacked-bar-chart";
import { ChartLegend } from "../charts/chart-legend";
import { DashboardColor } from "../consts";
import {
  BlockWithTitleAndValueContainer,
  BlockWithTitleAndValueContainerProps,
} from "../containers/block-with-title-and-value-container";

export type TotalNumberBlockProps = {
  containerProps: Pick<BlockWithTitleAndValueContainerProps, "title">;
  chartProps: ComparableStackedBarChartProps;
};

export const TotalNumberBlock: React.FC<TotalNumberBlockProps> = (props) => {
  const { containerProps, chartProps } = props;

  const getValueColor = () => {
    return DashboardColor.text.grey;
  };

  return (
    <BlockWithTitleAndValueContainer
      {...containerProps}
      value={chartProps.main.data.reduce((acc, d) => acc + d.value, 0)}
      valueColor={getValueColor()}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <ComparableStackedBarChart {...chartProps} />
        </Grid>
        <Grid xs={12}>
          <Grid container justifyContent="center">
            <Grid>
              <ChartLegend
                data={chartProps.categoryDef.map(({ key, displayName, color }) => ({
                  name: displayName ?? key,
                  color,
                }))}
                column={3}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BlockWithTitleAndValueContainer>
  );
};
