import React from "react";

import MuiSkeleton from "@mui/material/Skeleton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  fullHeight: {
    height: "100%",
    transform: "none",
  },
  halfHeight: {
    height: "100%",
    transform: "none",
  },
}));

export type SkeletonProps = {
  halfHeight?: boolean;
};

export const Skeleton: React.FC<SkeletonProps> = ({ halfHeight = false }) => {
  const { classes } = useStyles();

  return <MuiSkeleton className={halfHeight ? classes.halfHeight : classes.fullHeight} />;
};
