import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { ColDef } from "ag-grid-community";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DataGrid } from "components/ag-grid";
import { FullScreenFormDialog } from "components/react-table";
import { EmissionFactorProfile } from "generated/graphql";
import { DialogMode } from "utils/dialog";
import { Yup } from "utils/yup";
import { EditRenderer } from "./components/edit-renderer";
import { initProfile, IProfileForm, ProfileForm } from "./components/form";
import { useActions } from "./hook/actions";

const useStyles = makeStyles()(({ spacing }) => ({
  paper: {
    padding: spacing(2),
  },
  header: {
    paddingBottom: spacing(1),
  },
}));

export const EmissionFactorProfilePage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [rows, setRows] = useState<EmissionFactorProfile[]>([]);
  const [dialogMode, setDialogMode] = useState(DialogMode.CREATE);
  const [formInitValue, setFormInitValue] = useState<IProfileForm>(initProfile);

  const { classes } = useStyles();
  const intl = useIntl();
  const { getData, submitForm, gotoEditEmissionFactorPage } = useActions();

  const fetchProfiles = useCallback(async () => {
    const rows = await getData();
    setRows([...rows] as EmissionFactorProfile[]);
  }, [getData]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const handleCreateProfile = () => {
    setDialogMode(DialogMode.CREATE);
    setFormInitValue(initProfile);
    setIsOpen(true);
  };

  const handleEditProfile = ({ id, name, templateId }: EmissionFactorProfile) => {
    setDialogMode(DialogMode.EDIT);
    setFormInitValue({ id, name, templateId });
    setIsOpen(true);
  };

  const handleSubmitForm = async (values: IProfileForm, setSubmitting: (bool: boolean) => void) => {
    await submitForm({ mode: dialogMode, values, setSubmitting });
    setIsOpen(false);
    await fetchProfiles();
  };

  const columns: ColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "",
        width: 150,
        valueGetter: (params) => params.data,
        cellRenderer: EditRenderer,
        cellRendererParams: {
          onClickEdit: handleEditProfile,
          onClickEditEmissionFactors: gotoEditEmissionFactorPage,
        },
      },
      {
        field: "name",
        headerName: intl.formatMessage({ id: "emission-factor-profile.header.name" }),
        flex: 1,
      },
      {
        field: "template.name",
        headerName: intl.formatMessage({ id: "emission-factor-profile.header.template" }),
        flex: 1,
      },
    ],
    [gotoEditEmissionFactorPage, intl]
  );

  const createValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required("Profile Name is required"),
        templateId: Yup.string().required("Template is required"),
        cloneFromProfileId: Yup.string().optional(),
        isSwitchActiveProjects: Yup.boolean().optional(),
      }),
    []
  );

  const updateValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required("Profile Name is required"),
      }),
    []
  );

  return (
    <Paper className={classes.paper}>
      <Grid className={classes.header}>
        <Button color="primary" variant="outlined" onClick={handleCreateProfile}>
          {intl.formatMessage({ id: "button.add" })}
        </Button>
      </Grid>
      <DataGrid columnDefs={columns} rowData={rows} rowHeight={50} suppressMovableColumns suppressCellFocus />
      <Formik<IProfileForm>
        initialValues={formInitValue}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitForm(values, setSubmitting);
        }}
        enableReinitialize={true}
        validationSchema={dialogMode === DialogMode.CREATE ? createValidationSchema : updateValidationSchema}
        render={(formikProps) => (
          <FullScreenFormDialog
            isOpen={isOpen}
            handleDialogClose={() => {
              setIsOpen(false);
            }}
            handleSubmit={formikProps.submitForm}
            mode={dialogMode}
            title={intl.formatMessage({
              id: "dialog.title.emission-factor-profile",
            })}
          >
            <ProfileForm {...formikProps} mode={dialogMode} profiles={rows} />
          </FullScreenFormDialog>
        )}
      />
    </Paper>
  );
};
