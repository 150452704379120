import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Property } from "csstype";

import { CompactSelect } from "components/compact-select";
import { formatNumberWithComma } from "utils/format";
import { OptionType } from "utils/interfaces";
import { TitleValueLayout } from "../blocks/title-value-layout";
import { DashboardColor } from "../consts";
import { StyledContainer } from "../containers/styled-container";

export type IntensityBlockProps = {
  title: string;
  subTitle?: string;
  value: number;
  valueColor?: Property.Fill;
  unit: string;
  selectorProps: {
    options: OptionType[];
    nf1: string;
    onChangeNf1: (nf1: string) => void;
  };
};

export const IntensityBlock: React.FC<IntensityBlockProps> = (props) => {
  const { title, subTitle, value, valueColor, unit, selectorProps } = props;

  return (
    <StyledContainer>
      <Grid container height="100%" direction="column" spacing={0} alignItems="center" wrap="nowrap">
        <Grid xs={12} flexGrow={3}>
          <TitleValueLayout
            title={title}
            subTitle={subTitle}
            value={formatNumberWithComma(value)}
            style={{
              align: "center",
              title: { color: DashboardColor.text.grey, bold: true },
              subTitle: { color: DashboardColor.text.grey },
              value: { color: valueColor ?? DashboardColor.text.grey, bold: true },
            }}
          />
        </Grid>
        <Grid xs={12} paddingBottom={1}>
          <Grid container gap={1} alignItems="center" justifyContent="center">
            <Grid>
              <Typography color={DashboardColor.text.grey}>{`${unit} /`}</Typography>
            </Grid>
            <Grid>
              <CompactSelect
                width={100}
                height={24}
                options={selectorProps.options}
                value={selectorProps.options.find((o) => o.value === selectorProps.nf1)}
                onChange={(o: OptionType) => selectorProps.onChangeNf1(o.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
