import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetStationaryFuelDataDocument,
  GetStationaryFuelDataQuery,
  GetStationaryFuelDataQueryVariables,
  SubmitStationaryFuelDocument,
  SubmitStationaryFuelMutation,
  SubmitStationaryFuelMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getStationaryFuelData: (
    variables: GetStationaryFuelDataQueryVariables
  ) => Promise<GetStationaryFuelDataQuery["project"]["stationaryFuel"]>;
  submitStationaryFuel: (
    variables: SubmitStationaryFuelMutationVariables
  ) => Promise<SubmitStationaryFuelMutation["submitStationaryFuel"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getStationaryFuelData = useCallback(
    async (variables: GetStationaryFuelDataQueryVariables) => {
      const response = await client.query<GetStationaryFuelDataQuery, GetStationaryFuelDataQueryVariables>({
        query: GetStationaryFuelDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.stationaryFuel;
    },
    [client]
  );

  const submitStationaryFuel = useCallback(
    async (variables: SubmitStationaryFuelMutationVariables) => {
      const response = await client.mutate<SubmitStationaryFuelMutation, SubmitStationaryFuelMutationVariables>({
        mutation: SubmitStationaryFuelDocument,
        variables,
      });

      return response.data?.submitStationaryFuel ?? false;
    },
    [client]
  );

  return {
    getStationaryFuelData,
    submitStationaryFuel,
  };
};
