import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { ButtonTooltip } from "components/button-tooltip";
import { DashboardColor } from "./components/consts";

const useStyles = makeStyles()(() => ({
  icon: {
    width: 24,
    height: 24,
  },
}));

export type ActionButtonGroupProps = {
  onDownload: () => void;
  onRefresh: () => void;
  lastUpdate: string;
};

export const ActionButtonGroup: React.FC<ActionButtonGroupProps> = (props) => {
  const { onDownload, onRefresh, lastUpdate } = props;

  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Grid xs={12}>
      <Grid container alignItems="center" spacing={1}>
        <Grid>
          <ButtonTooltip title={intl.formatMessage({ id: "dashboard.common.download" })}>
            <IconButton color="primary" size="small" onClick={onDownload}>
              <DownloadIcon className={classes.icon} />
            </IconButton>
          </ButtonTooltip>
        </Grid>
        <Grid>
          <ButtonTooltip title={intl.formatMessage({ id: "dashboard.common.refresh" })}>
            <IconButton color="primary" size="small" onClick={onRefresh}>
              <RefreshIcon className={classes.icon} />
            </IconButton>
          </ButtonTooltip>
        </Grid>
        <Grid>
          <Typography variant="h5" color={DashboardColor.text.grey}>
            {intl.formatMessage({ id: "dashboard.common.last-update" }, { time: lastUpdate })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
