import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";

export type ProgressBlockProps = {
  width: string | number;
  height: string | number;
};

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const ProgressBlock: React.FC<ProgressBlockProps> = ({ width, height }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} style={{ width, height }}>
      <CircularProgress />
    </div>
  );
};
