import React from "react";
import Formatter, { FormatterProps } from "./formatter";

export type StringFormatterProps = Omit<FormatterProps, "string">;

const StringFormatter: React.FC<StringFormatterProps> = (props) => {
  return <Formatter string={true} {...props} />;
};

export default StringFormatter;
