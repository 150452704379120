import { GridApi } from "ag-grid-community";

export const getUsedCode = (gridApi: GridApi): string[] => {
  const usedCode: string[] = [];
  gridApi!.forEachNode((node) => {
    if (node.data.codeId) usedCode.push(node.data.codeId);
  });

  return usedCode;
};
