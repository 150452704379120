const domain = "iesg";

export const getRecords = <T>(): T | null => {
  const result = localStorage.getItem(domain);
  if (!result) {
    return null;
  }
  return JSON.parse(result);
};

export const getLang = (): string | null => {
  const local = getRecords<{ lang: string }>();
  if (!local) return null;
  return local.lang;
};

export const setLang = (lang: string): void => {
  localStorage.setItem(domain, JSON.stringify({ lang }));
};
