import React, { useCallback, useMemo } from "react";

import { useIntl } from "react-intl";

import { PercentageDatum } from "generated/graphql";
import { formatNumberToPercentage } from "utils/format";
import { getAbsDiff } from "utils/math";
import { DashboardColor } from "../../consts";
import { ChartInput } from "../types";
import { UPPER_BOUNDARY_FACTOR } from "../bar-chart/consts";
import { getBarChartBoundary } from "../bar-chart/utils";
import { StackedBarChart } from "./stacked-bar-chart";
import { StackedBarChartBarDef, StackedBarChartCategoryDef } from "./types";

export type StackedBarChartInput = {
  /** Name of stacked bar */
  name: string;
  data: PercentageDatum[];
};

export type ComparableStackedBarChartProps = {
  categoryDef: Omit<StackedBarChartCategoryDef, "data">[];
  /** Input for main bar, required  */
  main: StackedBarChartInput;
  /** Input for sub bar, optional */
  sub?: StackedBarChartInput;
};

export const ComparableStackedBarChart: React.FC<ComparableStackedBarChartProps> = (props) => {
  const { categoryDef, main, sub } = props;

  const intl = useIntl();

  const getValueSum = useCallback((data: PercentageDatum[]) => data.reduce((acc, d) => acc + d.value, 0), []);

  const barDef = useMemo(() => {
    const mainBarDef: StackedBarChartBarDef = {
      barName: main.name,
    };

    if (sub) {
      const subBarDef: StackedBarChartBarDef = {
        barName: sub.name,
      };

      const mainValueSum = getValueSum(main.data);
      const subValueSum = getValueSum(sub.data);

      if (mainValueSum > subValueSum) {
        mainBarDef.label = intl.formatMessage(
          { id: "dashboard.common.bar.label.up" },
          { percentage: formatNumberToPercentage(getAbsDiff(mainValueSum, subValueSum)) }
        );
        mainBarDef.labelColor = DashboardColor.chart.green;
      } else if (mainValueSum < subValueSum) {
        mainBarDef.label = intl.formatMessage(
          { id: "dashboard.common.bar.label.down" },
          { percentage: formatNumberToPercentage(getAbsDiff(mainValueSum, subValueSum)) }
        );
        mainBarDef.labelColor = DashboardColor.chart.red;
      } else {
        mainBarDef.label = intl.formatMessage({ id: "dashboard.common.bar.label.no-change" });
      }

      return [mainBarDef, subBarDef];
    } else {
      return [mainBarDef];
    }
  }, [main, sub, getValueSum, intl]);

  const upperBoundary = useMemo(() => {
    let valueSum = getValueSum(main.data);

    if (sub) {
      const subValueSum = getValueSum(sub.data);
      valueSum = Math.max(valueSum, subValueSum);
    }

    return valueSum * UPPER_BOUNDARY_FACTOR;
  }, [main, sub, getValueSum]);

  const gatherDataByCategoryName = useCallback(
    (categoryName: string) => {
      const dataByTitle: ChartInput[] = [];
      const mainTarget = main.data.find((d) => categoryName === d.name);
      if (mainTarget) dataByTitle.push({ name: main.name.toString(), value: mainTarget.value });

      if (sub) {
        const subTarget = sub.data.find((d) => categoryName === d.name);
        if (subTarget) dataByTitle.push({ name: sub.name.toString(), value: subTarget.value });
      }

      return dataByTitle;
    },
    [main, sub]
  );

  return (
    <StackedBarChart
      bound={getBarChartBoundary({ lower: 0, upper: upperBoundary, ticker: 5 })}
      categoryDef={categoryDef.map((c) => ({ ...c, data: gatherDataByCategoryName(c.key) }))}
      barDef={barDef}
    />
  );
};
