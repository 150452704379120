import React, { useContext } from "react";

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { i18n } from "i18n";
import Cookies from "js-cookie";

import { COOKIES, SERVER } from "utils/const";
import { LanguageContext } from "./language";
import { MessageContext, ShowMessage } from "./message";

const uploadLink = createUploadLink({
  uri: SERVER.GRAPHQL,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get(COOKIES.name.jwt);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = (locale: string, showMessage: ShowMessage) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const redirectMessage = i18n[locale]["error.token.redirect"];
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const response: { statusCode: number } | undefined = extensions?.response;
        if (response?.statusCode) {
          switch (response.statusCode) {
            // Unauthorized
            case 401: {
              showMessage({ message: redirectMessage, variant: "error" });

              setTimeout(() => {
                window.location.href = "/logout";
              }, 5000);
              return;
            }
          }
        }

        if (process.env.NODE_ENV !== "production") {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        }
      });
    }

    if (networkError) {
      if (process.env.NODE_ENV !== "production") {
        console.log(`[Network error]: ${networkError}`);
      }

      showMessage({
        message: "Failed to connect to server. Please check your network connection",
        variant: "error",
      });
    }
  });

const createLink = (locale: string, showMessage: ShowMessage) =>
  ApolloLink.from([authLink, errorLink(locale, showMessage), uploadLink]);

const cache = new InMemoryCache({
  addTypename: false,
});

const createClient = (locale: string, showMessage: ShowMessage) =>
  new ApolloClient({
    link: createLink(locale, showMessage),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });

export const AvistaApolloProvider: React.FC = ({ children }) => {
  const { locale } = useContext(LanguageContext);
  const { showMessage } = useContext(MessageContext);

  return <ApolloProvider client={createClient(locale, showMessage)}>{children}</ApolloProvider>;
};
