import Card from "@mui/material/Card";
import { withStyles } from "tss-react/mui";

export const StyledContainer = withStyles(Card, ({ spacing }) => ({
  root: {
    height: "100%",
    padding: spacing(2, 2),
    borderRadius: 16,
    overflow: "visible",
  },
}));
