export const getAbsDiff = (value: number, base: number): number => {
  return Math.abs(1 - value / base);
};

export const isApproximatelyZero = (value: number, fractionalDigits = 3): boolean => {
  return Math.round(value * 10 ** fractionalDigits) === 0;
};

export const roundValue = (value: number, fractionalDigits = 0): number => {
  return Math.round(value * 10 ** fractionalDigits) / 10 ** fractionalDigits;
};
