import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetProductResponsibilityPolicyDataDocument,
  GetProductResponsibilityPolicyDataQuery,
  GetProductResponsibilityPolicyDataQueryVariables,
  SubmitProductResponsibilityPolicyDocument,
  SubmitProductResponsibilityPolicyMutation,
  SubmitProductResponsibilityPolicyMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getProductResponsibilityPolicyData: (
    variables: GetProductResponsibilityPolicyDataQueryVariables
  ) => Promise<GetProductResponsibilityPolicyDataQuery["project"]["productResponsibilityPolicy"]>;
  submitProductResponsibilityPolicy: (
    variables: SubmitProductResponsibilityPolicyMutationVariables
  ) => Promise<SubmitProductResponsibilityPolicyMutation["submitProductResponsibilityPolicy"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getProductResponsibilityPolicyData = useCallback(
    async (variables: GetProductResponsibilityPolicyDataQueryVariables) => {
      const response = await client.query<
        GetProductResponsibilityPolicyDataQuery,
        GetProductResponsibilityPolicyDataQueryVariables
      >({
        query: GetProductResponsibilityPolicyDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.productResponsibilityPolicy;
    },
    [client]
  );

  const submitProductResponsibilityPolicy = useCallback(
    async (variables: SubmitProductResponsibilityPolicyMutationVariables) => {
      const response = await client.mutate<
        SubmitProductResponsibilityPolicyMutation,
        SubmitProductResponsibilityPolicyMutationVariables
      >({
        mutation: SubmitProductResponsibilityPolicyDocument,
        variables,
      });

      return response.data?.submitProductResponsibilityPolicy ?? false;
    },
    [client]
  );

  return {
    getProductResponsibilityPolicyData,
    submitProductResponsibilityPolicy,
  };
};
