import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "tss-react/mui";

import { CompactSelect } from "components/compact-select";
import { formatNumberWithComma } from "utils/format";
import { OptionType } from "utils/interfaces";
import { ComparableBarChart, ComparableBarChartProps } from "../charts/bar-chart/comparable-bar-chart";
import { DashboardColor } from "../consts";

const useStyles = makeStyles()(({ spacing }) => ({
  title: {
    color: DashboardColor.text.grey,
    fontWeight: "bold",
  },
  description: {
    fontWeight: "bold",
  },
  value: {
    fontWeight: "bold",
    paddingRight: spacing(0.5),
  },
  unitContainer: {
    display: "flex",
    alignItems: "flex-end",
    gap: spacing(0.5),
  },
  unit: {
    fontWeight: "bold",
  },
}));

export type BarChartWithValueBlockProps = ComparableBarChartProps & {
  description: string;
  /** special value display format */
  displayValue?: number;
  unit: string;
  selectProps?: {
    options: OptionType[];
    value: string;
    onChangeValue: (value: string) => void;
  };
};

export const BarChartWithValueBlock: React.FC<BarChartWithValueBlockProps> = (props) => {
  const { description, displayValue, unit, selectProps, ...barChartProps } = props;

  const { classes } = useStyles();

  return (
    <Grid container spacing={0} padding={0.5} height="inherit" direction="column">
      <Grid>
        <Grid container gap={1} wrap="nowrap">
          <Grid>
            <Typography variant="h5" className={classes.title}>
              {description}
            </Typography>
          </Grid>
          {selectProps && (
            <Grid>
              <CompactSelect
                width={180}
                height={24}
                options={selectProps.options}
                value={selectProps.options.find((o) => o.value === selectProps.value)}
                onChange={(o: OptionType) => selectProps.onChangeValue(o.value)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        <Grid container alignItems="flex-end">
          <Grid>
            <Typography
              variant="h2"
              className={classes.value}
              style={{ color: barChartProps.mainColor ?? DashboardColor.chart.grey1 }}
            >
              {formatNumberWithComma(displayValue ?? barChartProps.main.value)}
            </Typography>
          </Grid>
          <Grid className={classes.unitContainer}>
            <Typography variant="caption" className={classes.unit}>
              {unit}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid flexGrow={3}>
        <ComparableBarChart {...barChartProps} />
      </Grid>
    </Grid>
  );
};
