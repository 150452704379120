import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetTreeDataDocument,
  GetTreeDataQuery,
  GetTreeDataQueryVariables,
  SubmitTreeDocument,
  SubmitTreeMutation,
  SubmitTreeMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getTreeData: (variables: GetTreeDataQueryVariables) => Promise<GetTreeDataQuery["project"]["tree"]>;
  submitTree: (variables: SubmitTreeMutationVariables) => Promise<SubmitTreeMutation["submitTree"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getTreeData = useCallback(
    async (variables: GetTreeDataQueryVariables) => {
      const response = await client.query<GetTreeDataQuery, GetTreeDataQueryVariables>({
        query: GetTreeDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.tree;
    },
    [client]
  );

  const submitTree = useCallback(
    async (variables: SubmitTreeMutationVariables) => {
      const response = await client.mutate<SubmitTreeMutation, SubmitTreeMutationVariables>({
        mutation: SubmitTreeDocument,
        variables,
      });

      return response.data?.submitTree ?? false;
    },
    [client]
  );

  return {
    getTreeData,
    submitTree,
  };
};
