import { useLocation } from "react-router-dom";

import { EmissionFactorCategory } from "generated/graphql";
import { ROUTE_PATH } from "router/emission-factor";

export type UseUrlParserReturns = {
  profile: string;
  url: {
    profile: string;
    profileEditData: (category: EmissionFactorCategory) => string;
  };
};

export const useUrlParser = (): UseUrlParserReturns => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const profile = decodeURIComponent(query.get("profile") ?? "");

  const profileEditData = (category: EmissionFactorCategory) =>
    `/admin/emission-factor/edit${ROUTE_PATH[category]}?profile=${encodeURIComponent(profile)}`;

  return {
    profile,
    url: {
      profile: "/admin/emission-factor",
      profileEditData,
    },
  };
};
