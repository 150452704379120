import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { NonHazardousWasteRecordRow } from "./types";

export const newNonHazardousWasteRow: NonHazardousWasteRecordRow = {
  recordId: "",
  companyId: "",
  wasteSource: "",
  wasteType: "",
  startDate: "",
  endDate: "",
  quantityGenerated: 0,
  wasteManagementDisposeAmount: 0,
  wasteManagementRecyclingAmount: 0,
  wasteManagementCollectedAmount: 0,
  otherUsageAmount: 0,
  otherUsageRemarks: "",
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<NonHazardousWasteRecordRow, string> = {
  wasteSource: "tips.non-hazardous.waste-source",
  wasteManagementDisposeAmount: "tips.non-hazardous.dispose",
  wasteType: "tips.non-hazardous.waste-type",
  wasteManagementRecyclingAmount: "tips.non-hazardous.recycling",
  otherUsageAmount: "tips.non-hazardous.other",
};

export enum NonHazardousWasteType {
  Metal = "metal",
  Plastic = "plastic",
  Paper = "paper",
  Glass = "glass",
  FoodWaste = "foodWaste",
  Wood = "wood",
  GeneralIndustrialWaste = "generalIndustrialWaste",
  OtherGeneralRefuse = "otherGeneralRefuse",
  ConstructionWaste = "constructionWaste",
}

export const nonHazardousWasteTypeOptions: OptionType[] = [
  { label: "Metal 金属", value: NonHazardousWasteType.Metal },
  { label: "Plastic 塑胶", value: NonHazardousWasteType.Plastic },
  { label: "Paper 纸张", value: NonHazardousWasteType.Paper },
  { label: "Glass 玻璃", value: NonHazardousWasteType.Glass },
  { label: "Food Waste 厨馀", value: NonHazardousWasteType.FoodWaste },
  { label: "Wood 木材", value: NonHazardousWasteType.Wood },
  { label: "General industrial waste 一般工业废物", value: NonHazardousWasteType.GeneralIndustrialWaste },
  { label: "Other General Refuse 其他生活废物", value: NonHazardousWasteType.OtherGeneralRefuse },
  { label: "Construction waste 建筑废料", value: NonHazardousWasteType.ConstructionWaste },
];
