import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";

import { useApolloClient } from "@apollo/client";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { CellClassParams, CellEditingStoppedEvent, ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DataGrid } from "components/ag-grid";
import { BeforeExitPrompt } from "components/before-exit-prompt";
import { Breadcrumbs } from "components/breadcrumbs";
import {
  EmissionFactor,
  EmissionFactorCode,
  useGetEmissionFactorsLazyQuery,
  useSubmitEmissionFactorsMutation,
} from "generated/graphql";
import { useDialog } from "hook";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { CategoryLabels, UnitLabels, units } from "../emission-factor-edit/data";
import { DateEditor } from "./components/date-editor";
import { RowActionButtonGroup } from "./components/row-action-button-group";
import { SelectEditor } from "./components/select-editor";
import { UniqueSelectEditor } from "./components/unique-select-editor";
import { useUrlParser } from "./hook/url-parser";
import { Actions, Actions as ReducerActions, initState, reducer } from "./reducer";
import { codeIdFormatter, dateFormatter, unitFormatter, validateData } from "./utils";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  paperRoot: {
    padding: spacing(2),
  },
  paper: {
    padding: spacing(1, 2),
  },
  link: {
    cursor: "pointer",
  },
  error: {
    backgroundColor: palette.error.light,
  },
}));

export const EmissionFactorEditDataPage: React.FC = () => {
  const gridApi = useRef<GridApi>();
  const [state, dispatch] = useReducer(reducer, initState);

  const { classes } = useStyles();
  const intl = useIntl();
  const client = useApolloClient();
  const { setLoading } = useContext(LoadingContext);
  const { showMessage } = useContext(MessageContext);
  const { openDialog, Dialog, DialogType } = useDialog();
  const { profile, category, url } = useUrlParser();

  const [getData, getDataState] = useGetEmissionFactorsLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    variables: { profileName: profile, category },
    onCompleted: ({ emissionFactors: { emissionFactors, code } }) => {
      dispatch({
        type: Actions.Init,
        emissionFactor: emissionFactors as EmissionFactor[],
        code: code as EmissionFactorCode[],
        gridApi: gridApi.current!,
      });
    },
  });

  const [submitData, submitDataState] = useSubmitEmissionFactorsMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      showMessage({ message: intl.formatMessage({ id: "excel.common.success" }) });
      dispatch({ type: Actions.SetUnsaved, unsaved: false });
    },
    onError: (e) => {
      let errorMessage = e.message;
      if (e.networkError) errorMessage = intl.formatMessage({ id: "error.submit.data.network" });
      else if (e.graphQLErrors) errorMessage = intl.formatMessage({ id: "error.submit.data.server" });

      openDialog({
        content: errorMessage,
        type: DialogType.ERROR,
      });
    },
  });

  useEffect(() => {
    setLoading(getDataState.loading || submitDataState.loading);
  }, [setLoading, getDataState.loading, submitDataState.loading]);

  const unitOptions = useMemo(
    () => units.map((unit) => ({ label: intl.formatMessage({ id: UnitLabels[unit] }), value: unit })),
    [intl]
  );

  const getCellStyleClass = useCallback(
    (params: CellClassParams) => (params.data.error && params.data.error[params.colDef.field!] ? classes.error : null),
    [classes]
  );

  const handleGridReady = ({ api, columnApi }: GridReadyEvent) => {
    gridApi.current = api;
    getData({ variables: { profileName: profile, category } });
    columnApi.autoSizeAllColumns();
  };

  const handleCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const { oldValue, newValue, colDef, data, node } = event;

    if (oldValue !== newValue)
      dispatch({ type: ReducerActions.UpdateRow, gridApi: gridApi.current!, field: colDef.field, row: data, node });
  };

  const handleCreateRow = () => {
    dispatch({ type: Actions.CreateRow, gridApi: gridApi.current!, category });
  };

  const handleCloneRow = () => {
    const selectedNodes = gridApi.current!.getSelectedNodes();
    if (selectedNodes.length > 0)
      dispatch({ type: Actions.CloneRow, gridApi: gridApi.current!, row: selectedNodes[0].data });
  };

  const handleDeleteRow = () => {
    const selectedNodes = gridApi.current!.getSelectedNodes();
    if (selectedNodes.length > 0)
      dispatch({ type: Actions.DeleteRow, gridApi: gridApi.current!, row: selectedNodes[0].data });
  };

  const handleSubmitRow = async () => {
    gridApi.current!.stopEditing();

    const isAllDataValid = validateData(gridApi.current!);

    if (isAllDataValid) submitData({ variables: { profileName: profile, data: Object.values(state.data) } });
    else showMessage({ message: intl.formatMessage({ id: "errors.common.error-input" }), variant: "error" });
  };

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "codeId",
        headerName: "Code",
        pinned: "left",
        editable: true,
        valueFormatter: codeIdFormatter(state.code),
        cellEditor: UniqueSelectEditor,
        cellEditorParams: {
          options: state.code.map((c) => ({ label: c.code, value: c.id })),
          usedValue: state.usedCode,
        },
        minWidth: 80,
        cellEditorPopup: true,
        cellClass: getCellStyleClass,
      },
      {
        field: "name",
        headerName: "Name",
        editable: true,
        minWidth: 300,
        cellClass: getCellStyleClass,
      },
      {
        field: "value",
        headerName: "Emission Factor",
        editable: true,
        valueParser: (params) => {
          const num = Number(params.newValue);
          return Number.isNaN(num) ? 0 : num;
        },
      },
      {
        headerName: "Emission Factor Unit (actual)",
        valueFormatter: unitFormatter(intl),
        valueGetter: (params) => params.data.unit.actual,
        valueSetter: (params) => {
          params.data.unit.actual = params.newValue;
          return true;
        },
        editable: true,
        cellEditor: SelectEditor,
        cellEditorParams: { options: unitOptions },
        cellEditorPopup: true,
      },
      {
        headerName: "Emission Factor Unit (base)",
        valueFormatter: unitFormatter(intl),
        valueGetter: (params) => params.data.unit.base,
        valueSetter: (params) => {
          params.data.unit.base = params.newValue;
          return true;
        },
        editable: true,
        cellEditor: SelectEditor,
        cellEditorParams: { options: unitOptions },
        cellEditorPopup: true,
      },
      {
        field: "emissionUnit",
        headerName: "Emission Unit",
        valueFormatter: unitFormatter(intl),
        editable: true,
        cellEditor: SelectEditor,
        cellEditorParams: { options: unitOptions },
        cellEditorPopup: true,
      },
      {
        field: "reference",
        headerName: "Reference",
        editable: true,
      },
      {
        field: "yearOfPublication",
        headerName: "Year of Publication",
        valueFormatter: dateFormatter,
        editable: true,
        cellEditor: DateEditor,
      },
      {
        field: "reviewedOn",
        headerName: "Reviewed On",
        valueFormatter: dateFormatter,
        editable: true,
        cellEditor: DateEditor,
      },
    ],
    [intl, state.code, state.usedCode, unitOptions, getCellStyleClass]
  );

  return (
    <>
      <BeforeExitPrompt when={state.unsaved} message={intl.formatMessage({ id: "errors.common.leave-page" })} />
      <Grid container spacing={1} alignItems="center">
        <Grid xs={12}>
          <Paper className={classes.paper}>
            <Breadcrumbs
              separator=">"
              aria-label="Breadcrumbs"
              items={[
                {
                  content: intl.formatMessage({ id: "layout.menu.systemAdmin.emissionFactors" }),
                  link: url.profile,
                },
                {
                  content: profile,
                  link: url.profileEdit,
                },
                {
                  content: CategoryLabels[category] && intl.formatMessage({ id: CategoryLabels[category] }),
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper className={classes.paperRoot}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <RowActionButtonGroup
                  isCreatable={state.code.length > state.usedCode.length}
                  onCreate={handleCreateRow}
                  onClone={handleCloneRow}
                  onDelete={handleDeleteRow}
                  onSubmit={handleSubmitRow}
                />
              </Grid>
              <Grid xs={12}>
                <DataGrid
                  columnDefs={columns}
                  rowSelection={"single"}
                  alwaysShowHorizontalScroll
                  alwaysShowVerticalScroll
                  suppressMovableColumns
                  suppressRowTransform
                  stopEditingWhenCellsLoseFocus
                  undoRedoCellEditing
                  undoRedoCellEditingLimit={20}
                  onGridReady={handleGridReady}
                  onCellEditingStopped={handleCellEditingStopped}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Dialog />
      </Grid>
    </>
  );
};
