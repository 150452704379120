import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, SuccessfulRecord, TurnoverParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newTurnoverRow } from "../consts";
import { TurnoverRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: TurnoverRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getTurnoverData, submitTurnover } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const turnover = await getTurnoverData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = turnover.flatMap((t) =>
          t.records.map<TurnoverRecordRow>((r) => ({
            ...newTurnoverRow,
            recordId: r.id,
            originalCompanyId: t.projectCompany.id,
            companyId: t.projectCompany.id,
            remarks: r.remarks,
            asOfDate: tryParseDateString(r.asOfDate, DateFormat.Date),
            totalTurnover: r.totalTurnover,
            male: r.male,
            female: r.female,
            seniorManagement: r.seniorManagement,
            middleLevelManagement: r.middleLevelManagement,
            generalAndTechnicalEmployees: r.generalAndTechnicalEmployees,
            ageGroup1: r.ageGroup1,
            ageGroup2: r.ageGroup2,
            ageGroup3: r.ageGroup3,
            ageGroup4: r.ageGroup4,
            ageGroup5: r.ageGroup5,
            ageGroup6: r.ageGroup6,
            hk: r.hk,
            china: r.china,
            otherRegions: r.otherRegions,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getTurnoverData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<TurnoverParam>((e) => {
          const data = e.data as DataGridRow<TurnoverRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              asOfDate: toDate(data.asOfDate, DateFormat.Date),
              totalTurnover: data.totalTurnover,
              male: data.male,
              female: data.female,
              seniorManagement: data.seniorManagement,
              middleLevelManagement: data.middleLevelManagement,
              generalAndTechnicalEmployees: data.generalAndTechnicalEmployees,
              ageGroup1: data.ageGroup1,
              ageGroup2: data.ageGroup2,
              ageGroup3: data.ageGroup3,
              ageGroup4: data.ageGroup4,
              ageGroup5: data.ageGroup5,
              ageGroup6: data.ageGroup6,
              hk: data.hk,
              china: data.china,
              otherRegions: data.otherRegions,
            },
          };
        });

        return await submitTurnover({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitTurnover]
  );

  return { getData, submitData };
};
