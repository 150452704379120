import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { Nf3RecordRow } from "./types";

export const newNf3Row: Nf3RecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  product: "",
  productionVolume: 0,
  unit: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<Nf3RecordRow, string> = {
  unit: "tips.nf2.unit",
};

export enum ProductionUnit {
  Tonnes = "tonnes",
  Meter = "meter",
  M2 = "m2",
  RoomNight = "room night",
  Household = "no of household",
  KWH = "kWh",
  Others = "others",
}

export const productionUnitOptions: OptionType[] = [
  { label: "Tonnes 公吨", value: ProductionUnit.Tonnes },
  { label: "meter 米", value: ProductionUnit.Meter },
  { label: "sq. meter 平方米", value: ProductionUnit.M2 },
  { label: "room night 住客入住晚数", value: ProductionUnit.RoomNight },
  { label: "no. of household 住户数", value: ProductionUnit.Household },
  { label: "kWh 千瓦时", value: ProductionUnit.KWH },
  { label: "Others 其他", value: ProductionUnit.Others },
];
