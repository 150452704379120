import { GridApi, RowNode, ValueFormatterParams } from "ag-grid-community";
import cuid from "cuid";
import { IntlShape } from "react-intl";
import { ValidationError } from "yup";

import { EmissionFactor, EmissionFactorCategory, EmissionFactorCode, EmissionFactorUnit } from "generated/graphql";
import { UnitLabels } from "pages/emission-factor-edit/data";
import { validationSchema } from "../schema";
import { EmissionFactorDataRow } from "../types";

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${day}/${month}/${year}`;
};

export const getDefaultRowValue = (category: EmissionFactorCategory, codeId: string): EmissionFactorDataRow => ({
  rowId: cuid(),
  category,
  codeId,
  name: "",
  value: 0,
  unit: {
    actual: EmissionFactorUnit.G,
    base: EmissionFactorUnit.G,
  },
  emissionUnit: EmissionFactorUnit.G,
  reference: null,
  yearOfPublication: null,
  reviewedOn: null,
});

export const unitFormatter =
  (intl: IntlShape) =>
  (params: ValueFormatterParams): string => {
    if (params.value && UnitLabels[params.value]) {
      return intl.formatMessage({ id: UnitLabels[params.value] });
    } else {
      return "";
    }
  };

export const dateFormatter = (params: ValueFormatterParams): string => {
  return params.value ? formatDate(new Date(params.value)) : "";
};

export const codeIdFormatter =
  (code: EmissionFactorCode[]) =>
  (params: ValueFormatterParams): string => {
    if (params.value) {
      const targetCode = code.find((c) => c.id === params.value);

      if (targetCode) {
        return targetCode.code;
      }
    }

    return "";
  };

export const sortEmissionFactors = (
  emissionFactors: EmissionFactor[],
  code: EmissionFactorCode[]
): EmissionFactor[] => {
  const codeIndexMapping = {};
  code.forEach((c, index) => (codeIndexMapping[c.id] = index));

  return [...emissionFactors].sort((a, b) => (codeIndexMapping[a.codeId] < codeIndexMapping[b.codeId] ? -1 : 1));
};

export const validateData = (gridApi: GridApi): boolean => {
  const errorRows: RowNode[] = [];

  gridApi.forEachNode((node) => {
    try {
      validationSchema.validateSync(node.data, { abortEarly: false });

      if (node.data.error) {
        delete node.data.error;
      }
    } catch (e) {
      const validationError = e as ValidationError;
      const error = {};

      for (const i of validationError.inner) {
        error[i.path] = i.message;
      }

      node.data.error = error;
      errorRows.push(node);
    }
  });

  if (errorRows.length > 0) {
    gridApi.refreshCells({ force: true, rowNodes: errorRows });
  }

  return errorRows.length === 0;
};
