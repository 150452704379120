import React from "react";

import { ErrorMessagePopup } from "../components/error-message-popup";
import { FormatterProps } from "../components/formatter/formatter";

type ComponentProps = FormatterProps & {
  row: { errors: Record<string, string> };
};

export const getFormatter =
  (Component: React.FC<ComponentProps>, key: string): React.FC<ComponentProps> =>
  (p) =>
    <Component {...p}>{p.row.errors[key] ? <ErrorMessagePopup message={p.row.errors[key]} /> : undefined}</Component>;
