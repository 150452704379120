import React, { useContext } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ReactDOMServer from "react-dom/server";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { Role } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { LandingPageTitle } from "./components/landing-page-title";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    marginLeft: spacing(3),
  },
  alphavistaLogo: {
    height: "30px",
    transformY: "2px",
  },
}));

export const LandingPage: React.FC = () => {
  const intl = useIntl();
  const { me } = useContext(AuthContext);
  const { classes } = useStyles();

  const getGuide = React.useMemo(() => {
    if (!me.role) {
      return;
    }
    let key = "";
    switch (me.role) {
      default:
      case Role.ClientStaff: {
        key = "landing-page.guide.staff";
        break;
      }
      case Role.ClientAdmin: {
        key = "landing-page.guide.admin";
        break;
      }
    }

    return (
      <>
        <div>
          <LandingPageTitle isAdmin={me.role === Role.ClientAdmin} />
        </div>
        <br />
        <br />
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: key },
              {
                icon: ReactDOMServer.renderToString(<MenuIcon />),
                titleStyle: `style="background: #63b8a7;color: white;border-radius: 40px;padding: 5px 40px 5px 40px;"`,
                getStartedStyle: `style="font-style:italic;font-weight:bold"`,
                emphasizeStyle: `style="font-weight:bold"`,
                emphasizeChineseStyle: `style="font-weight:bolder"`,
                containerStyle: `style="display: grid;grid-template-columns: min-content auto;margin:8px"`,
                numberStyle: `style="background-color: #63b8a7;border-radius: 50%;width: 25px;height: 25px;display: inline-block;text-align: center;color: white;margin:8px;"`,
                descriptionStyle: `style="margin: auto auto auto 0px;"`,
              }
            ),
          }}
        ></div>
      </>
    );
  }, [me, intl]);

  return (
    <Typography variant="h4" className={classes.root}>
      {getGuide}
    </Typography>
  );
};
