import React from "react";

import Grid, { Grid2Props as GridProps } from "@mui/material/Unstable_Grid2";

import { Skeleton } from "./blocks/skeleton";

export type GridWithLoadingProps = GridProps & {
  isLoading: boolean;
  isVisible: boolean;
};

export const GridWithLoading: React.FC<GridWithLoadingProps> = (props) => {
  const { isLoading, isVisible, children, ...gridProps } = props;

  return <>{(isLoading || isVisible) && <Grid {...gridProps}>{isLoading ? <Skeleton /> : children}</Grid>}</>;
};
