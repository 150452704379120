import React from "react";

import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useIntl } from "react-intl";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "12px",
    maxWidth: "300px",
  },
}))(Tooltip);

export type HeaderWithTooltipsProps = {
  name: string;
  tips: string;
  editable?: boolean;
};

const HeaderWithTooltips: React.FC<HeaderWithTooltipsProps> = (props) => {
  const { name, tips, editable } = props;

  const intl = useIntl();

  const classname = editable === false ? "widget-HeaderCell__value disabled" : "widget-HeaderCell__value";

  return (
    <HtmlTooltip
      title={<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: tips }) }}></div>}
      placement="top"
      style={{ height: "100%" }}
      disableInteractive={false}
    >
      <div className={classname} style={{ height: "100%" }}>
        * {name}
      </div>
    </HtmlTooltip>
  );
};

export default HeaderWithTooltips;
