import { CommonReportRecordRow } from "utils/interfaces";
import { DataGridState } from ".";
import { filterRows } from "./filter-rows";
import { sort } from "./sort";

export const combineActionRowDisplay = <T extends CommonReportRecordRow>(
  params: Pick<DataGridState<T>, "rowsBaseMap" | "rowsWithDisplayValue" | "sortData" | "filterString">
): DataGridState<T>["rowsDisplay"] => {
  const { rowsBaseMap, rowsWithDisplayValue, sortData, filterString } = params;
  let rowDisplay = Object.values(rowsBaseMap);

  if (filterString?.length) {
    rowDisplay = filterRows({ rowsBaseMap, rowsWithDisplayValue, filterString });
  }
  if (sortData) {
    sort(rowsBaseMap, sortData, rowDisplay);
  }
  return rowDisplay;
};
