import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { getColorByValueDiff } from "pages/dashboard/utils";
import { formatNumberToPercentage } from "utils/format";
import { getAbsDiff } from "utils/math";
import { GridWithLoading, TotalBlock } from "..";
import { DASHBOARD_BLOCK_HEIGHT_50 } from "../consts";
import { SectionHeader } from "../section-header";

export type ProductResponsibilityDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  previousYear: string;
  isLoading: boolean;
};

export const ProductResponsibilityDashboard: React.FC<ProductResponsibilityDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data;
  const { totalProduct, retrievedProduct, totalComplaint } = current.productResponsibility;

  const intl = useIntl();

  const getCompareMessage = (main: number, sub?: number) => {
    if (sub && main !== sub) {
      return intl.formatMessage(
        { id: main > sub ? "dashboard.common.total.value.up" : "dashboard.common.total.value.down" },
        { percentage: formatNumberToPercentage(getAbsDiff(main, sub)), year: previousYear }
      );
    }

    return intl.formatMessage({ id: "dashboard.common.total.value.no-change" });
  };

  /** Blocks are always visible even if value is 0 */
  return (
    <Grid xs={4}>
      <Grid xs={12}>
        <SectionHeader
          title={intl.formatMessage({ id: "dashboard.social.productResponsibility.title" })}
          description={intl.formatMessage(
            { id: "dashboard.social.productResponsibility.description" },
            { year: currentYear }
          )}
        />
      </Grid>

      <Grid xs={12}>
        <Grid container spacing={2}>
          <GridWithLoading xs={6} height={DASHBOARD_BLOCK_HEIGHT_50} isLoading={isLoading} isVisible>
            <TotalBlock
              title={intl.formatMessage({ id: "dashboard.social.productResponsibility.totalProduct.title" })}
              value={totalProduct.value}
              valueColor={getColorByValueDiff(totalProduct.value, previous?.productResponsibility.totalProduct.value)}
              unit={getCompareMessage(totalProduct.value, previous?.productResponsibility.totalProduct.value)}
              unitColor={getColorByValueDiff(totalProduct.value, previous?.productResponsibility.totalProduct.value)}
            />
          </GridWithLoading>

          <GridWithLoading xs={6} height={DASHBOARD_BLOCK_HEIGHT_50} isLoading={isLoading} isVisible>
            <TotalBlock
              title={intl.formatMessage({ id: "dashboard.social.productResponsibility.retrievedProduct.title" })}
              value={retrievedProduct.value}
              valueColor={getColorByValueDiff(
                retrievedProduct.value,
                previous?.productResponsibility.retrievedProduct.value
              )}
              unit={getCompareMessage(retrievedProduct.value, previous?.productResponsibility.retrievedProduct.value)}
              unitColor={getColorByValueDiff(
                retrievedProduct.value,
                previous?.productResponsibility.retrievedProduct.value
              )}
            />
          </GridWithLoading>

          <GridWithLoading xs={6} height={DASHBOARD_BLOCK_HEIGHT_50} isLoading={isLoading} isVisible>
            <TotalBlock
              title={intl.formatMessage({ id: "dashboard.social.productResponsibility.totalComplaint.title" })}
              value={totalComplaint.value}
              valueColor={getColorByValueDiff(
                totalComplaint.value,
                previous?.productResponsibility.totalComplaint.value
              )}
              unit={getCompareMessage(totalComplaint.value, previous?.productResponsibility.totalComplaint.value)}
              unitColor={getColorByValueDiff(
                totalComplaint.value,
                previous?.productResponsibility.totalComplaint.value
              )}
            />
          </GridWithLoading>
        </Grid>
      </Grid>
    </Grid>
  );
};
