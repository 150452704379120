import React from "react";

import Grid from "@mui/material/Unstable_Grid2";

import { BarChartWithValueBlock, BarChartWithValueBlockProps } from "../blocks/bar-chart-with-value-block";
import { BlockWithHeaderContainer, BlockWithHeaderContainerProps } from "../containers/block-with-header-container";

export type WasteBlockProps = BlockWithHeaderContainerProps & {
  total?: BarChartWithValueBlockProps;
  intensity?: BarChartWithValueBlockProps;
};

export const WasteBlock: React.FC<WasteBlockProps> = (props) => {
  const { header, total, intensity } = props;

  return (
    <BlockWithHeaderContainer header={header}>
      <Grid xs={12} paddingTop={2} height="inherit">
        <Grid container spacing={2}>
          {total && (
            <Grid xs={6}>
              <BarChartWithValueBlock {...total} />
            </Grid>
          )}
          {intensity && (
            <Grid xs={6}>
              <BarChartWithValueBlock {...intensity} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </BlockWithHeaderContainer>
  );
};
