import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { formatNumberToPercentage, formatNumberWithComma } from "utils/format";
import { getAbsDiff } from "utils/math";
import { getColorByValueDiff } from "../../utils";
import { TitleValueLayout } from "../blocks/title-value-layout";
import { ChartLegend } from "../charts/chart-legend";
import { PieChartWithLargestValueLabel } from "../charts/pie-chart/pie-chart-with-largest-value-label";
import { ChartInputWithColor } from "../charts/types";
import { DashboardColor } from "../consts";
import { TwoBlockContainer } from "../containers/two-block-container";

export type ValueWithPieChartBlockProps = {
  data: ChartInputWithColor[];
  compareValue?: { name: string; value: number };
};

export const ValueWithPieChartBlock: React.FC<ValueWithPieChartBlockProps> = (props) => {
  const { data, compareValue } = props;

  const intl = useIntl();

  const valueSum = data.reduce((acc, d) => acc + d.value, 0);
  const valueColor = getColorByValueDiff(valueSum, compareValue?.value);

  const getCompareMessage = () => {
    if (compareValue && valueSum !== compareValue.value) {
      return intl.formatMessage(
        {
          id: valueSum > compareValue.value ? "dashboard.common.total.value.up" : "dashboard.common.total.value.down",
        },
        { percentage: formatNumberToPercentage(getAbsDiff(valueSum, compareValue.value)), year: compareValue.name }
      );
    }

    return intl.formatMessage({ id: "dashboard.common.total.value.no-change" });
  };

  return (
    <TwoBlockContainer
      left={
        <Grid container height="100%" direction="column" justifyContent="center">
          <Grid paddingLeft={2}>
            <TitleValueLayout
              title={intl.formatMessage({ id: "dashboard.social.supplyChain.total.title" })}
              value={formatNumberWithComma(valueSum)}
              footer={getCompareMessage()}
              style={{
                title: {
                  variant: "h4",
                  color: DashboardColor.text.grey,
                  bold: true,
                },
                value: {
                  color: valueColor,
                  bold: true,
                },
                footer: {
                  color: valueColor,
                },
              }}
            />
          </Grid>
        </Grid>
      }
      right={
        <Grid container height="100%" spacing={1} direction="column" alignItems="center" wrap="nowrap">
          <Grid xs={9}>
            <PieChartWithLargestValueLabel data={data} unit={""} labelType={"percentage"} />
          </Grid>
          <Grid>
            <ChartLegend data={data} column={3} gap={3} />
          </Grid>
        </Grid>
      }
    />
  );
};
