import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";

import CloneIcon from "@mui/icons-material/Bookmarks";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SubmitIcon from "@mui/icons-material/DoneOutline";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Unstable_Grid2";
import isEqual from "lodash/isEqual";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Lazy } from "yup";

import { BeforeExitPrompt } from "components/before-exit-prompt";
import { IntroModal } from "components/intro-modal";
import { SearchInput, SearchInputIcon } from "components/react-table";
import { TourGuide } from "components/tour-guide";
import { RecordStatus, Role, SuccessfulRecord } from "generated/graphql";
import { useDialog, useFetch, useSnackbar } from "hook";
import {
  cloneButtonId,
  deleteButtonId,
  reactGridContainerId,
  saveButtonId,
  submitButtonId,
} from "pages/excel/utils/step-message";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { FILE_SIZE_LIMIT } from "utils/const";
import { CommonReportRecordRow, IColumnItem, IUpdatedItem } from "utils/interfaces";
import {
  CompanyType,
  DataGridSourceType,
  HeaderWithTooltips,
  OnPreValidateRows,
  OnUpdateDataGridRows,
  useEvent,
} from "..";
import { UseActionsParams, useActions } from "../hook/action";
import { SubmitDataErrorCallback } from "../hook/types";
import { initState, reducer } from "../reducer";
import { isAllErrorRowsDisplaying, updateErrorStatus, validateRowsBySchema } from "../utils/data-grid-container-utils";
import { AddButton } from "./add-button";
import { DataGrid } from "./data-grid";

export type GuideStep = {
  content: React.ReactElement;
  target: string;
  disableBeacon: boolean;
};

const useStyles = makeStyles()(({ palette, typography }) => ({
  root: {
    fontSize: typography.body2.fontSize,
    lineHeight: typography.body2.lineHeight,
  },
  deleteBtn: {
    color: palette.error.main,
  },
  searchToolBar: {
    marginTop: 0,
    padding: 0,
  },
}));

export type DataGridContainerProps<T extends CommonReportRecordRow = CommonReportRecordRow> = {
  excludeClone?: { property: string; value: string | number }[];
  onInitData: () => Promise<void>;
  initRows: T[];
  openNotice?: boolean;
  newRowData: T;
  columns: IColumnItem[];
  fullSchema: Lazy;
  basicSchema?: Lazy;
  source: DataGridSourceType;
  canSave: boolean;
  guide?: { introContent?: string; steps?: GuideStep[] };
  onUpdateRows?: OnUpdateDataGridRows<T>;
  onPreValidate?: OnPreValidateRows<T>;
  tips?: Record<string, string>;
  disableRemarks?: boolean;
  disableFile?: boolean;
} & Pick<UseActionsParams, "submitData">;

export const DataGridContainer = <T extends CommonReportRecordRow>(props: DataGridContainerProps<T>): JSX.Element => {
  const {
    fullSchema,
    basicSchema,
    source,
    initRows,
    columns,
    guide,
    canSave,
    openNotice,
    newRowData,
    onUpdateRows,
    onPreValidate,
    onInitData,
    submitData,
    tips,
    disableRemarks,
    disableFile,
  } = props;

  const [enableCellSelect, setEnableCellSelect] = useState(true);
  const [isSavedOnce, setIsSavedOnce] = useState(false);

  const { classes } = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const { me, refetchToken } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showMessage } = useContext(MessageContext);
  const { Dialog, openDialog, DialogType } = useDialog();
  const { openSnackbar, Snackbar } = useSnackbar();

  /**
   * Memorizing reducer to prevent trigger dispatch twice when
   * there are some actions inside reducer may result in component re-rendering
   *
   * FYR:
   * https://stackoverflow.com/questions/55055793/react-usereducer-hook-fires-twice-how-to-pass-props-to-reducer/55056623#55056623
   */
  const memorizedReducer = useMemo(() => reducer<T>(), []);
  const [state, dispatch] = useReducer(memorizedReducer, initState<T>());
  const { isSubmitting, isTouched, rowsBaseMap, scrollIndex, rowsDisplay, selectedRowIndex } = state;

  const { refetch } = useFetch(async () => await onInitData());
  const { events, emitCreateEvent, emitUpdateEvent, emitDeleteEvent, resetEvents, removeEvents } = useEvent();
  const { saveStore, dispatchStore } = useActions({ openDialog, submitData });

  const [prevInitRows, setPrevInitRows] = useState(initRows);
  if (!isEqual(prevInitRows, initRows)) {
    setPrevInitRows(initRows);
    dispatch({
      type: "init",
      rowsBase: initRows,
      columnsForTransposedRows: columns,
    });
  }

  useEffect(() => {
    openSnackbar(`* ${intl.formatMessage({ id: "data-grid.reminder.mouse-tip" })}`, {
      variant: "info",
      autoHideDuration: 5000,
    });
  }, [openSnackbar, intl]);

  useEffect(() => {
    if (me.role === Role.ClientAdmin && source !== CompanyType.Company) setEnableCellSelect(true);
  }, [me, source]);

  const columnEnhanced = useMemo(
    () =>
      columns.map<IColumnItem>((c) => {
        // merge default Column setting
        if (!c.isAction && c.sortable !== false) c.sortable = true;

        // AddedTooltips
        // for headerRenderer, need element if sortable = true, avoid error make it as element
        if (tips && tips[c.key]) {
          c.headerRenderer = <HeaderWithTooltips name={c.name} tips={tips[c.key]} editable={c.editable} />;
        } else if (c.editable === false) {
          // only c.editable is false will render header in deafult color
          // no change if c.editable is null | undefined
          c.headerRenderer = <div className="widget-HeaderCell__value disabled">{c.name}</div>;
        }

        return c;
      }),
    [columns, tips]
  );

  const showErrorMessageSnackbar = () => {
    showMessage({
      message: intl.formatMessage({ id: "data-grid.reminder.error-tip1" }, { icon: '"!"' }),
      variant: "error",
      autoHideDuration: 5000,
    });
  };

  const validateRows = async (schema: Lazy) => {
    const rows = Object.values(rowsBaseMap);

    let targetRows: T[] = rows;
    if (onPreValidate) targetRows = onPreValidate(targetRows);

    const { hasError, errors } = await validateRowsBySchema(targetRows, schema);
    updateErrorStatus(rows, errors);
    if (hasError) {
      showErrorMessageSnackbar();

      const isNotUndefined = <DT extends object>(obj: DT | undefined): obj is DT => obj !== undefined;
      const errorRows = errors.map((e, i) => (e ? rows[i] : undefined)).filter(isNotUndefined);

      if (!isAllErrorRowsDisplaying(rowsDisplay, errorRows)) {
        openDialog({
          content: intl.formatMessage(
            { id: "error.validate.not-all-error-rows-displaying" },
            { searchIcon: <SearchInputIcon /> }
          ),
          type: DialogType.ALERT,
        });
      }
    }

    return { hasError, errors };
  };

  useEffect(() => {
    if (Object.values(rowsBaseMap).length === 0 || isSavedOnce) return;
    validateRows(fullSchema);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowsBaseMap, isSavedOnce]);

  const handleFileUploadValidate = (file: File) => {
    const unsavedFileSize = Object.values(rowsBaseMap)
      .flatMap((row) => row.fileToUpload)
      .reduce((size, file) => size + file.size, 0);

    const isFileSizeUnderLimit = unsavedFileSize + file.size <= FILE_SIZE_LIMIT;

    if (!isFileSizeUnderLimit) {
      openDialog({
        content: intl.formatMessage({ id: "error.submit.data.file.size" }),
        type: DialogType.ALERT,
      });
    }

    return isFileSizeUnderLimit;
  };

  const handleSubmitDataError: SubmitDataErrorCallback = (successEvents) => {
    dispatch({ type: "updateSuccessRow", events: successEvents, removeEvent: removeEvents });
  };

  const handleSave = async () => {
    if (isSubmitting) return;
    dispatch({ type: "setIsSubmitting", isSubmitting: true });
    setLoading(true);

    const { hasError, errors } = await validateRows(basicSchema ?? fullSchema);
    if (hasError) {
      const scrollIndex = errors.findIndex((e) => Object.keys(e).length > 0);
      dispatch({ type: "scrollIndex", scrollIndex });
    } else {
      dispatch({ type: "setIsTouched", isTouched: false });
      const isSaveSuccess = await saveStore(events, handleSubmitDataError);

      if (isSaveSuccess) {
        showMessage({
          message: intl.formatMessage({ id: "excel.common.success" }),
          autoHideDuration: 5000,
        });
        resetEvents();
        refetch();
        refetchToken();
        setIsSavedOnce(true);
      }
    }

    setLoading(false);

    setTimeout(() => {
      dispatch({ type: "setIsSubmitting", isSubmitting: false });
    }, 500);
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setLoading(true);

    const { hasError } = await validateRows(fullSchema);
    if (hasError) {
      openDialog({ content: intl.formatMessage({ id: "errors.common.error-input" }), type: DialogType.ERROR });
      setLoading(false);
      return;
    }

    dispatch({ type: "setIsSubmitting", isSubmitting: true });
    dispatch({ type: "setIsTouched", isTouched: false });

    const successIds = Object.values(state.rowsBaseMap)
      .filter(({ recordId, recordStatus }) => recordId && recordStatus === RecordStatus.Success)
      .map<SuccessfulRecord>(({ companyId, recordId }) => ({ companyId, recordId }));
    const isDispatchSuccess = await dispatchStore(events, handleSubmitDataError, successIds);

    dispatch({ type: "setIsSubmitting", isSubmitting: false });
    setLoading(false);
    refetchToken();

    if (isDispatchSuccess) {
      resetEvents();
      if (source === CompanyType.Company) {
        history.push("/project/user");
      } else {
        history.push("/stepper");
      }
    }
  };

  return (
    <>
      <BeforeExitPrompt when={isTouched} message={intl.formatMessage({ id: "errors.common.leave-page" })} />
      <Grid container={true} spacing={1} className={classes.root}>
        <Grid xs={12}>
          {guide && (
            <>
              {guide.introContent && (
                <IntroModal open={openNotice}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: guide.introContent,
                    }}
                  ></div>
                </IntroModal>
              )}
              {guide.steps && <TourGuide steps={guide.steps} />}
            </>
          )}
          <ButtonGroup variant="outlined" color="primary" style={{ marginLeft: "10px", float: "right" }}>
            <Button
              id={submitButtonId}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              <SubmitIcon />
              {intl.formatMessage({ id: "button.data-grid.submit" })}
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid xs={12}>
          <AddButton
            addRow={(cloneFromIndex) => {
              if (cloneFromIndex == -1) {
                const newRow = { ...newRowData, id: Object.values(rowsBaseMap).length + 1 };
                dispatch({ type: "addNewRow", createEvent: emitCreateEvent, newRow });
              } else {
                dispatch({
                  type: "cloneRow",
                  cloneEvent: emitCreateEvent,
                  cloneSourceRowIndex: cloneFromIndex,
                });
              }
            }}
            rowsLength={rowsDisplay.length}
          />
          <ButtonGroup variant="outlined" color="primary" style={{ marginLeft: "10px" }}>
            <Button
              id={cloneButtonId}
              variant="contained"
              color="primary"
              disabled={selectedRowIndex === -1}
              onClick={() => {
                dispatch({
                  type: "cloneRow",
                  cloneEvent: emitCreateEvent,
                  cloneSourceRowIndex: selectedRowIndex,
                });
              }}
            >
              <CloneIcon />
              {intl.formatMessage({ id: "data-grid.tools.clone" })}
            </Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" color="primary" style={{ marginLeft: "10px" }}>
            <Button
              id={deleteButtonId}
              className={classes.deleteBtn}
              variant="contained"
              color="primary"
              disabled={selectedRowIndex === -1}
              onClick={() => {
                dispatch({
                  type: "removeRow",
                  removeEvent: emitDeleteEvent,
                  removeRowIndex: selectedRowIndex,
                });
              }}
            >
              <DeleteIcon />
              {intl.formatMessage({ id: "data-grid.tools.delete" })}
            </Button>
          </ButtonGroup>
          {canSave && (
            <ButtonGroup variant="outlined" color="primary" style={{ marginLeft: "10px" }}>
              <Button
                id={saveButtonId}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  handleSave();
                }}
              >
                <SaveIcon />
                {intl.formatMessage({ id: "button.data-grid.save" })}
              </Button>
            </ButtonGroup>
          )}
          <div style={{ marginLeft: "10px", float: "right" }}>
            <SearchInput
              onChange={(filterString) => {
                dispatch({ type: "filter", filterString });
              }}
              value={state.filterString}
            />
          </div>
        </Grid>

        <Grid id={reactGridContainerId} xs={12} style={{ padding: "0", paddingBottom: "5px" }}>
          <DataGrid
            columns={columnEnhanced}
            onCellSelected={(selectedCell) => {
              dispatch({ type: "selectedRowIndex", selectedRowIndex: selectedCell.rowIdx });
            }}
            rows={rowsDisplay}
            scrollIndex={scrollIndex}
            enableCellSelect={enableCellSelect}
            onUploadFileValidate={handleFileUploadValidate}
            onGridRowsUpdated={(onGridUpdatedRowItem) => {
              dispatch({
                type: "updateRow",
                updatedItem: onGridUpdatedRowItem as IUpdatedItem<T>,
                updateEvent: emitUpdateEvent,
                onUpdateRows,
              });
            }}
            onGridSort={(columnKey, direction) => {
              dispatch({ type: "sort", sortData: { columnKey, direction } });
            }}
            disableRemarks={disableRemarks ?? false}
            disableFile={disableFile ?? false}
          />
        </Grid>
      </Grid>
      <Dialog />
      <Snackbar />
    </>
  );
};
