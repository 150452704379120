import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetProjectCompanyDataDocument,
  GetProjectCompanyDataQuery,
  GetProjectCompanyDataQueryVariables,
  SubmitProjectCompanyDocument,
  SubmitProjectCompanyMutation,
  SubmitProjectCompanyMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getProjectCompanyData: (
    varaibles: GetProjectCompanyDataQueryVariables
  ) => Promise<GetProjectCompanyDataQuery["project"]>;
  submitProjectCompany: (
    varaibles: SubmitProjectCompanyMutationVariables
  ) => Promise<SubmitProjectCompanyMutation["submitProjectCompany"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getProjectCompanyData = useCallback(
    async (variables: GetProjectCompanyDataQueryVariables) => {
      const response = await client.query<GetProjectCompanyDataQuery, GetProjectCompanyDataQueryVariables>({
        query: GetProjectCompanyDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project;
    },
    [client]
  );

  const submitProjectCompany = useCallback(
    async (variables: SubmitProjectCompanyMutationVariables) => {
      const response = await client.mutate<SubmitProjectCompanyMutation, SubmitProjectCompanyMutationVariables>({
        mutation: SubmitProjectCompanyDocument,
        variables,
      });

      return response.data?.submitProjectCompany ?? false;
    },
    [client]
  );

  return {
    getProjectCompanyData,
    submitProjectCompany,
  };
};
