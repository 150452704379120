import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newNf2Row, paymentUnitOptions, tips } from "./consts";
import { useActions } from "./hook/action";
import { Nf2RecordRow } from "./types";

const source = IndexType.NormalizationFactor2;

export const Nf2Page: React.FC = () => {
  const [initRows, setInitRows] = useState<Nf2RecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "value",
        name: intl.formatMessage({ id: "excel.common.value" }),
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "value"),
      },
      {
        key: "unit",
        name: intl.formatMessage({ id: "excel.common.unit" }),
        resizable: true,
        editor: SelectEditor,
        options: paymentUnitOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(paymentUnitOptions), "unit"),
      },
    ],
    [intl, companyOptions]
  );

  const introContent = intl.formatMessage({ id: "excel.nf2.guide" });
  const steps = useMemo(() => getSteps(intl), [intl]);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;
    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<Nf2RecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as Nf2RecordRow[]);

    return Yup.array(
      Yup.object<Partial<Nf2RecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .unique("errors.common.company-existed", "companyId", values)
          .allCompaniesValid("errors.common.validate-all-companies", result),
        value: Yup.number().required("errors.common.require").positive("errors.common.more-zero"),
        unit: Yup.string().required("errors.common.require"),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newNf2Row}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[{ property: "value", value: 0 }]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
