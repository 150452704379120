import React, { useMemo } from "react";

import DownloadIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import { useIntl } from "react-intl";

import { ButtonTooltip } from "components/button-tooltip";
import { DataTable, StyledTableCell } from "components/react-table";
import { downloadStepperDocument } from "utils/download";
import { DataRecordRowItem } from "../types";

export type DataRecordTableProps = {
  rows: DataRecordRowItem[];
};

export const DataRecordTable: React.FC<DataRecordTableProps> = (props) => {
  const { rows } = props;

  const intl = useIntl();

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: intl.formatMessage({ id: "scope3.manage.uploaded-data.name" }),
      },
      {
        accessor: "updatedAt",
        Header: intl.formatMessage({ id: "scope3.manage.uploaded-data.updated-at" }),
      },
      {
        accessor: "download",
        Header: "",
      },
    ],
    [intl]
  );

  const renderRow = (row, rowProps) => {
    const handleDownload = (fileId: string) => downloadStepperDocument(fileId);

    return (
      <TableRow {...rowProps}>
        {row.cells.map((cell) => {
          const Formatter = cell.render("Cell");
          return (
            <StyledTableCell key={cell.column.id} {...cell.getCellProps()}>
              {cell.column.id === "download" ? (
                <ButtonTooltip title={intl.formatMessage({ id: "button.download" })}>
                  <IconButton onClick={() => handleDownload(Formatter)} size="large">
                    <DownloadIcon />
                  </IconButton>
                </ButtonTooltip>
              ) : (
                <>{Formatter}</>
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <DataTable
      data={rows}
      columns={columns}
      renderRow={renderRow}
      isOnlyTable
      state={{ pageIndex: 0, pageSize: 0, totalCount: 0 }}
      goToPage={() => undefined}
      setPageSize={() => undefined}
      HeaderActionComponent={null}
      searchValue=""
      onSearchValueChange={() => undefined}
    />
  );
};
