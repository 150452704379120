/**
 * NOTE
 *
 * Since 2023/5/12, "Power Source" / "Location of Electricity Supplier" options in
 * Electricity Consumption data sheet were renewed according to ESG team's requirement.
 * (refer to Jira task IESG-231)
 *
 * Users are forced to select new options when modifying above data. However, it's not
 * complusory to transfer all data in old projects to new options. Therefore deprecated options
 * are remained for display purpose.
 *
 * This file stores deprecated options.
 *
 */

import { GroupedOptionType, OptionType } from "utils/interfaces";

export enum DeprecatedPowerSource {
  Citygrid = "citygrid",
}

export const deprecatedPowerSourceOptions: GroupedOptionType[] = [
  {
    label: "",
    options: [{ label: "(购电)国网 Citygrid", value: DeprecatedPowerSource.Citygrid }],
  },
];

export enum DeprecatedPowerSourceSupplierLocation {
  CLP = "clp",
  HkElectric = "hkElectric",
  Beijing = "Beijing",
  Tianjin = "Tianjin",
  Hebei = "Hebei",
  Shanxi = "Shanxi",
  Shandong = "Shandong",
  Mengxi = "Mengxi",
  Liaoning = "Liaoning",
  Monton = "Monton",
  Shanghai = "Shanghai",
  Jiangsu = "Jiangsu",
  Zhejiang = "Zhejiang",
  Anhui = "Anhui",
  Fujian = "Fujian",
  Henan = "Henan",
  Hubei = "Hubei",
  Hunan = "Hunan",
  Jiangxi = "Jiangxi",
  Sichuan = "Sichuan",
  Chongqing = "Chongqing",
  Shaanxi = "Shaanxi",
  Gansu = "Gansu",
  Qinghai = "Qinghai",
  Ningxia = "Ningxia",
  Xinjiang = "Xinjiang",
  Guangdong = "Guangdong",
  Guangxi = "Guangxi",
  Yunnan = "Yunnan",
  Guizhou = "Guizhou",
  Hainan = "Hainan",
  Macau = "Macau",
}

export const deprecatedPowerSourceSupplierLocationOptions: OptionType[] = [
  { label: "CLP", value: DeprecatedPowerSourceSupplierLocation.CLP },
  { label: "HK Electric", value: DeprecatedPowerSourceSupplierLocation.HkElectric },
  { label: "北京市 （华北）", value: DeprecatedPowerSourceSupplierLocation.Beijing },
  { label: "天津市 （华北）", value: DeprecatedPowerSourceSupplierLocation.Tianjin },
  { label: "河北省 （华北）", value: DeprecatedPowerSourceSupplierLocation.Hebei },
  { label: "山西省 （华北）", value: DeprecatedPowerSourceSupplierLocation.Shanxi },
  { label: "山东省 （华北）", value: DeprecatedPowerSourceSupplierLocation.Shandong },
  { label: "蒙西 （华北）", value: DeprecatedPowerSourceSupplierLocation.Mengxi },
  { label: "辽宁省 （东北）", value: DeprecatedPowerSourceSupplierLocation.Liaoning },
  { label: "蒙东 （东北）", value: DeprecatedPowerSourceSupplierLocation.Monton },
  { label: "上海市 （华东）", value: DeprecatedPowerSourceSupplierLocation.Shanghai },
  { label: "江苏省 （华东）", value: DeprecatedPowerSourceSupplierLocation.Jiangsu },
  { label: "浙江省 （华东）", value: DeprecatedPowerSourceSupplierLocation.Zhejiang },
  { label: "安徽省 （华东）", value: DeprecatedPowerSourceSupplierLocation.Anhui },
  { label: "福建省 （华东）", value: DeprecatedPowerSourceSupplierLocation.Fujian },
  { label: "河南省 （华中）", value: DeprecatedPowerSourceSupplierLocation.Henan },
  { label: "湖北省 （华中）", value: DeprecatedPowerSourceSupplierLocation.Hubei },
  { label: "湖南省 （华中）", value: DeprecatedPowerSourceSupplierLocation.Hunan },
  { label: "江西省 （华中）", value: DeprecatedPowerSourceSupplierLocation.Jiangxi },
  { label: "四川省 （华中）", value: DeprecatedPowerSourceSupplierLocation.Sichuan },
  { label: "重庆市 （华中）", value: DeprecatedPowerSourceSupplierLocation.Chongqing },
  { label: "陕西省 （西北）", value: DeprecatedPowerSourceSupplierLocation.Shaanxi },
  { label: "甘肃省 （西北）", value: DeprecatedPowerSourceSupplierLocation.Gansu },
  { label: "青海省 （西北）", value: DeprecatedPowerSourceSupplierLocation.Qinghai },
  { label: "宁夏自治区 （西北）", value: DeprecatedPowerSourceSupplierLocation.Ningxia },
  { label: "新疆自治区 （西北）", value: DeprecatedPowerSourceSupplierLocation.Xinjiang },
  { label: "广东省 （南方）", value: DeprecatedPowerSourceSupplierLocation.Guangdong },
  { label: "广西自治区 （南方）", value: DeprecatedPowerSourceSupplierLocation.Guangxi },
  { label: "云南省 （南方）", value: DeprecatedPowerSourceSupplierLocation.Yunnan },
  { label: "贵州省 （南方）", value: DeprecatedPowerSourceSupplierLocation.Guizhou },
  { label: "海南省 （南方）", value: DeprecatedPowerSourceSupplierLocation.Hainan },
  { label: "澳门", value: DeprecatedPowerSourceSupplierLocation.Macau },
];
