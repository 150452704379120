import React from "react";

import { useApolloClient } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { AvistaLogo } from "components/avista-logo";
import { AvistaWhiteLogo } from "components/avista-white-logo";
import { useLoginMutation } from "generated/graphql";
import { useDialog } from "hook";
import alphavistaLogoBlue from "static/images/alpha-vista-logo-blue.png";
import alphavistaLogoWhite from "static/images/alpha-vista-logo-white.png";
import loginCorner from "static/images/login-corner.png";
import { paleGreen } from "theme/color";
import { loginSchema } from "../schema";
import { LoginFormField } from "../types";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  container: {
    height: "100%",
  },
  leftPane: {
    backgroundColor: palette.primary.main,
    position: "relative",
    height: "100%",
  },
  titleContainer: {
    top: "30%",
    left: "5%",
    position: "absolute",
    width: "95%",
  },
  title: {
    fontSize: "3.3vw",
    height: "fit-content",
    marginBottom: spacing(8),
    color: "white",
    lineHeight: "100%",
  },
  description: {
    fontSize: "0.8vw",
    height: "fit-content",
    color: "white",
    maxWidth: "65%",
    lineHeight: "normal",
  },
  whiteLogo: {
    marginLeft: spacing(6),
    marginTop: spacing(4),
    height: "3vw",
  },
  logo: {
    marginTop: spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: spacing(),
  },
  rightPane: {
    textAlign: "center",
  },
  submit: {
    marginTop: spacing(3),
    backgroundColor: paleGreen,
    color: palette.primary.contrastText,
    width: "200px",
    borderRadius: "40px",
  },
  fieldInput: {
    margin: spacing(2),
    width: "50%",
    backgroundColor: "#f2f6f6",
  },
  errorMsg: {
    color: "red",
    whiteSpace: "pre-wrap",
  },
  loginCorner: {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    height: "18vw",
  },
  browserSupport: {
    textAlign: "justify",
    textJustify: "inter-word",
    margin: spacing(4),
  },
  alphavistaLogo: {
    width: "400px",
    marginBottom: spacing(2),
  },
  alphavistaLogoMini: {
    width: "160px",
    marginTop: spacing(10),
    marginBottom: spacing(2),
  },
}));

export type LoginPanelProps = {
  onOpenResetPasswordPanel: (id: string) => void;
  onSuccessLogin: () => void;
};

export const LoginPanel: React.FC<LoginPanelProps> = (props) => {
  const { onOpenResetPasswordPanel, onSuccessLogin } = props;

  const { classes } = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<LoginFormField>({
    resolver: yupResolver(loginSchema),
    defaultValues: { loginId: "", password: "" },
  });
  const client = useApolloClient();
  const { openDialog, Dialog, DialogType } = useDialog();

  const [login] = useLoginMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ login: { user } }) => {
      if (user) {
        if (user.resetPassword) onOpenResetPasswordPanel(user.id);
        else onSuccessLogin();
      } else {
        openDialog({
          content: "Reset password failed. Please contact ESG team. 重设密码失败，请连络ESG团队",
          type: DialogType.ERROR,
        });
      }
    },
    onError: (e) => {
      if (!e.networkError) {
        openDialog({ content: e.message, type: DialogType.ERROR });
      }
    },
  });

  const onSubmit = (data: LoginFormField) => {
    login({ variables: data });
  };

  return (
    <Grid container className={classes.container}>
      <Hidden mdDown>
        <Grid xs={6} className={classes.leftPane}>
          <AvistaWhiteLogo className={classes.whiteLogo} />
          <div className={classes.titleContainer}>
            <Grid>
              <img src={alphavistaLogoWhite} className={classes.alphavistaLogo} alt="alphavista-logo" />
            </Grid>
            <Typography className={classes.description}>
              划时代且便利高效的 一站式环境、社会及管治报告解决方案
              <br />
              <br />
              An all-in-one data collection platform that brings you convenience, cost effectiveness, and change.
            </Typography>
          </div>
          <img src={loginCorner} className={classes.loginCorner} alt="login-corner" />
        </Grid>
      </Hidden>
      <Grid xs={12} md={6} className={classes.rightPane}>
        <Hidden mdUp>
          <AvistaLogo className={classes.logo} />
        </Hidden>
        <Grid>
          <img src={alphavistaLogoBlue} className={classes.alphavistaLogoMini} alt="alphavista-logo" />
        </Grid>
        <form className={classes.form}>
          <Grid container spacing={2} direction="column">
            <Grid>
              <TextField
                {...register("loginId")}
                variant="filled"
                label="帐户名称 Login Id"
                margin="dense"
                className={classes.fieldInput}
              />
              {errors.loginId && touchedFields.loginId && <p className={classes.errorMsg}>{errors.loginId.message}</p>}
            </Grid>

            <Grid>
              <TextField
                {...register("password")}
                variant="filled"
                label="密码 Password"
                type="password"
                margin="dense"
                className={classes.fieldInput}
              />
              {errors.password && touchedFields.password && (
                <p className={classes.errorMsg}>{errors.password.message}</p>
              )}
            </Grid>

            <Grid>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                登录 Sign in
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className={classes.browserSupport}>
          本系统支援 Internet Explorer, Mozilla Firefox, Google Chrome, QQ浏览器, 搜狗高速浏览器,
          360安全浏览器。如果您使用的浏览器不在这份名单中，将有可能无法进入系统。
          <br />
          <br />
          This system supports Internet Explorer, Google Chrome, Mozilla Firefox, QQ Browser, Sogou Browser, 360 Secure
          Browser. ​If you are using a browser that is not on this list, you may not be able to access the system.​
        </div>
      </Grid>
      <Dialog />
    </Grid>
  );
};
