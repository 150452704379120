import React, { useMemo } from "react";

import { Variant } from "@mui/material/styles/createTypography";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Property } from "csstype";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing }) => ({
  value: {
    padding: spacing(1, 0),
  },
}));

type TypographyStyleProps = {
  variant?: Variant;
  color?: Property.Fill;
  bold?: boolean;
};

export type TitleValueLayoutProps = {
  title: string;
  subTitle?: string;
  value: string;
  footer?: string;
  style?: {
    spacing?: number;
    align?: "left" | "center" | "right";
    title?: TypographyStyleProps;
    subTitle?: TypographyStyleProps;
    value?: TypographyStyleProps;
    footer?: TypographyStyleProps;
  };
};

export const TitleValueLayout: React.FC<TitleValueLayoutProps> = (props) => {
  const { title, subTitle, value, footer, style } = props;

  const { classes } = useStyles();

  const textAlign = useMemo(() => style?.align ?? "left", [style]);

  return (
    <Grid padding={0} height="inherit">
      <Grid container direction="column" height="inherit" spacing={style?.spacing ?? 1} wrap="nowrap">
        <Grid>
          <Grid container direction="column" spacing={0}>
            <Grid>
              <Typography
                align={textAlign}
                variant={style?.title?.variant ?? "h4"}
                fontWeight={style?.title?.bold ? "bold" : "inherit"}
                color={style?.title?.color ?? "inherit"}
              >
                {title}
              </Typography>
            </Grid>
            {subTitle && (
              <Grid>
                <Typography
                  align={textAlign}
                  variant={style?.subTitle?.variant ?? "h6"}
                  fontWeight={style?.subTitle?.bold ? "bold" : "inherit"}
                  color={style?.subTitle?.color ?? "inherit"}
                >
                  {subTitle}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid flexGrow={2} className={classes.value}>
          <Grid container height="100%" direction="column" justifyContent="center">
            <Grid>
              <Typography
                align={textAlign}
                variant={style?.value?.variant ?? "h1"}
                fontWeight={style?.value?.bold ? "bold" : "inherit"}
                color={style?.value?.color ?? "inherit"}
              >
                {value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {footer && (
          <Grid>
            <Grid container direction="column" justifyContent="center">
              <Grid>
                <Typography
                  align={textAlign}
                  variant={style?.footer?.variant ?? "body1"}
                  fontWeight={style?.footer?.bold ? "bold" : "inherit"}
                  color={style?.footer?.color ?? "inherit"}
                >
                  {footer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
