import React from "react";

import { Route, RouteComponentProps, RouteProps } from "react-router-dom";

export type DynamicRouteProps = {
  path: string;
  component: RouteProps["component"];
  exact?: boolean;
};

export const DynamicRoute = (route: DynamicRouteProps[]): React.MemoExoticComponent<React.FC<RouteComponentProps>> =>
  React.memo((props: RouteComponentProps) => (
    <>
      {route.map((r, i) => {
        return (
          <Route
            path={`${props.match.url}${r.path}`}
            component={r.component}
            key={`${props.match.url}${r.path}${i}`}
            exact={r.exact}
          />
        );
      })}
    </>
  ));
