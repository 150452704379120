import React from "react";
import * as ReactDOM from "react-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { GroupedOptionType, OptionType } from "utils/interfaces";

interface SelectEditorProps {
  column: {
    key: string;
    options: OptionType[] | GroupedOptionType[];
    value: string | null;
    isDisable?: (props) => boolean;
    isCreatable: boolean;
    isMulti: boolean;
    dynamicOptionsHandler?: (row) => {}[];
  };
  onCommit: () => void;
}

class SelectEditor extends React.Component<SelectEditorProps, { value: string | [] }> {
  static defaultProps: SelectEditorProps;
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    if (props.column.isMulti && !props.value) {
      this.state = { value: [] };
    }
    this.isDisable = this.isDisable.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  getOptions({ dynamicOptionsHandler, options }) {
    // 3 type of options (dynamic, normal, group)
    let valueOptions = dynamicOptionsHandler ? dynamicOptionsHandler(this.props) : options;

    if (valueOptions.length !== 0 && valueOptions[0].options) {
      // if group options for flatten group options
      valueOptions = valueOptions.map((x) => x.options).flat();
    }
    return valueOptions;
  }

  getValue() {
    const result = {};
    result[this.props.column.key] = this.state.value;
    if (this.state.value === "") {
      result[this.props.column.key] = "";
      return result;
    }
    // handle multiple selection must return array
    if (this.props.column.isMulti) {
      if (!Array.isArray(this.state.value) && this.state.value !== "N/A") {
        result[this.props.column.key] = [];
      }

      return result;
    }

    if (!this.props.column.isCreatable) {
      const { dynamicOptionsHandler, options } = this.props.column;
      const valueOptions = this.getOptions({ dynamicOptionsHandler, options });
      // check value is exist in options
      const target = valueOptions.find((x) => x.value === this.state.value);
      if (!target) {
        result[this.props.column.key] = "";
      }
    }

    return result;
  }

  getInputNode() {
    // @Todo: `try to remove findDOMNode`
    /* eslint-disable-next-line react/no-find-dom-node */
    return (ReactDOM.findDOMNode(this) as HTMLInputElement).getElementsByTagName("input")[0];
  }

  handleChangeComplete = (obj) => {
    if (this.props.column.isMulti) {
      this.setState({ value: obj }, () => this.props.onCommit());
    } else {
      this.setState({ value: obj.value }, () => this.props.onCommit());
    }
  };

  handleOnBlur() {
    this.props.onCommit();
  }

  isDisable() {
    if (!this.props.column.isDisable) {
      return false;
    }
    return this.props.column.isDisable(this.props);
  }

  preRender() {
    const { options, isCreatable, isMulti, dynamicOptionsHandler } = this.props.column;
    if (isCreatable && !isMulti) {
      return (
        <Creatable
          placeholder="Select or Type 选择/输入"
          isClearable={false}
          autoFocus={true}
          openOnFocus={true}
          closeOnSelect={true}
          onChange={this.handleChangeComplete}
          // onInputKeyDown={this.handleKeyDown}
          isMulti={false}
          options={dynamicOptionsHandler ? dynamicOptionsHandler(this.props) : options}
          value={this.state.value}
          isDisabled={this.isDisable()}
          isOptionDisabled={(option) => !!(option as OptionType).disabled}
          formatCreateLabel={(input: string) => `Create 创建 "${input}"`}
        />
      );
    } else {
      return (
        <Select
          placeholder="Select 选择"
          autoFocus={true}
          openOnFocus={true}
          closeOnSelect={true}
          onChange={this.handleChangeComplete}
          // onInputKeyDown={this.handleKeyDown}
          isMulti={isMulti}
          options={dynamicOptionsHandler ? dynamicOptionsHandler(this.props) : options}
          value={this.state.value}
          isDisabled={this.isDisable()}
          isOptionDisabled={(option) => !!(option as OptionType).disabled}
          onBlur={this.handleOnBlur}
          noOptionsMessage={() => "No options 没有选项"}
          // ref={this.inputRef}
        />
      );
    }
  }

  render() {
    return this.preRender();
  }
}

SelectEditor.defaultProps = {
  column: {
    key: "",
    options: [],
    value: "",
    isDisable: () => false,
    isCreatable: false,
    isMulti: false,
  },
  onCommit: () => {
    /**/
  },
};

// const createSelectSingleEditor = (options, isCreatable, isDisable) => {
//   return (
//     <SelectEditor options={options} isCreatable={isCreatable} isDisable={isDisable}/>
//   )
// };
export default SelectEditor;
