import { useState } from "react";

import { Property } from "csstype";
import { useIntl } from "react-intl";

import { BasicDataSet, OptionDatum } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { isApproximatelyZero, roundValue } from "utils/math";
import { dummyOptionDaum } from "../components/consts";
import { getColorByValueDiff } from "../utils";

type DashboardHelperParams = {
  current: {
    total: BasicDataSet;
    intensity: OptionDatum[];
  };
  previous?: {
    total: BasicDataSet;
    intensity: OptionDatum[];
  };
  /**
   * NF1 label for select component
   *
   * default: "name"
   * */
  nf1Label?: "name" | "unit";
};

type DashboardHelperReturns = {
  total: {
    color: Property.Fill;
    isVisible: boolean;
    current: BasicDataSet;
    previous?: BasicDataSet;
  };
  intensity: {
    color: Property.Fill;
    isVisible: boolean;
    nf1Select: {
      options: OptionType[];
      nf1: string;
      onChangeNf1: (nf1: string) => void;
    };
    current: OptionDatum;
    previous?: OptionDatum;
  };
};

export const useDashboardHelper = (params: DashboardHelperParams): DashboardHelperReturns => {
  const { current, previous, nf1Label = "name" } = params;
  const { total, intensity } = current;
  const { total: pTotal, intensity: pIntensity } = previous ?? {};

  const intl = useIntl();

  const nf1Options = intensity
    .filter(({ value }) => !isApproximatelyZero(value))
    .map<OptionType>(({ option, unit }) => ({
      label: nf1Label === "unit" ? unit : intl.formatMessage({ id: `dashboard.env.nf1.${option}` }),
      value: option,
    }));

  const [nf1, setNf1] = useState("");
  if (nf1Options.length > 0 && !nf1) setNf1(nf1Options[0].value);

  const isTotalVisible = total.value > 0;
  const isIntensityVisible = nf1Options.length > 0 && intensity.some((d) => d.value > 0);

  const currentTotal = { ...total, value: roundValue(total.value, 3) };
  const previousTotal = pTotal?.value ? { ...pTotal, value: roundValue(pTotal.value, 3) } : undefined;

  const currentIntensity = intensity.find((c) => c.option === nf1) ?? dummyOptionDaum;
  const previousIntensity = pIntensity?.find((p) => p.option === nf1 && p.value > 0);

  const totalColor = getColorByValueDiff(total?.value, pTotal?.value);
  const intensityColor = getColorByValueDiff(currentIntensity.value, previousIntensity?.value);
  return {
    total: {
      color: totalColor,
      isVisible: isTotalVisible,
      current: currentTotal,
      previous: previousTotal,
    },
    intensity: {
      color: intensityColor,
      isVisible: isIntensityVisible,
      current: currentIntensity,
      previous: previousIntensity,
      nf1Select: {
        options: nf1Options,
        nf1,
        onChangeNf1: setNf1,
      },
    },
  };
};
