import React from "react";

import RemoveCircle from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "tss-react/mui";

import { IUser } from "../types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  button: {
    margin: spacing(1),
  },
  iconWrapper: {
    padding: 4,
  },
}));

export type AdminUsersTableProp = {
  adminUsers: IUser[];
  removeAdminUser: (id: string) => void;
};

export const AdminUsersTable: React.FC<AdminUsersTableProp> = (props) => {
  const { classes } = useStyles();
  const { adminUsers, removeAdminUser } = props;

  return (
    <Table className={classes.table} aria-label="table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Login Id</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {adminUsers.map((row) => (
          <TableRow key={row.loginId}>
            <TableCell className={classes.iconWrapper}>
              {!row.id && (
                <IconButton
                  className={classes.button}
                  aria-label="remove"
                  color="primary"
                  onClick={() => removeAdminUser(row.loginId)}
                  size="large"
                >
                  <RemoveCircle />
                </IconButton>
              )}
            </TableCell>
            <TableCell>{row.loginId}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.title}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>{row.email}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
