import React, { useContext } from "react";

import { Redirect, Route, RouteProps } from "react-router-dom";

import { AuthContext } from "provider/auth";
import { isLogin } from "utils/helper";

const PermissionManager = React.memo((props: { permission?: string; children: React.ReactElement }) => {
  const { me } = useContext(AuthContext);

  const Component = React.useCallback(() => {
    if (!props.permission) return props.children;
    else if (!me.role) return <></>;
    else if (me.role !== props.permission) return <Redirect to="/" />;
    else return props.children;
  }, [me, props.children, props.permission]);

  return <>{!isLogin() ? <Redirect to="/login" /> : <Component />}</>;
});

export type PrivateRouteProps = RouteProps & {
  permission?: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, permission, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PermissionManager permission={permission}>{Component ? <Component {...props} /> : <></>}</PermissionManager>
      )}
    />
  );
};
