import React from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({ font: { color: "red", padding: 20, fontWeight: "bold" } }));

export const Notes: React.FC = () => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Paper>
      <Typography className={classes.font}>{intl.formatMessage({ id: "user.notes" })}</Typography>
    </Paper>
  );
};
