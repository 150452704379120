import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetSupplyChainManagementDataDocument,
  GetSupplyChainManagementDataQuery,
  GetSupplyChainManagementDataQueryVariables,
  SubmitSupplyChainManagementDocument,
  SubmitSupplyChainManagementMutation,
  SubmitSupplyChainManagementMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getSupplyChainManagementData: (
    variables: GetSupplyChainManagementDataQueryVariables
  ) => Promise<GetSupplyChainManagementDataQuery["project"]["supplyChainManagement"]>;
  submitSupplyChainManagement: (
    variables: SubmitSupplyChainManagementMutationVariables
  ) => Promise<SubmitSupplyChainManagementMutation["submitSupplyChainManagement"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getSupplyChainManagementData = useCallback(
    async (variables: GetSupplyChainManagementDataQueryVariables) => {
      const response = await client.query<
        GetSupplyChainManagementDataQuery,
        GetSupplyChainManagementDataQueryVariables
      >({
        query: GetSupplyChainManagementDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.supplyChainManagement;
    },
    [client]
  );

  const submitSupplyChainManagement = useCallback(
    async (variables: SubmitSupplyChainManagementMutationVariables) => {
      const response = await client.mutate<
        SubmitSupplyChainManagementMutation,
        SubmitSupplyChainManagementMutationVariables
      >({
        mutation: SubmitSupplyChainManagementDocument,
        variables,
      });

      return response.data?.submitSupplyChainManagement ?? false;
    },
    [client]
  );

  return {
    getSupplyChainManagementData,
    submitSupplyChainManagement,
  };
};
