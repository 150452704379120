import React from "react";
import { VictoryChart, VictoryTheme } from "victory";

export type BarChartContainerProps = {
  barCount: number;
  bound: number[];
};

export const BarChartContainer: React.FC<BarChartContainerProps> = (props) => {
  const { barCount, bound, children } = props;
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domain={{ x: [1, barCount], y: [bound[0], bound[bound.length - 1]] }}
      domainPadding={{ x: 30, y: 0 }}
      padding={{ left: 90, right: 90, top: 30, bottom: 60 }}
      width={600}
      height={160 + 40 * barCount}
    >
      {children}
    </VictoryChart>
  );
};
