import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetProductResponsibilityDataDocument,
  GetProductResponsibilityDataQuery,
  GetProductResponsibilityDataQueryVariables,
  SubmitProductResponsibilityDocument,
  SubmitProductResponsibilityMutation,
  SubmitProductResponsibilityMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getProductResponsibilityData: (
    variables: GetProductResponsibilityDataQueryVariables
  ) => Promise<GetProductResponsibilityDataQuery["project"]["productResponsibility"]>;
  submitProductResponsibility: (
    variables: SubmitProductResponsibilityMutationVariables
  ) => Promise<SubmitProductResponsibilityMutation["submitProductResponsibility"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getProductResponsibilityData = useCallback(
    async (variables: GetProductResponsibilityDataQueryVariables) => {
      const response = await client.query<
        GetProductResponsibilityDataQuery,
        GetProductResponsibilityDataQueryVariables
      >({
        query: GetProductResponsibilityDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.productResponsibility;
    },
    [client]
  );

  const submitProductResponsibility = useCallback(
    async (variables: SubmitProductResponsibilityMutationVariables) => {
      const response = await client.mutate<
        SubmitProductResponsibilityMutation,
        SubmitProductResponsibilityMutationVariables
      >({
        mutation: SubmitProductResponsibilityDocument,
        variables,
      });

      return response.data?.submitProductResponsibility ?? false;
    },
    [client]
  );

  return {
    getProductResponsibilityData,
    submitProductResponsibility,
  };
};
