import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import {
  DataTable,
  FullScreenFormDialog,
  HeaderAddButton,
  StyledTableCell,
  useDebounceSearch,
  usePaginationMisc,
  useSort,
} from "components/react-table";
import { DataTableRow, SortByItem } from "components/react-table/table";
import {
  CloneProjectMode,
  ProjectStatus,
  ReportType,
  useCreateProjectMutation,
  useDownloadDataSummaryReportMutation,
  useDownloadEnvironmentalKpiReportMutation,
  useDownloadSocialKpiReportMutation,
  useGetProjectListDataLazyQuery,
  useUpdateProjectMutation,
} from "generated/graphql";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { DateFormat, toDate, toMoment } from "utils/date-time";
import { DialogMode } from "utils/dialog";
import { downloadFile } from "utils/download";
import { Yup } from "utils/yup";
import { SocialKpiReportDialog } from "../../components/social-kpi-report-dialog";
import { DownloadButtonGroup } from "./components/download-button/download-button-group";
import { ProjectForm } from "./components/form";
import { newProjectFormData } from "./consts";
import { ProjectFormData, ProjectListRow } from "./types";
import { toProjectListRow } from "./utils";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: spacing(1),
  },
  snackBar: {
    margin: spacing(1),
    width: "15%",
  },
  inlineFlex: {
    display: "inline-flex",
    alignItems: "center",
  },
  expandedContent: {},
  hideExpandedContent: {
    borderBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export const ProjectPage: React.FC = () => {
  const { classes } = useStyles();

  const [isOpen, setOpen] = useState(false);
  const [isSocialKpiReportDialogOpen, setIsSocialKpiReportDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ ...newProjectFormData });
  const [dialogMode, setDialogMode] = useState(DialogMode.CREATE);
  // row index which is expanding, -1 if no row is expanded
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  const [rows, setRows] = useState<ProjectListRow[]>([]);
  const selectedProject = useRef({ projectCode: "", endDate: new Date() });

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { showUpdateSuccessMessage, showUpdateFailedMessage } = useContext(MessageContext);
  const client = useApolloClient();
  const [search, setSearch] = useDebounceSearch(() => {
    setPageIndex(0);
    getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
  });
  const { sortBy, setSortBy, orderBy } = useSort({
    relation: {
      clientGroupName: ["clientGroup", "groupName"],
      clientGroupCode: ["clientGroup", "groupCode"],
    },
  });
  const {
    state: { pageIndex, pageSize, totalCount },
    setPageIndex,
    setPageSize,
    setTotalCount,
  } = usePaginationMisc();

  const [getProjectListData, getProjectListDataState] = useGetProjectListDataLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ projectList: { data, count } }) => {
      const rows = data.map(toProjectListRow);

      setRows(rows);
      setTotalCount(count);
    },
  });

  useEffect(() => {
    // Call the query when the component is mounted
    getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const [createProject, createProjectState] = useCreateProjectMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ createProject: { success, project, error } }) => {
      if (success) {
        setFormData({
          ...toProjectListRow(project!),
          cloneProjectId: "",
          cloneProjectCode: "",
        });
        setDialogMode(DialogMode.EDIT);
        getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
        showUpdateSuccessMessage();
      } else {
        showUpdateFailedMessage(error ?? undefined);
      }
    },
    onError: (e) => showUpdateFailedMessage(e.message),
  });

  const [updateProject, updateProjectState] = useUpdateProjectMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ updateProject: { success, project, error } }) => {
      if (success) {
        setFormData({
          ...toProjectListRow(project!),
          cloneProjectId: "",
          cloneProjectCode: "",
        });
        getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
        showUpdateSuccessMessage();
      } else {
        showUpdateFailedMessage(error ?? undefined);
      }
    },
    onError: (e) => showUpdateFailedMessage(e.message),
  });

  const [downloadDataSummaryReport, downloadDataSummaryReportState] = useDownloadDataSummaryReportMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ generateDataSummary: downloadPath }) => downloadFile(downloadPath),
    onError: () => showUpdateFailedMessage(),
  });

  const [downloadEnvironmentalKpiReport, downloadEnvironmentalKpiReportState] =
    useDownloadEnvironmentalKpiReportMutation({
      client,
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ generateEnvironmentalKpiReport: downloadPath }) => downloadFile(downloadPath),
      onError: () => showUpdateFailedMessage(),
    });

  const [downloadSocialKpiReport, downloadSocialKpiReportState] = useDownloadSocialKpiReportMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ generateSocialKpiReport: downloadPath }) => {
      setIsSocialKpiReportDialogOpen(false);
      downloadFile(downloadPath);
    },
    onError: () => showUpdateFailedMessage(),
  });

  useEffect(() => {
    const isLoading =
      getProjectListDataState.loading ||
      createProjectState.loading ||
      updateProjectState.loading ||
      downloadDataSummaryReportState.loading ||
      downloadEnvironmentalKpiReportState.loading ||
      downloadSocialKpiReportState.loading;
    setLoading(isLoading);
  }, [
    setLoading,
    getProjectListDataState,
    createProjectState,
    updateProjectState,
    downloadDataSummaryReportState,
    downloadEnvironmentalKpiReportState,
    downloadSocialKpiReportState,
  ]);

  const handleGoToPage = (pageIndex: number) => {
    setPageIndex(pageIndex);
    getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
  };

  const handleSetPageSize = (pageSize: number) => {
    setPageSize(pageSize);
    getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
  };

  const handleSortByChange = (newSortBy?: SortByItem) => {
    if (newSortBy === sortBy) {
      return;
    }

    setSortBy(newSortBy);
    getProjectListData({ variables: { pageIndex, pageSize, orderBy, keyword: search } });
  };

  const handleDialogOpen = (row?: { original: ProjectListRow }) => {
    if (row) {
      setDialogMode(DialogMode.EDIT);
      setFormData({
        ...row.original,
        cloneProjectId: "",
        cloneProjectCode: "",
      });
    } else {
      setDialogMode(DialogMode.CREATE);
      setFormData({ ...newProjectFormData });
    }
    setOpen(true);
  };

  const handleDialogClose = () => {
    setFormData({ ...newProjectFormData });
    setOpen(false);
  };

  const handleOpenSocialKpiReportDialog = (code: string, date: Date) => () => {
    selectedProject.current = { projectCode: code, endDate: date };
    setIsSocialKpiReportDialogOpen(true);
  };

  const handleDownloadDataSummaryReport = (projectCode: string) => (reportType: ReportType) =>
    downloadDataSummaryReport({ variables: { data: { reportType, code: projectCode } } });

  const handleDownloadEnvironmentalKpiReport = (projectCode: string) => () =>
    downloadEnvironmentalKpiReport({ variables: { data: { code: projectCode } } });

  const handleDownloadSocialKpiReport = (isIncludeResign: boolean) =>
    downloadSocialKpiReport({
      variables: {
        data: {
          code: selectedProject.current.projectCode,
          isIncludeResign,
        },
      },
    });

  const handleSubmitForm = async (
    value: ProjectFormData,
    setSubmitting: (bool: boolean) => void,
    dialogMode: DialogMode
  ) => {
    setSubmitting(true);

    if (dialogMode === DialogMode.EDIT) {
      await updateProject({
        variables: {
          data: {
            id: value.id,
            code: value.code,
            startDate: toMoment(value.startDate, DateFormat.Date).toISOString(),
            endDate: toMoment(value.endDate, DateFormat.Date).toISOString(),
            status: value.status as ProjectStatus,
            clientGroupId: value.clientGroupId,
            segment: value.segment,
            tailorMadeIndexFields: value.tailorMadeIndexFields,
            profileId: value.profileId!,
            scope3Permission: value.scope3Permission,
          },
        },
      });
    } else {
      await createProject({
        variables: {
          data: {
            cloneProjectId: value.cloneProjectId!,
            cloneProjectMode: value.cloneProjectMode ? (value.cloneProjectMode as CloneProjectMode) : undefined,
            code: value.code,
            startDate: toMoment(value.startDate, DateFormat.Date).toISOString(),
            endDate: toMoment(value.endDate, DateFormat.Date).toISOString(),
            status: value.status as ProjectStatus,
            clientGroupId: value.clientGroupId,
            segment: value.segment,
            tailorMadeIndexFields: value.tailorMadeIndexFields,
            profileId: value.profileId!,
            scope3Permission: value.scope3Permission,
          },
        },
      });
    }

    setSubmitting(false);
  };

  const columns = useMemo(
    () => [
      { accessor: "code", Header: "Project Code" },
      { accessor: "startDate", Header: "Start Date" },
      { accessor: "endDate", Header: "End Date" },
      { accessor: "status", Header: "Status" },
      { accessor: "clientGroupName", Header: "Client Group Name" },
      { accessor: "clientGroupCode", Header: "Client Group Code" },
    ],
    []
  );

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    status: Yup.string().required(),
    clientGroupId: Yup.string().required(),
  });

  const renderRow = (row: DataTableRow<ProjectListRow>, rowProps: TableRowProps) => {
    return (
      <React.Fragment key={row.index}>
        <TableRow {...rowProps}>
          <TableCell>
            <div className={classes.inlineFlex}>
              <DownloadButtonGroup
                isProjectHasEmissionFactorProfile={!!row.original.profileId}
                downloadDataSummaryReport={handleDownloadDataSummaryReport(row.original.code)}
                downloadEnvironmentalKpiReport={handleDownloadEnvironmentalKpiReport(row.original.code)}
                showSocialKpiReportDialog={handleOpenSocialKpiReportDialog(
                  row.original.code,
                  toDate(row.original.endDate, DateFormat.Date)
                )}
              />
              <IconButton aria-label="Edit" className={classes.fab} onClick={() => handleDialogOpen(row)} size="large">
                <EditIcon />
              </IconButton>
            </div>
          </TableCell>
          {row.cells.map((cell) => (
            <StyledTableCell key={cell.column.id} {...cell.getCellProps()}>
              <div>{cell.render("Cell")}</div>
            </StyledTableCell>
          ))}
          <TableCell component="th" scope="row">
            <IconButton
              aria-label="expand"
              onClick={() => setExpandedRowIndex(expandedRowIndex !== row.index ? row.index : -1)}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={8}
            className={expandedRowIndex === row.index ? classes.expandedContent : classes.hideExpandedContent}
          >
            <Collapse in={expandedRowIndex === row.index}>
              <Typography>Segments: {row.original.segment.join(", ")}</Typography>
              <Typography>
                Tailor-made Fields:
                {(row.original.tailorMadeIndexFields as ProjectListRow["tailorMadeIndexFields"])
                  .map((v) => v.fieldName)
                  .join(", ")}
              </Typography>
              {(row.original.permission as ProjectListRow["permission"]).map((p, i) => (
                <Typography key={i}>
                  Permitted User: {p.user.lastName} {p.user.firstName} ({p.user.loginId})
                </Typography>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <>
      <DataTable
        data={rows}
        columns={columns}
        state={{ pageIndex, pageSize, totalCount }}
        goToPage={handleGoToPage}
        setPageSize={handleSetPageSize}
        renderRow={renderRow}
        HeaderActionComponent={<HeaderAddButton onClick={() => handleDialogOpen()} />}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSortByChange={handleSortByChange}
      />
      <Formik<ProjectFormData>
        initialValues={{ ...formData }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitForm(values, setSubmitting, dialogMode);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        render={(formikProps) => (
          <FullScreenFormDialog
            isOpen={isOpen}
            handleDialogClose={() => {
              handleDialogClose();
              formikProps.resetForm();
            }}
            handleSubmit={formikProps.submitForm}
            mode={dialogMode}
            title={intl.formatMessage({ id: "dialog.title.project" })}
          >
            <ProjectForm {...{ ...formikProps, mode: dialogMode }} />
          </FullScreenFormDialog>
        )}
      />
      <SocialKpiReportDialog
        isOpen={isSocialKpiReportDialogOpen}
        onClose={() => setIsSocialKpiReportDialogOpen(false)}
        onSubmit={handleDownloadSocialKpiReport}
      />
    </>
  );
};
