import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { Typography } from "@mui/material";
import { CompactSelect } from "components/compact-select";
import { DashboardColor } from "pages/dashboard/components/consts";
import { OptionType } from "utils/interfaces";

export type TrainingIncludeResignSelectProps = {
  isLoading: boolean;
  options: OptionType[];
  isIncludeResign: boolean;
  onChangeIsIncludeResign: (isIncludeResign: boolean) => void;
};

export const TrainingIncludeResignSelect: React.FC<TrainingIncludeResignSelectProps> = (props) => {
  const { isLoading, options, isIncludeResign, onChangeIsIncludeResign } = props;

  const intl = useIntl();

  return (
    <Grid container spacing={1} alignItems="flex-end" paddingBottom={1} color={DashboardColor.text.grey}>
      <Grid fontWeight="bold">
        {intl.formatMessage({ id: "dashboard.social.training.includeResign" }, { br: <br /> })}
      </Grid>
      <Grid width={80}>
        {isLoading ? (
          <Typography color={DashboardColor.text.grey}>-</Typography>
        ) : (
          <CompactSelect
            height={20}
            options={options}
            value={options.find((o) => o.value === isIncludeResign.toString())}
            onChange={(option: OptionType) => onChangeIsIncludeResign(option.value === "true")}
          />
        )}
      </Grid>
    </Grid>
  );
};
