import React, { useRef, useState } from "react";

import { AlertDialog } from "../components/alert-dialog";
import { ConfirmDialog } from "../components/confirm-dialog";

export enum DialogType {
  ALERT = "ALERT",
  ERROR = "ERROR",
  CONFIRM = "CONFIRM",
}

export type OpenDialogParams = {
  content: React.ReactNode;
  title?: string;
} & ({ type: DialogType.CONFIRM; onConfirm: () => void } | { type: DialogType.ALERT | DialogType.ERROR });

export type UseDialogReturns = {
  Dialog: React.FC;
  openDialog: (params: OpenDialogParams) => void;
  DialogType: typeof DialogType;
};

const defaultConfirmCallback = () => undefined;

export const useDialog = (): UseDialogReturns => {
  const [dialogType, setDialogType] = useState(DialogType.ALERT);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState<React.ReactNode>("");
  const handleConfirm = useRef<() => void>(defaultConfirmCallback);

  const Dialog = React.useCallback(() => {
    switch (dialogType) {
      case DialogType.ALERT:
      case DialogType.ERROR:
        return <AlertDialog isOpen={open} title={title} content={content} handleClose={closeDialog} />;

      case DialogType.CONFIRM:
        return (
          <ConfirmDialog
            isOpen={open}
            title={title}
            content={content}
            handleClose={closeDialog}
            handleCloseConfirm={handleConfirm.current}
          />
        );

      default:
        return null;
    }
  }, [dialogType, open, title, content, handleConfirm]);

  const openDialog = (params: OpenDialogParams) => {
    setContent(params.content);
    setTitle(params.title ?? params.type);
    setDialogType(params.type);
    if (params.type === DialogType.CONFIRM && params.onConfirm) {
      handleConfirm.current = params.onConfirm;
    }

    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    handleConfirm.current = defaultConfirmCallback;
  };

  return {
    Dialog,
    openDialog,
    DialogType,
  };
};
