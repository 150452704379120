import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { Nf1RecordRow } from "./types";

export const tips: KeyRecord<Nf1RecordRow, string> = {
  useOfProperty: "tips.nf1.use-of-property",
  grossFloorArea: "tips.nf1.gross-floor-area",
  leaseStartDate: "tips.nf1.lease-start-date",
  leaseEndDate: "tips.nf1.lease-end-date",
};

export enum PropertyType {
  Own = "own",
  Lease = "lease",
}

export const propertyTypeOptions: OptionType[] = [
  { label: "Own 自置", value: PropertyType.Own },
  { label: "Lease 租赁 ", value: PropertyType.Lease },
];

export enum PropertyUsage {
  Plant = "plant",
  Office = "offcie",
  Warehouse = "warehouse",
  Hall = "hall",
}

export const propertyUsageOptions: OptionType[] = [
  { label: "Plant 工厂", value: PropertyUsage.Plant },
  { label: "Office 办公室", value: PropertyUsage.Office },
  { label: "Warehouse 仓库", value: PropertyUsage.Warehouse },
  { label: "Staff quarters 宿舍", value: PropertyUsage.Hall },
];

export enum Availability {
  Available = "true",
  Unavailable = "false",
}

export const availabilityOptions: OptionType[] = [
  { label: "有 Available", value: Availability.Available },
  { label: "沒有 Unavailable", value: Availability.Unavailable },
];

export enum GrossFloorUnit {
  M2 = "m2",
  FT2 = "ft2",
}

export const grossFloorUnitOptions: OptionType[] = [
  { label: "m2 平方米", value: GrossFloorUnit.M2 },
  { label: "ft2 平方呎", value: GrossFloorUnit.FT2 },
];

export const newNf1Row: Nf1RecordRow = {
  recordId: "",
  address: "",
  companyId: "",
  grossFloorArea: 0,
  grossFloorUnit: GrossFloorUnit.M2,
  leaseEndDate: "",
  leaseStartDate: "",
  water: "true",
  energy: "true",
  typeOfProperty: "",
  useOfProperty: "",
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
