import en from "./en.json";
import cn from "./zh-cn.json";
import tw from "./zh-tw.json";

export enum Language {
  /** English */
  Eng = "en",
  /** Simplified Chinese */
  SimChi = "zh-cn",
  /** Traditional Chinese */
  TradChi = "zh-tw",
}

export const i18n: Record<Language, Record<string, string>> = {
  [Language.Eng]: en,
  [Language.SimChi]: cn,
  [Language.TradChi]: tw,
};
