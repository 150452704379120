import { RecordStatus } from "generated/graphql";
import { TrainingRatioRecordRow } from "./types";

export const newTrainingRatioRow: TrainingRatioRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  numberOfEmployees: 0,
  male: 0,
  female: 0,
  seniorManagement: 0,
  middleLevelManagement: 0,
  generalAndTechnicalEmployees: 0,
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
