import React, { useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { MenuItem } from "./types";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  activeListItem: {
    borderLeft: `4px solid ${palette.primary.main}`,
    borderRadius: "4px",
    backgroundColor: palette.primary.light,
    "& $listItemText": {
      color: palette.text.primary,
    },
    "& $listItemIcon": {
      color: palette.primary.main,
      marginLeft: "-4px",
    },
  },
  listItemIcon: {
    marginRight: 0,
  },
  listItemText: {
    fontWeight: 500,
    color: palette.text.secondary,
  },
  nested: {
    paddingLeft: spacing(4),
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.primary.light,
      borderLeft: `4px solid ${palette.primary.main}`,
      borderRadius: "4px",
      "& $listItemIcon": {
        color: palette.primary.main,
        marginLeft: "-4px",
      },
    },
    "& + &": {
      marginTop: spacing(),
    },
  },
}));

export type NestedListItemProps = {
  menuItem: MenuItem;
  goTo: (link: string) => void;
  match: boolean;
};

export const NestedListItem: React.FC<NestedListItemProps> = (props) => {
  const { menuItem, goTo, match } = props;

  const [open, setOpen] = useState(match);

  const { classes, cx } = useStyles();
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem className={`${match ? classes.activeListItem : classes.listItem}`} onClick={handleClick}>
        <ListItemIcon className={classes.listItemIcon}>
          <menuItem.icon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.listItemText }} primary={menuItem.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {
          <List component="div" disablePadding>
            {menuItem.child &&
              menuItem.child.map((item) => {
                const match = location.pathname === item.path;
                return (
                  <div key={item.name}>
                    <ListItem
                      className={cx(`${match ? classes.activeListItem : classes.listItem}`, classes.nested)}
                      onClick={() => {
                        if (!match) goTo(item.path);
                      }}
                    >
                      <ListItemText className={cx(classes.listItemText)} primary={item.name} />
                    </ListItem>
                  </div>
                );
              })}
          </List>
        }
      </Collapse>
    </>
  );
};
