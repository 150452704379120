import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, Nf3Param, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { newNf3Row } from "../consts";
import { useAPI } from "../hook/api";
import { Nf3RecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: Nf3RecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getNf3Data, submitNf3 } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const nf3 = await getNf3Data({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = nf3.flatMap((n) =>
          n.records.map<Nf3RecordRow>((r) => ({
            ...newNf3Row,
            recordId: r.id,
            originalCompanyId: n.projectCompany.id,
            companyId: n.projectCompany.id,
            remarks: r.remarks,
            unit: r.unit,
            product: r.product,
            productionVolume: r.productionVolume,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getNf3Data]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<Nf3Param>((e) => {
          const data = e.data as DataGridRow<Nf3RecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              unit: data.unit,
              productionVolume: data.productionVolume,
              product: data.product,
            },
          };
        });

        return await submitNf3({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitNf3]
  );

  return { getData, submitData };
};
