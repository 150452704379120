import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, SuccessfulRecord, WaterConsumptionParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newWaterConsumptionRow } from "../consts";
import { WaterConsumptionRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: WaterConsumptionRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getWaterConsumptionData, submitWaterConsumption } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const waterConsumption = await getWaterConsumptionData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = waterConsumption.flatMap((w) =>
          w.records.map<WaterConsumptionRecordRow>((r) => ({
            ...newWaterConsumptionRow,
            recordId: r.id,
            originalCompanyId: w.projectCompany.id,
            companyId: w.projectCompany.id,
            remarks: r.remarks,
            address: r.address,
            meterNumber: r.meterNumber,
            waterSource: r.waterSource,
            startDate: tryParseDateString(r.startDate, DateFormat.Date),
            endDate: tryParseDateString(r.endDate, DateFormat.Date),
            waterConsumptionAmount: r.waterConsumptionAmount,
            waterConsumptionUnit: r.waterConsumptionUnit,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getWaterConsumptionData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<WaterConsumptionParam>((e) => {
          const data = e.data as DataGridRow<WaterConsumptionRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              address: data.address,
              meterNumber: data.meterNumber,
              waterSource: data.waterSource,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
              waterConsumptionAmount: +data.waterConsumptionAmount,
              waterConsumptionUnit: data.waterConsumptionUnit,
            },
          };
        });

        return await submitWaterConsumption({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitWaterConsumption]
  );

  return { getData, submitData };
};
