import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, NonHazardousWasteParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newNonHazardousWasteRow } from "../consts";
import { useAPI } from "../hook/api";
import { NonHazardousWasteRecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: NonHazardousWasteRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getNonHazardousWasteData, submitNonHazardousWaste } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const nonHazardousWaste = await getNonHazardousWasteData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = nonHazardousWaste.flatMap((n) =>
          n.records.map<NonHazardousWasteRecordRow>((r) => ({
            ...newNonHazardousWasteRow,
            recordId: r.id,
            originalCompanyId: n.projectCompany.id,
            companyId: n.projectCompany.id,
            remarks: r.remarks,
            wasteSource: r.wasteSource,
            wasteType: r.wasteType,
            startDate: tryParseDateString(r.startDate, DateFormat.Date),
            endDate: tryParseDateString(r.endDate, DateFormat.Date),
            wasteManagementDisposeAmount: r.wasteManagementDisposeAmount,
            wasteManagementRecyclingAmount: r.wasteManagementRecyclingAmount,
            otherUsageAmount: r.otherUsageAmount,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getNonHazardousWasteData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<NonHazardousWasteParam>((e) => {
          const data = e.data as DataGridRow<NonHazardousWasteRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              wasteSource: data.wasteSource,
              wasteType: data.wasteType,
              quantityGenerated: data.quantityGenerated,
              wasteManagementDisposeAmount: data.wasteManagementDisposeAmount,
              wasteManagementRecyclingAmount: data.wasteManagementRecyclingAmount,
              wasteManagementCollectedAmount: data.wasteManagementCollectedAmount,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
              otherUsageAmount: data.otherUsageAmount,
              otherUsageRemarks: data.otherUsageRemarks,
            },
          };
        });

        return await submitNonHazardousWaste({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitNonHazardousWaste]
  );

  return { getData, submitData };
};
