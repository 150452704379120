import { useCallback, useState } from "react";

type PaginationState = {
  pageSize: number;
  pageIndex: number;
  totalCount: number;
};

const defaultState: PaginationState = {
  pageSize: 5,
  pageIndex: 0,
  totalCount: 0,
};

export type ReturnType = {
  state: PaginationState;
  setTotalCount: (totalCount: number) => void;
  setPageIndex: (pageIndex: number) => void;
  setPageSize: (pageSize: number) => void;
};

export const usePaginationMisc = (initState: PaginationState = defaultState): ReturnType => {
  const [state, setState] = useState(initState);

  const setTotalCount = useCallback((totalCount: number) => setState((s) => ({ ...s, totalCount })), []);
  const setPageIndex = useCallback((pageIndex: number) => setState((s) => ({ ...s, pageIndex })), []);
  const setPageSize = useCallback((pageSize: number) => setState((s) => ({ ...s, pageSize })), []);

  return {
    state,
    setTotalCount,
    setPageIndex,
    setPageSize,
  };
};
