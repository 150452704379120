import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { MobileFuelRecordRow } from "./types";

export const newMobileFuelRow: MobileFuelRecordRow = {
  recordId: "",
  companyId: "",
  transportationOwnership: "",
  transportationLicense: "",
  transportationType: "",
  fuelType: "",
  fuelConsumption: 0,
  fuelUnit: "",
  startDate: "",
  endDate: "",
  yearBeginDistanceTravelled: 0,
  yearEndDistanceTravelled: 0,
  estimatedDistanceTravelled: 0,
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<MobileFuelRecordRow, string> = {
  fuelConsumption: "tips.fuel-mobile.fuel-consumption",
};

export enum TransportationType {
  Car = "car",
  Motorcycle = "motorcycle",
  LightGoodsVehicles1 = "lightGoodsVehicles1",
  LightGoodsVehicles2 = "lightGoodsVehicles2",
  LightGoodsVehicles3 = "lightGoodsVehicles3",
  HeavyGoodsVehicles1 = "heavyGoodsVehicles1",
  HeavyGoodsVehicles2 = "heavyGoodsVehicles2",
  Ship = "ship",
}

export const transportationTypeOptions: OptionType[] = [
  { label: "Passenger car 私家车/骄车", value: TransportationType.Car },
  { label: "Motorcycle 摩托车", value: TransportationType.Motorcycle },
  { label: "Light Goods Vehicles (<=2.5 tonnes) 轻型货车 (<=2.5 吨)", value: TransportationType.LightGoodsVehicles1 },
  {
    label: "Light Goods Vehicles (2.5-3.5 tonnes) 轻型货车 (2.5-3.5 吨)",
    value: TransportationType.LightGoodsVehicles2,
  },
  {
    label: "Light Goods Vehicles (3.5-5.5 tonnes) 轻型货车 (3.5-5.5 吨)",
    value: TransportationType.LightGoodsVehicles3,
  },
  {
    label: "Medium & Heavy Goods Vehicles (5.5-15 tonnes) 中重型货车 (5.5-15 吨)",
    value: TransportationType.HeavyGoodsVehicles1,
  },
  {
    label: "Medium & Heavy Goods Vehicles (>=15 tonnes) 中重型货车 (>=15 吨)",
    value: TransportationType.HeavyGoodsVehicles2,
  },
  { label: "Ship 船舶", value: TransportationType.Ship },
];

export enum FuelType {
  DieselOil = "Diesel Oil",
  UnleadedPetrol = "Unleaded Petrol",
  LPG = "LPG (Liquid)",
  Kerosene = "Kerosene",
  JetKerosene = "Jet Kerosene",
  GasOilForShip = "Gas Oil for Ship",
  Electric = "Electric",
  CNG = "CNG",
  LNG = "LNG",
}

export const fuelTypeOptions: OptionType[] = [
  { label: "Diesel oil 柴油", value: FuelType.DieselOil },
  { label: "Unleaded petrol 无铅汽油", value: FuelType.UnleadedPetrol },
  { label: "LPG (Liquid) 液化石油气 (液态)", value: FuelType.LPG },
  { label: "Kerosene 煤油", value: FuelType.Kerosene },
  { label: "Jet Kerosene 航空煤油", value: FuelType.JetKerosene },
  { label: "Marine gas oil 船用汽油", value: FuelType.GasOilForShip },
  { label: "Electric 电动", value: FuelType.Electric },
  { label: "CNG 压缩天然气/管道天然气", value: FuelType.CNG },
  { label: "LNG 液化天然气", value: FuelType.LNG },
];

export enum FuelUnit {
  Litre = "Litre",
  KG = "kg",
  M3 = "m3",
  KWH = "kWh",
}

export const fuelUnitOptions: OptionType[] = [
  { label: "Litre 升", value: FuelUnit.Litre },
  { label: "kg 公斤", value: FuelUnit.KG },
  { label: "m3 立方米", value: FuelUnit.M3 },
  { label: "kWh 千瓦時", value: FuelUnit.KWH },
];

export enum TransportationOwnership {
  Company = "company",
  ThirdParty = "thirdParty",
}

export const transportationOwnershipOptions: OptionType[] = [
  { label: "Company owned 公司拥有", value: TransportationOwnership.Company },
  { label: "Third party 第三方", value: TransportationOwnership.ThirdParty },
];
