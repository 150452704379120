import React, { useContext, useEffect, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { FastField, Field, FieldArray, Form, FormikProps } from "formik";
import orderBy from "lodash/orderBy";
import { makeStyles } from "tss-react/mui";

import { DatePicker } from "components/react-table";
import {
  CloneProjectMode,
  Project,
  ProjectStatus,
  TailorMadeIndexFieldInput,
  TailorMadeIndexFieldType,
  useGetProjectFormDataQuery,
  useGetProjectsByClientGroupLazyQuery,
} from "generated/graphql";
import { LoadingContext } from "provider/loading";
import { DialogMode } from "utils/dialog";
import { cloneProjectModeOptions } from "../consts";
import { ProjectFormData } from "../types";
import { Scope3PermissionList } from "./scope3-permission-list";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    flexWrap: "wrap",
    margin: spacing(1),
  },
  errorMsg: {
    color: "red",
  },
  field: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginTop: spacing(1),
    width: 220,
  },
  divider: {
    padding: spacing(1, 0),
  },
  button: {
    width: 100,
  },
  buttonContainer: {
    marginLeft: spacing(1),
  },
}));

type ClientGroupInfo = {
  id: string;
  groupCode: string;
  groupName: string;
};

type ProjectFormProps = FormikProps<ProjectFormData> & {
  mode: DialogMode;
};

const statusDropDownList = [ProjectStatus.Open, ProjectStatus.Closed];

const newIndexField: TailorMadeIndexFieldInput = {
  fieldName: "",
  fieldType: TailorMadeIndexFieldType.String,
};

const naOption = { label: "N/A", value: "" };

export const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const { values, touched, errors, handleChange, setFieldValue, mode } = props;
  const [clientGroups, setClientGroups] = useState<ClientGroupInfo[]>([]);
  const [clonableProjects, setClonableProjects] = useState<Pick<Project, "id" | "code">[]>([]);
  const [profiles, setProfiles] = useState([naOption]);

  const { classes } = useStyles();
  const client = useApolloClient();
  const { setLoading } = useContext(LoadingContext);

  const [getClonableProjects, getClonableProjectsState] = useGetProjectsByClientGroupLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ clientGroup: { projects } }) => setClonableProjects(projects),
  });

  const getProjectFormDataQuery = useGetProjectFormDataQuery({
    onCompleted: ({ clientGroups, emissionFactorProfiles }) => {
      setClientGroups(clientGroups ? (clientGroups as ClientGroupInfo[]) : []);
      setProfiles([naOption, ...emissionFactorProfiles.map(({ id, name }) => ({ label: name, value: id }))]);
    },
  });

  useEffect(() => {
    setLoading(getClonableProjectsState.loading || getProjectFormDataQuery.loading);
  }, [setLoading, getClonableProjectsState.loading, getProjectFormDataQuery.loading]);

  const clientGroupOptions = useMemo(
    () => orderBy(clientGroups, ["groupName"]).map(({ id, groupName }) => ({ label: groupName, value: id })),
    [clientGroups]
  );

  const clonableProjectOptions = useMemo(
    () => [naOption, ...orderBy(clonableProjects, "code").map(({ id, code }) => ({ label: code, value: id }))],
    [clonableProjects]
  );

  const handleClientGroupChange = async (event: SelectChangeEvent) => {
    setFieldValue("cloneProjectId", "");
    setFieldValue("cloneProjectCode", "");
    setFieldValue("cloneProjectMode", "");

    const clientGroup = clientGroups.find(({ id }) => id === event.target.value);

    if (clientGroup) {
      setFieldValue("clientGroupId", clientGroup.id);
      setFieldValue("clientGroupName", clientGroup.groupName);
      setFieldValue("clientGroupCode", clientGroup.groupCode);

      if (clientGroup.id) {
        getClonableProjects({ variables: { id: clientGroup.id } });
      } else {
        setClonableProjects([]);
      }
    } else {
      setFieldValue("clientGroupId", "");
      setFieldValue("clientGroupName", "");
      setFieldValue("clientGroupCode", "");
      setClonableProjects([]);
    }
  };

  const handleCloneFromProjectChange = async (event: SelectChangeEvent) => {
    const cloneProject = clonableProjects.find(({ id }) => id === event.target.value);

    if (cloneProject) {
      setFieldValue("cloneProjectId", cloneProject.id);
      setFieldValue("cloneProjectCode", cloneProject.code);
      setFieldValue("cloneProjectMode", CloneProjectMode.Nf1);
    } else {
      setFieldValue("cloneProjectId", "");
      setFieldValue("cloneProjectCode", "");
      setFieldValue("cloneProjectMode", "");
    }
  };

  return (
    <Form autoComplete="off">
      <Grid container spacing={3} className={classes.root}>
        <Grid xs={12} sm={mode === DialogMode.EDIT ? 6 : 12}>
          <FormControl className={classes.field}>
            <FastField
              required
              id="code"
              name="code"
              label="Project Code"
              component={TextField}
              variant="standard"
              fullWidth={true}
              value={values.code}
              onChange={handleChange}
              error={errors.code && touched.code}
            />
            {errors.code && touched.code && <div className={classes.errorMsg}>{errors.code}</div>}
          </FormControl>
        </Grid>
        {mode === DialogMode.EDIT && (
          <Grid xs={12} sm={6}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <FastField
                required
                id="status"
                name="status"
                component={Select}
                variant="standard"
                fullWidth={true}
                value={values.status}
                onChange={handleChange("status")}
                error={errors.status && touched.status}
              >
                {statusDropDownList.map((status, i) => {
                  return (
                    <MenuItem key={i} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </FastField>
              {errors.status && touched.status && <div className={classes.errorMsg}>{errors.status}</div>}
            </FormControl>
          </Grid>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid xs={12} sm={6}>
            <FormControl className={classes.field}>
              <FastField required id="startDate" name="startDate" label="Start Date" component={DatePicker} />
              {errors.startDate && touched.startDate && <div className={classes.errorMsg}>{errors.startDate}</div>}
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl className={classes.field}>
              <FastField required id="endDate" name="endDate" label="End Date" component={DatePicker} />
              {errors.endDate && touched.endDate && <div className={classes.errorMsg}>{errors.endDate}</div>}
            </FormControl>
          </Grid>
        </MuiPickersUtilsProvider>

        {mode === DialogMode.CREATE && (
          <>
            <Grid xs={12} sm={6}>
              <FormControl className={classes.field}>
                <InputLabel htmlFor="clientGroupName">Client Group Name</InputLabel>
                <Field
                  required
                  id="clientGroupId"
                  name="clientGroupId"
                  component={Select}
                  variant="standard"
                  options={clientGroupOptions}
                  label="Client Group Name"
                  placeholder="Search group name here"
                  value={values.clientGroupId}
                  onChange={handleClientGroupChange}
                  error={errors.clientGroupId && touched.clientGroupId}
                >
                  {clientGroupOptions.map((clientName, i) => (
                    <MenuItem key={i} value={clientName.value}>
                      {clientName.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl className={classes.field}>
                <Field
                  id="clientGroupCode"
                  name="clientGroupCode"
                  label="Client Group Code"
                  component={TextField}
                  variant="standard"
                  value={values.clientGroupCode}
                  inputProps={{ readOnly: true }}
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl className={classes.field}>
                <InputLabel htmlFor="cloneFromProject">Clone From Project</InputLabel>
                <Field
                  label="Clone From Project"
                  id="cloneProjectId"
                  name="cloneProjectId"
                  component={Select}
                  variant="standard"
                  value={values.cloneProjectId}
                  onChange={handleCloneFromProjectChange}
                >
                  {clonableProjectOptions.map((project, i) => (
                    <MenuItem key={i} value={project.value}>
                      {project.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl className={classes.field}>
                <InputLabel htmlFor="cloneProjectMode">Clone...</InputLabel>
                <Field
                  id="cloneProjectMode"
                  name="cloneProjectMode"
                  component={Select}
                  variant="standard"
                  value={values.cloneProjectMode}
                  onChange={handleChange("cloneProjectMode")}
                >
                  {cloneProjectModeOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid xs={12} sm={6}>
          <FormControl variant="standard" className={classes.field}>
            <InputLabel htmlFor="profileId">Emission Factor Profile</InputLabel>
            <Field
              label="Emission Factor Profile"
              name="profileId"
              id="profileId"
              component={Select}
              value={values.profileId}
              onChange={handleChange("profileId")}
            >
              {profiles.map((profile, i) => (
                <MenuItem key={i} value={profile.value}>
                  {profile.label}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        <Grid xs={12} className={classes.divider}>
          <Divider />
        </Grid>

        <Grid container>
          <FieldArray
            name="segment"
            render={(arrayHelpers) => (
              <>
                {values.segment && values.segment.length > 0 ? (
                  values.segment.map((s, index) => (
                    <Grid xs={"auto"} key={index}>
                      <FormControl className={classes.field}>
                        <FastField
                          id={`segment.${index}`}
                          name={`segment.${index}`}
                          label={`segment ${index + 1}`}
                          value={s}
                          onChange={handleChange}
                          component={TextField}
                          variant="standard"
                        />
                        <div>
                          <Button
                            className={classes.button}
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }} // remove a friend from the list
                          >
                            -
                          </Button>
                          <Button
                            className={classes.button}
                            disabled={values.segment.length > 14}
                            onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                          >
                            +
                          </Button>
                        </div>
                      </FormControl>
                    </Grid>
                  ))
                ) : (
                  <Grid className={classes.buttonContainer}>
                    <Button variant="contained" onClick={() => arrayHelpers.push("")}>
                      {/* show this when user has removed all segment from the list */}
                      Add a Segment Question
                    </Button>
                  </Grid>
                )}
              </>
            )}
          />
        </Grid>

        <Grid xs={12} className={classes.divider}>
          <Divider />
        </Grid>

        <Grid container>
          <FieldArray
            name="tailorMadeIndexFields"
            render={(arrayHelpers) => (
              <>
                {values.tailorMadeIndexFields && values.tailorMadeIndexFields.length > 0 ? (
                  values.tailorMadeIndexFields.map((tailorMadeIndex, index) => {
                    const indexName = `tailorMadeIndexFields.${index}.fieldName`;
                    const indexType = `tailorMadeIndexFields.${index}.fieldType`;

                    return (
                      <Grid xs={"auto"} key={index}>
                        <Grid container spacing={2}>
                          <FormControl className={classes.field}>
                            <Grid xs={12}>
                              <FormControl className={classes.field}>
                                <Field
                                  id={indexName}
                                  name={indexName}
                                  label={`Field Name ${index + 1}`}
                                  value={tailorMadeIndex.fieldName}
                                  onChange={handleChange}
                                  component={TextField}
                                  variant="standard"
                                  multiline
                                />
                              </FormControl>
                            </Grid>
                            <Grid xs={12}>
                              <FormControl className={classes.field}>
                                <InputLabel htmlFor={indexType}>{`Field Type ${index + 1}`}</InputLabel>
                                <Field
                                  id={indexType}
                                  name={indexType}
                                  value={tailorMadeIndex.fieldType}
                                  onChange={handleChange(indexType)}
                                  component={Select}
                                  variant="standard"
                                >
                                  {Object.values(TailorMadeIndexFieldType).map((fieldType, i) => {
                                    return (
                                      <MenuItem key={i} value={fieldType}>
                                        {fieldType}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid xs={12}>
                              <div>
                                <Button
                                  className={classes.button}
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }} // remove a friend from the list
                                >
                                  -
                                </Button>
                                <Button
                                  className={classes.button}
                                  onClick={() => arrayHelpers.push(newIndexField)} // insert an empty string at a position
                                >
                                  +
                                </Button>
                              </div>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid className={classes.buttonContainer}>
                    <Button variant="contained" onClick={() => arrayHelpers.push(newIndexField)}>
                      Add a tailor made sheet
                    </Button>
                  </Grid>
                )}
              </>
            )}
          />
        </Grid>

        <Grid xs={12}>
          <Divider />
        </Grid>

        <Scope3PermissionList
          permissions={values.scope3Permission}
          setPermissions={(permissions) => setFieldValue("scope3Permission", permissions)}
        />
      </Grid>
      {props.children}
    </Form>
  );
};
