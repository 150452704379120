import React, { useCallback, useEffect, useRef, useState } from "react";

import RemarksIcon from "@mui/icons-material/Comment";
import FileDialogIcon from "@mui/icons-material/Publish";
import { PropTypes } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Portal from "@mui/material/Portal";
import ReactDataGrid from "react-data-grid";
import { useIntl } from "react-intl";

import { ButtonTooltip } from "components/button-tooltip";
import { FileInfo } from "generated/graphql";
import { MIME_TYPES } from "utils/const";
import { CommonReportRecordRow, IColumnItem, IUpdatedItem } from "utils/interfaces";
import { useRemarkDialog } from "..";
import { FileDialog } from "./dialog/file-dialog";

type OnCellSelectedData = {
  rowIdx: number;
  idx: number;
};
export type DataGridProps<T extends CommonReportRecordRow> = {
  columns: IColumnItem[];
  scrollIndex: number;
  rows: T[];
  enableCellSelect: boolean;
  onUploadFileValidate: (file: File) => boolean;
  onCellSelected: (onCellSelectedData: OnCellSelectedData) => void;
  onGridSort: (columnKey: string, direction: "ASC" | "DESC" | "NONE") => void;
  onGridRowsUpdated: (updatedItems: IUpdatedItem<unknown>) => void;
  disableRemarks: boolean;
  disableFile: boolean;
};

export const DataGrid = <T extends CommonReportRecordRow>(props: DataGridProps<T>): JSX.Element => {
  const {
    columns,
    rows,
    scrollIndex,
    enableCellSelect,
    onUploadFileValidate,
    onCellSelected,
    onGridRowsUpdated,
    onGridSort,
    disableRemarks,
    disableFile,
  } = props;

  const [height, setHeight] = useState(300);
  const container = useRef<HTMLDivElement>(null);

  const refButtons = useRef<Record<number, React.RefObject<HTMLDivElement>>>({});
  const selectedRowIndex = useRef(0);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [fileDialogError, setFileDialogError] = useState("");

  const intl = useIntl();
  const { RemarkDialogWrapper, handleOpen } = useRemarkDialog();

  const updateWindowDimensions = () => {
    setHeight(window.innerHeight * 0.6);
  };

  const resizeEvent = useCallback(() => {
    updateWindowDimensions();
    document.getElementsByClassName("react-grid-Canvas")[0].scrollTop -= 100;
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [resizeEvent]);

  const handleOpenFileDialog = (index: number) => {
    selectedRowIndex.current = index;
    setFileDialogOpen(true);
  };

  const handleCloseFileDialog = () => {
    setFileDialogError("");
    setFileDialogOpen(false);
  };

  const handleUploadFile = (file: File) => {
    const row = rows[selectedRowIndex.current];

    if (!MIME_TYPES.includes(file.type)) {
      setFileDialogError(intl.formatMessage({ id: "data-grid.tools.file-error" }));
      return;
    }

    if (row.fileInfo.length + row.fileToUpload.length >= 5) {
      setFileDialogError(intl.formatMessage({ id: "data-grid.tools.file-max-error" }));
      return;
    }

    if (!onUploadFileValidate(file)) {
      handleCloseFileDialog();
      return;
    }

    onGridRowsUpdated({
      fromRow: selectedRowIndex.current,
      toRow: selectedRowIndex.current,
      fromRowData: row,
      updated: { fileToUpload: [...row.fileToUpload, file] },
    });
  };

  const handleDeletefile = (isExistingFile: boolean) => (index: number) => {
    const row = rows[selectedRowIndex.current];

    const updated: Partial<Pick<CommonReportRecordRow, "fileInfo" | "fileToUpload" | "fileToDelete">> = {};

    if (isExistingFile) {
      const newFileInfo = [...row.fileInfo];
      const removedFile = newFileInfo.splice(index, 1);

      updated.fileInfo = newFileInfo;
      updated.fileToDelete = [...row.fileToDelete, removedFile[0].id];
    } else {
      const newFileToUpload = [...row.fileToUpload];
      newFileToUpload.splice(index, 1);

      updated.fileToUpload = newFileToUpload;
    }

    onGridRowsUpdated({
      fromRow: selectedRowIndex.current,
      toRow: selectedRowIndex.current,
      fromRowData: row,
      updated,
    });
  };

  const getUploadIconColor = (row: { fileInfo: FileInfo[]; fileToUpload: File[] }): PropTypes.Color => {
    if (row.fileToUpload.length > 0) {
      return "secondary";
    } else if (row.fileInfo.length > 0) {
      return "primary";
    } else {
      return "default";
    }
  };

  const rowRenderer = ({ renderBaseRow, ...p }) => {
    if (!p.row.recordId) p.row.recordId = "";

    p.row.count = p.idx + 1;
    return <>{renderBaseRow({ ...p })}</>;
  };

  useEffect(() => {
    // @Todo: Optimize on the future
    const keys = Object.keys(refButtons.current);
    for (let i = keys.length; i < rows.length; i++) {
      refButtons.current[i] = React.createRef();
    }
  }, [rows.length]);

  const getCellActions = useCallback(
    (column: IColumnItem, row) => {
      if (!column.isAction) {
        return;
      }
      const idx = row.count - 1;
      return [
        {
          icon: (
            <>
              <div ref={container}></div>
              <div className="btn-row" id={idx.toString()} ref={refButtons.current[idx]}>
                <>
                  {!disableFile && (
                    <ButtonTooltip fontSize={13} title={intl.formatMessage({ id: "data-grid.tools.file-description" })}>
                      <IconButton
                        color={getUploadIconColor(row)}
                        onClick={() => handleOpenFileDialog(idx)}
                        className="left-icon-btn"
                        size="large"
                      >
                        <FileDialogIcon />
                      </IconButton>
                    </ButtonTooltip>
                  )}
                  {!disableRemarks && (
                    <ButtonTooltip title={intl.formatMessage({ id: "data-grid.tools.remark-description" })}>
                      <IconButton
                        color={row.remarks === "" ? "default" : "primary"}
                        onClick={() => handleOpen(row, idx)}
                        className="right-icon-btn"
                        size="large"
                      >
                        <RemarksIcon />
                      </IconButton>
                    </ButtonTooltip>
                  )}
                </>
              </div>
            </>
          ),
          callback: () => {
            /**/
          },
        },
      ];
    },
    [handleOpen, intl, disableRemarks, disableFile]
  );

  return (
    <>
      <ReactDataGrid
        columns={columns}
        rowGetter={(idx: number) => rows[idx]}
        rowsCount={rows.length}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={enableCellSelect}
        minHeight={height}
        rowHeight={60}
        headerRowHeight={50}
        getCellActions={getCellActions}
        scrollToRowIndex={scrollIndex}
        rowRenderer={rowRenderer}
        onGridSort={onGridSort}
        onCellSelected={onCellSelected}
        cellNavigationMode={"loopOverRow"}
      />
      <Portal container={container.current}>
        <>
          <RemarkDialogWrapper
            refButtons={refButtons.current}
            handleUpdate={(params) => {
              const { remark, updatedRowIndex } = params;
              onGridRowsUpdated({
                fromRow: updatedRowIndex,
                toRow: updatedRowIndex,
                fromRowData: rows[updatedRowIndex],
                updated: { remarks: remark },
              });
            }}
            isDisable={!enableCellSelect}
          />
          <FileDialog
            fileInfo={rows[selectedRowIndex.current]?.fileInfo ?? []}
            fileToUpload={rows[selectedRowIndex.current]?.fileToUpload ?? []}
            error={fileDialogError}
            refButtons={refButtons.current}
            rowIndex={selectedRowIndex.current}
            open={fileDialogOpen}
            onUploadFile={handleUploadFile}
            onDeleteFile={handleDeletefile(true)}
            onDeleteFileToUpload={handleDeletefile(false)}
            onCloseDialog={handleCloseFileDialog}
          />
        </>
      </Portal>
    </>
  );
};
