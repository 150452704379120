import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, LabourResponsibilityPolicyParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { newLabourResponsibilityPolicyRow } from "../consts";
import { LabourResponsibilityPolicyRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: LabourResponsibilityPolicyRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getLabourResponsibilityPolicyData, submitLabourResponsibilityPolicy } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const labourResponsibilityPolicy = await getLabourResponsibilityPolicyData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = labourResponsibilityPolicy.flatMap((l) =>
          l.records.map<LabourResponsibilityPolicyRecordRow>((r) => ({
            ...newLabourResponsibilityPolicyRow,
            recordId: r.id,
            originalCompanyId: l.projectCompany.id,
            companyId: l.projectCompany.id,
            remarks: r.remarks,
            healthDesc: r.healthDesc,
            reviewDesc: r.reviewDesc,
            eliminateDesc: r.eliminateDesc,
            preventiveDesc: r.preventiveDesc,
            trainingDesc: r.trainingDesc,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getLabourResponsibilityPolicyData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<LabourResponsibilityPolicyParam>((e) => {
          const data = e.data as DataGridRow<LabourResponsibilityPolicyRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              healthDesc: data.healthDesc,
              reviewDesc: data.reviewDesc,
              eliminateDesc: data.eliminateDesc,
              preventiveDesc: data.preventiveDesc,
              trainingDesc: data.trainingDesc,
            },
          };
        });

        return await submitLabourResponsibilityPolicy({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitLabourResponsibilityPolicy]
  );

  return { getData, submitData };
};
