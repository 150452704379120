import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { RefrigerantAndFireRecordRow } from "./types";

export const newRefrigerantAndFireRow: RefrigerantAndFireRecordRow = {
  recordId: "",
  companyId: "",
  refrigerantFireExtinguisher: "",
  ghgType: "",
  startDate: "",
  endDate: "",
  openingBalance: 0,
  quantityPurchased: 0,
  quantityDisposed: 0,
  endingBalance: 0,
  quantityUsed: 0,
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<RefrigerantAndFireRecordRow, string> = {
  openingBalance: "tips.refrigerant-and-fire.opening-balance",
  endingBalance: "tips.refrigerant-and-fire.ending-balance",
  ghgType: "tips.refrigerant-and-fire.ghg-type",
};

export enum ExtinguisherType {
  Refrigerant = "refrigerant",
  DryPowder = "dryPowder",
  CO2 = "CO2",
  Foam = "foam",
  Water = "water",
  Halogenated = "halogenated",
}

export const extinguisherTypeOptions: OptionType[] = [
  { label: "Refrigerant 冷媒/制冷剂", value: ExtinguisherType.Refrigerant },
  { label: "Fire Ex. (Dry Powder) 灭火器 (干粉)", value: ExtinguisherType.DryPowder },
  { label: "Fire Ex. (CO2) 灭火器 (二氧化碳)", value: ExtinguisherType.CO2 },
  { label: "Fire Ex. (Foam)灭火器 (泡沫)", value: ExtinguisherType.Foam },
  { label: "Fire Ex. (Water)灭火器 (清水)", value: ExtinguisherType.Water },
  { label: "Fire Ex. (Halogenated)灭火器 (如:卤代烷 1211)", value: ExtinguisherType.Halogenated },
];

export enum GhgType {
  CO2 = "Carbon dioxide (CO2)",
  CH4 = "Methane (CH4)",
  N2O = "Nitrous oxide (N2O)",
  CFC11 = "CFC-11 (CCl3F)",
  CFC12 = "CFC-12 (CCl2F2)",
  CFC13 = "CFC-13 (CClF3)",
  CFC113 = "CFC-113 (CCl2FCClF2)",
  CFC114 = "CFC-114 (CClF2CClF2)",
  CFC115 = "CFC-115 (CClF2CF3)",
  Halon1301 = "Halon-1301 (CBrF3)",
  Halon1211 = "Halon-1211 (CBrClF2)",
  Halon2402 = "Halon-2402 (CBrF2CBrF2)",
  CCL4 = "Carbon tetrachloride (CCl4)",
  CH3BR = "Methyl bromide (CH3Br)",
  CH3CCL3 = "Methyl chloroform (CH3CCl3)",
  HCFC21 = "HCFC-21 (CHCl2F)",
  HCFC22 = "HCFC-22 (CHCLF2)",
  HCFC123 = "HCFC-123 (CHCl2CF3)",
  HCFC124 = "HCFC-124 (CHClFCF3)",
  HCFC141b = "HCFC-141b (CH3CCl2F)",
  HCFC142b = "HCFC-142b (CH3CClF2)",
  HCFC225ca = "HCFC-225ca (CHCl2CF2CF3)",
  HCFC225cb = "HCFC-225cb (CHClFCF2CClF2)",
  HFC23 = "HFC-23 (CHF3)",
  HFC32 = "HFC-32 (CH2F2)",
  HFC41 = "HFC-41 (CH3F2)",
  HFC125 = "HFC-125 (CHF2CF3)",
  HFC134 = "HFC-134 (CHF2CHF2)",
  HFC134a = "HFC-134a (CH2FCF3)",
  HFC143 = "HFC-143 (CH2FCHF2)",
  HFC143a = "HFC-143a (CH3CF3)",
  HFC152 = "HFC-152 (CH2FCH2F)",
  HFC152a = "HFC-152a (CH3CHF2)",
  HFC161 = "HFC-161 (CH3CH2F)",
  HFC227ea = "HFC-227ea (CF3CHFCF3)",
  HFC236cb = "HFC-236cb (CH2FCF2CF3)",
  HFC236ea = "HFC-236ea (CHF2CHFCF3)",
  HFC236fa = "HFC-236fa (CF3CH2CF3)",
  HFC245ca = "HFC-245ca (CH2FCF2CHF2)",
  HFC245fa = "HFC-245fa (CHF2CH2CF3)",
  HFC365mfc = "HFC-365mfc (CH3CF2CH2CF3)",
  HFC4310mee = "HFC-43-10mee (CF3CHFCHFCF2CF3)",
  SF6 = "Sulfur hexafluoride (SF6)",
  NF3 = "Nitrogen trifluoride (NF3)",
  PFC14 = "PFC-14 (CF4)",
  PFC116 = "PFC-116 (C2F6)",
  PFC218 = "PFC-218 (C3F8)",
  PFC318 = "PFC-318 (c-C4F8)",
  PFC3110 = "PFC-31-10 (C4F10)",
  PFC4112 = "PFC-41-12 (C5F12)",
  PFC5114 = "PFC-51-14 (C6F14)",
  PCF9118 = "PCF-91-18 (C10F18)",
  SF5CF3 = "Trifluoromethyl sulfur pentafluoride (SF5CF3)",
  CC3F6 = "Perfluorocyclopropane (c-C3F6)",
  HFE125 = "HFE-125 (CHF2OCF3)",
  HFE134 = "HFE-134 (CHF2OCHF2)",
  HFE143a = "HFE-143a (CH3OCF3)",
  HCFE235da2 = "HCFE-235da2 (CHF2OCHClCF3)",
  HFE245cb2 = "HFE-245cb2 (CH3OCF2CF3)",
  HFE245fa2 = "HFE-245fa2 (CHF2OCH2CF3)",
  HFE347mcc3 = "HFE-347mcc3 (CH3OCF2CF2CF3)",
  HFE347pcf2 = "HFE-347pcf2 (CHF2CF2OCH2CF3)",
  HFE356pcc3 = "HFE-356pcc3 (CH3OCF2CF2CHF2)",
  HFE449sl = "HFE-449sl (HFE-7100) (C4F9OCH3)",
  HFE569sf2 = "HFE-569sf2 (HFE-7200) (C4F9OC2H5)",
  HFE4310pccc124 = "HFE-43-10pccc124 (H-Galden 1040x) (CHF2OCF2OC2F4OCHF2)",
  HFE236ca12 = "HFE-236ca12 (HG-10) (CHF2OCF2OCHF2)",
  HFE338pcc13 = "HFE-338pcc13 (HG-01) (CHF2OCF2CF2OCHF2)",
  HFE227ea = "HFE-227ea (CF3CHFOCF3)",
  HFE236ea2 = "HFE-236ea2 (CHF2OCHFCF3)",
  HFE236fa = "HFE-236fa (CF3CH2OCF3)",
  HFE245fa1 = "HFE-245fa1 (CHF2CH2OCF3)",
  HFE263fb2 = "HFE 263fb2 (CF3CH2OCH3)",
  HFE329mcc2 = "HFE-329mcc2 (CHF2CF2OCF2CF3)",
  HFE338mcf2 = "HFE-338mcf2 (CF3CH2OCF2CF3)",
  HFE347mcf2 = "HFE-347mcf2 (CHF2CH2OCF2CF3)",
  HFE356mec3 = "HFE-356mec3 (CH3OCF2CHFCF3)",
  HFE356pcf2 = "HFE-356pcf2 (CHF2CH2OCF2CHF2)",
  HFE356pcf3 = "HFE-356pcf3 (CHF2OCH2CF2CHF2)",
  HFE365mcf3 = "HFE 365mcf3 (CF3CF2CH2OCH3)",
  HFE374pc2 = "HFE-374pc2 (CHF2CF2OCH2CH3)",
  PFPMIE = "PFPMIE (CF3OCF(CF3)CF2OCF2OCF3)",
  CHCL3 = "Chloroform (CHCl3)",
  CH2CL2 = "Methylene chloride (CH2Cl2)",
  CH3CL = "Methyl chloride (CH3Cl)",
  Halon1201 = "Halon-1201 (CHBrF2)",
  R401A = "R-401A (53% HCFC-22 , 34% HCFC-124 , 13% HFC-152a)",
  R401B = "R-401B (61% HCFC-22 , 28% HCFC-124 , 11% HFC-152a)",
  R401C = "R-401C (33% HCFC-22 , 52% HCFC-124 , 15% HFC-152a)",
  R402A = "R-402A (38% HCFC-22 , 6% HFC-125 , 2% propane)",
  R402B = "R-402B (6% HCFC-22 , 38% HFC-125 , 2% propane)",
  R403B = "R-403B (56% HCFC-22 , 39% PFC-218 , 5% propane)",
  R404A = "R-404A (44% HFC-125 , 4% HFC-134a , 52% HFC 143a)",
  R406A = "R-406A (55% HCFC-22 , 41% HCFC-142b , 4% isobutane)",
  R407A = "R-407A (20% HFC-32 , 40% HFC-125 , 40% HFC-134a)",
  R407B = "R-407B (10% HFC-32 , 70% HFC-125 , 20% HFC-134a)",
  R407C = "R-407C (23% HFC-32 , 25% HFC-125 , 52% HFC-134a)",
  R407D = "R-407D (15% HFC-32 , 15% HFC-125 , 70% HFC-134a)",
  R407E = "R-407E (25% HFC-32 , 15% HFC-125 , 60% HFC-134a)",
  R408A = "R-408A (47% HCFC-22 , 7% HFC-125 , 46% HFC 143a)",
  R409A = "R-409A (60% HCFC-22 , 25% HCFC-124 , 15% HCFC-142b)",
  R410A = "R-410A (50% HFC-32 , 50% HFC-125)",
  R410B = "R-410B (45% HFC-32 , 55% HFC-125 )",
  R411A = "R-411A (87.5% HCFC-22 , 11 HFC-152a , 1.5% propylene)",
  R411B = "R-411B (94% HCFC-22 , 3% HFC-152a , 3% propylene)",
  R413A = "R-413A (88% HFC-134a , 9% PFC-218 , 3% isobutane)",
  R414A = "R-414A (51% HCFC-22 , 28.5% HCFC-124 , 16.5% HCFC-142b)",
  R414B = "R-414B (5% HCFC-22 , 39% HCFC-124 , 9.5% HCFC-142b)",
  R417A = "R-417A (46.6% HFC-125 , 5% HFC-134a , 3.4% butane)",
  R422A = "R-422A (85.1% HFC-125 , 11.5% HFC-134a , 3.4% isobutane)",
  R422D = "R-422D (65.1% HFC-125 , 31.5% HFC-134a , 3.4% isobutane)",
  R423A = "R-423A (47.5% HFC-227ea , 52.5% HFC-134a ,  )",
  R424A = "R-424A (50.5% HFC-125, 47% HFC-134a, 2.5% butane/pentane)",
  R426A = "R-426A (5.1% HFC-125, 93% HFC-134a, 1.9% butane/pentane)",
  R428A = "R-428A (77.5% HFC-125 , 2% HFC-143a , 1.9% isobutane)",
  R434A = "R-434A (63.2% HFC-125, 16% HFC-134a, 18% HFC-143a, 2.8% isobutane)",
  R500 = "R-500 (73.8% CFC-12 , 26.2% HFC-152a , 48.8% HCFC-22)",
  R502 = "R-502 (48.8% HCFC-22 , 51.2% CFC-115 )",
  R504 = "R-504 (48.2% HFC-32 , 51.8% CFC-115)",
  R507 = "R-507 (5% HFC-125 , 5% HFC143a)",
  R508A = "R-508A (39% HFC-23 , 61% PFC-116)",
  R508B = "R-508B (46% HFC-23 , 54% PFC-116)",
  Others = "others",
}

export const ghgTypeOptions: OptionType[] = [
  { label: "Carbon dioxide (CO2)", value: GhgType.CO2 },
  { label: "Methane (CH4)", value: GhgType.CH4 },
  { label: "Nitrous oxide (N2O)", value: GhgType.N2O },
  { label: "CFC-11 (CCl3F)", value: GhgType.CFC11 },
  { label: "CFC-12 (CCl2F2)", value: GhgType.CFC12 },
  { label: "CFC-13 (CClF3)", value: GhgType.CFC13 },
  { label: "CFC-113 (CCl2FCClF2)", value: GhgType.CFC113 },
  { label: "CFC-114 (CClF2CClF2)", value: GhgType.CFC114 },
  { label: "CFC-115 (CClF2CF3)", value: GhgType.CFC115 },
  { label: "Halon-1301 (CBrF3)", value: GhgType.Halon1301 },
  { label: "Halon-1211 (CBrClF2)", value: GhgType.Halon1211 },
  { label: "Halon-2402 (CBrF2CBrF2)", value: GhgType.Halon2402 },
  { label: "Carbon tetrachloride (CCl4)", value: GhgType.CCL4 },
  { label: "Methyl bromide (CH3Br)", value: GhgType.CH3BR },
  { label: "Methyl chloroform (CH3CCl3)", value: GhgType.CH3CCL3 },
  { label: "HCFC-21 (CHCl2F)", value: GhgType.HCFC21 },
  { label: "HCFC-22 (CHCLF2)", value: GhgType.HCFC22 },
  { label: "HCFC-123 (CHCl2CF3)", value: GhgType.HCFC123 },
  { label: "HCFC-124 (CHClFCF3)", value: GhgType.HCFC124 },
  { label: "HCFC-141b (CH3CCl2F)", value: GhgType.HCFC141b },
  { label: "HCFC-142b (CH3CClF2)", value: GhgType.HCFC142b },
  { label: "HCFC-225ca (CHCl2CF2CF3)", value: GhgType.HCFC225ca },
  { label: "HCFC-225cb (CHClFCF2CClF2)", value: GhgType.HCFC225cb },
  { label: "HFC-23 (CHF3)", value: GhgType.HFC23 },
  { label: "HFC-32 (CH2F2)", value: GhgType.HFC32 },
  { label: "HFC-41 (CH3F2)", value: GhgType.HFC41 },
  { label: "HFC-125 (CHF2CF3)", value: GhgType.HFC125 },
  { label: "HFC-134 (CHF2CHF2)", value: GhgType.HFC134 },
  { label: "HFC-134a (CH2FCF3)", value: GhgType.HFC134a },
  { label: "HFC-143 (CH2FCHF2)", value: GhgType.HFC143 },
  { label: "HFC-143a (CH3CF3)", value: GhgType.HFC143a },
  { label: "HFC-152 (CH2FCH2F)", value: GhgType.HFC152 },
  { label: "HFC-152a (CH3CHF2)", value: GhgType.HFC152a },
  { label: "HFC-161 (CH3CH2F)", value: GhgType.HFC161 },
  { label: "HFC-227ea (CF3CHFCF3)", value: GhgType.HFC227ea },
  { label: "HFC-236cb (CH2FCF2CF3)", value: GhgType.HFC236cb },
  { label: "HFC-236ea (CHF2CHFCF3)", value: GhgType.HFC236ea },
  { label: "HFC-236fa (CF3CH2CF3)", value: GhgType.HFC236fa },
  { label: "HFC-245ca (CH2FCF2CHF2)", value: GhgType.HFC245ca },
  { label: "HFC-245fa (CHF2CH2CF3)", value: GhgType.HFC245fa },
  { label: "HFC-365mfc (CH3CF2CH2CF3)", value: GhgType.HFC365mfc },
  { label: "HFC-43-10mee (CF3CHFCHFCF2CF3)", value: GhgType.HFC4310mee },
  { label: "Sulfur hexafluoride (SF6)", value: GhgType.SF6 },
  { label: "Nitrogen trifluoride (NF3)", value: GhgType.NF3 },
  { label: "PFC-14 (CF4)", value: GhgType.PFC14 },
  { label: "PFC-116 (C2F6)", value: GhgType.PFC116 },
  { label: "PFC-218 (C3F8)", value: GhgType.PFC218 },
  { label: "PFC-318 (c-C4F8)", value: GhgType.PFC318 },
  { label: "PFC-31-10 (C4F10)", value: GhgType.PFC3110 },
  { label: "PFC-41-12 (C5F12)", value: GhgType.PFC4112 },
  { label: "PFC-51-14 (C6F14)", value: GhgType.PFC5114 },
  { label: "PCF-91-18 (C10F18)", value: GhgType.PCF9118 },
  { label: "Trifluoromethyl sulfur pentafluoride (SF5CF3)", value: GhgType.SF5CF3 },
  { label: "Perfluorocyclopropane (c-C3F6)", value: GhgType.CC3F6 },
  { label: "HFE-125 (CHF2OCF3)", value: GhgType.HFE125 },
  { label: "HFE-134 (CHF2OCHF2)", value: GhgType.HFE134 },
  { label: "HFE-143a (CH3OCF3)", value: GhgType.HFE143a },
  { label: "HCFE-235da2 (CHF2OCHClCF3)", value: GhgType.HCFE235da2 },
  { label: "HFE-245cb2 (CH3OCF2CF3)", value: GhgType.HFE245cb2 },
  { label: "HFE-245fa2 (CHF2OCH2CF3)", value: GhgType.HFE245fa2 },
  { label: "HFE-347mcc3 (CH3OCF2CF2CF3)", value: GhgType.HFE347mcc3 },
  { label: "HFE-347pcf2 (CHF2CF2OCH2CF3)", value: GhgType.HFE347pcf2 },
  { label: "HFE-356pcc3 (CH3OCF2CF2CHF2)", value: GhgType.HFE356pcc3 },
  { label: "HFE-449sl (HFE-7100) (C4F9OCH3)", value: GhgType.HFE449sl },
  { label: "HFE-569sf2 (HFE-7200) (C4F9OC2H5)", value: GhgType.HFE569sf2 },
  { label: "HFE-43-10pccc124 (H-Galden 1040x) (CHF2OCF2OC2F4OCHF2)", value: GhgType.HFE4310pccc124 },
  { label: "HFE-236ca12 (HG-10) (CHF2OCF2OCHF2)", value: GhgType.HFE236ca12 },
  { label: "HFE-338pcc13 (HG-01) (CHF2OCF2CF2OCHF2)", value: GhgType.HFE338pcc13 },
  { label: "HFE-227ea (CF3CHFOCF3)", value: GhgType.HFE227ea },
  { label: "HFE-236ea2 (CHF2OCHFCF3)", value: GhgType.HFE236ea2 },
  { label: "HFE-236fa (CF3CH2OCF3)", value: GhgType.HFE236fa },
  { label: "HFE-245fa1 (CHF2CH2OCF3)", value: GhgType.HFE245fa1 },
  { label: "HFE 263fb2 (CF3CH2OCH3)", value: GhgType.HFE263fb2 },
  { label: "HFE-329mcc2 (CHF2CF2OCF2CF3)", value: GhgType.HFE329mcc2 },
  { label: "HFE-338mcf2 (CF3CH2OCF2CF3)", value: GhgType.HFE338mcf2 },
  { label: "HFE-347mcf2 (CHF2CH2OCF2CF3)", value: GhgType.HFE347mcf2 },
  { label: "HFE-356mec3 (CH3OCF2CHFCF3)", value: GhgType.HFE356mec3 },
  { label: "HFE-356pcf2 (CHF2CH2OCF2CHF2)", value: GhgType.HFE356pcf2 },
  { label: "HFE-356pcf3 (CHF2OCH2CF2CHF2)", value: GhgType.HFE356pcf3 },
  { label: "HFE 365mcf3 (CF3CF2CH2OCH3)", value: GhgType.HFE365mcf3 },
  { label: "HFE-374pc2 (CHF2CF2OCH2CH3)", value: GhgType.HFE374pc2 },
  { label: "PFPMIE (CF3OCF(CF3)CF2OCF2OCF3)", value: GhgType.PFPMIE },
  { label: "Chloroform (CHCl3)", value: GhgType.CHCL3 },
  { label: "Methylene chloride (CH2Cl2)", value: GhgType.CH2CL2 },
  { label: "Methyl chloride (CH3Cl)", value: GhgType.CH3CL },
  { label: "Halon-1201 (CHBrF2)", value: GhgType.Halon1201 },
  { label: "R-401A (53% HCFC-22 , 34% HCFC-124 , 13% HFC-152a)", value: GhgType.R401A },
  { label: "R-401B (61% HCFC-22 , 28% HCFC-124 , 11% HFC-152a)", value: GhgType.R401B },
  { label: "R-401C (33% HCFC-22 , 52% HCFC-124 , 15% HFC-152a)", value: GhgType.R401C },
  { label: "R-402A (38% HCFC-22 , 6% HFC-125 , 2% propane)", value: GhgType.R402A },
  { label: "R-402B (6% HCFC-22 , 38% HFC-125 , 2% propane)", value: GhgType.R402B },
  { label: "R-403B (56% HCFC-22 , 39% PFC-218 , 5% propane)", value: GhgType.R403B },
  { label: "R-404A (44% HFC-125 , 4% HFC-134a , 52% HFC 143a)", value: GhgType.R404A },
  { label: "R-406A (55% HCFC-22 , 41% HCFC-142b , 4% isobutane)", value: GhgType.R406A },
  { label: "R-407A (20% HFC-32 , 40% HFC-125 , 40% HFC-134a)", value: GhgType.R407A },
  { label: "R-407B (10% HFC-32 , 70% HFC-125 , 20% HFC-134a)", value: GhgType.R407B },
  { label: "R-407C (23% HFC-32 , 25% HFC-125 , 52% HFC-134a)", value: GhgType.R407C },
  { label: "R-407D (15% HFC-32 , 15% HFC-125 , 70% HFC-134a)", value: GhgType.R407D },
  { label: "R-407E (25% HFC-32 , 15% HFC-125 , 60% HFC-134a)", value: GhgType.R407E },
  { label: "R-408A (47% HCFC-22 , 7% HFC-125 , 46% HFC 143a)", value: GhgType.R408A },
  { label: "R-409A (60% HCFC-22 , 25% HCFC-124 , 15% HCFC-142b)", value: GhgType.R409A },
  { label: "R-410A (50% HFC-32 , 50% HFC-125)", value: GhgType.R410A },
  { label: "R-410B (45% HFC-32 , 55% HFC-125 )", value: GhgType.R410B },
  { label: "R-411A (87.5% HCFC-22 , 11 HFC-152a , 1.5% propylene)", value: GhgType.R411A },
  { label: "R-411B (94% HCFC-22 , 3% HFC-152a , 3% propylene)", value: GhgType.R411B },
  { label: "R-413A (88% HFC-134a , 9% PFC-218 , 3% isobutane)", value: GhgType.R413A },
  { label: "R-414A (51% HCFC-22 , 28.5% HCFC-124 , 16.5% HCFC-142b)", value: GhgType.R414A },
  { label: "R-414B (5% HCFC-22 , 39% HCFC-124 , 9.5% HCFC-142b)", value: GhgType.R414B },
  { label: "R-417A (46.6% HFC-125 , 5% HFC-134a , 3.4% butane)", value: GhgType.R417A },
  { label: "R-422A (85.1% HFC-125 , 11.5% HFC-134a , 3.4% isobutane)", value: GhgType.R422A },
  { label: "R-422D (65.1% HFC-125 , 31.5% HFC-134a , 3.4% isobutane)", value: GhgType.R422D },
  { label: "R-423A (47.5% HFC-227ea , 52.5% HFC-134a ,  )", value: GhgType.R423A },
  { label: "R-424A (50.5% HFC-125, 47% HFC-134a, 2.5% butane/pentane)", value: GhgType.R424A },
  { label: "R-426A (5.1% HFC-125, 93% HFC-134a, 1.9% butane/pentane)", value: GhgType.R426A },
  { label: "R-428A (77.5% HFC-125 , 2% HFC-143a , 1.9% isobutane)", value: GhgType.R428A },
  { label: "R-434A (63.2% HFC-125, 16% HFC-134a, 18% HFC-143a, 2.8% isobutane)", value: GhgType.R434A },
  { label: "R-500 (73.8% CFC-12 , 26.2% HFC-152a , 48.8% HCFC-22)", value: GhgType.R500 },
  { label: "R-502 (48.8% HCFC-22 , 51.2% CFC-115 )", value: GhgType.R502 },
  { label: "R-504 (48.2% HFC-32 , 51.8% CFC-115)", value: GhgType.R504 },
  { label: "R-507 (5% HFC-125 , 5% HFC143a)", value: GhgType.R507 },
  { label: "R-508A (39% HFC-23 , 61% PFC-116)", value: GhgType.R508A },
  { label: "R-508B (46% HFC-23 , 54% PFC-116)", value: GhgType.R508B },
  { label: "Others 其他", value: GhgType.Others },
];
