import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { DateFormat, toDateString, toMoment } from "utils/date-time";
import { GridWithLoading, TotalBlock } from "..";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_50 } from "../consts";
import { SectionHeader } from "../section-header";

export type InjuriesDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  isLoading: boolean;
};

export const InjuriesDashboard: React.FC<InjuriesDashboardProps> = (props) => {
  const { data, currentYear, isLoading } = props;
  const { current } = data;
  const { injuryLostDays, past3YearsFatalities } = current.injuriesAndFatalities;

  const intl = useIntl();

  const past3YearRange = `${toMoment(currentYear).subtract(2, "years").format(DateFormat.YearOnly)}-${toDateString(
    currentYear,
    DateFormat.YearOnlyShort
  )}`;

  return (
    <Grid xs={3}>
      <Grid xs={12}>
        <SectionHeader
          title={intl.formatMessage({ id: "dashboard.social.injuriesAndFatalities.title" })}
          description={intl.formatMessage(
            { id: "dashboard.social.injuriesAndFatalities.description" },
            { year: currentYear, past3YearRange }
          )}
        />
      </Grid>
      <Grid xs={8}>
        <Grid container spacing={2}>
          <GridWithLoading xs={12} height={DASHBOARD_BLOCK_HEIGHT_50} isLoading={isLoading} isVisible>
            <TotalBlock
              title={intl.formatMessage({ id: "dashboard.social.injuriesAndFatalities.injuryLostDays.title" })}
              value={injuryLostDays.value}
              valueColor={DashboardColor.chart.green}
            />
          </GridWithLoading>

          <GridWithLoading xs={12} height={DASHBOARD_BLOCK_HEIGHT_50} isLoading={isLoading} isVisible>
            <TotalBlock
              title={intl.formatMessage({
                id: "dashboard.social.injuriesAndFatalities.past3YearsFatalities.title",
              })}
              value={past3YearsFatalities.value}
              valueColor={DashboardColor.chart.green}
            />
          </GridWithLoading>
        </Grid>
      </Grid>
    </Grid>
  );
};
