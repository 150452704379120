import { Property } from "csstype";

import { ChartInput } from "./charts/types";
import { GetEnvDashboardDataQuery, GetSocialDashboardDataQuery, OptionDatum } from "generated/graphql";

type DashboardColorType = {
  chart: {
    blue1: Property.Fill;
    blue2: Property.Fill;
    blue3: Property.Fill;
    blue4: Property.Fill;
    blue5: Property.Fill;
    blue6: Property.Fill;
    blue7: Property.Fill;
    green: Property.Fill;
    grey1: Property.Fill;
    grey2: Property.Fill;
    grey3: Property.Fill;
    orange: Property.Fill;
    red: Property.Fill;
  };
  text: {
    grey: Property.Fill;
  };
  background: {
    grey: Property.Fill;
  };
};

export const DashboardColor: DashboardColorType = {
  chart: {
    blue1: "#4472C4",
    blue2: "#375DA1",
    blue3: "#A7B5DB",
    blue4: "#DAE3F3",
    blue5: "#5B9BD5",
    blue6: "#264478",
    blue7: "#255E91",
    green: "#70AD47",
    grey1: "#767171",
    grey2: "#A5A5A5",
    grey3: "#636363",
    orange: "#ED7D31",
    red: "#C00000",
  },
  text: {
    grey: "#7F7F7F",
  },
  background: {
    grey: "#F2F2F2",
  },
};

export enum TabPosition {
  Top = "TOP",
  Right = "RIGHT",
}

export enum DashboardTab {
  Environmental = "ENVIRONMENTAL",
  Social = "SOCIAL",
}

export const DASHBOARD_BLOCK_HEIGHT_100 = 400;
export const DASHBOARD_BLOCK_HEIGHT_80 = DASHBOARD_BLOCK_HEIGHT_100 * 0.8;
export const DASHBOARD_BLOCK_HEIGHT_50 = DASHBOARD_BLOCK_HEIGHT_100 * 0.5;

export const dummyChartInput: ChartInput = { name: "", value: 0 };

export const dummyOptionDaum: OptionDatum = { option: "", value: 0, unit: "" };

export const dummyEnvrionmentalDashboardData: GetEnvDashboardDataQuery["dashboardEnv"] = {
  current: {
    ghg: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
      breakdown: {
        data: [],
        unit: "",
      },
    },
    air: {
      nox: {
        value: 0,
        unit: "",
      },
      sox: {
        value: 0,
        unit: "",
      },
      pm: {
        value: 0,
        unit: "",
      },
    },
    energy: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
      breakdown: {
        data: [],
        unit: "",
      },
    },
    water: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
    },
    waste: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
    },
    hazardousWaste: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
    },
    nonHazardousWaste: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
      breakdown: {
        data: [],
        unit: "",
      },
    },
    packagingMaterial: {
      total: {
        value: 0,
        unit: "",
      },
      intensity: [],
    },
    project: { endDate: new Date() },
    updatedAt: new Date(),
  },
  previous: null,
};

export const dummySocialDashboardData: GetSocialDashboardDataQuery["dashboardSocial"] = {
  current: {
    project: {
      endDate: new Date(),
    },
    updatedAt: new Date(),
    workforce: {
      total: { value: 0 },
      gender: [],
      age: [],
      fullTimeOrPartTime: [],
      grade: [],
      region: [],
    },
    turnover: {
      total: {
        value: 0,
        percentageByYearEnd: 0,
        percentageByAverage: 0,
      },
      gender: [],
      age: [],
      grade: [],
      region: [],
    },
    training: {
      totalEmployees: {
        value: 0,
        percentageWithResign: 0,
        percentageWithoutResign: 0,
      },
      totalHours: { value: 0 },
      genderByEmployee: [],
      genderByHour: [],
      gradeByEmployee: [],
      gradeByHour: [],
    },
    injuriesAndFatalities: {
      injuryLostDays: { value: 0 },
      past3YearsFatalities: { value: 0 },
    },
    supplyChain: {
      total: { value: 0 },
      region: [],
    },
    productResponsibility: {
      totalProduct: { value: 0 },
      totalComplaint: { value: 0 },
      retrievedProduct: { value: 0 },
    },
  },
};

export enum SocialDashboardRegionKey {
  hk = "hk",
  china = "china",
  otherRegion = "otherRegion",
}

export const SocialDashboardRegionIntl: Record<SocialDashboardRegionKey, string> = {
  [SocialDashboardRegionKey.hk]: "dashboard.social.region.hk",
  [SocialDashboardRegionKey.china]: "dashboard.social.region.china",
  [SocialDashboardRegionKey.otherRegion]: "dashboard.social.region.otherRegion",
};

export const SocialDashboardRegionShortIntl: Record<SocialDashboardRegionKey, string> = {
  [SocialDashboardRegionKey.hk]: "dashboard.social.region.hk.short",
  [SocialDashboardRegionKey.china]: "dashboard.social.region.china",
  [SocialDashboardRegionKey.otherRegion]: "dashboard.social.region.otherRegion.short",
};

export const SocialDashboardRegionColor: Record<SocialDashboardRegionKey, Property.Fill> = {
  [SocialDashboardRegionKey.hk]: DashboardColor.chart.blue1,
  [SocialDashboardRegionKey.china]: DashboardColor.chart.orange,
  [SocialDashboardRegionKey.otherRegion]: DashboardColor.chart.grey2,
};

export const SupplyChainDashboardRegionColor: Record<SocialDashboardRegionKey, Property.Fill> = {
  [SocialDashboardRegionKey.hk]: DashboardColor.chart.blue1,
  [SocialDashboardRegionKey.china]: DashboardColor.chart.grey2,
  [SocialDashboardRegionKey.otherRegion]: DashboardColor.chart.blue5,
};

export enum SocialDashboardGenderKey {
  male = "male",
  female = "female",
}

export const SocialDashboardGenderIntl: Record<SocialDashboardGenderKey, string> = {
  [SocialDashboardGenderKey.male]: "dashboard.social.gender.male",
  [SocialDashboardGenderKey.female]: "dashboard.social.gender.female",
};

export const SocialDashboardGenderColor: Record<SocialDashboardGenderKey, Property.Fill> = {
  [SocialDashboardGenderKey.male]: DashboardColor.chart.blue1,
  [SocialDashboardGenderKey.female]: DashboardColor.chart.red,
};

export enum SocialDashboardAgeKey {
  ageBelow20 = "ageBelow20",
  age21to30 = "age21to30",
  age31to40 = "age31to40",
  age41to50 = "age41to50",
  age51to60 = "age51to60",
  ageAbove60 = "ageAbove60",
}

export const SocialDashboardAgeIntl: Record<SocialDashboardAgeKey, string> = {
  [SocialDashboardAgeKey.ageBelow20]: `dashboard.social.age.ageBelow20`,
  [SocialDashboardAgeKey.age21to30]: `dashboard.social.age.age21to30`,
  [SocialDashboardAgeKey.age31to40]: `dashboard.social.age.age31to40`,
  [SocialDashboardAgeKey.age41to50]: `dashboard.social.age.age41to50`,
  [SocialDashboardAgeKey.age51to60]: `dashboard.social.age.age51to60`,
  [SocialDashboardAgeKey.ageAbove60]: `dashboard.social.age.ageAbove60`,
};

export const SocialDashboardAgeColor: Record<SocialDashboardAgeKey, Property.Fill> = {
  [SocialDashboardAgeKey.ageBelow20]: DashboardColor.chart.blue1,
  [SocialDashboardAgeKey.age21to30]: DashboardColor.chart.grey2,
  [SocialDashboardAgeKey.age31to40]: DashboardColor.chart.blue5,
  [SocialDashboardAgeKey.age41to50]: DashboardColor.chart.blue6,
  [SocialDashboardAgeKey.age51to60]: DashboardColor.chart.grey3,
  [SocialDashboardAgeKey.ageAbove60]: DashboardColor.chart.blue7,
};

export enum SocialDashboardFullTimeOrPartTimeKey {
  fullTime = "fullTime",
  partTime = "partTime",
}

export const SocialDashboardFullTimeOrPartTimeIntl: Record<SocialDashboardFullTimeOrPartTimeKey, string> = {
  [SocialDashboardFullTimeOrPartTimeKey.fullTime]: `dashboard.social.fullTimeOrPartTime.fullTime`,
  [SocialDashboardFullTimeOrPartTimeKey.partTime]: `dashboard.social.fullTimeOrPartTime.partTime`,
};

export const SocialDashboardFullTimeOrPartTimeColor: Record<SocialDashboardFullTimeOrPartTimeKey, Property.Fill> = {
  [SocialDashboardFullTimeOrPartTimeKey.fullTime]: DashboardColor.chart.blue1,
  [SocialDashboardFullTimeOrPartTimeKey.partTime]: DashboardColor.chart.grey2,
};

export enum SocialDashboardGradeKey {
  seniorManagement = "seniorManagement",
  middleLevelManagement = "middleLevelManagement",
  generalAndTechnicalStaff = "generalAndTechnicalStaff",
}

export const SocialDashboardGradeIntl: Record<SocialDashboardGradeKey, string> = {
  [SocialDashboardGradeKey.seniorManagement]: "dashboard.social.grade.senior-management",
  [SocialDashboardGradeKey.middleLevelManagement]: "dashboard.social.grade.middle-management",
  [SocialDashboardGradeKey.generalAndTechnicalStaff]: "dashboard.social.grade.general-employees",
};

export const SocialDashboardGradeShortIntl: Record<SocialDashboardGradeKey, string> = {
  [SocialDashboardGradeKey.seniorManagement]: "dashboard.social.grade.senior-management.short",
  [SocialDashboardGradeKey.middleLevelManagement]: "dashboard.social.grade.middle-management.short",
  [SocialDashboardGradeKey.generalAndTechnicalStaff]: "dashboard.social.grade.general-employees.short",
};

export const SocialDashboardGradeColor: Record<SocialDashboardGradeKey, Property.Fill> = {
  [SocialDashboardGradeKey.seniorManagement]: DashboardColor.chart.blue1,
  [SocialDashboardGradeKey.middleLevelManagement]: DashboardColor.chart.grey2,
  [SocialDashboardGradeKey.generalAndTechnicalStaff]: DashboardColor.chart.blue5,
};

export enum TurnoverRateKey {
  byYearEnd = "percentageByYearEnd",
  byAverage = "percentageByAverage",
}

export const DashboardUnitIntl = {
  "tonnes-of-co2e": "dashboard.unit.tonnes-of-co2e",
  $: "dashboard.unit.dollar",
  million$: "dashboard.unit.million-dollar",
  hour: "dashboard.unit.hour",
  kg: "dashboard.unit.kg",
  m2: "dashboard.unit.m2",
  m3: "dashboard.unit.m3",
  km2: "dashboard.unit.km2",
  mwh: "dashboard.unit.mwh",
  others: "dashboard.unit.others",
  people: "dashboard.unit.people",
  tonnes: "dashboard.unit.tonnes",
  product: "dashboard.unit.product",
  "million-pieces": "dashboard.unit.million-pieces",
  hkd: "dashboard.unit.hkd",
  rmb: "dashboard.unit.rmb",
  usd: "dashboard.unit.usd",
};
