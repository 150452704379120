import React, { createContext, useState } from "react";

import { i18n, Language } from "i18n";
import { IntlProvider } from "react-intl";

const localStorageLangKey = "avista-iesg-lang";

export const LanguageContext = createContext<{ locale: Language; setLocale: (value: Language) => void }>({
  locale: Language.Eng,
  setLocale: () => undefined,
});

export const LanguageProvider: React.FC = ({ children }) => {
  const cachedLanguage = (localStorage.getItem(localStorageLangKey) as Language) ?? Language.Eng;

  const [locale, setLocale] = useState(cachedLanguage);

  const handleSetLocale = (newLocale: Language) => {
    setLocale(newLocale);
    localStorage.setItem(localStorageLangKey, newLocale);
  };

  return (
    <IntlProvider locale={locale} defaultLocale={Language.Eng} messages={i18n[locale]}>
      <LanguageContext.Provider value={{ locale, setLocale: handleSetLocale }}>{children}</LanguageContext.Provider>
    </IntlProvider>
  );
};
