import moment, { Moment, MomentInput } from "moment";

export enum DateFormat {
  Date = "DD/MM/YYYY",
  DateTime = "DD/MM/YYYY HH:mm:ss",
  DigitOnlyDate = "YYYYMMDD",
  YearOnly = "YYYY",
  YearOnlyShort = "YY",
}

export const defaultMoment = moment("01/01/1970", DateFormat.Date);

export const toDateString = (date: MomentInput, format: DateFormat): string => {
  return moment(date).format(format);
};

export const tryParseDateString = (date: MomentInput, format: DateFormat, fallback = ""): string => {
  const momentDate = moment(date);

  return momentDate.isValid() ? momentDate.format(format) : fallback;
};

export const toMoment = (date: MomentInput, format?: DateFormat): Moment => {
  return format ? moment(date, format) : moment(date);
};

export const toDate = (date: MomentInput, format?: DateFormat): Date => {
  return format ? moment(date, format).toDate() : moment(date).toDate();
};

export const isValidDateFormat = (value: MomentInput, format: DateFormat): boolean => {
  return moment(value, format).isValid();
};

export const changeDateFormat = (value: string, oldFormat: DateFormat, newFormat: DateFormat): string => {
  if (!isValidDateFormat(value, oldFormat)) return value;
  return moment(value, oldFormat).format(newFormat);
};

export const getDateDiffInDays = (from: MomentInput, to: MomentInput): number => {
  return moment(to).diff(from, "days");
};
