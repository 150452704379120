import React, { useContext } from "react";

import ErrorIcon from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";
import { StepLabelProps } from "@mui/material/StepLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { StepperStyleList } from "components/stepper-style-list";
import { Role } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { environmentalSteppers, socialSteppers } from "./data";
import { StepperItem } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  button: {
    marginTop: spacing(),
    marginRight: spacing(),
  },
  actionsContainer: {
    marginBottom: spacing(2),
  },
}));

export type ReportStepperProps = {
  offset: number;
  limit: number;
  indexes: string[];
  successfulIndex: string[];
  lockedIndex: Record<string, boolean>;
  setLockedIndex: (key: string, checked: boolean) => void;
  type: "env" | "social";
};

export const ReportStepper: React.FC<ReportStepperProps> = (props) => {
  const { offset, limit, indexes, successfulIndex, lockedIndex, setLockedIndex, type } = props;

  const { classes } = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { me } = useContext(AuthContext);

  const getSteppers = () => {
    if (!me.role) return [];
    if (me.role === Role.ClientStaff && indexes.length === 0) return [];

    let result: StepperItem[] = [];
    const stepperBase = type === "env" ? environmentalSteppers : socialSteppers;
    result = stepperBase.filter((s) => indexes.includes(s.key + ""));

    if (offset > 0) result = result.slice(offset);
    if (limit !== 0) result = result.slice(0, limit);

    return result;
  };

  const renderLabel = (item: StepperItem): { labelProps: StepLabelProps; labelContent: JSX.Element } => {
    switch (item.status) {
      case "complete":
      case "disable":
        return {
          labelProps: { StepIconProps: { completed: true, active: false } },
          labelContent: <>{item.label}</>,
        };
      case "error":
        return {
          labelProps: { error: true },
          labelContent: <>{item.label}</>,
        };
      default: {
        return {
          labelProps: { StepIconProps: { completed: true, active: false } },
          labelContent: (
            <Grid container padding={0} gap={0.5} alignItems="center">
              <Grid>
                <Typography display="inline" fontSize={14}>
                  {item.label && intl.formatMessage({ id: item.label })}
                </Typography>
              </Grid>
              {me.role === Role.ClientAdmin && lockedIndex[item.key] !== undefined && (
                <Grid>
                  {successfulIndex.includes(item.key) ? (
                    // checked = true -> index is unlocking
                    // checked = false -> index is locking
                    <Switch
                      checked={!props.lockedIndex[item.key]}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={(e) => setLockedIndex(item.key, !e.target.checked)}
                    />
                  ) : (
                    <ErrorIcon color="error" />
                  )}
                </Grid>
              )}
              {lockedIndex[item.key] && (
                <Grid>
                  <span style={{ color: "red", marginLeft: "5px" }}>
                    {intl.formatMessage({ id: "excel.stepper.lock" })}
                  </span>
                </Grid>
              )}
            </Grid>
          ),
        };
      }
    }
  };

  const renderContent = (item: StepperItem) => (
    <>
      {item.errMsg && <Typography>{item.errMsg}</Typography>}
      <div className={classes.actionsContainer}>
        {item.status !== "disable" && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(item.path)}
            className={classes.button}
            disabled={me.role === Role.ClientStaff && lockedIndex[item.key]}
          >
            {intl.formatMessage({ id: "button.stepper.go" })}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <StepperStyleList
      items={getSteppers().map((stepper) => {
        const { labelProps, labelContent } = renderLabel(stepper);
        return {
          key: stepper.label,
          labelProps,
          labelContent,
          content: renderContent(stepper),
        };
      })}
    />
  );
};
