import { useCallback, useEffect, useMemo, useRef } from "react";

import { EventEmitter } from "events";

import { EventAction } from "generated/graphql";
import { CommonReportRecordRow } from "utils/interfaces";
import { DataGridRow } from "..";

export interface DataGridEventItem {
  action: EventAction;
  data?: any;
}

export interface DataGridEvents {
  [key: string]: DataGridEventItem;
}

export type UseEventReturns = {
  events: DataGridEvents;
  emitCreateEvent: (data: DataGridRow) => void;
  emitUpdateEvent: (data: DataGridRow[]) => void;
  emitDeleteEvent: (data: DataGridRow) => void;
  resetEvents: () => void;
  removeEvents: (eventIds: string[]) => void;
};

/**
 * Record change of data
 *
 * @returns
 * events:
 *
 *  key: row ID
 *
 *  value: {
 *
 *    action: event action (CREATE/CHANGE/UPDATE/DELETE)
 *
 *    updated: row data
 *
 * }
 */
export const useEvent = (): UseEventReturns => {
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const events = useRef<DataGridEvents>({});

  const resetEvents = () => {
    events.current = {};
  };

  const removeEvents = (eventIds: string[]) => {
    const source = { ...events.current };
    for (const id of eventIds) {
      if (source[id]) delete source[id];
    }

    events.current = source;
  };

  const emitCreateEvent = (data: DataGridRow) => {
    eventEmitter.emit(EventAction.Create, data.rowId, data);
  };

  const emitUpdateEvent = (data: DataGridRow[]) => {
    eventEmitter.emit(
      EventAction.Update,
      data.map((d) => d.rowId),
      data
    );
  };

  const emitDeleteEvent = (data: DataGridRow) => {
    eventEmitter.emit(EventAction.Delete, data.rowId, data);
  };

  const createHandler = useCallback((eventId: string, data: CommonReportRecordRow) => {
    const source = { ...events.current };

    source[eventId] = {
      action: EventAction.Create,
      data: data,
    };

    events.current = source;
  }, []);

  const changeHandler = useCallback((eventIds: string[], data: CommonReportRecordRow[]) => {
    const source = { ...events.current };
    for (let index = 0; index < eventIds.length; index++) {
      const eventId = eventIds[index];
      if (eventId === "") continue;

      let action = EventAction.Update;
      if (source[eventId]?.action === EventAction.Create || source[eventId]?.action === EventAction.Delete) {
        action = source[eventId].action;
      } else {
        action = data[index].originalCompanyId !== data[index].companyId ? EventAction.Change : EventAction.Update;
      }

      source[eventId] = {
        action,
        data: data[index],
      };
    }

    events.current = source;
  }, []);

  const deleteHandler = useCallback((eventId: string, data: CommonReportRecordRow) => {
    const source = { ...events.current };

    if (data.recordId) {
      // Delete record existing in DB
      source[eventId] = {
        action: EventAction.Delete,
        data: data,
      };
    } else {
      // Delete new created record
      delete source[eventId];
    }

    events.current = source;
  }, []);

  useEffect(() => {
    eventEmitter.removeAllListeners();
    eventEmitter.on(EventAction.Create, createHandler);
    eventEmitter.on(EventAction.Update, changeHandler);
    eventEmitter.on(EventAction.Delete, deleteHandler);
  }, [eventEmitter, createHandler, changeHandler, deleteHandler]);

  return {
    events: events.current,
    emitCreateEvent,
    emitUpdateEvent,
    emitDeleteEvent,
    resetEvents,
    removeEvents,
  };
};
