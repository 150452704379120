import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetInjuryAndFatalitiesDataDocument,
  GetInjuryAndFatalitiesDataQuery,
  GetInjuryAndFatalitiesDataQueryVariables,
  SubmitInjuryAndFatalitiesDocument,
  SubmitInjuryAndFatalitiesMutation,
  SubmitInjuryAndFatalitiesMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getInjuryAndFatalitiesData: (
    variables: GetInjuryAndFatalitiesDataQueryVariables
  ) => Promise<GetInjuryAndFatalitiesDataQuery["project"]["injuryAndFatalities"]>;
  submitInjuryAndFatalities: (
    variables: SubmitInjuryAndFatalitiesMutationVariables
  ) => Promise<SubmitInjuryAndFatalitiesMutation["submitInjuryAndFatalities"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getInjuryAndFatalitiesData = useCallback(
    async (variables: GetInjuryAndFatalitiesDataQueryVariables) => {
      const response = await client.query<GetInjuryAndFatalitiesDataQuery, GetInjuryAndFatalitiesDataQueryVariables>({
        query: GetInjuryAndFatalitiesDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.injuryAndFatalities;
    },
    [client]
  );

  const submitInjuryAndFatalities = useCallback(
    async (variables: SubmitInjuryAndFatalitiesMutationVariables) => {
      const response = await client.mutate<
        SubmitInjuryAndFatalitiesMutation,
        SubmitInjuryAndFatalitiesMutationVariables
      >({
        mutation: SubmitInjuryAndFatalitiesDocument,
        variables,
      });

      return response.data?.submitInjuryAndFatalities ?? false;
    },
    [client]
  );

  return {
    getInjuryAndFatalitiesData,
    submitInjuryAndFatalities,
  };
};
