import React from "react";

import { Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export const NoProjectPage: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <>
      <Typography>{intl.formatMessage({ id: "error.project.no-exist" })}</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          history.push("/logout");
        }}
      >
        {intl.formatMessage({ id: "layout.logout" })}
      </Button>
    </>
  );
};
