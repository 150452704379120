import React from "react";

import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

export type BreadcrumbItem = {
  content: React.ReactChild;
  link?: string;
};

export type BreadcrumbsProps = MuiBreadcrumbsProps & {
  items: BreadcrumbItem[];
};

const useStyles = makeStyles()(() => ({
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { items, ...breadcrumbsProps } = props;

  const { classes } = useStyles();
  const history = useHistory();

  const renderLink = ({ content, link }: BreadcrumbItem, index: number) => (
    <Link key={index} color="inherit" onClick={() => history.push(link!)} className={classes.link}>
      {content}
    </Link>
  );

  const renderText = ({ content }: BreadcrumbItem, index: number) => (
    <Typography key={index} color="textPrimary">
      {content}
    </Typography>
  );

  return (
    <MuiBreadcrumbs {...breadcrumbsProps}>
      {items.map((item, index) => (item.link ? renderLink(item, index) : renderText(item, index)))}
    </MuiBreadcrumbs>
  );
};
