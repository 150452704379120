import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { Helmet } from "react-helmet";
import "static/scss/index.scss";

ReactDOM.render(
  <>
    <Helmet>
      <title>Avista App</title>
    </Helmet>
    <App />
  </>,
  document.getElementById("root")
);
