import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newProductResponsibilityPolicyRow } from "./consts";
import { useActions } from "./hook/action";
import { ProductResponsibilityPolicyRecordRow } from "./types";

const source = IndexType.ProductResponsibilityPolicy;

export const ProductResponsibilityPolicyPage: React.FC = () => {
  const [initRows, setInitRows] = useState<ProductResponsibilityPolicyRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "practicesDesc",
        name: intl.formatMessage({ id: "excel.productResponsibilityPolicy.practicesDesc" }),
        width: 330,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "practicesDesc"),
      },
      {
        key: "qualityDesc",
        name: intl.formatMessage({ id: "excel.productResponsibilityPolicy.qualityDesc" }),
        width: 300,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "qualityDesc"),
      },
      {
        key: "dataDesc",
        name: intl.formatMessage({ id: "excel.productResponsibilityPolicy.dataDesc" }),
        width: 330,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "dataDesc"),
      },
    ],
    [intl, companyOptions]
  );

  const introContent = intl.formatMessage({ id: "excel.productResponsibilityPolicy.guide" });
  const steps = getSteps(intl);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);
    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<ProductResponsibilityPolicyRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as ProductResponsibilityPolicyRecordRow[]);

    return Yup.array(
      Yup.object<Partial<ProductResponsibilityPolicyRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        practicesDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
        qualityDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
        dataDesc: Yup.string().required("error.labourResponsibilityPolicy.missing"),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newProductResponsibilityPolicyRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
