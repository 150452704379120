import React from "react";

import AddIcon from "@mui/icons-material/Add";
import CloneIcon from "@mui/icons-material/BookmarksOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SubmitIcon from "@mui/icons-material/DoneOutline";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

type RowActionButtonGroupProps = {
  isCreatable: boolean;
  onCreate: () => void;
  onClone: () => void;
  onDelete: () => void;
  onSubmit: () => void;
};

const useStyles = makeStyles()(({ palette }) => ({
  button: {
    marginLeft: 10,
  },
  deleteBtn: {
    color: palette.error.main,
  },
}));

export const RowActionButtonGroup: React.FC<RowActionButtonGroupProps> = (props) => {
  const { onCreate, onClone, onDelete, onSubmit, isCreatable } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <>
      <Button variant="contained" color="primary" disabled={!isCreatable} onClick={onCreate}>
        <AddIcon />
        {intl.formatMessage({ id: "button.data-grid.add-row" })}
      </Button>
      <Button className={classes.button} variant="contained" color="primary" disabled={!isCreatable} onClick={onClone}>
        <CloneIcon />
        {intl.formatMessage({ id: "data-grid.tools.clone" })}
      </Button>
      <Button
        className={`${classes.button} ${classes.deleteBtn}`}
        variant="contained"
        color="primary"
        onClick={onDelete}
      >
        <DeleteIcon />
        {intl.formatMessage({ id: "data-grid.tools.delete" })}
      </Button>
      <ButtonGroup className={classes.button} variant="outlined" color="primary" style={{ float: "right" }}>
        <Button className="submit-button" variant="contained" color="primary" onClick={onSubmit}>
          <SubmitIcon />
          {intl.formatMessage({ id: "button.data-grid.submit" })}
        </Button>
      </ButtonGroup>
    </>
  );
};
