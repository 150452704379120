import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, MobileFuelParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { FuelType, newMobileFuelRow } from "../consts";
import { MobileFuelRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: MobileFuelRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getMobileFuelData, submitMobileFuel } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const mobileFuel = await getMobileFuelData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = mobileFuel.flatMap((m) =>
          m.records.map<MobileFuelRecordRow>((r) => ({
            ...newMobileFuelRow,
            recordId: r.id,
            originalCompanyId: m.projectCompany.id,
            companyId: m.projectCompany.id,
            remarks: r.remarks,
            transportationOwnership: r.transportationOwnership,
            transportationLicense: r.transportationLicense,
            transportationType: r.transportationType,
            fuelType: r.fuelType,
            fuelUnit: r.fuelUnit,
            fuelConsumption: r.fuelConsumption,
            startDate: tryParseDateString(r.startDate, DateFormat.Date),
            endDate: tryParseDateString(r.endDate, DateFormat.Date),
            yearBeginDistanceTravelled: r.yearBeginDistanceTravelled,
            yearEndDistanceTravelled: r.yearEndDistanceTravelled,
            estimatedDistanceTravelled:
              r.fuelType === FuelType.Electric ? 0 : r.yearEndDistanceTravelled - r.yearBeginDistanceTravelled,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getMobileFuelData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<MobileFuelParam>((e) => {
          const data = e.data as DataGridRow<MobileFuelRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              transportationOwnership: data.transportationOwnership,
              transportationLicense: data.transportationLicense,
              transportationType: data.transportationType,
              fuelType: data.fuelType,
              fuelUnit: data.fuelUnit,
              fuelConsumption: data.fuelConsumption,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
              yearBeginDistanceTravelled: +data.yearBeginDistanceTravelled,
              yearEndDistanceTravelled: +data.yearEndDistanceTravelled,
            },
          };
        });

        return await submitMobileFuel({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitMobileFuel]
  );

  return { getData, submitData };
};
