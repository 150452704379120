import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, Nf1Param, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { Availability, newNf1Row } from "../consts";
import { Nf1RecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: Nf1RecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getNf1Data, submitNf1 } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const nf1 = await getNf1Data({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = nf1.flatMap((n) =>
          n.records.map<Nf1RecordRow>((r) => ({
            ...newNf1Row,
            recordId: r.id,
            originalCompanyId: n.projectCompany.id,
            companyId: n.projectCompany.id,
            remarks: r.remarks,
            typeOfProperty: r.typeOfProperty,
            useOfProperty: r.useOfProperty,
            address: r.address,
            grossFloorArea: r.grossFloorArea,
            grossFloorUnit: r.grossFloorUnit,
            leaseEndDate: tryParseDateString(r.leaseEndDate, DateFormat.Date),
            leaseStartDate: tryParseDateString(r.leaseStartDate, DateFormat.Date),
            water: r.water ? "true" : "false",
            energy: r.energy ? "true" : "false",
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getNf1Data]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<Nf1Param>((e) => {
          const data = e.data as DataGridRow<Nf1RecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              address: data.address,
              energy: data.energy === Availability.Available,
              grossFloorUnit: data.grossFloorUnit,
              leaseEndDate: toDate(data.leaseEndDate, DateFormat.Date),
              leaseStartDate: toDate(data.leaseStartDate, DateFormat.Date),
              grossFloorArea: data.grossFloorArea,
              typeOfProperty: data.typeOfProperty,
              useOfProperty: data.useOfProperty,
              water: data.water === Availability.Available,
            },
          };
        });

        return await submitNf1({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitNf1]
  );

  return { getData, submitData };
};
