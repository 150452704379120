import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import NoSsr from "@mui/material/NoSsr";
import { BrowserRouter as Router } from "react-router-dom";

import { Layout } from "layout";
import { AvistaProvider } from "provider";
import { AuthProvider } from "provider/auth";
import { SSEProvider } from "provider/sse";
import { AppRouter } from "router";

export const App: React.FC = () => (
  <AvistaProvider>
    <SSEProvider>
      <CssBaseline />
      <NoSsr>
        <Router>
          <AuthProvider>
            <Layout>
              <AppRouter />
            </Layout>
          </AuthProvider>
        </Router>
      </NoSsr>
    </SSEProvider>
  </AvistaProvider>
);
