import { EmissionFactorCategory } from "generated/graphql";
import { EmissionFactorEditPage } from "pages/emission-factor-edit";
import { EmissionFactorEditDataPage } from "pages/emission-factor-edit-data";
import { DynamicRoute, DynamicRouteProps } from "../dynamic-route";

export const ROUTE_PATH: Record<EmissionFactorCategory, string> = {
  [EmissionFactorCategory.NoxMobileCombustion]: "/nox-mobile-combustion",
  [EmissionFactorCategory.NoxStationaryCombustion]: "/nox-stationary-combustion",
  [EmissionFactorCategory.SoxMobileCombustion]: "/sox-mobile-combustion",
  [EmissionFactorCategory.SoxStationaryCombustion]: "/sox-stationary-combustion",
  [EmissionFactorCategory.PmMobileCombustion]: "/pm-mobile-combustion",
  [EmissionFactorCategory.PmStationaryCombustion]: "/pm-stationary-combustion",
  [EmissionFactorCategory.Co2EmissionStationary]: "/co2-emission-stationary",
  [EmissionFactorCategory.Ch4EmissionStationary]: "/ch4-emission-stationary",
  [EmissionFactorCategory.N2OEmissionStationary]: "/n2o-emission-stationary",
  [EmissionFactorCategory.Co2EmissionMobile]: "/co2-emission-mobile",
  [EmissionFactorCategory.Ch4EmissionMobile]: "/ch4-emission-mobile",
  [EmissionFactorCategory.N2OEmissionMobile]: "/n2o-emission-mobile",
  [EmissionFactorCategory.GhgRemoval]: "/ghg-removal",
  [EmissionFactorCategory.PurchasedTowngas]: "/purchased-towngas",
  [EmissionFactorCategory.PurchasedElectricityEmission]: "/purchased-electricity-emission",
  [EmissionFactorCategory.PurchasedWater]: "/purchased-water",
  [EmissionFactorCategory.PurchasedChillingSystem]: "/purchased-chilling-system",
  [EmissionFactorCategory.PurchasedSteam]: "/purchased-steam",
  [EmissionFactorCategory.Ghg]: "/ghg",
  [EmissionFactorCategory.IndustrialDesignation]: "/industrial-designation",
};

const pathParam = Object.values(ROUTE_PATH)
  .map((path) => path.slice(1))
  .join("|");

const route: DynamicRouteProps[] = [
  {
    path: `/edit`,
    component: EmissionFactorEditPage,
    exact: true,
  },
  {
    path: `/edit/:categoryKey(${pathParam})`,
    component: EmissionFactorEditDataPage,
    exact: true,
  },
];

export const EmissionFactorRoute = DynamicRoute(route);
