import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import AlphaVistaLogoBlueCompact from "static/images/alpha-vista-logo-blue-compact.png";

const useStyle = makeStyles()(({ palette }) => ({
  titleContainer: {
    marginBottom: "36px",
    display: "flex",
    alignItems: "flex-start",
  },
  whiteSpace: {
    whiteSpace: "pre",
  },
  adminConsole: {
    color: palette.primary.main,
    fontWeight: "bold",
  },
  logoContainer: {
    display: "inline-block",
  },
  alphaVistaLogo: {
    height: "30px",
  },
}));

export type LandingPageTitleProps = {
  isAdmin: boolean;
};

export const LandingPageTitle: React.FC<LandingPageTitleProps> = ({ isAdmin }) => {
  const intl = useIntl();
  const { classes, cx } = useStyle();

  return (
    <h2 className={classes.titleContainer}>
      <span className={classes.whiteSpace}>{intl.formatMessage({ id: "landing-page.title" })}</span>
      <div className={classes.logoContainer}>
        <img src={AlphaVistaLogoBlueCompact} className={classes.alphaVistaLogo} />
      </div>
      {isAdmin && (
        <span className={cx(classes.adminConsole, classes.whiteSpace)}>
          {intl.formatMessage({ id: "landing-page.title.admin" })}
        </span>
      )}
      <span className={classes.whiteSpace}>{intl.formatMessage({ id: "landing-page.title.end" })}</span>
    </h2>
  );
};
