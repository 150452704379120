import { Scope3Category } from "generated/graphql";
import { Scope3DataFormFields } from "./types";

export const defaultFormFields: Scope3DataFormFields = {
  category: Scope3Category.FlightEmission,
  file: undefined,
};

export const orderedBusinessTravelCategories: Scope3Category[] = [
  Scope3Category.FlightEmission,
  Scope3Category.ChinaHighSpeedRailEmission,
  Scope3Category.TaxiEmission,
];

export const orderedUseOfSoldProductsCategories: Scope3Category[] = [Scope3Category.VehicleEmission];
