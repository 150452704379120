import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetEnvDashboardDataQuery } from "generated/graphql";
import { GridWithLoading, IntensityBlock, TotalBlock, YearlyComparisonBlock } from "..";
import { useDashboardHelper } from "../../hook/dashboard-helper";
import { getChartInput, getUnitIntlKey } from "../../utils";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100, DASHBOARD_BLOCK_HEIGHT_50 } from "../consts";
import { SectionHeader } from "../section-header";

export type WaterDashboardProps = {
  data: GetEnvDashboardDataQuery;
  currentYear: string;
  previousYear?: string;
  isLoading: boolean;
};

export const WaterDashboard: React.FC<WaterDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data.dashboardEnv;
  const { waterTotal, waterIntensity } = data.dashboardTarget ?? {};

  const intl = useIntl();

  const { total: totalHelper, intensity: intensityHelper } = useDashboardHelper({
    current: current.water,
    previous: previous?.water,
    nf1Label: "unit",
  });

  const targetIntensity = waterIntensity?.find(({ option }) => option === intensityHelper.current.option);

  const currentTotalInput = getChartInput({ name: currentYear, datum: totalHelper.current })!;
  const previousTotalInput = getChartInput({ name: previousYear, datum: totalHelper.previous });
  const targetTotalInput =
    waterTotal?.value && waterTotal.value > 0
      ? getChartInput({ name: intl.formatMessage({ id: "dashboard.common.target" }), datum: waterTotal })
      : undefined;

  const currentIntensityInput = getChartInput({ name: currentYear, datum: intensityHelper.current })!;
  const previousIntensityInput = getChartInput({ name: previousYear, datum: intensityHelper.previous });
  const targetIntensityInput =
    targetIntensity?.value && targetIntensity.value > 0
      ? getChartInput({ name: intl.formatMessage({ id: "dashboard.common.target" }), datum: targetIntensity })
      : undefined;

  return (
    <>
      {(isLoading || totalHelper.isVisible || intensityHelper.isVisible) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.env.water.title" })}
              description={intl.formatMessage({ id: "dashboard.env.water.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid xs={2}>
                <Grid container spacing={2}>
                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={totalHelper.isVisible}
                  >
                    <TotalBlock
                      title={intl.formatMessage({ id: "dashboard.env.water.total.title" })}
                      value={totalHelper.current.value}
                      unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                    />
                  </GridWithLoading>

                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={intensityHelper.isVisible}
                  >
                    <IntensityBlock
                      title={intl.formatMessage({ id: "dashboard.env.water.intensity.title" })}
                      value={intensityHelper.current.value}
                      unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                      valueColor={intensityHelper.color}
                      selectorProps={{
                        ...intensityHelper.nf1Select,
                        options: intensityHelper.nf1Select.options.map(({ label, value }) => ({
                          label: intl.formatMessage({ id: getUnitIntlKey(label) }),
                          value,
                        })),
                      }}
                    />
                  </GridWithLoading>
                </Grid>
              </Grid>

              <GridWithLoading
                xs={7}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={totalHelper.isVisible || intensityHelper.isVisible}
              >
                <YearlyComparisonBlock
                  title={intl.formatMessage({ id: "dashboard.common.yearly-comparison" })}
                  left={
                    totalHelper.isVisible
                      ? {
                          title: intl.formatMessage({ id: "dashboard.env.water.total.title" }),
                          main: currentTotalInput,
                          mainColor: totalHelper.color,
                          sub: previousTotalInput,
                          manual: targetTotalInput,
                          manualColor: DashboardColor.chart.blue1,
                          truncateBaseline: true,
                        }
                      : undefined
                  }
                  right={
                    intensityHelper.isVisible
                      ? {
                          title: intl.formatMessage({ id: "dashboard.env.water.intensity.title" }),
                          main: currentIntensityInput,
                          mainColor: intensityHelper.color,
                          sub: previousIntensityInput,
                          manual: targetIntensityInput,
                          manualColor: DashboardColor.chart.blue1,
                        }
                      : undefined
                  }
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
