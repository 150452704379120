import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100 } from "../consts";
import { StyledContainer } from "./styled-container";

export type TwoBlockContainerProps = {
  title?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
};

export const TwoBlockContainer: React.FC<TwoBlockContainerProps> = (props) => {
  const { left: leftComponent, right: rightComponent, title } = props;

  return (
    <StyledContainer>
      <Grid container height={DASHBOARD_BLOCK_HEIGHT_100} spacing={1} direction="column" wrap="nowrap">
        <Grid xs={12}>
          <Grid xs={12} textAlign="center">
            <Typography variant={"h4"} color={DashboardColor.text.grey} fontWeight="bold">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} flexGrow={3}>
          <Grid container gap={1} alignItems="center" justifyContent="center" wrap="nowrap">
            <Grid xs={6} height="inherit">
              {leftComponent}
            </Grid>
            <Grid xs={6} height="inherit">
              {rightComponent}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
