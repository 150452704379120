import React, { useMemo } from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Form, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { UploadPanel } from "components/upload-panel";
import { DataRecordRowItem, Scope3DataFormFields } from "../types";
import { DataRecordTable } from "./data-record-table";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    margin: spacing(2),
  },
  title: {
    marginBottom: spacing(2),
  },
  divider: {
    margin: spacing(2, 0),
  },
}));

export type Scope3DataFormProps = FormikProps<Scope3DataFormFields> & {
  data: DataRecordRowItem[];
};

export const Scope3DataForm: React.FC<Scope3DataFormProps> = (props) => {
  const { values, errors, touched, setFieldTouched, setFieldValue, data } = props;

  const fileName = useMemo(() => (values.file ? values.file.name : ""), [values.file]);

  const { classes } = useStyles();
  const intl = useIntl();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFieldTouched("file", true);
      setFieldValue("file", event.target.files[0]);
    }
  };

  return (
    <Form autoComplete="off" className={classes.root}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography className={classes.title} variant="h4">
            {intl.formatMessage({ id: "scope3.manage.title.uploaded-data" })}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <DataRecordTable rows={data} />
        </Grid>

        <Grid xs={12} className={classes.divider}>
          <Divider />
        </Grid>

        <Grid xs={12}>
          <Typography className={classes.title} variant="h4">
            {intl.formatMessage({ id: "scope3.manage.title.upload" })}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <UploadPanel
            fileName={fileName}
            onFileChange={handleFileChange}
            error={!!errors.file && touched.file}
            errorMessage={errors.file ? intl.formatMessage({ id: errors.file }) : ""}
            formatTips={intl.formatMessage({ id: "scope3.manage.upload.file-format" })}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
