import React, { useCallback, useContext, useEffect, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType, TailorMadeIndexField, TailorMadeIndexFieldType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { getApolloError } from "utils/apollo-error";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { basicValidationSchema } from "../utils/validation";
import { newOthersRow } from "./consts";
import { useActions } from "./hook/action";
import { OthersRecordRow } from "./types";

const source = IndexType.Others;

export const OthersPage: React.FC = () => {
  const [columns, setColumns] = useState<IColumnItem[]>([]);
  const [initRows, setInitRows] = useState<OthersRecordRow[]>([]);
  const [newRowData, setNewRowData] = useState<OthersRecordRow>({ ...newOthersRow });

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { getData, submitData } = useActions();

  const initColumns = useCallback(
    (tailorMadeIndexFields: Pick<TailorMadeIndexField, "fieldName" | "fieldType">[]) => {
      const columns: IColumnItem[] = [
        {
          key: "count",
          name: "",
          width: 80,
          frozen: true,
          isAction: true,
          formatter: Formatter,
        },
        {
          key: "companyId",
          name: intl.formatMessage({ id: "excel.common.company-name" }),
          editor: SelectEditor,
          options: companyOptions,
          resizable: true,
          width: 200,
          frozen: true,
          formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
        },
      ];

      const fieldsColumns = tailorMadeIndexFields.map<IColumnItem>((field) => {
        const column: IColumnItem = {
          key: field.fieldName!,
          name: field.fieldName!,
          width: 150,
          resizable: true,
          formatter: getFormatter(Formatter, field.fieldName!),
        };

        switch (field.fieldType) {
          case TailorMadeIndexFieldType.Number:
            column.editor = InputEditor;
            column.type = "number";
            break;
          case TailorMadeIndexFieldType.DateTime:
            column.editor = DateRangeEditor;
            column.dateRangeMetaData = {
              minDate,
              maxDate,
              startDateProperty: "startDate",
              endDateProperty: "endDate",
              selectsStart: false,
              selectsEnd: true,
              handleExcludeDays: [],
            };
            break;
          case TailorMadeIndexFieldType.String:
          default:
            column.editor = InputEditor;
            break;
        }
        return column;
      });
      columns.push(...fieldsColumns);

      setColumns(columns);
    },
    [companyOptions, intl, maxDate, minDate]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    try {
      setLoading(true);

      const companyIds = companyOptions.map(({ value }) => value);

      const { tailorMadeIndexFields, rows, newRow } = await getData(companyIds);

      initColumns(tailorMadeIndexFields);

      setNewRowData(newRow);
      setInitRows([...rows]);
    } catch (e) {
      const err = getApolloError(e);
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [companyOptions, setLoading, getData, initColumns]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<OthersRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as OthersRecordRow[]);

    return Yup.array(
      Yup.object<Partial<OthersRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newRowData}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
