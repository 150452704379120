import React, { PropsWithChildren } from "react";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "tss-react/mui";

import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100 } from "../consts";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    height: "100%",
    borderRadius: 16,
    overflow: "visible",
  },
  header: {
    padding: spacing(1.5, 2),
    backgroundColor: DashboardColor.background.grey,
  },
  content: {
    padding: spacing(1.5, 2, 2, 2),
  },
  greyText: {
    color: DashboardColor.text.grey,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export type BlockWithHeaderContainerProps = {
  header: string;
};

export const BlockWithHeaderContainer: React.FC<PropsWithChildren<BlockWithHeaderContainerProps>> = (props) => {
  const { header, children } = props;
  const { classes } = useStyles();

  return (
    <Card className={classes.root}>
      <Grid xs={12} className={classes.header}>
        <Typography variant="h5" className={`${classes.greyText} ${classes.bold}`}>
          {header}
        </Typography>
      </Grid>
      <Grid xs={12} height={DASHBOARD_BLOCK_HEIGHT_100 * 0.7} className={classes.content} flexGrow={3}>
        {children}
      </Grid>
    </Card>
  );
};
