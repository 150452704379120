import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EnergyConsumptionParam, EventAction, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, toDateString } from "utils/date-time";
import { newEnergyConsumptionRow } from "../consts";
import { EnergyConsumptionRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: EnergyConsumptionRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getEnergyConsumptionData, submitEnergyConsumption } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const energyConsumption = await getEnergyConsumptionData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = energyConsumption.flatMap((e) =>
          e.records.map<EnergyConsumptionRecordRow>((r) => ({
            ...newEnergyConsumptionRow,
            recordId: r.id,
            originalCompanyId: e.projectCompany?.id ?? "",
            companyId: e.projectCompany?.id ?? "",
            remarks: r.remarks!,
            address: r.address!,
            meterNumber: r.meterNumber!,
            powerSource: r.powerSource!,
            powerSourceSupplierLocation: r.powerSourceSupplierLocation!,
            startDate: r.startDate ? toDateString(r.startDate, DateFormat.Date) : "",
            endDate: r.endDate ? toDateString(r.endDate, DateFormat.Date) : "",
            energyConsumptionAmount: r.energyConsumptionAmount!,
            energyConsumptionUnit: r.energyConsumptionUnit!,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getEnergyConsumptionData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<EnergyConsumptionParam>((e) => {
          const data = e.data as DataGridRow<EnergyConsumptionRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              address: data.address,
              meterNumber: data.meterNumber,
              powerSource: data.powerSource,
              powerSourceSupplierLocation: data.powerSourceSupplierLocation,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
              energyConsumptionAmount: data.energyConsumptionAmount,
              energyConsumptionUnit: data.energyConsumptionUnit,
            },
          };
        });

        return await submitEnergyConsumption({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitEnergyConsumption]
  );

  return { getData, submitData };
};
