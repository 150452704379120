import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { CommunityInvestmentParam, EventAction, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { newCommunityInvestmentRow } from "../consts";
import { CommunityInvestmentRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: CommunityInvestmentRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getCommunityInvestmentData, submitCommunityInvestment } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const communityInvestments = await getCommunityInvestmentData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = communityInvestments.flatMap((c) =>
          c.records.map<CommunityInvestmentRecordRow>((r) => ({
            ...newCommunityInvestmentRow,
            recordId: r.id,
            originalCompanyId: c.projectCompany.id,
            companyId: c.projectCompany.id,
            remarks: r.remarks,
            policyDescription: r.policyDescription,
            organizer: r.organizer,
            date: r.date,
            eventDescription: r.eventDescription,
            resources: r.resources,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getCommunityInvestmentData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<CommunityInvestmentParam>((e) => {
          const data = e.data as DataGridRow<CommunityInvestmentRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              policyDescription: data.policyDescription,
              organizer: data.organizer,
              date: data.date,
              eventDescription: data.eventDescription,
              resources: data.resources,
            },
          };
        });

        return await submitCommunityInvestment({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitCommunityInvestment]
  );

  return { getData, submitData };
};
