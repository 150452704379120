import React, { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { getChartInput } from "pages/dashboard/utils";
import { formatNumberWithComma } from "utils/format";
import { OptionType } from "utils/interfaces";
import { isApproximatelyZero } from "utils/math";
import {
  GridWithLoading,
  MultiColumnBreakdownBarChartProps,
  MultiColumnBreakdownBlock,
  MultiColumnBreakdownPieChartProps,
  TotalBlock,
  TrainingIncludeResignSelect,
  TrainingTotalEmployeeBlock,
  YearlyComparisonBlock,
} from "..";
import { useSocialPercentageDataHelper } from "../../hook/social-percentage-data-helper";
import {
  DASHBOARD_BLOCK_HEIGHT_100,
  DASHBOARD_BLOCK_HEIGHT_50,
  SocialDashboardGenderColor,
  SocialDashboardGenderIntl,
  SocialDashboardGradeColor,
  SocialDashboardGradeIntl,
  SocialDashboardGradeShortIntl,
  TabPosition,
} from "../consts";
import { SectionHeader } from "../section-header";

export type WorkforceDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  previousYear: string;
  isLoading: boolean;
};

export const TrainingDashboard: React.FC<WorkforceDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { totalEmployees, totalHours } = data.current.training;

  const [isIncludeResign, setIsIncludeResign] = useState(true);

  const intl = useIntl();

  const genderByEmployeeHelper = useSocialPercentageDataHelper({ data: data.current.training.genderByEmployee });
  const genderByHourHelper = useSocialPercentageDataHelper({ data: data.current.training.genderByHour });
  const gradeByEmployeeHelper = useSocialPercentageDataHelper({ data: data.current.training.gradeByEmployee });
  const gradeByHourHelper = useSocialPercentageDataHelper({ data: data.current.training.gradeByHour });

  const options: OptionType[] = [
    { label: intl.formatMessage({ id: "dashboard.social.training.includeResign.option.yes" }), value: "true" },
    { label: intl.formatMessage({ id: "dashboard.social.training.includeResign.option.no" }), value: "false" },
  ];

  const currentTotalEmployeesInput = getChartInput({ name: currentYear, datum: data.current.training.totalEmployees })!;
  const currentTotalHoursInput = getChartInput({ name: currentYear, datum: data.current.training.totalHours })!;

  const previousTotalEmployeesInput = getChartInput({
    name: previousYear,
    datum: data.previous?.training.totalEmployees,
  });
  const previousTotalHoursInput = getChartInput({ name: previousYear, datum: data.previous?.training.totalHours });

  const getPercentageByEmployeeCharts = () => {
    const charts: MultiColumnBreakdownPieChartProps[] = [];

    if (genderByEmployeeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.gender.title" }),
        data: genderByEmployeeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value,
          color: SocialDashboardGenderColor[name],
        })),
        legendColumn: 2,
      });
    }

    if (gradeByEmployeeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.grade.title" }),
        data: gradeByEmployeeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeIntl[name] }),
          value,
          color: SocialDashboardGradeColor[name],
        })),
        legendColumn: 1,
      });
    }

    return charts;
  };

  const getAverageHourCharts = () => {
    const charts: MultiColumnBreakdownBarChartProps[] = [];

    if (genderByHourHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.gender.title" }),
        data: genderByHourHelper.data.map(({ name, averageWithResign, averageWithoutResign }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value: isIncludeResign ? averageWithResign : averageWithoutResign,
          color: SocialDashboardGenderColor[name],
          label: formatNumberWithComma(isIncludeResign ? averageWithResign : averageWithoutResign, 2),
        })),
        chartProps: {
          axisLabel: intl.formatMessage({ id: "dashboard.social.training.breakdown.byHour.axisLabel" }),
        },
      });
    }

    if (gradeByHourHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.grade.title" }),
        data: gradeByHourHelper.data.map(({ name, averageWithResign, averageWithoutResign }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeShortIntl[name] }),
          value: isIncludeResign ? averageWithResign : averageWithoutResign,
          color: SocialDashboardGradeColor[name],
          label: formatNumberWithComma(isIncludeResign ? averageWithResign : averageWithoutResign, 2),
        })),
        chartProps: {
          axisLabel: intl.formatMessage({ id: "dashboard.social.training.breakdown.byHour.axisLabel" }),
        },
      });
    }

    return charts;
  };

  const percentageByEmployeeCharts = getPercentageByEmployeeCharts();
  const averageHourCharts = getAverageHourCharts();

  return (
    <>
      {(isLoading || percentageByEmployeeCharts.length > 0 || averageHourCharts.length > 0) && (
        <>
          <Grid xs={12}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid flex={1}>
                <SectionHeader
                  title={intl.formatMessage({ id: "dashboard.social.training.title" })}
                  description={intl.formatMessage(
                    { id: "dashboard.social.training.description" },
                    { year: currentYear }
                  )}
                />
              </Grid>
              <Grid flex={4}>
                <TrainingIncludeResignSelect
                  isLoading={isLoading}
                  options={options}
                  isIncludeResign={isIncludeResign}
                  onChangeIsIncludeResign={setIsIncludeResign}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid xs={2}>
                <Grid container spacing={2}>
                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={!isApproximatelyZero(totalEmployees.value)}
                  >
                    <TrainingTotalEmployeeBlock isIncludeResign={isIncludeResign} current={totalEmployees} />
                  </GridWithLoading>

                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={!isApproximatelyZero(totalHours.value)}
                  >
                    <TotalBlock
                      title={intl.formatMessage({ id: "dashboard.social.training.totalTrainingHours.title" })}
                      value={totalHours.value}
                    />
                  </GridWithLoading>
                </Grid>
              </Grid>

              <GridWithLoading
                xs={5}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={!isApproximatelyZero(totalEmployees.value) || !isApproximatelyZero(totalHours.value)}
              >
                <YearlyComparisonBlock
                  title={intl.formatMessage({ id: "dashboard.common.yearly-comparison" })}
                  left={{
                    title: intl.formatMessage({ id: "dashboard.social.training.totalEmployeesTrained.title" }),
                    main: currentTotalEmployeesInput,
                    sub: previousTotalEmployeesInput,
                    truncateBaseline: true,
                  }}
                  right={{
                    title: intl.formatMessage({ id: "dashboard.social.training.totalTrainingHours.title" }),
                    main: currentTotalHoursInput,
                    sub: previousTotalHoursInput,
                    truncateBaseline: true,
                  }}
                />
              </GridWithLoading>

              <GridWithLoading
                xs={5}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={percentageByEmployeeCharts.length > 0 || averageHourCharts.length > 0}
              >
                <MultiColumnBreakdownBlock
                  mains={{
                    tabName: intl.formatMessage({ id: "dashboard.social.training.breakdown.mains.tab" }),
                    charts: percentageByEmployeeCharts,
                  }}
                  subs={{
                    tabName: intl.formatMessage({ id: "dashboard.social.training.breakdown.subs.tab" }),
                    chartType: "bar",
                    charts: averageHourCharts,
                  }}
                  tabPosition={TabPosition.Top}
                  legendProps={{
                    position: "bottom",
                    showPercentage: false,
                    showValue: false,
                  }}
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
