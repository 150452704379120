import React, { useContext, useEffect, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import {
  DataTable,
  FullScreenFormDialog,
  HeaderAddButton,
  StyledTableCell,
  useDebounceSearch,
  usePaginationMisc,
  useSort,
} from "components/react-table";
import { useDeleteDocumentMutation, useGetDocumentsLazyQuery, useUploadDocumentMutation } from "generated/graphql";
import { useDialog } from "hook";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { MIME_TYPES, SERVER } from "utils/const";
import { DateFormat, toDateString } from "utils/date-time";
import { DialogMode } from "utils/dialog";
import { Yup } from "utils/yup";
import { DocumentForm } from "./components/form";
import { initialValue } from "./consts";
import { DocFormFields, DocRowItem } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: spacing(1),
  },
  snackBar: {
    margin: spacing(1),
    width: "15%",
  },
  inlineFlex: {
    display: "inline-flex",
  },
}));

export const DocumentPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<DocRowItem[]>([]);

  const { classes } = useStyles();
  const intl = useIntl();
  const client = useApolloClient();
  const { me } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showUpdateSuccessMessage, showUpdateFailedMessage } = useContext(MessageContext);

  const { openDialog, Dialog: PopupDialog, DialogType } = useDialog();
  const [search, setSearch] = useDebounceSearch(() => {
    setPageIndex(0);
    getDataState.refetch({ keyword: search });
  });
  const {
    state: { pageSize, pageIndex, totalCount },
    setPageSize,
    setPageIndex,
    setTotalCount,
  } = usePaginationMisc();
  const { sortBy, setSortBy, orderBy } = useSort({ relation: { name: ["file", "fileName"] } });

  const [getData, getDataState] = useGetDocumentsLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ documentList: { data, count } }) => {
      const rows = data.map<DocRowItem>((doc) => ({
        id: doc.id,
        name: doc.file.originalName,
        desc: doc.desc,
        updatedAt: toDateString(doc.updatedAt, DateFormat.DateTime),
        download: doc.file.storageName,
      }));

      setData(rows);
      setTotalCount(count);
    },
  });

  const [uploadDocument, uploadDocumentState] = useUploadDocumentMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      showUpdateSuccessMessage();
      handleDialogClose();
      getDataState.refetch();
    },
    onError: () => showUpdateFailedMessage(),
  });

  const [deleteDocument, deleteDocumentState] = useDeleteDocumentMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      showUpdateSuccessMessage();
      getDataState.refetch();
    },
    onError: () => showUpdateFailedMessage(),
  });

  useEffect(() => {
    if (me.project) getData({ variables: { projectId: me.project.id, pageIndex, pageSize, keyword: search, orderBy } });

    // Ignore side-effect by 'search'
    // Changes of 'search' will be handled by useDebounceSearch hook
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [me.project, getData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    setLoading(getDataState.loading || uploadDocumentState.loading || deleteDocumentState.loading);
  }, [setLoading, getDataState.loading, uploadDocumentState.loading, deleteDocumentState.loading]);

  const handleDialogOpen = () => setIsOpen(true);
  const handleDialogClose = () => setIsOpen(false);

  const handleSubmitForm = (values: DocFormFields, setSubmitting: (value: boolean) => void) => {
    setSubmitting(true);

    uploadDocument({ variables: { desc: values.desc, file: values.file } });
    setSubmitting(false);
  };

  const validationSchema = Yup.object({
    desc: Yup.string().required(intl.formatMessage({ id: "errors.common.require" })),
    file: Yup.mixed()
      .required(intl.formatMessage({ id: "errors.document.file-required" }))
      .test("isValidExtension", "errors.document.file-format", function (value) {
        if (!value) return false;
        const fileMimeType = value.type;
        return MIME_TYPES.includes(fileMimeType);
      }),
  });

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: intl.formatMessage({ id: "header.document.doc-name" }),
      },
      {
        accessor: "desc",
        Header: intl.formatMessage({ id: "header.document.doc-desc" }),
      },
      {
        accessor: "updatedAt",
        Header: intl.formatMessage({ id: "header.document.doc-update-date" }),
      },
      {
        accessor: "download",
        Header: "",
      },
    ],
    [intl]
  );

  const renderRow = (row, rowProps) => {
    const handleDelete = (id: string) => {
      openDialog({
        title: intl.formatMessage({ id: "delete.document.title" }),
        content: intl.formatMessage({ id: "delete.document.content" }),
        type: DialogType.CONFIRM,
        onConfirm: () => deleteDocument({ variables: { documentId: id } }),
      });
    };

    return (
      <TableRow {...rowProps}>
        <TableCell>
          <div className={classes.inlineFlex}>
            <IconButton
              aria-label="Edit"
              className={classes.fab}
              onClick={() => handleDelete(row.original.id)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
        {row.cells.map((cell) => {
          const Formatter = cell.render("Cell");
          return (
            <StyledTableCell key={cell.column.id} {...cell.getCellProps()}>
              {cell.column.id === "download" ? (
                <IconButton size="large" href={`${SERVER.STEPPER_DOC}/${Formatter}`}>
                  <DownloadIcon />
                </IconButton>
              ) : (
                Formatter
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        state={{ pageSize, pageIndex, totalCount }}
        goToPage={setPageIndex}
        setPageSize={(value) => {
          setPageSize(value);
          setPageIndex(0);
        }}
        renderRow={renderRow}
        HeaderActionComponent={<HeaderAddButton onClick={handleDialogOpen} />}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSortByChange={setSortBy}
      />
      <Formik<DocFormFields>
        initialValues={initialValue}
        onSubmit={(values, { setSubmitting }) => handleSubmitForm(values, setSubmitting)}
        enableReinitialize={true}
        validationSchema={validationSchema}
        render={(formikProps) => (
          <FullScreenFormDialog
            isOpen={isOpen}
            handleDialogClose={() => {
              handleDialogClose();
              formikProps.resetForm();
            }}
            handleSubmit={formikProps.submitForm}
            mode={DialogMode.CREATE}
            title={intl.formatMessage({ id: "dialog.title.document" })}
          >
            <DocumentForm {...formikProps} />
          </FullScreenFormDialog>
        )}
      />
      <PopupDialog />
    </>
  );
};
