import { RecordStatus } from "generated/graphql";
import { KeyRecord } from "utils/types";
import { OfficePaperUsageRecordRow } from "./types";

export const newOfficePaperUsageRow: OfficePaperUsageRecordRow = {
  recordId: "",
  companyId: "",
  openingBalance: 0,
  quantityPurchased: 0,
  endingBalance: 0,
  quantityRecycled: 0,
  quantityDisposed: 0,
  quantityUsed: 0,
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<OfficePaperUsageRecordRow, string> = {
  quantityDisposed: "tips.paper.quantity-disposed",
};
