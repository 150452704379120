import React from "react";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { withStyles } from "tss-react/mui";

type ButtonTooltipProps = TooltipProps & { fontSize?: number };

/* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
const MuiToolTip: React.FC<ButtonTooltipProps> = ({ fontSize, ...tooltipProps }) => <Tooltip {...tooltipProps} />;

export const ButtonTooltip = withStyles(MuiToolTip, ({ typography }, { fontSize }) => ({
  tooltip: {
    fontSize: typography.pxToRem(fontSize ?? 15),
  },
}));
