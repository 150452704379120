import React from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing }) => ({
  upload: {
    marginBottom: spacing(1),
  },
  uploadButtonText: {
    marginLeft: spacing(0.5),
  },
  fileName: {
    display: "inline-flex",
    alignItems: "center",
  },
  errorMsg: {
    color: "red",
  },
}));

export type UploadPanelProps = {
  fileName: string;
  onFileChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: boolean;
  errorMessage?: string;
  formatTips: string;
};

export const UploadPanel: React.FC<UploadPanelProps> = (props) => {
  const { fileName, onFileChange, error = false, errorMessage, formatTips: uploadTips } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <>
      <Grid container spacing={1} className={classes.upload}>
        <Grid>
          <Button variant="contained" color="primary" component="label">
            <CloudUploadIcon />
            <div className={classes.uploadButtonText}>{intl.formatMessage({ id: "data-grid.tools.file-upload" })}</div>
            <input type="file" id="file" name="file" onChange={onFileChange} style={{ display: "none" }} />
          </Button>
        </Grid>
        <Grid className={classes.fileName}>
          <Typography>{fileName}</Typography>
        </Grid>
      </Grid>
      {error && <div className={classes.errorMsg}>{errorMessage}</div>}
      <Typography variant="body2">{uploadTips}</Typography>
    </>
  );
};
