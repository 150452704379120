import React from "react";
import {
  Add as AddIcon,
  Bookmarks as CloneIcon,
  DeleteOutline as DeleteIcon,
  DoneOutline as SubmitIcon,
} from "@mui/icons-material";
import { GuideStep } from "components/data-grid/components/data-grid-container";
import { IntlShape } from "react-intl";

export const addButtonId = "add-row-button";
export const cloneButtonId = "clone-button";
export const deleteButtonId = "delete-button";
export const saveButtonId = "save-button";
export const submitButtonId = "submit-button";
export const reactGridContainerId = "react-grid-container";

export const getSteps = (intl: IntlShape): GuideStep[] => {
  const steps = [
    {
      content: <>{intl.formatMessage({ id: "excel.step.add-entry" }, { addIcon: <AddIcon /> })}</>,
      target: `#${addButtonId}`,
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "excel.step.complete-fields" })}</>,
      target: `#${reactGridContainerId}`,
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "excel.step.submit" }, { doneOutlineIcon: <SubmitIcon /> })}</>,
      target: `#${submitButtonId}`,
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "excel.step.clone" }, { cloneIcon: <CloneIcon /> })}</>,
      target: `#${cloneButtonId}`,
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "excel.step.delete" }, { deleteIcon: <DeleteIcon /> })}</>,
      target: `#${deleteButtonId}`,
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "excel.step.save" })}</>,
      target: `#${saveButtonId}`,
      disableBeacon: true,
    },
  ];

  return steps;
};
