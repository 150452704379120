import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetNonHazardousWasteDataDocument,
  GetNonHazardousWasteDataQuery,
  GetNonHazardousWasteDataQueryVariables,
  SubmitNonHazardousWasteDocument,
  SubmitNonHazardousWasteMutation,
  SubmitNonHazardousWasteMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getNonHazardousWasteData: (
    variables: GetNonHazardousWasteDataQueryVariables
  ) => Promise<GetNonHazardousWasteDataQuery["project"]["nonHazardousWaste"]>;
  submitNonHazardousWaste: (
    variables: SubmitNonHazardousWasteMutationVariables
  ) => Promise<SubmitNonHazardousWasteMutation["submitNonHazardousWaste"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getNonHazardousWasteData = useCallback(
    async (variables: GetNonHazardousWasteDataQueryVariables) => {
      const response = await client.query<GetNonHazardousWasteDataQuery, GetNonHazardousWasteDataQueryVariables>({
        query: GetNonHazardousWasteDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.nonHazardousWaste;
    },
    [client]
  );

  const submitNonHazardousWaste = useCallback(
    async (variables: SubmitNonHazardousWasteMutationVariables) => {
      const response = await client.mutate<SubmitNonHazardousWasteMutation, SubmitNonHazardousWasteMutationVariables>({
        mutation: SubmitNonHazardousWasteDocument,
        variables,
      });

      return response.data?.submitNonHazardousWaste ?? false;
    },
    [client]
  );

  return {
    getNonHazardousWasteData,
    submitNonHazardousWaste,
  };
};
