import React, { useCallback, useContext } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AvistaWhiteEnLogo } from "components/avista-white-en-logo";
import { LangSelect } from "./lang-select";
import { LayoutContext, LayoutContextProps } from "./layout-context";
import { title } from "./title";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  nav: {
    flexGrow: 1,
    padding: spacing(0, 3),
  },
  account: {
    "& span": {
      fontSize: "1.0rem",
    },
  },
  title: {
    marginLeft: spacing(),
    color: palette.primary.contrastText,
  },
  logout: {
    color: palette.primary.contrastText,
  },
  logoButton: {
    padding: 0,
  },
  whiteLogo: {
    margin: spacing(1),
    height: "3rem",
  },
}));

export const Header: React.FC = () => {
  const { classes, cx } = useStyles();
  const intl = useIntl();
  const { layoutClasses, handleDrawerOpen } = useContext<LayoutContextProps>(LayoutContext);
  const history = useHistory();
  const location = useLocation();

  const logout = useCallback(() => {
    history.push("/logout");
  }, [history]);

  return (
    <AppBar position="fixed" color="default" className={cx(layoutClasses.appBar)}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => {
            handleDrawerOpen();
          }}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Button
          className={classes.logoButton}
          onClick={() => {
            history.push("/");
          }}
        >
          <AvistaWhiteEnLogo className={classes.whiteLogo} />
        </Button>
        {
          <Typography className={classes.title} variant="h4">
            {title[location.pathname] && intl.formatMessage({ id: title[location.pathname] })}
          </Typography>
        }

        <div className={classes.nav}>{/*<Navigation />*/}</div>
        <div className={classes.account}>
          <LangSelect />
        </div>
        <div className={classes.account}>
          <Button onClick={logout}>
            <PersonRoundedIcon className={classes.logout} />
            <span className={classes.logout}>{intl.formatMessage({ id: "layout.logout" })}</span>
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};
