import { Scope3Category } from "generated/graphql";

export const Scope3CategoryIntl: Record<Scope3Category, string> = {
  [Scope3Category.FlightEmission]: "scope3.category.flight",
  [Scope3Category.VehicleEmission]: "scope3.category.vehicle",
  [Scope3Category.ChinaHighSpeedRailEmission]: "scope3.category.china-high-speed-rail",
  [Scope3Category.TaxiEmission]: "scope3.category.taxi",
};

export const placeholderIntlKey = "placeholder.none";
