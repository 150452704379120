import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetEnergyConsumptionDataDocument,
  GetEnergyConsumptionDataQuery,
  GetEnergyConsumptionDataQueryVariables,
  SubmitEnergyConsumptionDocument,
  SubmitEnergyConsumptionMutation,
  SubmitEnergyConsumptionMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getEnergyConsumptionData: (
    variables: GetEnergyConsumptionDataQueryVariables
  ) => Promise<GetEnergyConsumptionDataQuery["project"]["energyConsumption"]>;
  submitEnergyConsumption: (
    variables: SubmitEnergyConsumptionMutationVariables
  ) => Promise<SubmitEnergyConsumptionMutation["submitEnergyConsumption"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getEnergyConsumptionData = useCallback(
    async (variables: GetEnergyConsumptionDataQueryVariables) => {
      const response = await client.query<GetEnergyConsumptionDataQuery, GetEnergyConsumptionDataQueryVariables>({
        query: GetEnergyConsumptionDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.energyConsumption;
    },
    [client]
  );

  const submitEnergyConsumption = useCallback(
    async (variables: SubmitEnergyConsumptionMutationVariables) => {
      const response = await client.mutate<SubmitEnergyConsumptionMutation, SubmitEnergyConsumptionMutationVariables>({
        mutation: SubmitEnergyConsumptionDocument,
        variables,
      });

      return response.data?.submitEnergyConsumption ?? false;
    },
    [client]
  );

  return {
    getEnergyConsumptionData,
    submitEnergyConsumption,
  };
};
