import * as Yup from "yup";

import difference from "lodash/difference";
import { RecordStatus } from "generated/graphql";
import { range } from "lodash";
import { CommonReportRecordRow } from "utils/interfaces";
import { DataGridRow } from "../types";

export const updateErrorStatus = <T extends CommonReportRecordRow>(
  rows: T[],
  errors: Record<string, string>[]
): void => {
  for (let i = 0; i < rows.length; i++) {
    if (errors[i]) {
      rows[i].errors = { ...errors[i] };
      rows[i].recordStatus = RecordStatus.Failure;
    } else {
      rows[i].errors = {};
      rows[i].recordStatus = RecordStatus.Success;
    }
  }
};

export const validateRowsBySchema = async <T extends CommonReportRecordRow>(
  rows: T[],
  schema: Yup.Lazy
): Promise<{ hasError: boolean; errors: Record<string, string>[] }> => {
  const totalErrors: Record<string, string>[] = range(rows.length).map(() => ({}));
  let hasError = false;

  await schema.validate(rows, { abortEarly: false }).catch((e: Yup.ValidationError) => {
    e.inner.forEach((er) => {
      if (!er.errors[0]) return;

      const regexp = er.path.match(/\[(\d*)\]\.(.*)/);
      if (!regexp) return;
      const [, index, field] = regexp;

      totalErrors[index][field] = er.errors[0];
      hasError = true;
    });
  });

  return { hasError, errors: totalErrors };
};

export const isAllErrorRowsDisplaying = <T extends CommonReportRecordRow = CommonReportRecordRow>(
  rowsDisplay: DataGridRow<T>[],
  errorRows: DataGridRow<T>[]
) => {
  const displayingRowsId = rowsDisplay.map((r) => r.rowId);
  const errorRowsId = errorRows.map((r) => r.rowId);

  const errorRowsNotDisplaying = difference(errorRowsId, displayingRowsId);
  return errorRowsNotDisplaying.length === 0;
};
