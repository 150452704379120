import React, { useContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import {
  GetEnvDashboardDataQuery,
  useDownloadClientEnvironmentalKpiReportMutation,
  useGetEnvDashboardDataLazyQuery,
  useNotUploadedScope3CategoryLazyQuery,
} from "generated/graphql";
import { useDialog } from "hook";
import { AuthContext } from "provider/auth";
import { LanguageContext } from "provider/language";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { DateFormat, toDateString } from "utils/date-time";
import { downloadFile } from "utils/download";
import { formatDateWithTZ } from "utils/format";
import { Scope3CategoryIntl } from "utils/intl";
import {
  AirDashboard,
  EnergyDashboard,
  GhgDashboard,
  PackagingMaterialDashbaord,
  WasteDashboard,
  WaterDashboard,
} from ".";
import { ActionButtonGroup } from "../action-button-group";
import { dummyEnvrionmentalDashboardData } from "./consts";

export const EnvironmentalDashboard: React.FC = () => {
  const [data, setData] = useState<GetEnvDashboardDataQuery>({ dashboardEnv: dummyEnvrionmentalDashboardData });

  const intl = useIntl();
  const client = useApolloClient();
  const { me } = useContext(AuthContext);
  const { locale } = useContext(LanguageContext);
  const { setLoading } = useContext(LoadingContext);
  const { showMessage } = useContext(MessageContext);

  const { Dialog, openDialog, DialogType } = useDialog();

  const [getData, getDataState] = useGetEnvDashboardDataLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => setData(d),
  });

  const [getNotUploadedScope3Category, getNotUploadedScope3CategoryState] = useNotUploadedScope3CategoryLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
  });

  const [downloadClientReport, downloadClientReportState] = useDownloadClientEnvironmentalKpiReportMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ generateClientEnvironmentalKpiReport: downloadUrl }) => downloadFile(downloadUrl),
    onError: () => showMessage({ message: intl.formatMessage({ id: "error.common" }), variant: "error" }),
  });

  useEffect(() => {
    if (me.project) getData({ variables: { projectId: me.project.id } });
  }, [me.project, getData]);

  useEffect(() => {
    setLoading(getDataState.loading || downloadClientReportState.loading || getNotUploadedScope3CategoryState.loading);
  }, [setLoading, getDataState.loading, downloadClientReportState.loading, getNotUploadedScope3CategoryState.loading]);

  const currentYear = getDataState.loading
    ? "-"
    : toDateString(data.dashboardEnv.current.project.endDate, DateFormat.YearOnly);
  const previousYear =
    getDataState.loading || !data.dashboardEnv.previous
      ? "-"
      : toDateString(data.dashboardEnv.previous.project.endDate, DateFormat.YearOnly);

  const lastUpdate = getDataState.loading ? "-" : formatDateWithTZ(new Date(data.dashboardEnv.current.updatedAt));

  const handleRefesh = () => {
    if (me.project) getData({ variables: { projectId: me.project.id, refresh: true } });
  };

  const handleDownload = async () => {
    if (me.project) {
      const res = await getNotUploadedScope3Category({ variables: { projectId: me.project.id } });
      const isAllCategoriesHaveData = res.data?.notUploadedScope3Category.length === 0;

      if (isAllCategoriesHaveData) {
        downloadClientReport({
          variables: {
            projectCode: me.project.code,
            language: locale,
          },
        });
      } else {
        openDialog({
          content: intl.formatMessage(
            { id: "error.scope3.missing-category" },
            {
              page: intl.formatMessage({ id: "layout.menu.admin.scope3" }),
              category: res.data?.notUploadedScope3Category
                .map((c) => intl.formatMessage({ id: Scope3CategoryIntl[c] }))
                .join(", "),
            }
          ),
          type: DialogType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <ActionButtonGroup onDownload={handleDownload} onRefresh={handleRefesh} lastUpdate={lastUpdate} />

        <GhgDashboard
          data={data}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
        <AirDashboard
          data={data}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
        <EnergyDashboard
          data={data}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
        <WaterDashboard
          data={data}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
        <WasteDashboard
          data={data}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
        <PackagingMaterialDashbaord
          data={data.dashboardEnv}
          currentYear={currentYear}
          previousYear={previousYear}
          isLoading={getDataState.loading}
        />
      </Grid>

      <Dialog />
    </>
  );
};
