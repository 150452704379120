import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetWorkforceDataDocument,
  GetWorkforceDataQuery,
  GetWorkforceDataQueryVariables,
  SubmitWorkforceDocument,
  SubmitWorkforceMutation,
  SubmitWorkforceMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getWorkforceData: (
    variables: GetWorkforceDataQueryVariables
  ) => Promise<GetWorkforceDataQuery["project"]["workforce"]>;
  submitWorkforce: (variables: SubmitWorkforceMutationVariables) => Promise<SubmitWorkforceMutation["submitWorkforce"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getWorkforceData = useCallback(
    async (variables: GetWorkforceDataQueryVariables) => {
      const response = await client.query<GetWorkforceDataQuery, GetWorkforceDataQueryVariables>({
        query: GetWorkforceDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.workforce;
    },
    [client]
  );

  const submitWorkforce = useCallback(
    async (variables: SubmitWorkforceMutationVariables) => {
      const response = await client.mutate<SubmitWorkforceMutation, SubmitWorkforceMutationVariables>({
        mutation: SubmitWorkforceDocument,
        variables,
      });

      return response.data?.submitWorkforce ?? false;
    },
    [client]
  );

  return {
    getWorkforceData,
    submitWorkforce,
  };
};
