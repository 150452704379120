import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetEnvDashboardDataQuery } from "generated/graphql";
import { EmissionBlock, GridWithLoading } from "..";
import { getChartInput, getColorByValueDiff, getUnitIntlKey } from "../../utils";
import { DASHBOARD_BLOCK_HEIGHT_80 } from "../consts";
import { SectionHeader } from "../section-header";

export type AirDashboardProps = {
  data: GetEnvDashboardDataQuery;
  currentYear: string;
  previousYear?: string;
  isLoading: boolean;
};

export const AirDashboard: React.FC<AirDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data.dashboardEnv;
  const { nox, sox, pm } = current.air;
  const { nox: pNox, sox: pSox, pm: pPm } = previous?.air ?? {};

  const intl = useIntl();

  const isNoxVisible = nox.value > 0;
  const isSoxVisible = sox.value > 0;
  const isPmVisible = pm.value > 0;

  const currentNox = getChartInput({ name: currentYear, datum: nox })!;
  const previousNox = getChartInput({ name: previousYear, datum: pNox });
  const currentSox = getChartInput({ name: currentYear, datum: sox })!;
  const previousSox = getChartInput({ name: previousYear, datum: pSox });
  const currentPm = getChartInput({ name: currentYear, datum: pm })!;
  const previousPm = getChartInput({ name: previousYear, datum: pPm });

  return (
    <>
      {(isLoading || isNoxVisible || isSoxVisible || isPmVisible) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.env.air.title" })}
              description={intl.formatMessage({ id: "dashboard.env.air.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <GridWithLoading xs={3} height={DASHBOARD_BLOCK_HEIGHT_80} isLoading={isLoading} isVisible={isNoxVisible}>
                <EmissionBlock
                  header={intl.formatMessage({ id: "dashboard.env.air.nox.title" })}
                  description={intl.formatMessage({ id: "dashboard.env.air.emissions" })}
                  unit={intl.formatMessage({ id: getUnitIntlKey(nox.unit) })}
                  main={currentNox}
                  mainColor={getColorByValueDiff(currentNox.value, previousNox?.value)}
                  sub={previousNox}
                />
              </GridWithLoading>

              <GridWithLoading xs={3} height={DASHBOARD_BLOCK_HEIGHT_80} isLoading={isLoading} isVisible={isSoxVisible}>
                <EmissionBlock
                  header={intl.formatMessage({ id: "dashboard.env.air.sox.title" })}
                  description={intl.formatMessage({ id: "dashboard.env.air.emissions" })}
                  unit={intl.formatMessage({ id: getUnitIntlKey(sox.unit) })}
                  main={currentSox}
                  mainColor={getColorByValueDiff(currentSox.value, previousSox?.value)}
                  sub={previousSox}
                />
              </GridWithLoading>

              <GridWithLoading xs={3} height={DASHBOARD_BLOCK_HEIGHT_80} isLoading={isLoading} isVisible={isPmVisible}>
                <EmissionBlock
                  header={intl.formatMessage({ id: "dashboard.env.air.pm.title" })}
                  description={intl.formatMessage({ id: "dashboard.env.air.emissions" })}
                  unit={intl.formatMessage({ id: getUnitIntlKey(pm.unit) })}
                  main={currentPm}
                  mainColor={getColorByValueDiff(currentPm.value, previousPm?.value)}
                  sub={previousPm}
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
