import React, { useContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { IntroModal } from "components/intro-modal";
import {
  IndexType,
  ProjectCompany,
  Role,
  useGetDatasheetIndicesLazyQuery,
  useSubmitLockedIndexMutation,
} from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { ReportStepper } from "../components/stepper";

export const StepperPage: React.FC = () => {
  const [indexes, setIndexes] = useState<IndexType[]>([]);
  const [successfulIndex, setSuccessfulIndex] = useState<IndexType[]>([]);
  const [lockedIndexMapping, setLockedIndexMapping] = useState<Record<string, boolean>>({});

  const intl = useIntl();
  const { me, refetchToken } = useContext(AuthContext);
  const { loading } = useContext(LoadingContext);

  const client = useApolloClient();
  const [getDatasheetIndices, getDatasheetIndicesState] = useGetDatasheetIndicesLazyQuery({
    client,
    onCompleted: (d) => {
      setSuccessfulIndex(d.project.successfulIndex);

      let companies: ProjectCompany[] = [];
      if (Role.ClientAdmin === me.role) {
        companies = d.project.projectCompany as ProjectCompany[];
      } else if (d.permission?.projectCompanies) {
        companies = d.permission.projectCompanies as ProjectCompany[];
      }

      // Make sure NF1, Water & Energy always exist
      const indices = Array.from(
        new Set([
          ...companies.flatMap(({ indexes }) => indexes),
          IndexType.NormalizationFactor1,
          IndexType.WaterConsumption,
          IndexType.EnergyConsumption,
        ])
      );

      setIndexes(indices);
      setLockedIndexMapping(
        indices.reduce((t, i) => {
          t[i] = d.project.lockedIndex.includes(i);
          return t;
        }, {})
      );
    },
  });

  const [submitLockedIndex] = useSubmitLockedIndexMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      const newLockedIndexMapping = { ...lockedIndexMapping };
      Object.keys(newLockedIndexMapping).forEach((k) => {
        newLockedIndexMapping[k] = d.updateLockedIndex.includes(k as IndexType);
      });
      setLockedIndexMapping(newLockedIndexMapping);
    },
  });

  useEffect(() => {
    if (me.project?.id && me.user?.id && !getDatasheetIndicesState.called) {
      getDatasheetIndices({
        variables: {
          projectId: me.project.id,
          userId: me.user.id,
        },
      });
    }
  }, [me.project?.id, me.user?.id, getDatasheetIndices, getDatasheetIndicesState.called]);

  const handleSetLockedIndex = (name: string, isLocked: boolean) => {
    const newLockedIndex = Object.keys(lockedIndexMapping).filter((s) => {
      if (s === name) return isLocked;
      return lockedIndexMapping[s];
    });
    submitLockedIndex({ variables: { projectId: me.project!.id, lockedIndex: newLockedIndex as IndexType[] } });
  };

  const handleClickLockAll = () => {
    const newLockedIndex = indexes.filter((i) => successfulIndex.includes(i));
    submitLockedIndex({ variables: { projectId: me.project!.id, lockedIndex: newLockedIndex as IndexType[] } });
    refetchToken();
  };

  const renderGuideHtml = (id: string) => (
    <IntroModal>
      {intl.formatMessage(
        { id },
        {
          br: <br />,
          // render MUI component directly on the guide
          // set empty function to onClick to prevent default behaviours
          checkedIcon: <Switch checked />,
          notCheckedIcon: <Switch checked={false} onClick={() => undefined} />,
        }
      )}
    </IntroModal>
  );

  const renderGuide = () => {
    if (loading) return "";

    if (me.role === Role.ClientAdmin) {
      return renderGuideHtml("stepper.guide.admin");
    } else {
      if (indexes.length === 0) {
        return (
          <Typography variant="h6" paddingTop={2.5} paddingBottom={1.25}>
            {intl.formatMessage({ id: "stepper.guide.staff" })}
          </Typography>
        );
      } else {
        return renderGuideHtml("stepper.guide.staff");
      }
    }
  };

  return (
    <DatasheetPaper>
      <Grid container justifyContent="space-between">
        <Grid xs={6}>
          {renderGuide()}
          {me.role === Role.ClientAdmin && (
            <Button variant="contained" color="primary" onClick={handleClickLockAll}>
              <LockIcon />
              {intl.formatMessage({ id: "excel.lock.all" })}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid xs={6}>
          <Typography variant="h4">---{intl.formatMessage({ id: "stepper.type.env" })}---</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="h4">---{intl.formatMessage({ id: "stepper.type.social" })}---</Typography>
        </Grid>
        {indexes.length > 0 && (
          <>
            <Grid xs={3}>
              <ReportStepper
                offset={0}
                limit={7}
                indexes={indexes}
                lockedIndex={lockedIndexMapping}
                setLockedIndex={handleSetLockedIndex}
                successfulIndex={successfulIndex}
                type={"env"}
              />
            </Grid>
            <Grid xs={3}>
              <ReportStepper
                offset={7}
                limit={8}
                indexes={indexes}
                lockedIndex={lockedIndexMapping}
                setLockedIndex={handleSetLockedIndex}
                successfulIndex={successfulIndex}
                type={"env"}
              />
            </Grid>
            <Grid xs={3}>
              <ReportStepper
                offset={0}
                limit={5}
                indexes={indexes}
                lockedIndex={lockedIndexMapping}
                setLockedIndex={handleSetLockedIndex}
                successfulIndex={successfulIndex}
                type={"social"}
              />
            </Grid>
            <Grid xs={3}>
              <ReportStepper
                offset={5}
                limit={5}
                indexes={indexes}
                lockedIndex={lockedIndexMapping}
                setLockedIndex={handleSetLockedIndex}
                successfulIndex={successfulIndex}
                type={"social"}
              />
            </Grid>
          </>
        )}
      </Grid>
    </DatasheetPaper>
  );
};
