import { RecordStatus } from "generated/graphql";
import { OthersRecordRow } from "./types";

export const newOthersRow: OthersRecordRow = {
  recordId: "",
  companyId: "",
  errors: {},
  remarks: "",
  recordStatus: RecordStatus.Failure,
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
};
