import React from "react";
import FormatterDisplayValue, { FormatterDisplayValueProps } from "./formatter-display-value";

export type FormatterProps = Omit<FormatterDisplayValueProps, "answer"> & {
  value: string | number;
};

const Formatter: React.FC<FormatterProps> = (props) => {
  return <FormatterDisplayValue answer={props.value} {...props} />;
};

export default Formatter;
