import React from "react";

import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { FastFieldProps } from "formik";
import { DateFormat, toDate } from "utils/date-time";

export const DatePicker: React.FC<FastFieldProps> = ({ field, form, ...other }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createTheme()}>
      <MuiDatePicker
        name={field.name}
        value={toDate(field.value, DateFormat.Date)}
        format="DD/MM/YYYY"
        onError={(err) => {
          if (err) {
            form.setFieldError(field.name, `${err?.toString()}`);
          }
        }}
        onChange={(date) => {
          const momentDate = toDate(date, DateFormat.Date);
          form.setFieldValue(field.name, momentDate, true);
        }}
        {...other}
      />
    </ThemeProvider>
  </StyledEngineProvider>
);
