import React, { ElementRef, forwardRef, useImperativeHandle, useState } from "react";

import { ICellEditorReactComp } from "ag-grid-react";
import DatePicker from "react-datepicker";
import { makeStyles } from "tss-react/mui";

const DateEditorInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, ref) => (
  <input ref={ref} {...props} style={{ width: "100%", height: "100%", border: "none", padding: "2px" }} />
));

const useStyles = makeStyles()(() => ({
  datePickerWrapper: {
    backgroundColor: "white",
    display: "flex",
  },
}));

export type DateEditorProps = {
  onDateChanged: () => void;
};

export const DateEditor = React.forwardRef((props: DateEditorProps, ref) => {
  const [date, setDate] = useState<Date | null>(null);
  const { classes } = useStyles();

  useImperativeHandle<ElementRef<typeof DateEditor>, ICellEditorReactComp>(ref, () => ({
    getValue() {
      return date;
    },
  }));

  return (
    <DatePicker
      portalId={"root"}
      customInput={<DateEditorInput />}
      dateFormat={"dd/MM/yyyy"}
      popperClassName={"ag-custom-component-popup"}
      wrapperClassName={classes.datePickerWrapper}
      selected={date}
      onChange={(date) => setDate(date)}
    />
  );
});
