import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, RefrigerantAndFireParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newRefrigerantAndFireRow } from "../consts";
import { RefrigerantAndFireRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: RefrigerantAndFireRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getRefrigerantAndFireData, submitRefrigerantAndFire } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const refrigerantAndFire = await getRefrigerantAndFireData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = refrigerantAndFire.flatMap((re) =>
          re.records.map<RefrigerantAndFireRecordRow>((r) => ({
            ...newRefrigerantAndFireRow,
            recordId: r.id,
            originalCompanyId: re.projectCompany.id,
            companyId: re.projectCompany.id,
            remarks: r.remarks,
            refrigerantFireExtinguisher: r.refrigerantFireExtinguisher,
            ghgType: r.ghgType,
            openingBalance: r.openingBalance,
            quantityPurchased: r.quantityPurchased,
            quantityDisposed: r.quantityDisposed,
            endingBalance: r.endingBalance,
            quantityUsed: r.quantityUsed,
            startDate: tryParseDateString(r.startDate, DateFormat.Date),
            endDate: tryParseDateString(r.endDate, DateFormat.Date),
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getRefrigerantAndFireData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<RefrigerantAndFireParam>((e) => {
          const data = e.data as DataGridRow<RefrigerantAndFireRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              remarks: data.remarks,
              refrigerantFireExtinguisher: data.refrigerantFireExtinguisher,
              ghgType: data.ghgType,
              openingBalance: data.openingBalance,
              quantityPurchased: data.quantityPurchased,
              quantityDisposed: data.quantityDisposed,
              endingBalance: data.endingBalance,
              quantityUsed: data.quantityUsed,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
            },
          };
        });

        return await submitRefrigerantAndFire({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitRefrigerantAndFire]
  );

  return { getData, submitData };
};
