import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useIntl } from "react-intl";

export type VersionUpdateDialog = {
  reminder: boolean;
};

export const VersionUpdateDialog: React.FC<VersionUpdateDialog> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [remindTimeout, setRemindTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setOpen(props.reminder);
  }, [props.reminder]);

  const handleClose = () => {
    setOpen(false);
    setRemindTimeout(
      setTimeout(() => {
        setOpen(true);
        clearTimeout(remindTimeout);
      }, 180000)
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{intl.formatMessage({ id: "sse.dialog.title" })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: "sse.dialog.context" })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {intl.formatMessage({ id: "sse.dialog.delay" })}
        </Button>
        <Button onClick={() => window.location.reload()} color="primary" autoFocus>
          {intl.formatMessage({ id: "sse.dialog.refetch" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
