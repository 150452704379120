import React from "react";

import { useApolloClient } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { AvistaLogo } from "components/avista-logo";
import { useFirstTimeLoginResetPasswordMutation } from "generated/graphql";
import { useDialog } from "hook";
import { appName } from "utils/const";
import { resetPasswordSchema } from "../schema";
import { ResetPasswordFormField } from "../types";
import { paleGreen } from "theme/color";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  logo: {
    marginTop: spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    marginTop: spacing(3),
    backgroundColor: paleGreen,
    color: palette.primary.contrastText,
    width: 200,
    borderRadius: 40,
  },
  field: {
    margin: spacing(2),
    width: "50%",
  },
  fieldInput: {
    backgroundColor: "#f2f6f6",
    width: "100%",
    maxWidth: 300,
  },
  errorMsg: {
    color: "red",
    whiteSpace: "pre-wrap",
  },
}));

export type ResetPasswordPanelProps = { userId: string; onSuccessLogin: () => void };

export const ResetPasswordPanel: React.FC<ResetPasswordPanelProps> = (props) => {
  const { userId, onSuccessLogin } = props;

  const { classes } = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<ResetPasswordFormField>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: { password: "", password2: "" },
  });
  const client = useApolloClient();
  const { openDialog, Dialog, DialogType } = useDialog();

  const [resetPassword] = useFirstTimeLoginResetPasswordMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ firstTimeLoginResetPassword: isSuccess }) => {
      if (isSuccess) onSuccessLogin();
      else
        openDialog({
          content: "Reset password failed. Please contact ESG team. 重设密码失败，请连络ESG团队",
          type: DialogType.ERROR,
        });
    },
  });

  const onSubmit = (data: ResetPasswordFormField) => {
    resetPassword({ variables: { id: userId, password: data.password } });
  };

  return (
    <Grid container spacing={2} direction="column" className={classes.form}>
      <form>
        <Grid xs={12}>
          <AvistaLogo className={classes.logo} />
        </Grid>

        <Grid xs={12}>
          <Typography variant="h5">
            Welcome to {appName}, please change your password.
            <br />
            欢迎登入{appName}, 请修改你的密码。
          </Typography>
        </Grid>

        <Grid xs={12}>
          <TextField
            {...register("password")}
            label="New password 新密码"
            type="password"
            margin="dense"
            className={classes.fieldInput}
          />
          {errors.password && touchedFields.password && <p className={classes.errorMsg}>{errors.password.message}</p>}
        </Grid>
        <Grid xs={12}>
          <TextField
            {...register("password2")}
            label="Confirm new password 确认新密码"
            type="password"
            margin="dense"
            className={classes.fieldInput}
          />
          {errors.password2 && touchedFields.password2 && (
            <p className={classes.errorMsg}>{errors.password2.message}</p>
          )}
        </Grid>

        <Grid xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            提交 Submit
          </Button>
        </Grid>
      </form>
      <Dialog />
    </Grid>
  );
};
