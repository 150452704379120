import React from "react";

import { BarChartWithValueBlock, BarChartWithValueBlockProps } from "../blocks/bar-chart-with-value-block";
import { BlockWithHeaderContainer, BlockWithHeaderContainerProps } from "../containers/block-with-header-container";

export type EmissionBlockProps = BlockWithHeaderContainerProps & BarChartWithValueBlockProps;

export const EmissionBlock: React.FC<EmissionBlockProps> = (props) => {
  const { header, ...barChartWithValueBlockProps } = props;

  return (
    <BlockWithHeaderContainer header={header}>
      <BarChartWithValueBlock {...barChartWithValueBlockProps} />
    </BlockWithHeaderContainer>
  );
};
