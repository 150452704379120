import React, { RefObject } from "react";

import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing }) => ({
  typography: {
    padding: spacing(2),
  },
  textField: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
  },
}));

export type RemarkDialogProps = {
  remarks: string;
  open: boolean;
  handleClose: (textRef: React.RefObject<HTMLInputElement>) => void;
  anchorEl?: RefObject<Element>;
  isDisable: boolean;
};

export const RemarkDialog: React.FC<RemarkDialogProps> = (props) => {
  const { remarks, open, handleClose, anchorEl, isDisable } = props;

  const textRef = React.useRef<HTMLInputElement>(null);

  const { classes } = useStyles();

  return (
    <div>
      {anchorEl?.current && (
        <Popover
          id={"simple-popover"}
          open={open}
          anchorEl={anchorEl.current}
          onClose={() => handleClose(textRef)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <TextField
            disabled={isDisable}
            inputRef={textRef}
            id="outlined-name"
            label="Remark"
            className={classes.textField}
            defaultValue={remarks}
            multiline
            rows="3"
            margin="normal"
            variant="outlined"
          />
        </Popover>
      )}
    </div>
  );
};
