import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { GetEmissionFactorProfilesQuery, GetEmissionFactorTemplatesQuery } from "generated/graphql";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { useCallback, useContext } from "react";
import { DialogMode } from "utils/dialog";
import { IProfileForm } from "../components/form";
import { useAPI } from "./api";

export type Actions = {
  getData: () => Promise<GetEmissionFactorProfilesQuery["emissionFactorProfiles"]>;
  getTemplates: () => Promise<GetEmissionFactorTemplatesQuery["emissionFactorTemplates"]>;
  submitForm: (params: SubmitFormParams) => Promise<void>;
  gotoEditEmissionFactorPage: (profileName: string) => void;
};

export type SubmitFormParams = {
  mode: DialogMode;
  values: IProfileForm;
  setSubmitting: (bool: boolean) => void;
};

export const useActions = (): Actions => {
  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    getEmissionFactorProfiles,
    createEmissionFactorProfile,
    updateEmissionFactorProfile,
    getEmissionFactorTemplates,
  } = useAPI();
  const { setLoading } = useContext(LoadingContext);
  const { showMessage } = useContext(MessageContext);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      return await getEmissionFactorProfiles();
    } finally {
      setLoading(false);
    }
  }, [getEmissionFactorProfiles, setLoading]);

  const getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      return await getEmissionFactorTemplates();
    } finally {
      setLoading(false);
    }
  }, [getEmissionFactorTemplates, setLoading]);

  const submitForm = useCallback(
    async ({ mode, values, setSubmitting }: SubmitFormParams) => {
      setLoading(true);
      setSubmitting(true);

      if (mode === DialogMode.CREATE) {
        await createEmissionFactorProfile({
          data: {
            name: values.name,
            templateId: values.templateId,
            cloneFromProfileId: values.cloneFromProfileId,
            isSwtichActiveProject: values.isSwitchActiveProjects ?? false,
          },
        });
      } else {
        await updateEmissionFactorProfile({
          profileId: values.id!,
          data: { name: values.name },
        });
      }

      setSubmitting(false);
      setLoading(false);

      showMessage({ message: intl.formatMessage({ id: "excel.common.success" }) });
    },
    [createEmissionFactorProfile, updateEmissionFactorProfile, intl, setLoading, showMessage]
  );

  const gotoEditEmissionFactorPage = (profileName: string) => {
    history.push(`${pathname}/edit?profile=${encodeURIComponent(profileName)}`);
  };

  return {
    getData,
    getTemplates,
    submitForm,
    gotoEditEmissionFactorPage,
  };
};
