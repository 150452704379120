import React, { useCallback, useContext, useState } from "react";

import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Role } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { isLogin } from "utils/helper";
import { AdminLayout } from "./admin";
import { LayoutContext } from "./components/layout-context";
import { NoRoleLayout } from "./components/no-role-layout";
import { StaffLayout } from "./staff";
import { SystemAdminLayout } from "./systemAdmin";

const drawerWidth = 271;
const useStyles = makeStyles()(({ mixins, spacing, breakpoints, transitions, palette }) => ({
  root: {
    display: "flex",
  },
  TopPlaceHolder: { height: mixins.toolbar.height, minHeight: mixins.toolbar.minHeight },
  main: {
    display: "block",
    padding: spacing(3),
    [breakpoints.down("sm")]: {
      padding: spacing(2, 3),
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    justifyContent: "flex-end",
  },
  appBar: {
    transition: transitions.create(["margin", "width"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px) !important`,
    marginLeft: drawerWidth,
    transition: transitions.create(["margin", "width"], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
  },
  contentShift: {
    flexGrow: 1,
    padding: spacing(3),
    transition: transitions.create("margin", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
  },
  sidebar: {
    width: "265px",
  },
  content: {
    transition: transitions.create("margin", {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerActiveButton: {
    color: "red",
    background: "#eee",
  },
}));

export const Layout: React.FC = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { classes, cx } = useStyles();
  const { me } = useContext(AuthContext);
  const location = useLocation();

  const getLayout = useCallback(() => {
    if (!me.project && me.role !== Role.Admin) {
      return <NoRoleLayout />;
    }
    switch (me.role) {
      case Role.ClientAdmin:
        return <AdminLayout scope3Permission={me.project!.scope3Permission!} />;
      case Role.ClientStaff:
        return <StaffLayout />;
      case Role.Admin:
        return <SystemAdminLayout />;
    }
  }, [me]);

  if (location.pathname === "/login" || !isLogin()) {
    return children as React.ReactElement;
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  return (
    <LayoutContext.Provider value={{ isDrawerOpen, layoutClasses: classes, handleDrawerClose, handleDrawerOpen }}>
      {getLayout()}
      <div className={classes.TopPlaceHolder} />
      <main className={cx(classes.main, classes.content)}>{children}</main>
    </LayoutContext.Provider>
  );
};
