import React, { useMemo, useState } from "react";

import DownArrowIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { TabPosition } from "../consts";
import { StyledContainer } from "./styled-container";

export const useStyles = makeStyles()(({ spacing, shadows }) => ({
  root: {
    minHeight: 100,
    height: "100%",
    position: "relative",
    marginRight: spacing(4),
  },
  contentContainer: {
    zIndex: 2,
    borderRadius: 16,
    boxShadow: shadows[2],
  },
  behindBox: {
    width: "calc(100% - 2px)",
    height: "100%",
    borderRadius: 16,
    boxShadow: shadows[2],

    position: "absolute",
    transformOrigin: "top",
    transform: "translateX(10px) translateY(-10px)",
    zIndex: -2,
  },
  sideTab: {
    padding: spacing(0.5, 1),
    backgroundColor: "white",
    cursor: "pointer",
    whiteSpace: "nowrap",

    display: "flex",
    gap: spacing(0.5),

    userSelect: "none",
    msUserSelect: "none",
    mozUserSelect: "none",
    WebkitUserSelect: "none",
  },
  sideTabTop: {
    borderBottom: "none",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    zIndex: 1,

    position: "absolute",
    transformOrigin: "bottom",
    top: 0,
    left: 4,
    transform: "translate(20px, -110%)",
  },
  sideTabRight: {
    borderTop: "none",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 1,

    position: "absolute",
    transformOrigin: "top",
    top: "50%",
    left: "100%",
    transform: "translateX(-46%) rotate(-90deg)",
  },
  downArrowIcon: {
    width: 16,
    height: 16,
  },
}));

type BlockElementInfo = {
  content: React.ReactNode;
  tabName: string;
};

export type TabSwapContainerProps = {
  main: BlockElementInfo;
  sub?: BlockElementInfo;
  tabPosition: TabPosition;
};

export const TabSwapContainer: React.FC<TabSwapContainerProps> = (props) => {
  const { main, sub, tabPosition } = props;

  const [isShowingMainSide, setIsShowingMainSide] = useState(true);

  const { classes, cx } = useStyles();

  const sideTabPositionClass = useMemo(() => {
    switch (tabPosition) {
      case TabPosition.Right:
      default:
        return classes.sideTabRight;
      case TabPosition.Top:
        return classes.sideTabTop;
    }
  }, [tabPosition, classes.sideTabRight, classes.sideTabTop]);

  const handleClickSideTab = () => {
    setIsShowingMainSide(!isShowingMainSide);
  };

  return (
    <div className={classes.root}>
      {sub && (
        <>
          <Card className={classes.behindBox} />
          <Box className={cx(classes.sideTab, sideTabPositionClass)} boxShadow={1} onClick={handleClickSideTab}>
            <DownArrowIcon className={classes.downArrowIcon} />
            <Typography>{isShowingMainSide ? sub.tabName : main.tabName}</Typography>
          </Box>
        </>
      )}

      <StyledContainer className={classes.contentContainer}>
        {sub && !isShowingMainSide ? sub.content : main.content}
      </StyledContainer>
    </div>
  );
};
