import React, { useState } from "react";

import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from "react-joyride";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ palette }) => ({
  tourGuideButton: {
    padding: "15px 0",
    fontWeight: "bold",
    color: palette.primary.main,
  },
  tourGuideIcon: {
    color: palette.primary.main,
    marginRight: "2px",
  },
  tourGuide: {
    display: "inline-block",
    marginRight: "15px",
  },
}));

export type TourGuideProps = {
  steps: Step[];
};

export const TourGuide: React.FC<TourGuideProps> = (props) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const [storeHelpers, setStoreHelpers] = useState<StoreHelpers>();
  const [isTourRunning, setIsTourRunning] = useState(false);

  const getHelpers = (helpers: StoreHelpers) => {
    if (storeHelpers) {
      setStoreHelpers(helpers);
    }
  };

  const handleClickStart = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsTourRunning(true);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsTourRunning(false);
    }
  };

  return (
    <div className={classes.tourGuide}>
      <Button onClick={handleClickStart} className={classes.tourGuideButton}>
        <PlayCircleFilled className={classes.tourGuideIcon} /> {intl.formatMessage({ id: "button.start-tour-guide" })}
      </Button>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={isTourRunning}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={props.steps}
        locale={{
          back: intl.formatMessage({ id: "button.joyride.back" }),
          close: intl.formatMessage({ id: "button.joyride.close" }),
          last: intl.formatMessage({ id: "button.joyride.last" }),
          next: intl.formatMessage({ id: "button.joyride.next" }),
          skip: intl.formatMessage({ id: "button.joyride.skip" }),
        }}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#1F5489",
          },
        }}
      />
    </div>
  );
};
