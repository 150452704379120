import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetDevelopmentTrainingDataDocument,
  GetDevelopmentTrainingDataQuery,
  GetDevelopmentTrainingDataQueryVariables,
  SubmitDevelopmentTrainingDocument,
  SubmitDevelopmentTrainingMutation,
  SubmitDevelopmentTrainingMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getDevelopmentTrainingData: (
    variables: GetDevelopmentTrainingDataQueryVariables
  ) => Promise<GetDevelopmentTrainingDataQuery["project"]["developmentTraining"]>;
  submitDevelopmentTraining: (
    variables: SubmitDevelopmentTrainingMutationVariables
  ) => Promise<SubmitDevelopmentTrainingMutation["submitDevelopmentTraining"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getDevelopmentTrainingData = useCallback(
    async (variables: GetDevelopmentTrainingDataQueryVariables) => {
      const response = await client.query<GetDevelopmentTrainingDataQuery, GetDevelopmentTrainingDataQueryVariables>({
        query: GetDevelopmentTrainingDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.developmentTraining;
    },
    [client]
  );

  const submitDevelopmentTraining = useCallback(
    async (variables: SubmitDevelopmentTrainingMutationVariables) => {
      const response = await client.mutate<
        SubmitDevelopmentTrainingMutation,
        SubmitDevelopmentTrainingMutationVariables
      >({
        mutation: SubmitDevelopmentTrainingDocument,
        variables,
      });

      return response.data?.submitDevelopmentTraining ?? false;
    },
    [client]
  );

  return {
    getDevelopmentTrainingData,
    submitDevelopmentTraining,
  };
};
