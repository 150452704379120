import React from "react";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import theme from "../theme";
import { AvistaApolloProvider } from "./apollo";
import { LanguageProvider } from "./language";
import { LoadingProvider } from "./loading";
import { MessageProvider } from "./message";

export const AvistaProvider: React.FC = ({ children }) => {
  return (
    <LanguageProvider>
      <LoadingProvider>
        <MessageProvider>
          <AvistaApolloProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
          </AvistaApolloProvider>
        </MessageProvider>
      </LoadingProvider>
    </LanguageProvider>
  );
};
