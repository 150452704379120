import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { FullScreenFormDialog } from "components/react-table";
import { UserInput } from "generated/graphql";
import { DialogMode } from "utils/dialog";
import { ObjectSchema } from "yup";
import { UserFormFields } from "../types";

type UserFormProps = {
  mode: DialogMode;
  isOpen: boolean;
  data: UserFormFields;
  schema: ObjectSchema<UserInput>;
  onClose: () => void;
  onSubmit: (data: UserFormFields) => void;
  onResetPassword: (userId: string) => void;
};

const useStyles = makeStyles()(() => ({
  errorMsg: {
    color: "red",
  },
  textField: {
    width: "100%",
    maxWidth: 400,
  },
}));

export const UserForm: React.FC<UserFormProps> = (props) => {
  const { mode, isOpen, data, schema, onClose, onSubmit, onResetPassword } = props;

  const intl = useIntl();
  const { classes } = useStyles();
  const {
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<UserFormFields>({ resolver: yupResolver(schema) });

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  return (
    <FullScreenFormDialog
      isOpen={isOpen}
      handleDialogClose={onClose}
      handleSubmit={handleSubmit(onSubmit)}
      title={intl.formatMessage({ id: "dialog.title.user" })}
      mode={mode}
    >
      <Grid>
        <Grid container margin={2} spacing={4}>
          <Grid xs={12} sm={6}>
            <TextField
              {...register("loginId", { required: true })}
              variant="standard"
              label={intl.formatMessage({ id: "user.login-id" })}
              className={classes.textField}
            />
            {errors.loginId && touchedFields.loginId && <p className={classes.errorMsg}>{errors.loginId.message}</p>}
          </Grid>

          <Grid xs={12} sm={6} />

          {mode === DialogMode.EDIT && (
            <>
              <Grid xs={12} sm={6}>
                <TextField
                  {...register("password1")}
                  variant="standard"
                  label={intl.formatMessage({ id: "user.password" })}
                  type="password"
                  className={classes.textField}
                />
                {errors.password1 && touchedFields.password1 && (
                  <p className={classes.errorMsg}>{errors.password1.message}</p>
                )}
              </Grid>

              <Grid xs={12} sm={6}>
                <TextField
                  {...register("password2")}
                  variant="standard"
                  label={intl.formatMessage({ id: "user.confirm-password" })}
                  type="password"
                  className={classes.textField}
                />
                {errors.password2 && touchedFields.password2 && (
                  <p className={classes.errorMsg}>{errors.password2.message}</p>
                )}
              </Grid>
            </>
          )}

          <Grid xs={12} sm={6}>
            <TextField
              {...register("firstName", { required: true })}
              variant="standard"
              label={intl.formatMessage({ id: "user.first-name" })}
              className={classes.textField}
            />
            {errors.firstName && touchedFields.firstName && (
              <p className={classes.errorMsg}>{errors.firstName.message}</p>
            )}
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              {...register("lastName", { required: true })}
              variant="standard"
              label={intl.formatMessage({ id: "user.last-name" })}
              className={classes.textField}
            />
            {errors.lastName && touchedFields.lastName && <p className={classes.errorMsg}>{errors.lastName.message}</p>}
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              {...register("email", { required: true })}
              variant="standard"
              label={intl.formatMessage({ id: "user.email" })}
              className={classes.textField}
            />
            {errors.email && touchedFields.email && <p className={classes.errorMsg}>{errors.email.message}</p>}
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              {...register("phone")}
              variant="standard"
              label={intl.formatMessage({ id: "user.phone" })}
              className={classes.textField}
            />
            {errors.phone && touchedFields.phone && <p className={classes.errorMsg}>{errors.phone.message}</p>}
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              {...register("title")}
              variant="standard"
              label={intl.formatMessage({ id: "user.title" })}
              className={classes.textField}
            />
            {errors.title && touchedFields.title && <p className={classes.errorMsg}>{errors.title.message}</p>}
          </Grid>

          <Grid xs={12} sm={6} />

          {mode === DialogMode.EDIT && (
            <Grid xs={12} sm={6}>
              <Button variant="contained" onClick={() => onResetPassword(getValues("id")!)}>
                {intl.formatMessage({ id: "user.reset-password" })}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FullScreenFormDialog>
  );
};
