import React, { useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  title: {
    fontSize: "18px",
  },
  content: {
    fontSize: "16px",
    lineHeight: "24px",
    padding: spacing(3),
  },
  infoButton: {
    padding: "15px 0",
    fontWeight: "bold",
    color: palette.primary.main,
  },
  infoIcon: {
    color: palette.primary.main,
    marginRight: "2px",
  },
  introModal: {
    display: "inline-block",
    marginRight: "15px",
  },
}));

export type IntroModalProps = {
  children: React.ReactNode;
  open?: boolean;
};

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  );
});

export const IntroModal = React.memo((props: IntroModalProps) => {
  const [open, setOpen] = useState(props.open ?? false);

  const intl = useIntl();
  const { classes } = useStyles();

  const title = intl.formatMessage({ id: "button.guideline" });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.introModal}>
      <Button onClick={handleClickOpen} className={classes.infoButton}>
        <InfoIcon className={classes.infoIcon} /> {title}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          <span className={classes.title}>{title}</span>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.content}>{props.children}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {intl.formatMessage({ id: "button.ok" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
