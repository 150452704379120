import { RecordStatus } from "generated/graphql";
import { LabourResponsibilityPolicyRecordRow } from "./types";

export const newLabourResponsibilityPolicyRow: LabourResponsibilityPolicyRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  healthDesc: "",
  reviewDesc: "",
  eliminateDesc: "",
  preventiveDesc: "",
  trainingDesc: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
