import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DisabledFormatter,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newOfficePaperUsageRow, tips } from "./consts";
import { useActions } from "./hook/action";
import { OfficePaperUsageRecordRow } from "./types";

const source = IndexType.OfficePaperUsage;

export const OfficePaperUsagePage: React.FC = () => {
  const [initRows, setInitRows] = useState<OfficePaperUsageRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 250,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "openingBalance",
        name: intl.formatMessage({ id: "excel.paper.opening-balance" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "openingBalance"),
      },
      {
        key: "quantityPurchased",
        name: intl.formatMessage({ id: "excel.paper.quantity-purchased" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityPurchased"),
      },
      {
        key: "endingBalance",
        name: intl.formatMessage({ id: "excel.paper.year-end-balance" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "endingBalance"),
      },
      {
        key: "quantityUsed",
        name: intl.formatMessage({ id: "excel.paper.quantity-used" }),
        width: 280,
        resizable: true,
        editable: false,
        formatter: getFormatter(DisabledFormatter, "quantityUsed"),
      },
      {
        key: "quantityRecycled",
        name: intl.formatMessage({ id: "excel.paper.quantity-recycled" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityRecycled"),
      },
      {
        key: "quantityDisposed",
        name: intl.formatMessage({ id: "excel.paper.quantity-disposed" }),
        width: 280,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "quantityDisposed"),
      },
    ],
    [companyOptions, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<OfficePaperUsageRecordRow> = (rows, updatedItem) => {
    const { openingBalance, quantityPurchased, endingBalance } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(openingBalance, quantityPurchased, endingBalance)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        rows[i].quantityUsed = +(+rows[i].openingBalance - +rows[i].endingBalance + +rows[i].quantityPurchased);

        if (+rows[i].quantityUsed < 0)
          openDialog({ content: intl.formatMessage({ id: "errors.paper.quantity-used" }), type: DialogType.ALERT });
      }
    }

    return rows;
  };

  const validationSchema = Yup.lazy<Partial<OfficePaperUsageRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as OfficePaperUsageRecordRow[]);

    return Yup.array(
      Yup.object<Partial<OfficePaperUsageRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .unique("errors.common.company-existed", "companyId", values)
          .allCompaniesValid("errors.common.validate-all-companies", result),
        openingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityPurchased: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        endingBalance: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityRecycled: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityDisposed: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        quantityUsed: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.paper.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newOfficePaperUsageRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        excludeClone={[
          { property: "openingBalance", value: 0 },
          { property: "quantityPurchased", value: 0 },
          { property: "endingBalance", value: 0 },
          { property: "quantityRecycled", value: 0 },
          { property: "quantityDisposed", value: 0 },
          { property: "quantityUsed", value: 0 },
        ]}
        guide={{ introContent, steps }}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
