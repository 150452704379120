import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  checkIsSameMinDate,
  createSelectGroupedFormatter,
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  StringFormatter,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { usePermission } from "hook/permission";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { getDateDiffInDays, toDate } from "utils/date-time";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { ExcludedResultItem, getExcludedResultItems, getExcludeResultItem } from "../utils/date-range";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import {
  energyConsumptionUnitOptions,
  flattenPowerSupplierOptions,
  newEnergyConsumptionRow,
  PowerSource,
  powerSourceOptions,
  powerSupplierOptionsMapping,
} from "./consts";
import { deprecatedPowerSourceOptions, deprecatedPowerSourceSupplierLocationOptions } from "./consts-deprecated";
import { useActions } from "./hook/action";
import { EnergyConsumptionRecordRow } from "./types";

const source = IndexType.EnergyConsumption;

export const EnergyConsumptionPage: React.FC = () => {
  const [initRows, setInitRows] = useState<EnergyConsumptionRecordRow[]>([]);
  const [excludeDays, setExcludeDays] = useState<ExcludedResultItem[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const {
    companyOptions,
    addressOptions,
    addressPeriod,
    minDate,
    maxDate,
    isValidAllCompanies,
    isValidAllCompaniesAddress,
  } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();
  const editable = usePermission();

  const companies = useMemo(
    () => companyOptions.filter((c) => addressOptions[c.value].length !== 0),
    [addressOptions, companyOptions]
  );

  const getNewRow = () => ({ ...newEnergyConsumptionRow, minDate, maxDate });

  const getAddressPeriod = useCallback(
    (company: string, address: string) =>
      addressPeriod[company] ? addressPeriod[company].find((d) => d.address === address) : null,
    [addressPeriod]
  );

  const getExcludeDays = useCallback(
    (row: EnergyConsumptionRecordRow) =>
      getExcludeResultItem(excludeDays, { ...row, source: row.powerSource })?.excludeDays ?? [],
    [excludeDays]
  );

  const calculateExcludeDays = useCallback((rows: EnergyConsumptionRecordRow[]) => {
    const items = getExcludedResultItems(rows.map(({ powerSource, ...rest }) => ({ ...rest, source: powerSource })));
    setExcludeDays(items);
  }, []);

  const initData = useCallback(async () => {
    if (companies.length === 0 || checkIsSameMinDate(minDate, maxDate)) return;

    setLoading(true);

    const { rows } = await getData(companies.map((i) => i.value));

    const rowsWithMinMaxDate = rows.map((r) => {
      const period = getAddressPeriod(r.originalCompanyId!, r.address);

      return {
        ...r,
        minDate: period ? toDate(period.minDate) : minDate,
        maxDate: period ? toDate(period.maxDate) : maxDate,
      };
    });

    setLoading(false);
    calculateExcludeDays(rowsWithMinMaxDate);
    setInitRows([...rowsWithMinMaxDate]);
  }, [companies, setLoading, getAddressPeriod, getData, calculateExcludeDays, maxDate, minDate]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<EnergyConsumptionRecordRow> = (rows, updatedItem) => {
    const { address, endDate, startDate, powerSource, meterNumber } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(address)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        const period = getAddressPeriod(rows[i].companyId, rows[i].address);
        if (!period) continue;
        rows[i].maxDate = toDate(period.maxDate);
        rows[i].minDate = toDate(period.minDate);
      }
    }
    if (isAnyNotNullOrUndefined(endDate, startDate, powerSource, address, meterNumber)) calculateExcludeDays(rows);

    if (isAnyNotNullOrUndefined(powerSource)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        if (powerSource !== rows[i].powerSource) {
          rows[i].powerSourceSupplierLocation = "";
        }
      }
    }

    if (powerSource === PowerSource.SelfSolarPower)
      openDialog({ content: intl.formatMessage({ id: "errors.energy.self-solar-power" }), type: DialogType.ALERT });

    return rows;
  };

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companies,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companies), "companyId"),
      },
      {
        key: "address",
        name: intl.formatMessage({ id: "excel.energy.address" }),
        width: 250,
        resizable: true,

        editor: SelectEditor,
        dynamicOptionsHandler: (row: { rowData: EnergyConsumptionRecordRow }) =>
          row.rowData.companyId === "" || !addressOptions[row.rowData.companyId]
            ? []
            : addressOptions[row.rowData.companyId],
        formatter: getFormatter(createSelectSingleFormatter([]), "address"),
        // pass empty array to formatter because formatter cannot map will directly show value , maybe suitable for dynamic case
      },
      {
        key: "meterNumber",
        name: intl.formatMessage({ id: "excel.energy.meter-number" }),
        width: 100,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(StringFormatter, "meterNumber"),
      },
      {
        key: "powerSource",
        name: intl.formatMessage({ id: "excel.energy.power-source" }),
        editor: SelectEditor,
        options: powerSourceOptions,
        resizable: true,
        width: 200,
        isCreatable: true,
        formatter: getFormatter(
          createSelectGroupedFormatter([...powerSourceOptions, ...deprecatedPowerSourceOptions]),
          "powerSource"
        ),
      },
      {
        key: "powerSourceSupplierLocation",
        name: intl.formatMessage({ id: "excel.energy.power-source-supplier-location" }),
        editor: SelectEditor,
        resizable: true,
        width: 200,
        dynamicOptionsHandler: (row: { rowData: EnergyConsumptionRecordRow }) =>
          powerSupplierOptionsMapping[row.rowData.powerSource] ?? [],
        formatter: getFormatter(
          createSelectSingleFormatter([
            ...flattenPowerSupplierOptions,
            ...deprecatedPowerSourceSupplierLocationOptions,
          ]),
          "powerSourceSupplierLocation"
        ),
        isCreatable: true,
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.energy.start-date" }),
        width: 100,
        resizable: true,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: getExcludeDays,
        },
        formatter: getFormatter(Formatter, "startDate"),
        editable,
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.energy.end-date" }),
        width: 100,
        resizable: true,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: getExcludeDays,
        },
        formatter: getFormatter(Formatter, "endDate"),
        isDisable: () => !editable,
        editable,
      },
      {
        key: "energyConsumptionAmount",
        name: intl.formatMessage({ id: "excel.energy.consumption-amount" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "energyConsumptionAmount"),
      },
      {
        key: "energyConsumptionUnit",
        name: intl.formatMessage({ id: "excel.energy.unit" }),
        editor: SelectEditor,
        options: energyConsumptionUnitOptions,
        resizable: true,
        width: 100,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(energyConsumptionUnitOptions), "energyConsumptionUnit"),
      },
    ],
    [intl, addressOptions, companies, getExcludeDays, maxDate, minDate]
  );

  const validationSchema = Yup.lazy<Partial<EnergyConsumptionRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as EnergyConsumptionRecordRow[]);

    const dateCheck = Yup.string()
      .required("errors.common.require")
      .test("365", "errors.common.in-rent-period", function () {
        const row: EnergyConsumptionRecordRow = this.parent;
        if (row.companyId === "" || row.address === "" || row.powerSource === "" || row.meterNumber === "") {
          return true;
        }

        // + 1 to include endDate
        const numberOfPeriodDays = getDateDiffInDays(row.minDate, row.maxDate) + 1;
        const days = getExcludeDays(this.parent as EnergyConsumptionRecordRow);
        return days.length === numberOfPeriodDays;
      });

    return Yup.array(
      Yup.object<Partial<EnergyConsumptionRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        address: Yup.string()
          .required("errors.common.require")
          .test("isValidAllCompaniesAddress", "errors.common.validate-all-address", function () {
            const validateCompaniesAddressResult = isValidAllCompaniesAddress(
              values as EnergyConsumptionRecordRow[],
              this.parent as EnergyConsumptionRecordRow
            );
            return validateCompaniesAddressResult.isValid
              ? true
              : this.createError({ message: validateCompaniesAddressResult.error });
          }),
        powerSource: Yup.string().required("errors.common.require"),
        meterNumber: Yup.string().required("errors.common.require"),
        powerSourceSupplierLocation: Yup.string().required("errors.common.require"),
        energyConsumptionAmount: Yup.number().required("errors.common.require").moreThan(-1, "errors.common.more-zero"),
        energyConsumptionUnit: Yup.string().required("errors.common.require"),
        startDate: dateCheck,
        endDate: dateCheck,
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.energy.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={getNewRow()}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "energyConsumptionAmount", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
