import React, { useContext, useEffect } from "react";

import { useApolloClient } from "@apollo/client";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/styles";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { BeforeExitPrompt } from "components/before-exit-prompt";
import {
  BasicDataSet,
  BasicTarget,
  DashboardTarget,
  DashboardTargetInput,
  OptionDatum,
  OptionTarget,
  useGetTargetDashboardDataQuery,
  useSubmitDashboardTargetMutation,
} from "generated/graphql";
import { getUnitIntlKey } from "pages/dashboard/utils";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { placeholderIntlKey } from "utils/intl";

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(2, 3),
}));

const ErrorText: React.FC = ({ children }) => (
  <Typography variant="body2" color="red">
    {children}
  </Typography>
);

type FilterKey<T extends Record<string, unknown>, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
type TotalFieldKey = Exclude<FilterKey<DashboardTarget, BasicTarget>, "updatedAt">;
type FieldArrayKey = Exclude<FilterKey<DashboardTarget, OptionTarget[]>, "updatedAt">;

export const DashboardTargetPage: React.FC = () => {
  const intl = useIntl();
  const client = useApolloClient();
  const { me } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showMessage } = useContext(MessageContext);

  const { control, register, handleSubmit, setValue, formState, reset } = useForm<DashboardTargetInput>({
    mode: "onTouched",
  });
  type FormKey = Parameters<typeof register>[0];
  const ghgIntensityFieldArray = useFieldArray({ control, name: "ghgIntensity" });
  const energyIntensityFieldArray = useFieldArray({ control, name: "energyIntensity" });
  const waterIntensityFieldArray = useFieldArray({ control, name: "waterIntensity" });
  const wasteIntensityFieldArray = useFieldArray({ control, name: "wasteIntensity" });
  const hazardousWasteIntensityFieldArray = useFieldArray({ control, name: "hazardousWasteIntensity" });
  const nonHazardousWasteIntensityFieldArray = useFieldArray({ control, name: "nonHazardousWasteIntensity" });

  const { data, loading, refetch } = useGetTargetDashboardDataQuery({
    client,
    variables: { projectId: me?.project?.id ?? "" },
    onCompleted: ({ dashboardTarget }) => {
      if (!dashboardTarget) return;

      const getBasicTargetInput = ({ value }: BasicDataSet) => ({ value });
      const getOptionTargetInput = (source: OptionDatum[]) => source.map(({ option, value }) => ({ option, value }));

      setValue("ghgTotal", getBasicTargetInput(dashboardTarget.ghgTotal));
      setValue("ghgIntensity", getOptionTargetInput(dashboardTarget.ghgIntensity));
      setValue("energyTotal", getBasicTargetInput(dashboardTarget.energyTotal));
      setValue("energyIntensity", getOptionTargetInput(dashboardTarget.energyIntensity));
      setValue("waterTotal", getBasicTargetInput(dashboardTarget.waterTotal));
      setValue("waterIntensity", getOptionTargetInput(dashboardTarget.waterIntensity));
      setValue("wasteTotal", getBasicTargetInput(dashboardTarget.wasteTotal));
      setValue("wasteIntensity", getOptionTargetInput(dashboardTarget.wasteIntensity));
      setValue("hazardousWasteTotal", getBasicTargetInput(dashboardTarget.hazardousWasteTotal));
      setValue("hazardousWasteIntensity", getOptionTargetInput(dashboardTarget.hazardousWasteIntensity));
      setValue("nonHazardousWasteTotal", getBasicTargetInput(dashboardTarget.nonHazardousWasteTotal));
      setValue("nonHazardousWasteIntensity", getOptionTargetInput(dashboardTarget.nonHazardousWasteIntensity));
    },
  });
  const [submitDashboardTarget, { loading: submitting }] = useSubmitDashboardTargetMutation({
    client,
    onCompleted: ({ submitDashboardTarget: isSubmitSuccess }) => {
      if (isSubmitSuccess) {
        reset();
        showMessage({ message: intl.formatMessage({ id: "excel.common.success" }), variant: "success" });
        refetch();
      } else {
        showMessage({ message: intl.formatMessage({ id: "error.common" }), variant: "error" });
      }
    },
    onError: () => {
      showMessage({ message: intl.formatMessage({ id: "error.common" }), variant: "error" });
    },
  });

  useEffect(() => {
    setLoading(loading || submitting);
  }, [setLoading, loading, submitting]);

  const handleSubmitForm = handleSubmit((data) => {
    submitDashboardTarget({ variables: { projectId: me?.project?.id ?? "", data } });
  });

  const getTotalFieldUnit = (key: TotalFieldKey) =>
    intl.formatMessage({
      id: loading || !data?.dashboardTarget ? placeholderIntlKey : getUnitIntlKey(data.dashboardTarget[key].unit),
    });

  const renderNumberField = (key: FormKey) => (
    <OutlinedInput
      style={{ backgroundColor: "white" }}
      size="small"
      type="number"
      inputProps={{ step: "any" }}
      {...register(key, {
        valueAsNumber: true,
        min: { value: 0, message: intl.formatMessage({ id: "dashboard-target.error.not-negative" }) },
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          // remove leading zeros
          let value = parseFloat(e.target.value);
          // if value is not filled in, set as 0
          if (Number.isNaN(value)) value = 0;
          setValue(key, value);
        },
      })}
    />
  );

  const renderTotalField = ({ titleIntl, key }: { titleIntl: string; key: TotalFieldKey }) => (
    <>
      <Grid xs={12}>
        <Typography variant="h3">
          {intl.formatMessage(
            { id: "dashboard-target.total.title" },
            { category: intl.formatMessage({ id: titleIntl }) }
          )}
        </Typography>
      </Grid>

      <Grid xs={12}>
        <Grid container gap={1} alignItems="flex-end">
          <Grid>{renderNumberField(`${key}.value`)}</Grid>

          <Grid>
            <Typography variant="body1">{getTotalFieldUnit(key)}</Typography>
          </Grid>
          {formState.errors[key]?.value?.message && (
            <Grid>
              <ErrorText>{formState.errors[key]!.value!.message}</ErrorText>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );

  const renderIntensityField = ({
    titleIntl,
    key,
    fields,
    totalFieldKey,
  }: {
    titleIntl: string;
    key: FieldArrayKey;
    fields: FieldArrayWithId[];
    totalFieldKey: TotalFieldKey;
  }) => (
    <>
      <Grid xs={12}>
        <Typography variant="h3">
          {intl.formatMessage(
            { id: "dashboard-target.intensity.title" },
            { category: intl.formatMessage({ id: titleIntl }) }
          )}
        </Typography>
      </Grid>

      {fields.map((field, index) => (
        <Grid key={field.id} xs={12}>
          <Grid container gap={2} alignItems="center">
            <Grid width={160}>
              <Typography variant="h5">
                {intl.formatMessage({
                  id:
                    loading || !data?.dashboardTarget
                      ? placeholderIntlKey
                      : `dashboard.env.nf1.${data.dashboardTarget[key][index].option}`,
                })}
              </Typography>
            </Grid>

            <Grid>{renderNumberField(`${key}.${index}.value`)}</Grid>

            <Grid>
              <Typography variant="body1">
                {`${getTotalFieldUnit(totalFieldKey)} / ${intl.formatMessage({
                  id:
                    loading || !data?.dashboardTarget
                      ? placeholderIntlKey
                      : getUnitIntlKey(data.dashboardTarget[key][index].unit),
                })}`}
              </Typography>
            </Grid>
            {formState.errors[key] && formState.errors[key]![index]?.value?.message && (
              <Grid>
                <ErrorText>{formState.errors[key]![index]!.value!.message}</ErrorText>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );

  return (
    <StyledPaper>
      <BeforeExitPrompt when={formState.isDirty} message={intl.formatMessage({ id: "errors.common.leave-page" })} />
      <Grid container rowSpacing={2}>
        <form onSubmit={handleSubmitForm}>
          {renderTotalField({ titleIntl: "dashboard.env.ghg", key: "ghgTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.ghg",
            key: "ghgIntensity",
            fields: ghgIntensityFieldArray.fields,
            totalFieldKey: "ghgTotal",
          })}

          {renderTotalField({ titleIntl: "dashboard.env.energy", key: "energyTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.energy",
            key: "energyIntensity",
            fields: energyIntensityFieldArray.fields,
            totalFieldKey: "energyTotal",
          })}

          {renderTotalField({ titleIntl: "dashboard.env.water", key: "waterTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.water",
            key: "waterIntensity",
            fields: waterIntensityFieldArray.fields,
            totalFieldKey: "waterTotal",
          })}

          {renderTotalField({ titleIntl: "dashboard.env.waste", key: "wasteTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.waste",
            key: "wasteIntensity",
            fields: wasteIntensityFieldArray.fields,
            totalFieldKey: "wasteTotal",
          })}

          {renderTotalField({ titleIntl: "dashboard.env.waste.hazardous", key: "hazardousWasteTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.waste.hazardous",
            key: "hazardousWasteIntensity",
            fields: hazardousWasteIntensityFieldArray.fields,
            totalFieldKey: "hazardousWasteTotal",
          })}

          {renderTotalField({ titleIntl: "dashboard.env.waste.non-hazardous", key: "nonHazardousWasteTotal" })}
          {renderIntensityField({
            titleIntl: "dashboard.env.waste.non-hazardous",
            key: "nonHazardousWasteIntensity",
            fields: nonHazardousWasteIntensityFieldArray.fields,
            totalFieldKey: "nonHazardousWasteTotal",
          })}

          <Grid xs={12}>
            <Button variant="contained" type="submit">
              {intl.formatMessage({ id: "button.submit" })}
            </Button>
          </Grid>
        </form>
      </Grid>
    </StyledPaper>
  );
};
