import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetCommunityInvestmentDataDocument,
  GetCommunityInvestmentDataQuery,
  GetCommunityInvestmentDataQueryVariables,
  SubmitCommunityInvestmentDocument,
  SubmitCommunityInvestmentMutation,
  SubmitCommunityInvestmentMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getCommunityInvestmentData: (
    variables: GetCommunityInvestmentDataQueryVariables
  ) => Promise<GetCommunityInvestmentDataQuery["project"]["communityInvestment"]>;
  submitCommunityInvestment: (
    variables: SubmitCommunityInvestmentMutationVariables
  ) => Promise<SubmitCommunityInvestmentMutation["submitCommunityInvestment"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getCommunityInvestmentData = useCallback(
    async (variables: GetCommunityInvestmentDataQueryVariables) => {
      const response = await client.query<GetCommunityInvestmentDataQuery, GetCommunityInvestmentDataQueryVariables>({
        query: GetCommunityInvestmentDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.communityInvestment;
    },
    [client]
  );

  const submitCommunityInvestment = useCallback(
    async (variables: SubmitCommunityInvestmentMutationVariables) => {
      const response = await client.mutate<
        SubmitCommunityInvestmentMutation,
        SubmitCommunityInvestmentMutationVariables
      >({
        mutation: SubmitCommunityInvestmentDocument,
        variables,
      });

      return response.data?.submitCommunityInvestment ?? false;
    },
    [client]
  );

  return {
    getCommunityInvestmentData,
    submitCommunityInvestment,
  };
};
