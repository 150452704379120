import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, InjuryAndFatalitiesParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newInjuryAndFatalitiesRow } from "../consts";
import { InjuryAndFatalitiesRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: InjuryAndFatalitiesRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getInjuryAndFatalitiesData, submitInjuryAndFatalities } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const injuryAndFatalities = await getInjuryAndFatalitiesData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = injuryAndFatalities.flatMap((i) =>
          i.records.map<InjuryAndFatalitiesRecordRow>((r) => ({
            ...newInjuryAndFatalitiesRow,
            recordId: r.id,
            originalCompanyId: i.projectCompany.id,
            companyId: i.projectCompany.id,
            remarks: r.remarks,
            occurrenceDate: tryParseDateString(r.occurrenceDate, DateFormat.Date),
            numberOfInjuries: r.numberOfInjuries,
            daysOfInjury: r.daysOfInjury,
            numberOfFatalities: r.numberOfFatalities,
            incidentDescription: r.incidentDescription,
            status: r.status,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getInjuryAndFatalitiesData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<InjuryAndFatalitiesParam>((e) => {
          const data = e.data as DataGridRow<InjuryAndFatalitiesRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              occurrenceDate: toDate(data.occurrenceDate, DateFormat.Date),
              numberOfInjuries: data.numberOfInjuries,
              daysOfInjury: data.daysOfInjury,
              numberOfFatalities: data.numberOfFatalities,
              incidentDescription: data.incidentDescription,
              status: data.status,
            },
          };
        });

        return await submitInjuryAndFatalities({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitInjuryAndFatalities]
  );

  return { getData, submitData };
};
