import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { LoginPanel } from "./components/login-panel";
import { ResetPasswordPanel } from "./components/reset-password-panel";
import { LoginPageMode } from "./consts";

const useStyles = makeStyles()(({ palette }) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: palette.primary.contrastText,
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

export const Login: React.FC = () => {
  const [userId, setUserId] = useState("");
  const [mode, setMode] = useState(LoginPageMode.Login);

  const { classes } = useStyles();
  const history = useHistory();

  const handleOpenResetPasswordPanel = (id: string) => {
    if (id) {
      setMode(LoginPageMode.ResetPassword);
      setUserId(id);
    }
  };

  const handleSuccessLogin = () => {
    history.push("/");
  };

  return (
    <main className={classes.main}>
      {mode === LoginPageMode.Login ? (
        <LoginPanel onOpenResetPasswordPanel={handleOpenResetPasswordPanel} onSuccessLogin={handleSuccessLogin} />
      ) : (
        <ResetPasswordPanel userId={userId} onSuccessLogin={handleSuccessLogin} />
      )}
    </main>
  );
};
