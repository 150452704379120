import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetTurnoverDataDocument,
  GetTurnoverDataQuery,
  GetTurnoverDataQueryVariables,
  SubmitTurnoverDocument,
  SubmitTurnoverMutation,
  SubmitTurnoverMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getTurnoverData: (variables: GetTurnoverDataQueryVariables) => Promise<GetTurnoverDataQuery["project"]["turnover"]>;
  submitTurnover: (variables: SubmitTurnoverMutationVariables) => Promise<SubmitTurnoverMutation["submitTurnover"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getTurnoverData = useCallback(
    async (variables: GetTurnoverDataQueryVariables) => {
      const response = await client.query<GetTurnoverDataQuery, GetTurnoverDataQueryVariables>({
        query: GetTurnoverDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.turnover;
    },
    [client]
  );

  const submitTurnover = useCallback(
    async (variables: SubmitTurnoverMutationVariables) => {
      const response = await client.mutate<SubmitTurnoverMutation, SubmitTurnoverMutationVariables>({
        mutation: SubmitTurnoverDocument,
        variables,
      });

      return response.data?.submitTurnover ?? false;
    },
    [client]
  );

  return {
    getTurnoverData,
    submitTurnover,
  };
};
