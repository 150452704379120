import React from "react";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()(({ spacing }) => ({
  value: {
    marginTop: spacing(1),
  },
}));

export type FormatterDisplayValueProps = {
  isDisable: boolean;
  answer: number | string;
  string: string | boolean;
};

const FormatterDisplayValue: React.FC<FormatterDisplayValueProps> = (props) => {
  const { classes } = useStyles();

  let className = props.children ? "formatter error-cell" : "formatter";
  if (props.isDisable) className = "formatter disabled";
  const answer =
    isNaN(props.answer as number) || props.answer === "" || props.string
      ? props.answer
      : new Intl.NumberFormat().format(props.answer as number);
  return (
    <div className={className}>
      <section className={classes.value}>{answer}</section>
      {props.children}
    </div>
  );
};

export default FormatterDisplayValue;
