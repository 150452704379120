import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, SuccessfulRecord, TrainingRatioParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { useCallback, useContext } from "react";
import { newTrainingRatioRow } from "../consts";
import { useAPI } from "../hook/api";
import { TrainingRatioRecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: TrainingRatioRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getTrainingRatioData, submitTrainingRatio } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const trainingRatio = await getTrainingRatioData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = trainingRatio.flatMap((t) =>
          t.records.map<TrainingRatioRecordRow>((r) => ({
            ...newTrainingRatioRow,
            recordId: r.id,
            originalCompanyId: t.projectCompany.id,
            companyId: t.projectCompany.id,
            remarks: r.remarks,
            numberOfEmployees: r.numberOfEmployees,
            male: r.male,
            female: r.female,
            middleLevelManagement: r.middleLevelManagement,
            seniorManagement: r.seniorManagement,
            generalAndTechnicalEmployees: r.generalAndTechnicalEmployees,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getTrainingRatioData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<TrainingRatioParam>((e) => {
          const data = e.data as DataGridRow<TrainingRatioRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              numberOfEmployees: data.numberOfEmployees,
              male: data.male,
              female: data.female,
              seniorManagement: data.seniorManagement,
              middleLevelManagement: data.middleLevelManagement,
              generalAndTechnicalEmployees: data.generalAndTechnicalEmployees,
            },
          };
        });

        return await submitTrainingRatio({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitTrainingRatio]
  );

  return { getData, submitData };
};
