import { ValidateCompanyResult } from "components/data-grid/hook/project-company";
import * as yup from "yup";
import { NumberSchema, StringSchema } from "yup";

import { DateFormat, toMoment } from "../date-time";

function allCompaniesValid(this: StringSchema, msg: string, { isValid, error }: ValidateCompanyResult) {
  return this.test("allCompaniesValid", msg, function () {
    return isValid ? true : this.createError({ message: error });
  });
}

/** Is start date same of before end date */
function validDateRange(this: StringSchema, msg: string, startDateProperty: string, endDateProperty: string) {
  return this.test("validDateRange", msg, function () {
    if (this.parent[startDateProperty] === "" || this.parent[endDateProperty] === "") {
      return false;
    }
    const startDate = toMoment(this.parent[startDateProperty], DateFormat.Date);
    const endDate = toMoment(this.parent[endDateProperty], DateFormat.Date);
    return startDate.isSameOrBefore(endDate);
  });
}

/** Is value unique in an array of values  */
function unique(this: StringSchema, msg: string, property: string, values: Record<string, unknown>[]) {
  return this.test("isUnique", msg, function (param) {
    if (!param) return false;

    const paramInArrayCount = values.filter((x) => {
      const value = x[property];
      return typeof value === "string" && value.trim() === param.trim();
    }).length;

    // True if length === 0 (not exist) or 1 (only exists once)
    return paramInArrayCount <= 1;
  });
}

/** Does value exist in an array of values */
function notExist(this: StringSchema, msg: string, property: string, values: Record<string, unknown>[]) {
  return this.test("notExist", msg, function (param) {
    if (!param) return false;

    return !values.some((x) => {
      const value = x[property];
      return typeof value === "string" && value.trim() === param.trim();
    });
  });
}

/** Does sum of every property equal to "equalTo" property */
function sumEqual(this: NumberSchema, msg: string, properties: string[], equalTo: string) {
  return this.test("sumProperties", msg, function () {
    const values = properties.map((p) => this.parent[p]);
    const sum = values.reduce((a, b) => {
      if (!b) b = 0;
      return a + b;
    }, 0);
    return this.parent[equalTo] === sum;
  });
}

/** Is number 0 or positive integer */
function nonNegativeInteger(this: NumberSchema, msg: string) {
  return this.min(0, msg).integer(msg);
}

yup.addMethod(yup.string, "allCompaniesValid", allCompaniesValid);
yup.addMethod(yup.string, "validDateRange", validDateRange);
yup.addMethod(yup.string, "unique", unique);
yup.addMethod(yup.string, "notExist", notExist);
yup.addMethod(yup.number, "sumEqual", sumEqual);
yup.addMethod(yup.number, "nonNegativeInteger", nonNegativeInteger);

export const Yup = yup;
