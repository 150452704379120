import { useEffect, useRef, useState } from "react";

export const useFetch = (fetchFn: () => void): { refetch: () => void } => {
  const isInitialized = useRef(false);
  const [isRefetch, setIsRefetch] = useState(false);

  const refetch = () => {
    setIsRefetch(true);
  };

  useEffect(() => {
    if (!isInitialized.current) {
      fetchFn();
      isInitialized.current = true;
    }
  }, [fetchFn]);

  useEffect(() => {
    if (isRefetch) {
      fetchFn();
      setIsRefetch(false);
    }
  }, [fetchFn, isRefetch]);

  return { refetch };
};
