import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, OtherMobileMachineryParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { useCallback, useContext } from "react";
import { newOtherMobileMachineryRow } from "../consts";
import { useAPI } from "../hook/api";
import { OtherMobileMachineryRecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: OtherMobileMachineryRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getOtherMobileMachineryData, submitOtherMobileMachinery } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const otherMobileMachinery = await getOtherMobileMachineryData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = otherMobileMachinery.flatMap((o) =>
          o.records.map<OtherMobileMachineryRecordRow>((r) => ({
            ...newOtherMobileMachineryRow,
            recordId: r.id,
            originalCompanyId: o.projectCompany.id,
            companyId: o.projectCompany.id,
            remarks: r.remarks,
            machineryType: r.machineryType,
            fuelType: r.fuelType,
            petrolEngine: r.petrolEngine,
            fuelConsumption: r.fuelConsumption,
            fuelUnit: r.fuelUnit,
            enginePower: r.enginePower,
            numberOfHour: r.numberOfHour,
            energy: r.energy,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getOtherMobileMachineryData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<OtherMobileMachineryParam>((e) => {
          const data = e.data as DataGridRow<OtherMobileMachineryRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              fileToUpload: data.fileToUpload ?? [],
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              remarks: data.remarks,
              machineryType: data.machineryType,
              fuelType: data.fuelType,
              petrolEngine: data.petrolEngine ?? "",
              fuelConsumption: data.fuelConsumption,
              fuelUnit: data.fuelUnit,
              enginePower: data.enginePower,
              numberOfHour: data.numberOfHour,
              energy: data.energy,
              forkliftClassification: data.forkliftClassification,
            },
          };
        });

        return await submitOtherMobileMachinery({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitOtherMobileMachinery]
  );

  return { getData, submitData };
};
