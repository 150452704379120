import { IndexType } from "generated/graphql";

import { CompanyType } from "components/data-grid";
import { title } from "layout/components/title";
import { StepperItem } from "./types";

const environmentalRoute: Omit<StepperItem, "label">[] = [
  {
    key: CompanyType.Company,
    status: "",
    path: "/stepper/company",
  },
  {
    key: IndexType.NormalizationFactor1,
    path: "/stepper/nf1",
    status: "",
  },
  {
    key: IndexType.NormalizationFactor2,
    path: "/stepper/nf2",
    status: "",
  },
  {
    key: IndexType.NormalizationFactor3,
    path: "/stepper/nf3",
    status: "",
  },
  {
    key: IndexType.EnergyConsumption,
    path: "/stepper/energy",
    status: "",
  },
  {
    key: IndexType.MobileFuel,
    path: "/stepper/fuel-mobile",
    status: "",
  },
  {
    key: IndexType.StationaryFuel,
    path: "/stepper/fuel-stationary",
    status: "",
  },
  {
    key: IndexType.OtherMobileMachinery,
    path: "/stepper/other-mobile-machinery",
    status: "",
  },
  {
    key: IndexType.WaterConsumption,
    path: "/stepper/water",
    status: "",
  },
  {
    key: IndexType.OfficePaperUsage,
    path: "/stepper/paper",
    status: "",
  },
  {
    key: IndexType.NonHazardousWaste,
    path: "/stepper/non-hazardous-waste",
    status: "",
  },
  {
    key: IndexType.HazardousWaste,
    path: "/stepper/hazardous-waste",
    status: "",
  },
  {
    key: IndexType.PackagingMaterial,
    path: "/stepper/packaging-material",
    status: "",
  },
  {
    key: IndexType.RefrigerantAndFireEx,
    path: "/stepper/refrigerant-and-fire",
    status: "",
  },
  {
    key: IndexType.Tree,
    path: "/stepper/tree",
    status: "",
  },
  {
    key: IndexType.Others,
    path: "/stepper/others",
    status: "",
  },
];

const socialRoute: Omit<StepperItem, "label">[] = [
  {
    key: IndexType.Workforce,
    path: "/stepper/workforce",
    status: "",
  },
  {
    key: IndexType.Turnover,
    path: "/stepper/turnover",
    status: "",
  },
  {
    key: IndexType.InjuryAndFatalities,
    path: "/stepper/injury-and-fatalities",
    status: "",
  },
  {
    key: IndexType.DevelopmentAndTraining,
    path: "/stepper/development-and-training",
    status: "",
  },
  {
    key: IndexType.TrainingRatio,
    path: "/stepper/training-ratio",
    status: "",
  },

  {
    key: IndexType.LabourResponsibilityPolicy,
    path: "/stepper/labour-responsibility-policy",
    status: "",
  },
  {
    key: IndexType.SupplyChainManagement,
    path: "/stepper/supply-chain-management",
    status: "",
  },
  {
    key: IndexType.ProductResponsibility,
    path: "/stepper/product-responsibility",
    status: "",
  },

  {
    key: IndexType.ProductResponsibilityPolicy,
    path: "/stepper/product-responsibility-policy",
    status: "",
  },
  {
    key: IndexType.CommunityInvestment,
    path: "/stepper/community-investment",
    status: "",
  },
];

export const environmentalSteppers = environmentalRoute.map<StepperItem>((r) => {
  return {
    ...r,
    label: title[r.path],
  };
});

export const socialSteppers = socialRoute.map<StepperItem>((r) => {
  return {
    ...r,
    label: title[r.path],
  };
});
