import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { KeyArray } from "utils/types";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newTrainingRatioRow } from "./consts";
import { useActions } from "./hook/action";
import { TrainingRatioRecordRow } from "./types";

const source = IndexType.TrainingRatio;

export const TrainingRatioPage: React.FC = () => {
  const [initRows, setInitRows] = useState<TrainingRatioRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "numberOfEmployees",
        name: intl.formatMessage({ id: "excel.trainingRatio.numberOfEmployees" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "numberOfEmployees"),
      },
      {
        key: "male",
        name: intl.formatMessage({ id: "excel.workforce.male" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "male"),
      },
      {
        key: "female",
        name: intl.formatMessage({ id: "excel.workforce.female" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "female"),
      },
      {
        key: "seniorManagement",
        name: intl.formatMessage({ id: "excel.workforce.seniorManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "seniorManagement"),
      },
      {
        key: "middleLevelManagement",
        name: intl.formatMessage({ id: "excel.workforce.middleLevelManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "middleLevelManagement"),
      },
      {
        key: "generalAndTechnicalEmployees",
        name: intl.formatMessage({ id: "excel.workforce.generalAndTechnicalEmployees" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "generalAndTechnicalEmployees"),
      },
    ],
    [intl, companyOptions]
  );

  const introContent = intl.formatMessage({ id: "excel.trainingRatio.guide" });
  const steps = getSteps(intl);

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<TrainingRatioRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as TrainingRatioRecordRow[]);

    const gender: KeyArray<TrainingRatioRecordRow, "male" | "female"> = ["male", "female"];
    const grade: KeyArray<
      TrainingRatioRecordRow,
      "seniorManagement" | "middleLevelManagement" | "generalAndTechnicalEmployees"
    > = ["seniorManagement", "middleLevelManagement", "generalAndTechnicalEmployees"];

    const validateByCategory = (keys: string[]) =>
      Yup.number()
        .required("errors.common.require")
        .sumEqual("error.workforce.sumOfTotal", keys, "numberOfEmployees")
        .nonNegativeInteger("error.common.positiveInteger");

    return Yup.array(
      Yup.object<Partial<TrainingRatioRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        numberOfEmployees: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),

        male: validateByCategory(gender),
        female: validateByCategory(gender),

        seniorManagement: validateByCategory(grade),
        middleLevelManagement: validateByCategory(grade),
        generalAndTechnicalEmployees: validateByCategory(grade),
      }).required()
    ).defined();
  });

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newTrainingRatioRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "numberOfEmployees", value: 0 },
          { property: "male", value: 0 },
          { property: "female", value: 0 },
          { property: "seniorManagement", value: 0 },
          { property: "middleLevelManagement", value: 0 },
          { property: "generalAndTechnicalEmployees", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
