import cuid from "cuid";

import { CommonReportRecordRow, IColumnItem, IUpdatedItem } from "utils/interfaces";
import { UseEventReturns } from "../hook/event";
import { SubmitDataErrorParams } from "../hook/types";
import { DataGridRow, OnUpdateDataGridRows } from "../types";
import { combineActionRowDisplay } from "./combine-action-row-display";
import { getRowsDisplayValues, RowWithDisplayValue } from "./get-rows-display-values";

export interface DataGridState<T extends CommonReportRecordRow = CommonReportRecordRow> {
  /**
   * Key-value mapping of rows, which key is rowID and value is row content.
   *
   * rowsBaseMap follows the order of rows passed during initialization, and is used as
   * reference of "no sorting specified" order.
   */
  rowsBaseMap: Record<string, DataGridRow<T>>;
  /**
   * Array of rows which is filtered and sorted.
   *
   * Rows inside DataGrid always follow order of rowsDisplay.
   */
  rowsDisplay: DataGridRow<T>[];
  /**
   * Array of rows including their rowID and values by column. Values stored here are those
   * showing on screen.
   *
   * rowsWithDisplayValue is used for filtering. values stored in rowsBaseMap may not be the
   * same as what users see (e.g., columns providing dropdown options). This property provides
   * a quick way to check filter condition.
   */
  rowsWithDisplayValue: RowWithDisplayValue[];
  columns: IColumnItem[];
  scrollIndex: number;
  isSorting: boolean;
  isFiltering: boolean;
  isSubmitting: boolean;
  isTouched: boolean;
  selectedRowIndex: number;
  filterString: string;
  sortData?: {
    columnKey: string;
    direction: "ASC" | "DESC" | "NONE";
  };
}

export const initState = <T extends CommonReportRecordRow = CommonReportRecordRow>(): DataGridState<T> => ({
  rowsBaseMap: {},
  rowsDisplay: [],
  rowsWithDisplayValue: [],
  columns: [],
  scrollIndex: 0,
  isSorting: false,
  isFiltering: false,
  isSubmitting: false,
  isTouched: false,
  selectedRowIndex: -1,
  filterString: "",
});

export type DataGridActionType<T extends CommonReportRecordRow> =
  | {
      type: "init";
      rowsBase: T[];
      columnsForTransposedRows: IColumnItem[];
    }
  | { type: "sort"; sortData?: DataGridState<T>["sortData"] }
  | { type: "filter"; filterString: string }
  | { type: "addNewRow"; createEvent: UseEventReturns["emitCreateEvent"]; newRow: T }
  | { type: "setIsSubmitting"; isSubmitting: boolean }
  | { type: "setIsTouched"; isTouched: boolean }
  | {
      type: "updateRow";
      updateEvent: UseEventReturns["emitUpdateEvent"];
      onUpdateRows?: OnUpdateDataGridRows<T>;
      updatedItem: IUpdatedItem<T>;
    }
  | { type: "updateSuccessRow"; events: SubmitDataErrorParams["success"]; removeEvent: UseEventReturns["removeEvents"] }
  | { type: "cloneRow"; cloneEvent: UseEventReturns["emitCreateEvent"]; cloneSourceRowIndex: number }
  | {
      type: "removeRow";
      removeEvent: UseEventReturns["emitDeleteEvent"];
      removeRowIndex: number;
    }
  | { type: "selectedRowIndex"; selectedRowIndex: number }
  | { type: "scrollIndex"; scrollIndex: number };

export const reducer =
  <T extends CommonReportRecordRow>() =>
  (state: DataGridState<T>, action: DataGridActionType<T>): DataGridState<T> => {
    switch (action.type) {
      case "init": {
        const { rowsBase, columnsForTransposedRows } = action;
        const rowsBaseMap: Record<string, DataGridRow<T>> = {};

        for (const row of rowsBase) {
          const key = cuid();
          rowsBaseMap[key] = { ...row, rowId: key };
        }
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(rowsBaseMap), columnsForTransposedRows);

        const rowsDisplay = combineActionRowDisplay({
          rowsBaseMap,
          rowsWithDisplayValue,
          filterString: state.filterString,
          sortData: state.sortData,
        });

        return {
          ...state,
          scrollIndex: 0,
          columns: columnsForTransposedRows,
          rowsBaseMap,
          rowsDisplay,
          rowsWithDisplayValue,
        };
      }
      case "sort": {
        const rowsDisplay = combineActionRowDisplay({
          rowsBaseMap: state.rowsBaseMap,
          rowsWithDisplayValue: state.rowsWithDisplayValue,
          filterString: state.filterString,
          sortData: action.sortData,
        });

        return {
          ...state,
          isSorting: true,
          rowsDisplay,
          sortData: action.sortData,
        };
      }
      case "filter": {
        const rowsDisplay = combineActionRowDisplay({
          rowsBaseMap: state.rowsBaseMap,
          rowsWithDisplayValue: state.rowsWithDisplayValue,
          filterString: action.filterString,
          sortData: state.sortData,
        });

        return {
          ...state,
          rowsDisplay: rowsDisplay,
          isFiltering: true,
          filterString: action.filterString,
        };
      }
      case "setIsSubmitting": {
        return { ...state, isSubmitting: action.isSubmitting };
      }
      case "setIsTouched": {
        return { ...state, isTouched: action.isTouched };
      }
      case "addNewRow": {
        const { rowsBaseMap, rowsDisplay, columns } = state;
        const { createEvent } = action;
        const newRowsBaseMap = { ...rowsBaseMap };
        const newRowsDisplay = [...rowsDisplay];

        const newRow: DataGridRow<T> = { ...action.newRow, rowId: cuid() };
        newRowsBaseMap[newRow.rowId] = newRow;
        newRowsDisplay.push(newRow);
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(newRowsBaseMap), columns);

        createEvent(newRow);

        return {
          ...state,
          rowsBaseMap: newRowsBaseMap,
          rowsDisplay: newRowsDisplay,
          rowsWithDisplayValue,
          scrollIndex: rowsDisplay.length - 1,
          isTouched: true,
        };
      }
      case "cloneRow": {
        const { rowsBaseMap, rowsDisplay, columns } = state;
        const { cloneSourceRowIndex, cloneEvent } = action;
        const newRowsBaseMap = { ...rowsBaseMap };
        const newRowsDisplay = [...rowsDisplay];

        const cloneSourceRow = rowsDisplay[cloneSourceRowIndex];
        const clonedRow = {
          ...cloneSourceRow,
          rowId: cuid(),
          recordId: "",
          errors: {},
        } as DataGridRow<T>;

        if (clonedRow.fileInfo) clonedRow.fileInfo = [];
        if (clonedRow.fileToUpload) clonedRow.fileToUpload = [];

        newRowsBaseMap[clonedRow.rowId] = clonedRow;
        newRowsDisplay.splice(cloneSourceRowIndex + 1, 0, clonedRow);
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(newRowsBaseMap), columns);

        cloneEvent(clonedRow);

        return {
          ...state,
          rowsBaseMap: newRowsBaseMap,
          rowsDisplay: newRowsDisplay,
          rowsWithDisplayValue,
          scrollIndex: cloneSourceRowIndex,
          isTouched: true,
        };
      }
      case "updateRow": {
        const { rowsBaseMap, rowsDisplay, columns } = state;
        const { updatedItem, updateEvent, onUpdateRows } = action;
        const { updated, fromRow, toRow } = updatedItem;

        const newRowsBaseMap = { ...rowsBaseMap };
        let newRowsDisplay = [...rowsDisplay] as DataGridRow<T>[];

        for (let i = fromRow; i <= toRow; i++) {
          let rowData = newRowsDisplay[i];
          rowData = { ...rowData, ...updated };

          newRowsDisplay[i] = rowData;
        }

        if (onUpdateRows) {
          newRowsDisplay = onUpdateRows(newRowsDisplay, updatedItem);
        }

        for (let i = fromRow; i <= toRow; i++) {
          const rowData = newRowsDisplay[i];
          newRowsBaseMap[rowData.rowId] = rowData;
        }
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(newRowsBaseMap), columns);

        const updatedRows = newRowsDisplay.slice(fromRow, toRow + 1);
        updateEvent(updatedRows);

        return {
          ...state,
          rowsBaseMap: newRowsBaseMap,
          rowsDisplay: newRowsDisplay,
          rowsWithDisplayValue,
          isTouched: true,
        };
      }
      case "updateSuccessRow": {
        const { rowsBaseMap, columns } = state;
        const { events, removeEvent } = action;

        const newRowsBaseMap = { ...rowsBaseMap };

        for (const { id: rowId, recordId } of events) {
          if (newRowsBaseMap[rowId]) newRowsBaseMap[rowId].recordId = recordId;
        }
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(newRowsBaseMap), columns);

        removeEvent(events.map(({ id }) => id));

        return {
          ...state,
          rowsBaseMap: newRowsBaseMap,
          rowsWithDisplayValue,
          isTouched: true,
        };
      }
      case "removeRow": {
        const { rowsBaseMap, rowsDisplay, columns } = state;
        const { removeRowIndex, removeEvent } = action;

        const newRowsBaseMap = { ...rowsBaseMap };
        const newRowsDisplay = [...rowsDisplay];

        const removedRow = newRowsDisplay.splice(removeRowIndex, 1)[0];
        delete newRowsBaseMap[removedRow.rowId];
        const rowsWithDisplayValue = getRowsDisplayValues(Object.values(newRowsBaseMap), columns);

        removeEvent(removedRow);

        return {
          ...state,
          rowsBaseMap: newRowsBaseMap,
          rowsDisplay: newRowsDisplay,
          rowsWithDisplayValue,
          isTouched: true,
        };
      }
      case "selectedRowIndex": {
        return { ...state, selectedRowIndex: action.selectedRowIndex };
      }
      case "scrollIndex": {
        return { ...state, scrollIndex: action.scrollIndex };
      }
      default:
        return state;
    }
  };
