import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetTailorMadeIndicesAndFieldsDocument,
  GetTailorMadeIndicesAndFieldsQuery,
  GetTailorMadeIndicesAndFieldsQueryVariables,
  SubmitTailorMadeIndexDocument,
  SubmitTailorMadeIndexMutation,
  SubmitTailorMadeIndexMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getTailorMadeIndicesAndFields: (
    variables: GetTailorMadeIndicesAndFieldsQueryVariables
  ) => Promise<GetTailorMadeIndicesAndFieldsQuery["project"]>;
  submitTailorMadeIndex: (
    varaibles: SubmitTailorMadeIndexMutationVariables
  ) => Promise<SubmitTailorMadeIndexMutation["submitTailorMadeIndex"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getTailorMadeIndicesAndFields = useCallback(
    async (variables: GetTailorMadeIndicesAndFieldsQueryVariables) => {
      const response = await client.query<
        GetTailorMadeIndicesAndFieldsQuery,
        GetTailorMadeIndicesAndFieldsQueryVariables
      >({
        query: GetTailorMadeIndicesAndFieldsDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project;
    },
    [client]
  );

  const submitTailorMadeIndex = useCallback(
    async (variables: SubmitTailorMadeIndexMutationVariables) => {
      const response = await client.mutate<SubmitTailorMadeIndexMutation, SubmitTailorMadeIndexMutationVariables>({
        mutation: SubmitTailorMadeIndexDocument,
        variables,
      });

      return response.data?.submitTailorMadeIndex ?? false;
    },
    [client]
  );

  return {
    getTailorMadeIndicesAndFields,
    submitTailorMadeIndex,
  };
};
