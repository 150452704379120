import { RecordStatus } from "generated/graphql";
import { ProductResponsibilityPolicyRecordRow } from "./types";

export const newProductResponsibilityPolicyRow: ProductResponsibilityPolicyRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  practicesDesc: "",
  qualityDesc: "",
  dataDesc: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
