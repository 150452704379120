import React, { useState } from "react";

import { AssessmentOutlined as DashboardIcon } from "@mui/icons-material";
import { Divider, Tab, Tabs, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DashboardTab } from "./components/consts";
import { EnvironmentalDashboard, SocialDashboard } from "./components";

const StyledTabs = withStyles({
  root: {
    minHeight: 36,
  },
})(Tabs);

const StyledTab = withStyles(({ palette, spacing, typography }) => ({
  root: {
    minHeight: 36,
    minWidth: 240,
    padding: spacing(0, 1.5),
    color: palette.primary.main,
    fontSize: typography.h5.fontSize,
    fontWeight: "bold",
    textTransform: "none",
  },
}))(Tab);

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    height: "100%",
  },
  dashboardText: {
    padding: spacing(0, 1),
    fontWeight: "bold",
  },
}));

const Page: React.FC = () => {
  const [tab, setTab] = useState(DashboardTab.Environmental);

  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <>
      <Grid container>
        <Grid xs={12}>
          <Grid container>
            <Grid xs={2}>
              <Grid container alignItems="center" className={classes.container}>
                <DashboardIcon color="primary" />
                <Typography variant="h5" color="primary" className={classes.dashboardText}>
                  {intl.formatMessage({ id: "dashboard.header" })}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={8}>
              <StyledTabs value={tab} onChange={(_, value) => setTab(value)}>
                <StyledTab label={intl.formatMessage({ id: "dashboard.tab.env" })} value={DashboardTab.Environmental} />
                <StyledTab label={intl.formatMessage({ id: "dashboard.tab.social" })} value={DashboardTab.Social} />
              </StyledTabs>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>{tab === DashboardTab.Environmental ? <EnvironmentalDashboard /> : <SocialDashboard />}</Grid>
      </Grid>
    </>
  );
};

export default Page;
