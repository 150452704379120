import { AuthContext } from "provider/auth";
import { useContext, useMemo } from "react";

const limitedClientGroupCode = ["0034"];
const adminUser = ["ava342024"];

/**
 *  NOTE
 *  TODO
 *
 *  A workaround to limit some functionalities for specific users.
 */
export const usePermission = () => {
  const { me } = useContext(AuthContext);

  const isPermitted = useMemo(() => {
    const clientGroupCode = me.clientGroup?.groupCode;
    const username = me.user?.loginId;

    const isLimitedClient = !!clientGroupCode && limitedClientGroupCode.includes(clientGroupCode);
    const isAdmin = !!username && adminUser.includes(username);

    return isLimitedClient ? isAdmin : true;
  }, [me]);

  return isPermitted;
};
