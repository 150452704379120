import React from "react";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Breadcrumbs } from "components/breadcrumbs";
import { title } from "layout/components/title";

import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles()(({ spacing }) => ({
  paper: {
    padding: spacing(1, 2),
  },
}));

export const Layer: React.FC = ({ children }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { pathname } = useLocation();

  const renderBreadcrumbs = () => (
    <>
      {pathname !== "/stepper" && (
        <Grid xs={12}>
          <Paper className={classes.paper}>
            <Breadcrumbs
              items={[
                {
                  link: "/stepper",
                  content: intl.formatMessage({ id: "layout.menu.admin.iesg" }),
                },
                {
                  content: title[pathname] && intl.formatMessage({ id: title[pathname] }),
                },
              ]}
            />
          </Paper>
        </Grid>
      )}
    </>
  );

  return (
    <>
      <Grid container={true} spacing={1}>
        {renderBreadcrumbs()}
        <Grid xs={12}>{children}</Grid>
      </Grid>
    </>
  );
};
