import React from "react";

import TableBody from "@mui/material/TableBody";

export type BodyProps = {
  tableBody: React.ReactNode;
};

export const Body: React.FC<BodyProps> = (props) => {
  return <TableBody>{props.tableBody}</TableBody>;
};
