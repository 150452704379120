import React, { useContext } from "react";

import { LanguageContext } from "provider/language";
import { formatNumberToPercentage, formatNumberWithNotation } from "utils/format";
import { DashboardColor } from "../../consts";
import { ChartInputWithLabelColor } from "../types";
import { PieChart } from "./pie-chart";
import { PieChartLabel } from "./pie-chart-label";

export type PieChartWithLargestValueLabelProps = {
  data: Required<Omit<ChartInputWithLabelColor, "label">>[];
  /** unit of value, only displayed with value when labelType="total" */
  unit?: string;
  /** Content type of label, default is total*/
  labelType?: "total" | "percentage";
};

export const PieChartWithLargestValueLabel: React.FC<PieChartWithLargestValueLabelProps> = (props) => {
  const { data, unit, labelType } = props;

  const { locale } = useContext(LanguageContext);

  const valueSum = data.map(({ value }) => value).reduce((a, b) => a + b, 0);

  const getDatumWithMaxValue = () =>
    data.reduce((a, b) => (a.value >= b.value ? a : b), {
      name: "",
      value: 0,
      color: DashboardColor.background.grey,
    });
  const datumWithMaxValue = getDatumWithMaxValue();

  const getValueLabelText = () => {
    switch (labelType) {
      case "total":
      default:
        return formatNumberWithNotation(locale, valueSum);
      case "percentage":
        return formatNumberToPercentage(datumWithMaxValue.value / valueSum);
    }
  };

  return (
    <PieChart
      names={data.map((d) => d.name)}
      data={data}
      labelComponent={
        <>
          <PieChartLabel
            xByRatio={0.5}
            yByRatio={labelType === "percentage" && datumWithMaxValue.name.length > 16 ? 0.35 : 0.43}
            text={getValueLabelText()}
            style={{
              fontSize: 48,
              fill: datumWithMaxValue.color,
            }}
          />
          {unit && (
            <PieChartLabel
              xByRatio={0.5}
              yByRatio={0.57}
              text={unit}
              style={{
                fontSize: 32,
                fill: datumWithMaxValue.color,
              }}
            />
          )}
          {labelType === "percentage" && (
            <PieChartLabel
              xByRatio={0.5}
              yByRatio={unit ? 0.69 : datumWithMaxValue.name.length > 16 ? 0.59 : 0.55}
              text={
                datumWithMaxValue.name.length > 16
                  ? datumWithMaxValue.name.replaceAll(" ", "\n")
                  : datumWithMaxValue.name
              }
              style={{
                fontSize: 32,
                fill: DashboardColor.text.grey,
              }}
            />
          )}
        </>
      }
    />
  );
};
