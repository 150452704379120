import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetOfficePaperUsageDataDocument,
  GetOfficePaperUsageDataQuery,
  GetOfficePaperUsageDataQueryVariables,
  SubmitOfficePaperUsageDocument,
  SubmitOfficePaperUsageMutation,
  SubmitOfficePaperUsageMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getOfficePaperUsageData: (
    variables: GetOfficePaperUsageDataQueryVariables
  ) => Promise<GetOfficePaperUsageDataQuery["project"]["officePaperUsage"]>;
  submitOfficePaperUsage: (
    variables: SubmitOfficePaperUsageMutationVariables
  ) => Promise<SubmitOfficePaperUsageMutation["submitOfficePaperUsage"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getOfficePaperUsageData = useCallback(
    async (variables: GetOfficePaperUsageDataQueryVariables) => {
      const response = await client.query<GetOfficePaperUsageDataQuery, GetOfficePaperUsageDataQueryVariables>({
        query: GetOfficePaperUsageDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.officePaperUsage;
    },
    [client]
  );

  const submitOfficePaperUsage = useCallback(
    async (variables: SubmitOfficePaperUsageMutationVariables) => {
      const response = await client.mutate<SubmitOfficePaperUsageMutation, SubmitOfficePaperUsageMutationVariables>({
        mutation: SubmitOfficePaperUsageDocument,
        variables,
      });

      return response.data?.submitOfficePaperUsage ?? false;
    },
    [client]
  );

  return {
    getOfficePaperUsageData,
    submitOfficePaperUsage,
  };
};
