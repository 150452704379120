import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { ComparableBarChart, ComparableBarChartProps } from "../charts/bar-chart/comparable-bar-chart";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100, dummyChartInput } from "../consts";
import { StyledContainer } from "../containers/styled-container";
import { TwoBlockContainer } from "../containers/two-block-container";

type BlockProps = ComparableBarChartProps & {
  title?: string;
  subTitle?: string;
};

export type YearlyComparisonBlockProps = {
  title: string;
  left?: BlockProps;
  right?: BlockProps;
};

export const YearlyComparisonBlock: React.FC<YearlyComparisonBlockProps> = (props) => {
  const { left, right, title } = props;

  const renderBlock = (blockProps?: BlockProps) => {
    const { title, subTitle, ...barChartProps } = blockProps ?? { main: dummyChartInput };

    return (
      blockProps && (
        <StyledContainer>
          <Grid container height={DASHBOARD_BLOCK_HEIGHT_100 * 0.7} spacing={1} direction="column" wrap="nowrap">
            {(title || subTitle) && (
              <Grid xs={12} paddingX={0.5} paddingY={0} textAlign="center" color={DashboardColor.text.grey}>
                <Grid container>
                  <Grid xs={12}>
                    <Typography variant="h5">{title}</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="h6">{subTitle}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid xs={12} flexGrow={3}>
              <ComparableBarChart {...barChartProps} />
            </Grid>
          </Grid>
        </StyledContainer>
      )
    );
  };

  return <TwoBlockContainer title={title} left={renderBlock(left)} right={renderBlock(right)} />;
};
