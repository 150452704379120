import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { PackagingMaterialRecordRow } from "./types";

export const newPackagingMaterialRow: PackagingMaterialRecordRow = {
  recordId: "",
  companyId: "",
  packagingMaterialType: "",
  startDate: "",
  endDate: "",
  openingBalance: 0,
  quantityPurchased: 0,
  endingBalance: 0,
  quantityUsed: 0,
  recyclingAmount: 0,
  recyclingMaterialUsageAmount: 0,
  quantityUsedAfterRecycling: 0,
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<PackagingMaterialRecordRow, string> = {
  packagingMaterialType: "tips.material.type",
  recyclingAmount: "tips.material.recycling-amount",
  recyclingMaterialUsageAmount: "tips.material.usage-amount",
};

export enum MaterialType {
  Paper = "paper",
  Plastic = "plastic",
  Metal = "metal",
  Glass = "glass",
  Wood = "wood",
}

export const materialTypeOptions: OptionType[] = [
  { label: "Paper 纸", value: MaterialType.Paper },
  { label: "Plastic 塑胶", value: MaterialType.Plastic },
  { label: "Metal 金属", value: MaterialType.Metal },
  { label: "Glass 玻璃", value: MaterialType.Glass },
  { label: "Wood 木材", value: MaterialType.Wood },
];
