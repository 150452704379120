import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetMobileFuelDataDocument,
  GetMobileFuelDataQuery,
  GetMobileFuelDataQueryVariables,
  SubmitMobileFuelDocument,
  SubmitMobileFuelMutation,
  SubmitMobileFuelMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getMobileFuelData: (
    variables: GetMobileFuelDataQueryVariables
  ) => Promise<GetMobileFuelDataQuery["project"]["mobileFuel"]>;
  submitMobileFuel: (
    variables: SubmitMobileFuelMutationVariables
  ) => Promise<SubmitMobileFuelMutation["submitMobileFuel"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getMobileFuelData = useCallback(
    async (variables: GetMobileFuelDataQueryVariables) => {
      const response = await client.query<GetMobileFuelDataQuery, GetMobileFuelDataQueryVariables>({
        query: GetMobileFuelDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.mobileFuel;
    },
    [client]
  );

  const submitMobileFuel = useCallback(
    async (variables: SubmitMobileFuelMutationVariables) => {
      const response = await client.mutate<SubmitMobileFuelMutation, SubmitMobileFuelMutationVariables>({
        mutation: SubmitMobileFuelDocument,
        variables,
      });

      return response.data?.submitMobileFuel ?? false;
    },
    [client]
  );

  return {
    getMobileFuelData,
    submitMobileFuel,
  };
};
