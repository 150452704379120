import React from "react";

import { Property } from "csstype";

import { formatNumberWithComma } from "utils/format";
import { TitleValueLayout } from "../blocks/title-value-layout";
import { DashboardColor } from "../consts";
import { StyledContainer } from "../containers/styled-container";

export type TotalBlockProps = {
  title: string;
  subTitle?: string;
  value: number;
  valueColor?: Property.Fill;
  unit?: string;
  unitColor?: Property.Fill;
};

export const TotalBlock: React.FC<TotalBlockProps> = (props) => {
  const { title, subTitle, value, valueColor, unit, unitColor } = props;

  return (
    <StyledContainer>
      <TitleValueLayout
        title={title}
        subTitle={subTitle}
        value={formatNumberWithComma(value)}
        footer={unit}
        style={{
          align: "center",
          title: {
            color: DashboardColor.text.grey,
            bold: true,
          },
          subTitle: {
            color: DashboardColor.text.grey,
          },
          value: {
            color: valueColor ?? DashboardColor.text.grey,
            bold: true,
          },
          footer: {
            color: unitColor ?? DashboardColor.text.grey,
          },
        }}
      />
    </StyledContainer>
  );
};
