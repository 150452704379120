import React, { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export const DataGrid: React.FC<AgGridReactProps> = (props) => {
  const defaultColDef = useMemo(
    (): ColDef => ({
      valueSetter: (params) => {
        const fieldName = params.colDef.field;

        if (fieldName) {
          params.data[fieldName] = typeof params.newValue === "string" ? params.newValue.trim() : params.newValue;
        }

        return true;
      },
    }),
    []
  );

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: 640 }}>
      <AgGridReact defaultColDef={defaultColDef} {...props} />
    </div>
  );
};
