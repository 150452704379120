import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { useApolloClient } from "@apollo/client";
import {
  DataTable,
  FullScreenFormDialog,
  HeaderAddButton,
  StyledTableCell,
  useDebounceSearch,
  usePaginationMisc,
  useSort,
} from "components/react-table";
import {
  useCreateClientGroupMutation,
  useGetClientGroupsDataQuery,
  User,
  useResetAdminUserPasswordMutation,
  useUpdateClientGroupMutation,
} from "generated/graphql";
import { useDialog } from "hook";
import { LoadingContext } from "provider/loading";
import { MessageContext } from "provider/message";
import { DialogMode } from "utils/dialog";
import { Yup } from "utils/yup";
import { ClientGroupForm } from "./components/form";
import { newClientGroupRow } from "./consts";
import { ClientGroupListRow } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    width: "100%",
    marginTop: spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: spacing(1),
  },
  snackBar: {
    margin: spacing(1),
    width: "15%",
  },
  inlineFlex: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  button: {
    margin: spacing(1),
  },
  expandedContent: {},
  collapsedContent: {
    borderBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export const ClientGroupPage: React.FC = () => {
  const { classes } = useStyles();

  const [isOpen, setOpen] = useState(false);
  const [initRow, setInitRow] = useState({ ...newClientGroupRow });
  const [dialogMode, setDialogMode] = useState(DialogMode.CREATE);
  const [data, setData] = useState<ClientGroupListRow[]>([]);
  // row index which is expanding, -1 if no row is expanded
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  const selectedUserId = useRef("");

  const intl = useIntl();
  const client = useApolloClient();
  const { setLoading } = useContext(LoadingContext);
  const { showUpdateSuccessMessage, showUpdateFailedMessage } = useContext(MessageContext);

  const [search, setSearch] = useDebounceSearch(() => {
    setPageIndex(0);
    getDataQuery.refetch({ keyword: search });
  });
  const { setSortBy, orderBy } = useSort();
  const {
    state: { pageSize, pageIndex, totalCount },
    setPageSize,
    setPageIndex,
    setTotalCount,
  } = usePaginationMisc();
  const { Dialog, DialogType, openDialog } = useDialog();

  const getDataQuery = useGetClientGroupsDataQuery({
    client,
    notifyOnNetworkStatusChange: true,
    variables: { pageIndex, pageSize, orderBy },
    onCompleted: ({ clientGroupList: { data, count } }) => {
      const rows = data.map((node) => ({
        id: node.id,
        groupCode: node.groupCode,
        groupName: node.groupName,
        adminUsers: node.adminUsers,
        errors: {},
      }));

      setData(rows as ClientGroupListRow[]);
      setTotalCount(count);
    },
  });

  const [createClientGroup, createClientGroupState] = useCreateClientGroupMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ createClientGroup: { success, clientGroup, error } }) => {
      if (success) {
        setInitRow({ ...initRow, ...clientGroup! });
        setDialogMode(DialogMode.EDIT);
        showUpdateSuccessMessage();
        getDataQuery.refetch();
      } else {
        showUpdateFailedMessage(error ?? undefined);
      }
    },
    onError: () => showUpdateFailedMessage(),
  });

  const [updateClientGroup, updateClientGroupState] = useUpdateClientGroupMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ updateClientGroup: { success, clientGroup, error } }) => {
      if (success) {
        setInitRow({ ...initRow, ...clientGroup! });
        showUpdateSuccessMessage();
        getDataQuery.refetch();
      } else {
        showUpdateFailedMessage(error ?? undefined);
      }
    },
    onError: () => showUpdateFailedMessage(),
  });

  const [resetAdminUserPassword, resetAdminUserPasswordState] = useResetAdminUserPasswordMutation({
    client,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ resetUserPassword: { password } }) => {
      openDialog({ content: `New password: ${password}`, type: DialogType.ALERT });
    },
    onError: () => showUpdateFailedMessage("Reset Password Failed"),
  });

  useEffect(() => {
    const isLoading =
      getDataQuery.loading ||
      createClientGroupState.loading ||
      updateClientGroupState.loading ||
      resetAdminUserPasswordState.loading;

    setLoading(isLoading);
  }, [
    setLoading,
    getDataQuery.loading,
    createClientGroupState.loading,
    updateClientGroupState.loading,
    resetAdminUserPasswordState.loading,
  ]);

  const handleResetPassword = () => {
    resetAdminUserPassword({ variables: { userId: selectedUserId.current } });
  };

  const handleOpenResetPasswordDialog = (userId: string) => {
    selectedUserId.current = userId;
    openDialog({
      content: "Are You Sure To Reset Password?",
      type: DialogType.CONFIRM,
      onConfirm: handleResetPassword,
    });
  };

  const handleDialogOpen = (row?) => {
    if (row) {
      setDialogMode(DialogMode.EDIT);
      setInitRow({ ...row.original });
    } else {
      setDialogMode(DialogMode.CREATE);
      setInitRow({ ...newClientGroupRow });
    }
    setOpen(true);
  };

  const handleDialogClose = () => {
    setInitRow({ ...newClientGroupRow });
    setOpen(false);
  };

  const handleSubmitForm = async (
    values: ClientGroupListRow,
    setSubmitting: (value: boolean) => void,
    dialogMode: DialogMode
  ) => {
    try {
      if (dialogMode === DialogMode.EDIT) {
        const { id, groupCode, groupName, adminUsers } = values;

        await updateClientGroup({
          variables: {
            clientGroupId: id,
            data: { groupCode, groupName },
            adminUserCreateData: adminUsers.filter((adminUser) => !adminUser.id),
          },
        });
      } else {
        const { groupCode, groupName, adminUsers } = values;

        createClientGroup({
          variables: {
            data: { groupCode, groupName },
            adminUserData: adminUsers,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const columns = useMemo(
    () => [
      { accessor: "groupCode", Header: "Client Group Code" },
      { accessor: "groupName", Header: "Client Group Name" },
    ],
    []
  );

  const validationSchema = Yup.object().shape({
    groupName: Yup.string().required("Group Name is required"),
    groupCode: Yup.string().required("Group Code is required").min(4),
    adminUsers: Yup.array().min(1, "Please add at least 1 admin user"),
  });

  const renderedRow = (row, rowProps) => (
    <React.Fragment key={row.index}>
      <TableRow {...rowProps}>
        <TableCell>
          <div className={classes.inlineFlex}>
            <IconButton aria-label="Edit" className={classes.fab} onClick={() => handleDialogOpen(row)} size="large">
              <EditIcon />
            </IconButton>
          </div>
        </TableCell>
        {row.cells.map((cell) => {
          const Formatter = cell.render("Cell");
          return (
            <StyledTableCell key={cell.column.id} {...cell.getCellProps()}>
              <div>{Formatter}</div>
            </StyledTableCell>
          );
        })}
        <TableCell component="th" scope="row">
          <IconButton
            size="medium"
            className={classes.button}
            aria-label="expand"
            onClick={() => setExpandedRowIndex(expandedRowIndex !== row.index ? row.index : -1)}
          >
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={8}
          className={expandedRowIndex === row.index ? classes.expandedContent : classes.collapsedContent}
        >
          <Collapse in={expandedRowIndex === row.index}>
            <Grid container>
              {row.original.adminUsers.map((p: User) => (
                <Grid key={p.loginId} xs={12}>
                  <Grid container className={classes.inlineFlex} alignItems="center">
                    <Grid>
                      <Typography variant="h5">login ID: {p.loginId}</Typography>
                    </Grid>
                    <Grid>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.button}
                        size="small"
                        onClick={() => handleOpenResetPasswordDialog(p.id)}
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        state={{ pageSize, pageIndex, totalCount }}
        goToPage={setPageIndex}
        setPageSize={setPageSize}
        renderRow={renderedRow}
        HeaderActionComponent={<HeaderAddButton onClick={() => handleDialogOpen()} />}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSortByChange={setSortBy}
      />
      <Formik
        initialValues={{ ...initRow }}
        onSubmit={(values, { setSubmitting }) => handleSubmitForm(values, setSubmitting, dialogMode)}
        enableReinitialize={true}
        validationSchema={validationSchema}
        render={(formikProps) => (
          <FullScreenFormDialog
            isOpen={isOpen}
            handleDialogClose={() => {
              handleDialogClose();
              formikProps.resetForm({ ...initRow });
            }}
            handleSubmit={formikProps.submitForm}
            mode={dialogMode}
            title={intl.formatMessage({ id: "dialog.title.client-group" })}
          >
            <ClientGroupForm {...formikProps} mode={dialogMode} />
          </FullScreenFormDialog>
        )}
      />
      <Dialog />
    </>
  );
};
