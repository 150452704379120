import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import { makeStyles } from "tss-react/mui";

import palette from "theme/palette";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    alignItems: "center",
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.common.neutral}`,
    borderRadius: "4px",
    display: "flex",
    flexBasis: "420px",
    paddingBottom: spacing(0.5),
    paddingLeft: spacing(),
    paddingRight: spacing(),
    paddingTop: spacing(0.5),
  },
  icon: {
    marginRight: spacing(),
    color: palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px",
  },
}));

interface SearchInputProps {
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

export const SearchInputIcon: React.FC = () => {
  const { classes } = useStyles();

  return <SearchIcon className={classes.icon} />;
};

export const SearchInput: React.FC<SearchInputProps> = ({ onChange, placeholder, value }) => {
  const { classes } = useStyles();

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={classes.root}>
      <SearchInputIcon />
      <Input
        className={classes.input}
        disableUnderline
        onChange={handleOnChange}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};
