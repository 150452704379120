import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, StationaryFuelParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newStationaryFuelRow } from "../consts";
import { StationaryFuelRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: StationaryFuelRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getStationaryFuelData, submitStationaryFuel } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const stationaryFuel = await getStationaryFuelData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = stationaryFuel.flatMap((s) =>
          s.records.map<StationaryFuelRecordRow>((r) => ({
            ...newStationaryFuelRow,
            recordId: r.id,
            originalCompanyId: s.projectCompany.id,
            companyId: s.projectCompany.id,
            remarks: r.remarks,
            facilityName: r.facilityName,
            assetIDLocation: r.assetIDLocation,
            fuelType: r.fuelType,
            fuelUnit: r.fuelUnit,
            fuelConsumption: r.fuelConsumption,
            startDate: tryParseDateString(r.startDate, DateFormat.Date),
            endDate: tryParseDateString(r.endDate, DateFormat.Date),
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getStationaryFuelData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<StationaryFuelParam>((e) => {
          const data = e.data as DataGridRow<StationaryFuelRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              facilityName: data.facilityName,
              assetIDLocation: data.assetIDLocation,
              fuelType: data.fuelType,
              fuelConsumption: data.fuelConsumption,
              fuelUnit: data.fuelUnit,
              startDate: toDate(data.startDate, DateFormat.Date),
              endDate: toDate(data.endDate, DateFormat.Date),
            },
          };
        });

        return await submitStationaryFuel({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitStationaryFuel]
  );

  return { getData, submitData };
};
