import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { GridWithLoading, MultiColumnBreakdownBlock, MultiColumnBreakdownPieChartProps, TotalNumberBlock } from "..";
import { useSocialPercentageDataHelper } from "../../hook/social-percentage-data-helper";
import {
  DASHBOARD_BLOCK_HEIGHT_100,
  SocialDashboardAgeColor,
  SocialDashboardAgeIntl,
  SocialDashboardFullTimeOrPartTimeColor,
  SocialDashboardFullTimeOrPartTimeIntl,
  SocialDashboardGenderColor,
  SocialDashboardGenderIntl,
  SocialDashboardGradeColor,
  SocialDashboardGradeIntl,
  SocialDashboardRegionColor,
  SocialDashboardRegionIntl,
  TabPosition,
} from "../consts";
import { SectionHeader } from "../section-header";

export type WorkforceDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  previousYear: string;
  isLoading: boolean;
};

export const WorkforceDashboard: React.FC<WorkforceDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;

  const intl = useIntl();

  const regionHelper = useSocialPercentageDataHelper({ data: data.current.workforce.region });
  const genderHelper = useSocialPercentageDataHelper({ data: data.current.workforce.gender });
  const ageHelper = useSocialPercentageDataHelper({ data: data.current.workforce.age });
  const fullTimeOrPartTimeHelper = useSocialPercentageDataHelper({ data: data.current.workforce.fullTimeOrPartTime });
  const gradeHelper = useSocialPercentageDataHelper({ data: data.current.workforce.grade });

  const pRegionHelper = useSocialPercentageDataHelper({ data: data.previous?.workforce.region ?? [] });
  const pGenderHelper = useSocialPercentageDataHelper({ data: data.previous?.workforce.gender ?? [] });
  const pAgeHelper = useSocialPercentageDataHelper({ data: data.previous?.workforce.age ?? [] });
  const pFullTimeOrPartTimeHelper = useSocialPercentageDataHelper({
    data: data.previous?.workforce.fullTimeOrPartTime ?? [],
  });
  const pGradeHelper = useSocialPercentageDataHelper({ data: data.previous?.workforce.grade ?? [] });

  const getBreakdownCharts = () => {
    const charts: MultiColumnBreakdownPieChartProps[] = [];

    if (genderHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.gender.title" }),
        data: genderHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value,
          color: SocialDashboardGenderColor[name],
        })),
        legendColumn: 2,
      });
    }

    if (ageHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.age.title" }),
        data: ageHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardAgeIntl[name] }),
          value,
          color: SocialDashboardAgeColor[name],
        })),
        legendColumn: 3,
      });
    }

    if (fullTimeOrPartTimeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.fullTimeOrPartTime.title" }),
        data: fullTimeOrPartTimeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardFullTimeOrPartTimeIntl[name] }),
          value,
          color: SocialDashboardFullTimeOrPartTimeColor[name],
        })),
        legendColumn: 2,
      });
    }

    if (gradeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.grade.title" }),
        data: gradeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeIntl[name] }),
          value,
          color: SocialDashboardGradeColor[name],
        })),
        legendColumn: 1,
      });
    }

    return charts;
  };

  const getPBreakdownCharts = () => {
    const charts: MultiColumnBreakdownPieChartProps[] = [];

    if (pGenderHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.gender.title" }),
        data: pGenderHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value,
          color: SocialDashboardGenderColor[name],
        })),
        legendColumn: 2,
      });
    }

    if (pAgeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.age.title" }),
        data: pAgeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardAgeIntl[name] }),
          value,
          color: SocialDashboardAgeColor[name],
        })),
        legendColumn: 3,
      });
    }

    if (pFullTimeOrPartTimeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.fullTimeOrPartTime.title" }),
        data: pFullTimeOrPartTimeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardFullTimeOrPartTimeIntl[name] }),
          value,
          color: SocialDashboardFullTimeOrPartTimeColor[name],
        })),
        legendColumn: 2,
      });
    }

    if (pGradeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.workforce.breakdown.grade.title" }),
        data: pGradeHelper.data.map(({ name, value }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeIntl[name] }),
          value,
          color: SocialDashboardGradeColor[name],
        })),
        legendColumn: 1,
      });
    }

    return charts;
  };

  const breakdownCharts = getBreakdownCharts();
  const pBreakdownCharts = getPBreakdownCharts();

  return (
    <>
      {(isLoading || breakdownCharts.length > 0) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.social.workforce.title" })}
              description={intl.formatMessage({ id: "dashboard.social.workforce.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <GridWithLoading
                xs={3}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={regionHelper.isVisible}
              >
                <TotalNumberBlock
                  containerProps={{
                    title: intl.formatMessage({ id: "dashboard.social.workforce.total-number-of-employees" }),
                  }}
                  chartProps={{
                    categoryDef: regionHelper.data.map(({ name }) => ({
                      key: name,
                      displayName: intl.formatMessage({ id: SocialDashboardRegionIntl[name] }),
                      color: SocialDashboardRegionColor[name],
                    })),
                    main: {
                      name: currentYear,
                      data: regionHelper.data,
                    },
                    sub: pRegionHelper.isVisible
                      ? {
                          name: previousYear,
                          data: pRegionHelper.data,
                        }
                      : undefined,
                  }}
                />
              </GridWithLoading>

              <GridWithLoading
                xs={9}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={breakdownCharts.length > 0}
              >
                <MultiColumnBreakdownBlock
                  mains={{
                    title: intl.formatMessage({ id: "dashboard.social.common.breakdown.title" }, { year: currentYear }),
                    tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                    charts: breakdownCharts,
                  }}
                  subs={
                    pBreakdownCharts.length > 0
                      ? {
                          title: intl.formatMessage(
                            { id: "dashboard.social.common.breakdown.title" },
                            { year: previousYear }
                          ),
                          tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                          charts: pBreakdownCharts,
                        }
                      : undefined
                  }
                  tabPosition={TabPosition.Right}
                  legendProps={{
                    position: "bottom",
                    showPercentage: false,
                    showValue: false,
                  }}
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
