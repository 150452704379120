import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import {
  facilityOptions,
  newStationaryFuelRow,
  stationaryFuelTypeOptions,
  stationaryFuelUnitOptions,
  tips,
} from "./consts";
import { useActions } from "./hook/action";
import { StationaryFuelRecordRow } from "./types";

const source = IndexType.StationaryFuel;

export const StationaryFuelPage: React.FC = () => {
  const [initRows, setInitRows] = useState<StationaryFuelRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "facilityName",
        name: intl.formatMessage({ id: "excel.fuel-stationary.name-facility" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: facilityOptions,
        formatter: getFormatter(createSelectSingleFormatter(facilityOptions), "facilityName"),
        isCreatable: true,
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.fuel-stationary.start-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "startDate"),
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.fuel-stationary.end-date" }),
        resizable: true,
        width: 200,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "endDate"),
      },
      {
        key: "assetIDLocation",
        name: intl.formatMessage({ id: "excel.fuel-stationary.location" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(Formatter, "assetIDLocation"),
      },
      {
        key: "fuelType",
        name: intl.formatMessage({ id: "excel.fuel-stationary.fuel-type" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: stationaryFuelTypeOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(stationaryFuelTypeOptions), "fuelType"),
      },
      {
        key: "fuelConsumption",
        name: intl.formatMessage({ id: "excel.fuel-stationary.fuel-consumption" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "fuelConsumption"),
      },
      {
        key: "fuelUnit",
        name: intl.formatMessage({ id: "excel.fuel-stationary.fuel-unit" }),
        width: 200,
        resizable: true,
        editor: SelectEditor,
        options: stationaryFuelUnitOptions,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(stationaryFuelUnitOptions), "fuelUnit"),
      },
    ],
    [companyOptions, maxDate, minDate, intl]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<StationaryFuelRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as StationaryFuelRecordRow[]);

    return Yup.array(
      Yup.object<Partial<StationaryFuelRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        startDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        endDate: Yup.string()
          .required("errors.common.require")
          .validDateRange("errors.common.date", "startDate", "endDate"),
        facilityName: Yup.string().required("errors.common.require"),
        assetIDLocation: Yup.string().required("errors.common.require"),
        fuelType: Yup.string().required("errors.common.require"),
        fuelConsumption: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        fuelUnit: Yup.string().required("errors.common.require"),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.fuel-stationary.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newStationaryFuelRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "fuelConsumption", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
      />
    </DatasheetPaper>
  );
};
