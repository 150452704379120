import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, SuccessfulRecord, TreeParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { newTreeRow, YesNo } from "../consts";
import { TreeRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: TreeRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getTreeData, submitTree } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const tree = await getTreeData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = tree.flatMap((t) =>
          t.records.map<TreeRecordRow>((r) => ({
            ...newTreeRow,
            recordId: r.id,
            originalCompanyId: t.projectCompany.id,
            companyId: t.projectCompany.id,
            remarks: r.remarks,
            numberOfTree: r.numberOfTree,
            isPlantedWithinOrAfter: r.isPlantedWithinOrAfter ? YesNo.Yes : YesNo.No,
            isWithinCompanySiteBoundary: r.isWithinCompanySiteBoundary ? YesNo.Yes : YesNo.No,
            isHigherThan5: r.isHigherThan5 ? YesNo.Yes : YesNo.No,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getTreeData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<TreeParam>((e) => {
          const data = e.data as DataGridRow<TreeRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload ?? [],
              fileToDelete: data.fileToDelete,
              isHigherThan5: data.isHigherThan5 === YesNo.Yes,
              isWithinCompanySiteBoundary: data.isWithinCompanySiteBoundary === YesNo.Yes,
              isPlantedWithinOrAfter: data.isPlantedWithinOrAfter === YesNo.Yes,
              numberOfTree: data.numberOfTree,
              recordStatus: data.recordStatus,
            },
          };
        });

        return await submitTree({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitTree]
  );

  return { getData, submitData };
};
