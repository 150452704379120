import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { StationaryFuelRecordRow } from "./types";

export const newStationaryFuelRow: StationaryFuelRecordRow = {
  recordId: "",
  companyId: "",
  facilityName: "",
  assetIDLocation: "",
  fuelType: "",
  fuelConsumption: 0,
  fuelUnit: "",
  startDate: "",
  endDate: "",
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<StationaryFuelRecordRow, string> = {
  fuelConsumption: "tips.fuel-stationary.fuel-consumption",
  facilityName: "tips.fuel-stationary.facility-name",
  fuelType: "tips.fuel-stationary.fuel-type",
  fuelUnit: "tips.fuel-stationary.fuel-unit",
};

export enum StationaryFuelType {
  DieselOil = "Diesel Oil",
  LPGGaseous = "LPG (Gaseous)",
  LPGLiquid = "LPG (Liquid)",
  Kerosene = "Kerosene",
  Charcoal = "Charcoal",
  HKTowngas = "HK Towngas",
  Towngas = "Towngas",
  CNG = "CNG",
  LNG = "LNG",
  UnleadedPetrol = "Unleaded Petrol",
  Coal = "Coal",
  Wood = "Wood",
  LandfillGas = "Landfill gas",
  AlcoholFuel = "Alcohol fuel",
}

export const stationaryFuelTypeOptions: OptionType[] = [
  { label: "Diesel oil 柴油", value: StationaryFuelType.DieselOil },
  { label: "LPG (Gaseous) 液化石油气 (气态)", value: StationaryFuelType.LPGGaseous },
  { label: "LPG (Liquid) 液化石油气 (液态)", value: StationaryFuelType.LPGLiquid },
  { label: "Kerosene 煤油", value: StationaryFuelType.Kerosene },
  { label: "Charcoal 木炭", value: StationaryFuelType.Charcoal },
  { label: "HK Towngas 香港煤气", value: StationaryFuelType.HKTowngas },
  { label: "Towngas (other region) 煤气 (其他地区)", value: StationaryFuelType.Towngas },
  { label: "CNG 压缩天然气/管道天然气", value: StationaryFuelType.CNG },
  { label: "LNG 液化天然气", value: StationaryFuelType.LNG },
  { label: "Unleaded petrol 无铅汽油", value: StationaryFuelType.UnleadedPetrol },
  { label: "Coal 煤", value: StationaryFuelType.Coal },
  { label: "Wood 木质", value: StationaryFuelType.Wood },
  { label: "Landfill gas 填埋气", value: StationaryFuelType.LandfillGas },
  { label: "Alcohol fuel 醇基液体燃料", value: StationaryFuelType.AlcoholFuel },
];

export enum StationaryFuelUnit {
  Litre = "Litre",
  KG = "kg",
  M3 = "m3",
  KWH = "kWh",
  MJ = "MJ",
}
export const stationaryFuelUnitOptions: OptionType[] = [
  { label: "Litre 升", value: StationaryFuelUnit.Litre },
  { label: "kg 公斤", value: StationaryFuelUnit.KG },
  { label: "m3 立方米", value: StationaryFuelUnit.M3 },
  { label: "kWh 千瓦時", value: StationaryFuelUnit.KWH },
  { label: "MJ 百万焦耳", value: StationaryFuelUnit.MJ },
];

export enum Facility {
  Kitchen = "kitchen",
  Boiler = "boiler",
  Generator = "generator",
  WaterHeater = "waterHeater",
  HeatAndPower = "heatAndPower",
  Others = "others",
}

export const facilityOptions: OptionType[] = [
  { label: "Kitchen stove 食堂燃气炉/灶", value: Facility.Kitchen },
  { label: "Boiler 锅炉", value: Facility.Boiler },
  { label: "Generator 发电机", value: Facility.Generator },
  { label: "Dormitory water heater宿舍热水炉", value: Facility.WaterHeater },
  { label: "Combined heat and power 热电厂", value: Facility.HeatAndPower },
  { label: "Others 其他", value: Facility.Others },
];
