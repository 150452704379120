import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, ProductResponsibilityParam, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newProductResponsibilityRow } from "../consts";
import { useAPI } from "../hook/api";
import { ProductResponsibilityRecordRow } from "../types";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: ProductResponsibilityRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getProductResponsibilityData, submitProductResponsibility } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const productResponsibility = await getProductResponsibilityData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = productResponsibility.flatMap((p) =>
          p.records.map<ProductResponsibilityRecordRow>((r) => ({
            ...newProductResponsibilityRow,
            recordId: r.id,
            originalCompanyId: p.projectCompany.id,
            companyId: p.projectCompany.id,
            remarks: r.remarks,
            asOfDate: tryParseDateString(r.asOfDate, DateFormat.Date),
            unitOfProduct: r.unitOfProduct,
            numberOfComplaints: r.numberOfComplaints,
            numberOfProducts: r.numberOfProducts,
            numberOfRetrieved: r.numberOfRetrieved,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getProductResponsibilityData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<ProductResponsibilityParam>((e) => {
          const data = e.data as DataGridRow<ProductResponsibilityRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              asOfDate: toDate(data.asOfDate, DateFormat.Date),
              numberOfProducts: data.numberOfProducts,
              unitOfProduct: data.unitOfProduct,
              numberOfRetrieved: data.numberOfRetrieved,
              numberOfComplaints: data.numberOfComplaints,
            },
          };
        });

        return await submitProductResponsibility({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitProductResponsibility]
  );

  return { getData, submitData };
};
