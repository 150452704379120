import { SortOrder } from "generated/graphql";
import { useMemo, useState } from "react";
import { SortByItem } from "../table";

type UseSortOptions = {
  relation: Record<string, [string, string]>;
};

type OrderBy = SortOrder;
type OrderByWithRelation = Record<string, SortOrder>;
export type OrderByInput = Record<string, OrderBy | OrderByWithRelation>;

type Sort = {
  sortBy: SortByItem | undefined;
  setSortBy: (item?: SortByItem) => void;
  orderBy: OrderByInput;
};

export const useSort = (options?: UseSortOptions): Sort => {
  const [sortBy, setSortBy] = useState<SortByItem>();

  const validateAndSetSortBy = (item?: SortByItem) => {
    if (item !== sortBy) setSortBy(item);
  };

  const orderBy: OrderByInput = useMemo(() => {
    if (!sortBy) return {};

    const sortOrder = sortBy.desc ? SortOrder.Desc : SortOrder.Asc;

    if (options && options.relation && options.relation[sortBy.id]) {
      const [relation, field] = options.relation[sortBy.id];

      return { [relation]: { [field]: sortOrder } };
    } else {
      return { [sortBy.id]: sortOrder };
    }
  }, [sortBy, options]);

  return { sortBy: sortBy, setSortBy: validateAndSetSortBy, orderBy };
};
