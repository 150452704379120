import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetEnvDashboardDataQuery } from "generated/graphql";
import { GridWithLoading, IntensityBlock, MultiColumnBreakdownBlock, TotalBlock, YearlyComparisonBlock } from "..";
import { useBreakdownHelper } from "../../hook/breakdown-helper";
import { useDashboardHelper } from "../../hook/dashboard-helper";
import { getChartInput, getUnitIntlKey } from "../../utils";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100, DASHBOARD_BLOCK_HEIGHT_50, TabPosition } from "../consts";
import { SectionHeader } from "../section-header";

const breakdownPieChartColors = [
  DashboardColor.chart.blue2,
  DashboardColor.chart.blue1,
  DashboardColor.chart.blue3,
  DashboardColor.chart.blue4,
];

export type EnergyDashboardProps = {
  data: GetEnvDashboardDataQuery;
  currentYear: string;
  previousYear?: string;
  isLoading: boolean;
};

export const EnergyDashboard: React.FC<EnergyDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current, previous } = data.dashboardEnv;
  const { energyTotal, energyIntensity } = data.dashboardTarget ?? {};
  const { breakdown } = current.energy;
  const { breakdown: pBreakdown } = previous?.energy ?? {};

  const intl = useIntl();

  const { total: totalHelper, intensity: intensityHelper } = useDashboardHelper({
    current: current.energy,
    previous: previous?.energy,
    nf1Label: "unit",
  });

  const { groupedData, isGroupedDataVisible } = useBreakdownHelper({
    data: breakdown.data.map(({ name, ...rest }) => ({
      name: intl.formatMessage({ id: `dashboard.env.energy.breakdown.${name}` }),
      ...rest,
    })),
    color: breakdownPieChartColors,
  });

  const { groupedData: pGroupedData, isGroupedDataVisible: isPGroupedDataVisible } = useBreakdownHelper({
    data: pBreakdown?.data.map(({ name, ...rest }) => ({
      name: intl.formatMessage({ id: `dashboard.env.energy.breakdown.${name}` }),
      ...rest,
    })),
    color: breakdownPieChartColors,
  });

  const targetIntensity = energyIntensity?.find((t) => t.option === intensityHelper.nf1Select.nf1);

  const currentTotalInput = getChartInput({ name: currentYear, datum: totalHelper.current })!;
  const previousTotalInput = getChartInput({ name: previousYear, datum: totalHelper.previous });
  const targetTotalInput =
    energyTotal?.value && energyTotal.value > 0
      ? getChartInput({ name: intl.formatMessage({ id: "dashboard.common.target" }), datum: energyTotal })
      : undefined;

  const currentIntensityInput = getChartInput({ name: currentYear, datum: intensityHelper.current })!;
  const previousIntensityInput = getChartInput({ name: previousYear, datum: intensityHelper.previous });
  const targetIntensityInput =
    targetIntensity?.value && targetIntensity.value > 0
      ? getChartInput({ name: intl.formatMessage({ id: "dashboard.common.target" }), datum: targetIntensity })
      : undefined;

  return (
    <>
      {(isLoading || totalHelper.isVisible || intensityHelper.isVisible || isGroupedDataVisible) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.env.energy.title" })}
              description={intl.formatMessage({ id: "dashboard.env.energy.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              {(isLoading || totalHelper.isVisible || intensityHelper.isVisible) && (
                <>
                  <Grid xs={2}>
                    <Grid container spacing={2}>
                      <GridWithLoading
                        xs={12}
                        height={DASHBOARD_BLOCK_HEIGHT_50}
                        isLoading={isLoading}
                        isVisible={totalHelper.isVisible}
                      >
                        <TotalBlock
                          title={intl.formatMessage({ id: "dashboard.env.energy.total.title" })}
                          value={totalHelper.current.value}
                          unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                        />
                      </GridWithLoading>

                      <GridWithLoading
                        xs={12}
                        height={DASHBOARD_BLOCK_HEIGHT_50}
                        isLoading={isLoading}
                        isVisible={intensityHelper.isVisible}
                      >
                        <IntensityBlock
                          title={intl.formatMessage({ id: "dashboard.env.energy.intensity.title" })}
                          value={intensityHelper.current.value}
                          unit={intl.formatMessage({ id: getUnitIntlKey(totalHelper.current.unit) })}
                          selectorProps={{
                            ...intensityHelper.nf1Select,
                            options: intensityHelper.nf1Select.options.map(({ label, value }) => ({
                              label: intl.formatMessage({ id: getUnitIntlKey(label) }),
                              value,
                            })),
                          }}
                        />
                      </GridWithLoading>
                    </Grid>
                  </Grid>

                  <GridWithLoading
                    xs={7}
                    height={DASHBOARD_BLOCK_HEIGHT_100}
                    isLoading={isLoading}
                    isVisible={totalHelper.isVisible || intensityHelper.isVisible}
                  >
                    <YearlyComparisonBlock
                      title={intl.formatMessage({ id: "dashboard.common.yearly-comparison" })}
                      left={
                        totalHelper.isVisible
                          ? {
                              title: intl.formatMessage({ id: "dashboard.env.energy.total.title" }),
                              main: currentTotalInput,
                              mainColor: totalHelper.color,
                              sub: previousTotalInput,
                              manual: targetTotalInput,
                              manualColor: DashboardColor.chart.blue1,
                              truncateBaseline: true,
                            }
                          : undefined
                      }
                      right={
                        intensityHelper.isVisible
                          ? {
                              title: intl.formatMessage({ id: "dashboard.env.energy.intensity.title" }),
                              main: currentIntensityInput,
                              mainColor: intensityHelper.color,
                              sub: previousIntensityInput,
                              manual: targetIntensityInput,
                              manualColor: DashboardColor.chart.blue1,
                            }
                          : undefined
                      }
                    />
                  </GridWithLoading>
                </>
              )}

              <GridWithLoading
                xs={3}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={isGroupedDataVisible}
              >
                <MultiColumnBreakdownBlock
                  mains={{
                    tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                    charts: [
                      {
                        title: intl.formatMessage(
                          { id: "dashboard.env.energy.breakdown.title" },
                          { year: currentYear }
                        ),
                        data: groupedData,
                        unit: intl.formatMessage({ id: getUnitIntlKey(breakdown.unit) }),
                        labelType: "total",
                      },
                    ],
                  }}
                  subs={
                    isPGroupedDataVisible
                      ? {
                          tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                          charts: [
                            {
                              title: intl.formatMessage(
                                { id: "dashboard.env.energy.breakdown.title" },
                                { year: previousYear }
                              ),
                              data: pGroupedData,
                              unit: intl.formatMessage({ id: getUnitIntlKey(breakdown.unit) }),
                              labelType: "total",
                            },
                          ],
                        }
                      : undefined
                  }
                  tabPosition={TabPosition.Right}
                  legendProps={{
                    position: "right",
                    showPercentage: true,
                    showValue: true,
                  }}
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
