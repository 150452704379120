import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import Group from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";
import { MenuItem } from "../components/sidebar/types";

export const menu: MenuItem[] = [
  { name: "layout.menu.systemAdmin.clientGroups", path: "/admin/client-group", icon: Group, child: [] },
  {
    name: "layout.menu.systemAdmin.projects",
    path: "/admin/project-list",
    icon: DashboardIcon,
    child: [],
  },
  {
    name: "layout.menu.systemAdmin.emissionFactors",
    path: "/admin/emission-factor",
    icon: ListIcon,
    child: [],
  },
];
