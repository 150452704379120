import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { DevelopmentTrainingParam, EventAction, SuccessfulRecord } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, toDateString } from "utils/date-time";
import { newDevelopmentTrainingRow } from "../consts";
import { DevelopmentTrainingRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: DevelopmentTrainingRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getDevelopmentTrainingData, submitDevelopmentTraining } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const developmentTrainings = await getDevelopmentTrainingData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = developmentTrainings.flatMap((d) =>
          d.records.map<DevelopmentTrainingRecordRow>((r) => ({
            ...newDevelopmentTrainingRow,
            recordId: r.id,
            originalCompanyId: d.projectCompany?.id ?? "",
            companyId: d.projectCompany?.id ?? "",
            remarks: r.remarks!,
            courseName: r.courseName!,
            type: r.type!,
            host: r.host!,
            date: r.date ? toDateString(r.date, DateFormat.Date) : "",
            numberOfHours: r.numberOfHours!,
            numberOfEmployees: r.numberOfEmployees!,
            male: r.male!,
            female: r.female!,
            seniorManagement: r.seniorManagement!,
            middleLevelManagement: r.middleLevelManagement!,
            generalAndTechnicalEmployees: r.generalAndTechnicalEmployees!,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getDevelopmentTrainingData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<DevelopmentTrainingParam>((e) => {
          const data = e.data as DataGridRow<DevelopmentTrainingRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              courseName: data.courseName,
              type: data.type,
              host: data.host,
              date: toDate(data.date, DateFormat.Date),
              numberOfHours: data.numberOfHours,
              numberOfEmployees: data.numberOfEmployees,
              male: data.male,
              female: data.female,
              seniorManagement: data.seniorManagement,
              middleLevelManagement: data.middleLevelManagement,
              generalAndTechnicalEmployees: data.generalAndTechnicalEmployees,
            },
          };
        });

        return await submitDevelopmentTraining({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitDevelopmentTraining]
  );

  return { getData, submitData };
};
