import React from "react";

import Divider from "@mui/material/Divider";
import { makeStyles } from "tss-react/mui";

import { LayoutContext, LayoutContextProps } from "../layout-context";
import { MenuItem } from "./types";
import { MenuList } from "./menu-list";
import { Profile } from "./profile";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    backgroundColor: palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: spacing(),
    paddingRight: spacing(),
  },
  logoDivider: {
    marginBottom: spacing(2),
  },
  profileDivider: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  listDivider: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
}));

export type SideBarProps = {
  menu: MenuItem[];
};

export const Sidebar: React.FC<SideBarProps> = (props) => {
  const { classes, cx } = useStyles();
  const { layoutClasses } = React.useContext<LayoutContextProps>(LayoutContext);

  return (
    <nav className={cx(classes.root, layoutClasses.sidebar)}>
      <Profile />
      <Divider className={classes.profileDivider} />
      <MenuList menu={props.menu} />
    </nav>
  );
};
