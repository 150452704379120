import React from "react";

import { Header } from "../components/header";
import { DrawerContainer } from "../components/drawer-container";
import { Sidebar } from "../components/sidebar";
import { menu } from "./menu";

export const SystemAdminLayout: React.FC = () => {
  return (
    <>
      <Header />
      <DrawerContainer>
        <Sidebar menu={menu} />
      </DrawerContainer>
    </>
  );
};
