import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetHazardousWasteDataDocument,
  GetHazardousWasteDataQuery,
  GetHazardousWasteDataQueryVariables,
  SubmitHazardousWasteDocument,
  SubmitHazardousWasteMutation,
  SubmitHazardousWasteMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getHazardousWasteData: (
    variables: GetHazardousWasteDataQueryVariables
  ) => Promise<GetHazardousWasteDataQuery["project"]["hazardousWaste"]>;
  submitHazardousWaste: (
    variables: SubmitHazardousWasteMutationVariables
  ) => Promise<SubmitHazardousWasteMutation["submitHazardousWaste"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getHazardousWasteData = useCallback(
    async (variables: GetHazardousWasteDataQueryVariables) => {
      const response = await client.query<GetHazardousWasteDataQuery, GetHazardousWasteDataQueryVariables>({
        query: GetHazardousWasteDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.hazardousWaste;
    },
    [client]
  );

  const submitHazardousWaste = useCallback(
    async (variables: SubmitHazardousWasteMutationVariables) => {
      const response = await client.mutate<SubmitHazardousWasteMutation, SubmitHazardousWasteMutationVariables>({
        mutation: SubmitHazardousWasteDocument,
        variables,
      });

      return response.data?.submitHazardousWaste ?? false;
    },
    [client]
  );

  return {
    getHazardousWasteData,
    submitHazardousWaste,
  };
};
