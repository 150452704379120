import { DateFormat, getDateDiffInDays, toMoment } from "utils/date-time";

export type RowIdentifier = {
  companyId: string;
  address: string;
  source: string;
  meterNumber: string;
};

export type ExcludeDaysRow = RowIdentifier & {
  startDate: string;
  endDate: string;
};

export type ExcludedResultItem = {
  id: RowIdentifier;
  excludeDays: Date[];
};

const isRowValid = (r: ExcludeDaysRow): boolean =>
  r.startDate !== "" &&
  r.endDate !== "" &&
  r.companyId !== "" &&
  r.address !== "" &&
  r.source !== "" &&
  r.meterNumber !== "";

export const getExcludeResultItem = (
  sources: ExcludedResultItem[],
  row: RowIdentifier
): ExcludedResultItem | undefined =>
  sources.find(
    ({ id: unique }) =>
      unique.companyId === row.companyId &&
      unique.address === row.address &&
      unique.source === row.source &&
      unique.meterNumber === row.meterNumber
  );

export const getExcludedResultItems = (rows: ExcludeDaysRow[]): ExcludedResultItem[] => {
  const resultItems: ExcludedResultItem[] = [];
  rows.forEach((r) => {
    const startDate = toMoment(r.startDate, DateFormat.Date);
    const endDate = toMoment(r.endDate, DateFormat.Date);

    if (isRowValid(r) && endDate.isSameOrAfter(startDate)) {
      let target = getExcludeResultItem(resultItems, r);

      if (!target) {
        target = {
          id: {
            companyId: r.companyId,
            address: r.address,
            source: r.source,
            meterNumber: r.meterNumber,
          },
          excludeDays: [],
        };
        resultItems.push(target);
      }

      const diffDays = getDateDiffInDays(startDate, endDate);
      const dateToExclude = toMoment(r.startDate, DateFormat.Date);
      for (let i = 0; i <= diffDays; i++) {
        target.excludeDays.push(dateToExclude.toDate());
        dateToExclude.add(1, "day");
      }
    }
  });

  return resultItems;
};
