import React from "react";

import CloudUpload from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { FileInfo } from "generated/graphql";
import { SERVER } from "utils/const";
import { FileDialogRow } from "./file-dialog-row";

const useStyles = makeStyles()(() => ({
  link: {
    fontSize: "14px",
    padding: "10px",
  },
  buttonText: {
    paddingLeft: "8px",
  },
  mimeTypeNotice: {
    fontSize: "12px",
    padding: "10px",
  },
  mimeTypeError: {
    color: "red",
    fontSize: "12px",
    padding: "10px",
  },
  scrollList: {
    overflowY: "auto",
    maxHeight: "200px",
  },
}));

export type FileDialogProps = {
  fileInfo: Pick<FileInfo, "originalName" | "storageName">[];
  fileToUpload: File[];
  error: string;
  refButtons: Record<number, React.RefObject<HTMLDivElement>>;
  rowIndex: number;
  open: boolean;
  onUploadFile: (file: File) => void;
  onDeleteFile: (index: number) => void;
  onDeleteFileToUpload: (index: number) => void;
  onCloseDialog: () => void;
};

export const FileDialog: React.FC<FileDialogProps> = (props) => {
  const {
    fileInfo,
    fileToUpload,
    error,
    refButtons,
    rowIndex,
    open,
    onUploadFile,
    onDeleteFile,
    onDeleteFileToUpload,
    onCloseDialog,
  } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files[0]) {
      onUploadFile(event.target.files[0]);
    }
  };

  return (
    <div>
      <Popover
        id={"simple-popover"}
        open={open}
        anchorEl={refButtons[rowIndex]?.current}
        onClose={onCloseDialog}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List className={classes.scrollList}>
          {fileInfo.map((f, idx) => (
            <FileDialogRow
              key={`file-${idx}`}
              index={idx}
              fileName={f.originalName}
              onDelete={onDeleteFile}
              downloadUrl={`${SERVER.STEPPER_DOC}/${f.storageName}`}
            />
          ))}
          {fileToUpload.map((f, idx) => (
            <FileDialogRow key={`upload-${idx}`} index={idx} fileName={f.name} onDelete={onDeleteFileToUpload} />
          ))}
        </List>
        <List>
          <ListItem>
            <Button variant="contained" component="label">
              <CloudUpload />
              <div className={classes.buttonText}>{intl.formatMessage({ id: "data-grid.tools.file-upload" })}</div>
              <input type="file" onChange={handleChange} style={{ display: "none" }} />
            </Button>
            <div className={classes.mimeTypeNotice}>{intl.formatMessage({ id: "data-grid.tools.file-format" })}</div>
          </ListItem>
          {error && (
            <ListItem>
              <div className={classes.mimeTypeError}>{error}</div>
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
};
