import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { KeyArray } from "utils/types";
import { Yup } from "utils/yup";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newTurnoverRow } from "./consts";
import { useActions } from "./hook/action";
import { TurnoverRecordRow } from "./types";

const source = IndexType.Turnover;

export const TurnoverPage: React.FC = () => {
  const [initRows, setInitRows] = useState<TurnoverRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { companyOptions, isValidAllCompanies, minDate, maxDate } = useProjectCompany(source);
  const { Dialog, openDialog, DialogType } = useDialog();

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        width: 200,
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companyOptions,
        resizable: true,
        frozen: true,

        formatter: getFormatter(createSelectSingleFormatter(companyOptions), "companyId"),
      },
      {
        key: "asOfDate",
        name: intl.formatMessage({ id: "excel.workforce.asOfDate" }),
        width: 150,
        resizable: true,
        editor: DateRangeEditor,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "",
          endDateProperty: "",
          selectsStart: false,
          selectsEnd: false,
          handleExcludeDays: [],
        },
        formatter: getFormatter(Formatter, "asOfDate"),
      },
      {
        key: "totalTurnover",
        name: intl.formatMessage({ id: "excel.turnover.totalTurnover" }),
        width: 350,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "totalTurnover"),
      },
      {
        key: "male",
        name: intl.formatMessage({ id: "excel.workforce.male" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "male"),
      },
      {
        key: "female",
        name: intl.formatMessage({ id: "excel.workforce.female" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "female"),
      },
      {
        key: "seniorManagement",
        name: intl.formatMessage({ id: "excel.workforce.seniorManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "seniorManagement"),
      },
      {
        key: "middleLevelManagement",
        name: intl.formatMessage({ id: "excel.workforce.middleLevelManagement" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "middleLevelManagement"),
      },
      {
        key: "generalAndTechnicalEmployees",
        name: intl.formatMessage({ id: "excel.workforce.generalAndTechnicalEmployees" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "generalAndTechnicalEmployees"),
      },
      {
        key: "ageGroup1",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup1" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup1"),
      },
      {
        key: "ageGroup2",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup2" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup2"),
      },
      {
        key: "ageGroup3",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup3" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup3"),
      },
      {
        key: "ageGroup4",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup4" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup4"),
      },
      {
        key: "ageGroup5",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup5" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup5"),
      },
      {
        key: "ageGroup6",
        name: intl.formatMessage({ id: "excel.workforce.ageGroup6" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "ageGroup6"),
      },
      {
        key: "hk",
        name: intl.formatMessage({ id: "excel.workforce.hk" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "hk"),
      },
      {
        key: "china",
        name: intl.formatMessage({ id: "excel.workforce.china" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "china"),
      },
      {
        key: "otherRegions",
        name: intl.formatMessage({ id: "excel.workforce.otherRegions" }),
        width: 150,
        resizable: true,
        editor: InputEditor,
        inputDataMeta: {
          positive: true,
          integer: true,
        },
        type: "number",
        formatter: getFormatter(Formatter, "otherRegions"),
      },
    ],
    [intl, companyOptions, minDate, maxDate]
  );

  const initData = useCallback(async () => {
    if (companyOptions.length === 0) return;

    setLoading(true);

    const { rows } = await getData(companyOptions.map((i) => i.value));

    setLoading(false);
    setInitRows([...rows]);
  }, [companyOptions, setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const handleUpdateRows: OnUpdateDataGridRows<TurnoverRecordRow> = (rows, updatedItem) => {
    const { otherRegions } = updatedItem.updated;

    if (otherRegions && otherRegions > 0) {
      openDialog({ content: intl.formatMessage({ id: "error.workforce.otherRegions" }), type: DialogType.ALERT });
    }

    return rows;
  };

  const validationSchema = Yup.lazy<Partial<TurnoverRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as TurnoverRecordRow[]);

    const gender: KeyArray<TurnoverRecordRow, "male" | "female"> = ["male", "female"];
    const grade: KeyArray<
      TurnoverRecordRow,
      "seniorManagement" | "middleLevelManagement" | "generalAndTechnicalEmployees"
    > = ["seniorManagement", "middleLevelManagement", "generalAndTechnicalEmployees"];
    const age: KeyArray<
      TurnoverRecordRow,
      "ageGroup1" | "ageGroup2" | "ageGroup3" | "ageGroup4" | "ageGroup5" | "ageGroup6"
    > = ["ageGroup1", "ageGroup2", "ageGroup3", "ageGroup4", "ageGroup5", "ageGroup6"];
    const region: KeyArray<TurnoverRecordRow, "hk" | "china" | "otherRegions"> = ["hk", "china", "otherRegions"];

    const validateByCategory = (keys: string[]) =>
      Yup.number()
        .required("errors.common.require")
        .sumEqual("error.workforce.sumOfTotal", keys, "totalTurnover")
        .nonNegativeInteger("error.common.positiveInteger");

    return Yup.array(
      Yup.object<Partial<TurnoverRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        asOfDate: Yup.string().required("errors.common.require"),
        totalTurnover: Yup.number()
          .required("errors.common.require")
          .nonNegativeInteger("error.common.positiveInteger"),

        male: validateByCategory(gender),
        female: validateByCategory(gender),

        seniorManagement: validateByCategory(grade),
        middleLevelManagement: validateByCategory(grade),
        generalAndTechnicalEmployees: validateByCategory(grade),

        ageGroup1: validateByCategory(age),
        ageGroup2: validateByCategory(age),
        ageGroup3: validateByCategory(age),
        ageGroup4: validateByCategory(age),
        ageGroup5: validateByCategory(age),
        ageGroup6: validateByCategory(age),

        hk: validateByCategory(region),
        china: validateByCategory(region),
        otherRegions: validateByCategory(region),
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.turnover.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        initRows={initRows}
        newRowData={newTurnoverRow}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "asOfDate", value: "" },
          { property: "totalTurnover", value: 0 },
          { property: "male", value: 0 },
          { property: "female", value: 0 },
          { property: "seniorManagement", value: 0 },
          { property: "middleLevelManagement", value: 0 },
          { property: "generalAndTechnicalEmployees", value: 0 },
          { property: "ageGroup1", value: 0 },
          { property: "ageGroup2", value: 0 },
          { property: "ageGroup3", value: 0 },
          { property: "ageGroup4", value: 0 },
          { property: "ageGroup5", value: 0 },
          { property: "ageGroup6", value: 0 },
          { property: "hk", value: 0 },
          { property: "china", value: 0 },
          { property: "otherRegions", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
