import React, { useCallback, useRef, useState } from "react";

import { DialogWrapperPropsBase } from "./types";
import { RemarkDialog } from "../components/dialog/remark-dialog";

interface RemarkDialogWrapperProps extends DialogWrapperPropsBase {
  handleUpdate: (data: { updatedRowIndex: number; remark: string }) => void;
}

type UseRemarkDialogProps = {
  remark: string;
  setRemark: (remark: string) => void;
  handleOpen: (row: { remarks: string }, idx: number) => void;
  RemarkDialogWrapper: React.FC<RemarkDialogWrapperProps>;
};

export const useRemarkDialog = (): UseRemarkDialogProps => {
  const [remark, setRemark] = useState("");
  const [open, setOpen] = useState(false);
  const rowNumber = useRef(0);

  const handleClose = useCallback((textRef) => {
    setRemark(textRef.current.value);
    setOpen(false);
  }, []);

  const handleOpen = (row: { remarks: string }, idx: number) => {
    setRemark(row.remarks);
    rowNumber.current = idx;
    setOpen(true);
  };

  const RemarkDialogWrapper = useCallback(
    (props: RemarkDialogWrapperProps) => {
      const dialogClose = (textRef) => {
        handleClose(textRef);
        if (textRef.current.value !== remark) {
          props.handleUpdate({
            updatedRowIndex: rowNumber.current,
            remark: textRef.current.value,
          });
        }
      };

      return (
        <RemarkDialog
          isDisable={props.isDisable}
          remarks={remark}
          open={open}
          handleClose={dialogClose}
          anchorEl={props.refButtons[rowNumber.current]}
        />
      );
    },
    [remark, open, handleClose]
  );

  return {
    setRemark,
    remark,
    handleOpen,
    RemarkDialogWrapper,
  };
};
