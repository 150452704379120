import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetNf2DataDocument,
  GetNf2DataQuery,
  GetNf2DataQueryVariables,
  SubmitNf2Document,
  SubmitNf2Mutation,
  SubmitNf2MutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getNf2Data: (variables: GetNf2DataQueryVariables) => Promise<GetNf2DataQuery["project"]["normalizationFactor2"]>;
  submitNf2: (variables: SubmitNf2MutationVariables) => Promise<SubmitNf2Mutation["submitNF2"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getNf2Data = useCallback(
    async (variables: GetNf2DataQueryVariables) => {
      const response = await client.query<GetNf2DataQuery, GetNf2DataQueryVariables>({
        query: GetNf2DataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.normalizationFactor2;
    },
    [client]
  );

  const submitNf2 = useCallback(
    async (variables: SubmitNf2MutationVariables) => {
      const response = await client.mutate<SubmitNf2Mutation, SubmitNf2MutationVariables>({
        mutation: SubmitNf2Document,
        variables,
      });

      return response.data?.submitNF2 ?? false;
    },
    [client]
  );

  return {
    getNf2Data,
    submitNf2,
  };
};
