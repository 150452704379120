import Dashboard from "pages/dashboard";
import { DashboardTargetPage } from "pages/dashboard-target";
import { DocumentPage } from "pages/document";
import { UserPage } from "pages/user";
import { UserPermissionPage } from "pages/user-permission";
import { DynamicRoute } from "../dynamic-route";

const route = [
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/dashboard/target",
    component: DashboardTargetPage,
    exact: true,
  },
  {
    path: "/document",
    component: DocumentPage,
    exact: true,
  },
  {
    path: "/user-permission",
    component: UserPermissionPage,
    exact: true,
  },
  {
    path: "/user",
    component: UserPage,
    exact: true,
  },
];

export const ProjectRoute = DynamicRoute(route);
