import { DateFormat, toDate, isValidDateFormat } from "utils/date-time";
import { DataGridState } from ".";

export const sort = (
  rowsBaseMap: DataGridState["rowsBaseMap"],
  sortData: NonNullable<DataGridState["sortData"]>,
  row: DataGridState["rowsDisplay"]
): DataGridState["rowsDisplay"] => {
  const { columnKey, direction } = sortData;
  const rowIds = Object.keys(rowsBaseMap);

  const comparer = (a: DataGridState["rowsDisplay"][number], b: DataGridState["rowsDisplay"][number]) => {
    const aData = a[columnKey] as string;
    const bData = b[columnKey] as string;
    const isSortAsDate = isValidDateFormat(aData, DateFormat.Date) && isValidDateFormat(bData, DateFormat.Date);

    switch (direction) {
      case "ASC":
        if (isSortAsDate) {
          return toDate(aData, DateFormat.Date) > toDate(bData, DateFormat.Date) ? 1 : -1;
        } else {
          return aData > bData ? 1 : -1;
        }
      case "DESC":
        if (isSortAsDate) {
          return toDate(aData, DateFormat.Date) < toDate(bData, DateFormat.Date) ? 1 : -1;
        } else {
          return aData < bData ? 1 : -1;
        }
      case "NONE":
        return rowIds.indexOf(a.rowId) - rowIds.indexOf(b.rowId);
      default:
        return 0;
    }
  };

  return row.sort(comparer);
};
