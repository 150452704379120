import { useCallback, useContext } from "react";

import { DataGridEvents, DataGridRow, formSubmitDataEvent } from "components/data-grid";
import { EventAction, SuccessfulRecord, SupplyChainManagementParam } from "generated/graphql";
import { AuthContext } from "provider/auth";
import { DateFormat, toDate, tryParseDateString } from "utils/date-time";
import { newSupplyChainManagementRow } from "../consts";
import { SupplyChainManagementRecordRow } from "../types";
import { useAPI } from "./api";

type UseActionsReturns = {
  getData: (companyids: string[]) => Promise<{ rows: SupplyChainManagementRecordRow[] }>;
  submitData: (events: DataGridEvents, successIds?: SuccessfulRecord[]) => Promise<boolean>;
};

export const useActions = (): UseActionsReturns => {
  const { me } = useContext(AuthContext);
  const { getSupplyChainManagementData, submitSupplyChainManagement } = useAPI();

  const getData = useCallback(
    async (companyIds: string[]) => {
      const defaultReturn = { rows: [] };
      if (!me.project?.id || companyIds.length === 0) return defaultReturn;

      try {
        const supplyChainManagement = await getSupplyChainManagementData({
          projectId: me.project.id,
          companies: companyIds,
        });

        const rows = supplyChainManagement.flatMap((s) =>
          s.records.map<SupplyChainManagementRecordRow>((r) => ({
            ...newSupplyChainManagementRow,
            recordId: r.id,
            originalCompanyId: s.projectCompany.id,
            companyId: s.projectCompany.id,
            remarks: r.remarks,
            asOfDate: tryParseDateString(r.asOfDate, DateFormat.Date),
            numberOfSuppliers: r.numberOfSuppliers,
            hk: r.hk,
            china: r.china,
            otherRegions: r.otherRegions,
            engagingDesc: r.engagingDesc,
            numberOfEngaged: r.numberOfEngaged,
            identifyDesc: r.identifyDesc,
            promoteDesc: r.promoteDesc,
            fileInfo: r.file,
          }))
        );

        return { rows };
      } catch (e) {
        throw e;
      }
    },
    [me, getSupplyChainManagementData]
  );

  const submitData = useCallback(
    async (events: DataGridEvents, successIds?: SuccessfulRecord[]) => {
      try {
        const params = Object.values(events).map<SupplyChainManagementParam>((e) => {
          const data = e.data as DataGridRow<SupplyChainManagementRecordRow>;
          return {
            event: formSubmitDataEvent(e.action, data),
            data: {
              id: e.action === EventAction.Create ? undefined : data.recordId,
              companyId: data.companyId,
              remarks: data.remarks,
              fileToUpload: data.fileToUpload,
              fileToDelete: data.fileToDelete,
              recordStatus: data.recordStatus,
              asOfDate: toDate(data.asOfDate, DateFormat.Date),
              numberOfSuppliers: +data.numberOfSuppliers,
              hk: data.hk,
              china: data.china,
              otherRegions: data.otherRegions,
              engagingDesc: data.engagingDesc,
              numberOfEngaged: data.numberOfEngaged,
              identifyDesc: data.identifyDesc,
              promoteDesc: data.promoteDesc,
            },
          };
        });

        return await submitSupplyChainManagement({ data: params, successRecords: successIds ?? [] });
      } catch (e) {
        throw e;
      }
    },
    [submitSupplyChainManagement]
  );

  return { getData, submitData };
};
