import React, { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";

import { GetSocialDashboardDataQuery } from "generated/graphql";
import { formatNumberToPercentage } from "utils/format";
import { OptionType } from "utils/interfaces";
import { isApproximatelyZero } from "utils/math";
import {
  GridWithLoading,
  MultiColumnBreakdownBarChartProps,
  MultiColumnBreakdownBlock,
  TotalBlock,
  TurnoverRateBlock,
} from "..";
import { useSocialPercentageDataHelper } from "../../hook/social-percentage-data-helper";
import {
  DASHBOARD_BLOCK_HEIGHT_100,
  DASHBOARD_BLOCK_HEIGHT_50,
  SocialDashboardAgeColor,
  SocialDashboardAgeIntl,
  SocialDashboardGenderColor,
  SocialDashboardGenderIntl,
  SocialDashboardGradeColor,
  SocialDashboardGradeShortIntl,
  SocialDashboardRegionColor,
  SocialDashboardRegionShortIntl,
  TabPosition,
  TurnoverRateKey,
} from "../consts";
import { SectionHeader } from "../section-header";

export type WorkforceDashboardProps = {
  data: GetSocialDashboardDataQuery["dashboardSocial"];
  currentYear: string;
  previousYear: string;
  isLoading: boolean;
};

export const TurnoverDashboard: React.FC<WorkforceDashboardProps> = (props) => {
  const { data, currentYear, previousYear, isLoading } = props;
  const { current } = data;
  const { total } = data.current.turnover;

  const [rateMode, setRateMode] = useState(TurnoverRateKey.byYearEnd);

  const intl = useIntl();

  const regionHelper = useSocialPercentageDataHelper({ data: current.turnover.region });
  const genderHelper = useSocialPercentageDataHelper({ data: current.turnover.gender });
  const ageHelper = useSocialPercentageDataHelper({ data: current.turnover.age });
  const gradeHelper = useSocialPercentageDataHelper({ data: current.turnover.grade });

  const pRegionHelper = useSocialPercentageDataHelper({ data: data.previous?.turnover.region ?? [] });
  const pGenderHelper = useSocialPercentageDataHelper({ data: data.previous?.turnover.gender ?? [] });
  const pAgeHelper = useSocialPercentageDataHelper({ data: data.previous?.turnover.age ?? [] });
  const pGradeHelper = useSocialPercentageDataHelper({ data: data.previous?.turnover.grade ?? [] });

  const options: OptionType[] = [
    { value: TurnoverRateKey.byYearEnd, label: intl.formatMessage({ id: "dashboard.social.turnover.rate.byYearEnd" }) },
    { value: TurnoverRateKey.byAverage, label: intl.formatMessage({ id: "dashboard.social.turnover.rate.byAverage" }) },
  ];

  const getBreakdownCharts = () => {
    const charts: MultiColumnBreakdownBarChartProps[] = [];

    if (genderHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.gender.title" }),
        data: genderHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value,
          color: SocialDashboardGenderColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (ageHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.age.title" }),
        data: ageHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardAgeIntl[name] }),
          value,
          color: SocialDashboardAgeColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (gradeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.grade.title" }),
        data: gradeHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeShortIntl[name] }),
          value,
          color: SocialDashboardGradeColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (regionHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.region.title" }),
        data: regionHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardRegionShortIntl[name] }),
          value,
          color: SocialDashboardRegionColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    return charts;
  };

  const getPBreakdownCharts = () => {
    const charts: MultiColumnBreakdownBarChartProps[] = [];

    if (pGenderHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.gender.title" }),
        data: pGenderHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardGenderIntl[name] }),
          value,
          color: SocialDashboardGenderColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (pAgeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.age.title" }),
        data: pAgeHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardAgeIntl[name] }),
          value,
          color: SocialDashboardAgeColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (pGradeHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.grade.title" }),
        data: gradeHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardGradeShortIntl[name] }),
          value,
          color: SocialDashboardGradeColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    if (pRegionHelper.isVisible) {
      charts.push({
        title: intl.formatMessage({ id: "dashboard.social.region.title" }),
        data: pRegionHelper.data.map(({ name, value, ...percentage }) => ({
          name: intl.formatMessage({ id: SocialDashboardRegionShortIntl[name] }),
          value,
          color: SocialDashboardRegionColor[name],
          label: formatNumberToPercentage(percentage[rateMode], 1),
        })),
      });
    }

    return charts;
  };

  const breakdownCharts = getBreakdownCharts();
  const pBreakdownCharts = getPBreakdownCharts();

  return (
    <>
      {(isLoading || breakdownCharts.length > 0) && (
        <>
          <Grid xs={12}>
            <SectionHeader
              title={intl.formatMessage({ id: "dashboard.social.turnover.title" })}
              description={intl.formatMessage({ id: "dashboard.social.turnover.description" }, { year: currentYear })}
            />
          </Grid>

          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid xs={2} flexDirection="row">
                <Grid container spacing={2}>
                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={!isApproximatelyZero(total.value)}
                  >
                    <TotalBlock
                      title={intl.formatMessage({ id: "dashboard.social.turnover.totalNumberOfTurnover.title" })}
                      value={total.value}
                    />
                  </GridWithLoading>

                  <GridWithLoading
                    xs={12}
                    height={DASHBOARD_BLOCK_HEIGHT_50}
                    isLoading={isLoading}
                    isVisible={
                      !isApproximatelyZero(total.percentageByYearEnd) || !isApproximatelyZero(total.percentageByAverage)
                    }
                  >
                    <TurnoverRateBlock
                      title={intl.formatMessage({ id: "dashboard.social.turnover.totalTurnoverRate.title" })}
                      data={{
                        percentageByYearEnd: total.percentageByYearEnd,
                        percentageByAverage: total.percentageByAverage,
                      }}
                      rateMode={rateMode}
                      rateModeOptions={options}
                      onChangeRateMode={setRateMode}
                    />
                  </GridWithLoading>
                </Grid>
              </Grid>

              <GridWithLoading
                xs={10}
                height={DASHBOARD_BLOCK_HEIGHT_100}
                isLoading={isLoading}
                isVisible={breakdownCharts.length > 0}
              >
                <MultiColumnBreakdownBlock
                  mains={{
                    title: intl.formatMessage({ id: "dashboard.social.common.breakdown.title" }, { year: currentYear }),
                    tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                    chartType: "bar",
                    charts: breakdownCharts,
                  }}
                  subs={
                    pBreakdownCharts.length > 0
                      ? {
                          title: intl.formatMessage(
                            { id: "dashboard.social.common.breakdown.title" },
                            { year: previousYear }
                          ),
                          tabName: intl.formatMessage({ id: "dashboard.common.yearly-comparison" }),
                          chartType: "bar",
                          charts: pBreakdownCharts,
                        }
                      : undefined
                  }
                  tabPosition={TabPosition.Right}
                  legendProps={{
                    position: "bottom",
                    showPercentage: false,
                    showValue: false,
                  }}
                />
              </GridWithLoading>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
