import { EmissionFactorCategory } from "generated/graphql";
import { useLocation, useParams } from "react-router-dom";
import { ROUTE_PATH } from "router/emission-factor";

export type UseUrlParserReturns = {
  profile: string;
  category: EmissionFactorCategory;
  url: {
    profile: string;
    profileEdit: string;
  };
};

export const useUrlParser = (): UseUrlParserReturns => {
  const { search } = useLocation();
  const { categoryKey } = useParams<{ categoryKey: string }>();

  const filterCategoryResult = Object.keys(ROUTE_PATH).filter((key) => ROUTE_PATH[key] === `/${categoryKey}`);
  const category = filterCategoryResult[0] as EmissionFactorCategory;

  const query = new URLSearchParams(search);
  const profile = decodeURIComponent(query.get("profile") ?? "");

  return {
    profile,
    category,
    url: {
      profile: "/admin/emission-factor",
      profileEdit: `/admin/emission-factor/edit?profile=${encodeURIComponent(profile)}`,
    },
  };
};
