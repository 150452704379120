import { GetProjectListDataQuery } from "generated/graphql";
import { DateFormat, toDateString } from "utils/date-time";
import { ProjectListRow } from "./types";

export const toProjectListRow = (project: GetProjectListDataQuery["projectList"]["data"][number]): ProjectListRow => ({
  id: project.id,
  code: project.code,
  startDate: toDateString(project.startDate, DateFormat.Date),
  endDate: toDateString(project.endDate, DateFormat.Date),
  status: project.status,
  clientGroupId: project.clientGroup.id,
  clientGroupName: project.clientGroup.groupName,
  clientGroupCode: project.clientGroup.groupCode,
  creater: project.createdBy.lastName + " " + project.createdBy.firstName,
  segment: project.segment,
  tailorMadeIndexFields: project.tailorMadeIndexFields,
  profileId: project.profileId ?? "",
  permission: project.permission.map(({ user }) => ({ user })),
  scope3Permission: project.scope3Permission?.categories ?? [],
  errors: {},
});
