import React, { useContext } from "react";

import Drawer from "@mui/material/Drawer";
import { LayoutContext } from "./layout-context";

export const DrawerContainer: React.FC = ({ children }) => {
  const layoutContext = useContext(LayoutContext);
  const { isDrawerOpen, layoutClasses, handleDrawerClose } = layoutContext;
  return (
    <Drawer
      className={layoutClasses.drawer}
      classes={{
        paper: layoutClasses.drawerPaper,
      }}
      variant="temporary"
      anchor="left"
      open={isDrawerOpen}
      onClose={handleDrawerClose}
    >
      {children}
    </Drawer>
  );
};
