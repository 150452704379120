import React, { useContext, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { IntroModal } from "components/intro-modal";
import { TourGuide } from "components/tour-guide";
import {
  useGetPermissionLazyQuery,
  useGetUsersByClientGroupQuery,
  useSubmitPermissionMutation,
} from "generated/graphql";
import { AuthContext } from "provider/auth";
import { LoadingContext } from "provider/loading";
import { CompanyList } from "./components/company-list";
import { UserList } from "./components/user-list";
import { QueryUser } from "./types";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    padding: spacing(4),
  },
}));

export const UserPermissionPage: React.FunctionComponent = () => {
  const { classes } = useStyles();

  const [userList, setUserList] = useState<QueryUser[]>([]);
  const [selectedUser, setSelectedUser] = useState("");

  const intl = useIntl();

  const { me } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const client = useApolloClient();

  const getUsers = useGetUsersByClientGroupQuery({
    client,
    variables: { clientGroupId: me.clientGroup?.id ?? "" },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ clientGroup: { users } }) => {
      setUserList(users);
      setSelectedUser(users.length > 0 ? users[0].id : "");
    },
  });

  const [getPermission, getPermissionState] = useGetPermissionLazyQuery({
    client,
    notifyOnNetworkStatusChange: true,
  });

  const [submitPermission, submitPermissionState] = useSubmitPermissionMutation({
    client,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setLoading(getUsers.loading || submitPermissionState.loading);
  }, [setLoading, getUsers.loading, submitPermissionState.loading]);

  useEffect(() => {
    if (selectedUser) {
      getPermission({
        variables: { projectId: me.project?.id ?? "", userId: selectedUser },
      });
    }
  }, [getPermission, me.project?.id, selectedUser]);

  const steps = [
    {
      content: <>{intl.formatMessage({ id: "user-permission.step-1" })}</>,
      target: "#user-list",
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "user-permission.step-2" })}</>,
      target: "#company-list",
      disableBeacon: true,
    },
    {
      content: <>{intl.formatMessage({ id: "user-permission.step-3" })}</>,
      target: "#submit-button",
      disableBeacon: true,
    },
  ];

  const handleSubmit = async (userId: string, projectCompanyIds: string[]) => {
    submitPermission({ variables: { projectId: me.project?.id ?? "", userId, projectCompanyIds } });
    return true;
  };

  return (
    <div className={classes.root}>
      <Grid xs={12}>
        <IntroModal>{intl.formatMessage({ id: "user-permission.guide" })}</IntroModal>
        <TourGuide steps={steps} />
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={12} sm={4} id="user-list">
          <UserList users={userList} selectedUser={selectedUser} onSelectUser={setSelectedUser} />
        </Grid>
        <Grid xs={12} sm={8} id="company-list">
          <CompanyList
            isLoading={getPermissionState.loading}
            isSubmitting={submitPermissionState.loading}
            selectedUserId={selectedUser}
            projectCompanies={getPermissionState.data?.permission?.project.projectCompany ?? []}
            permittedCompanies={getPermissionState.data?.permission?.projectCompanies.map(({ id }) => id) ?? []}
            onSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </div>
  );
};
