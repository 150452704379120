import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { WaterConsumptionRecordRow } from "./types";

export const newWaterConsumptionRow: WaterConsumptionRecordRow = {
  recordId: "",
  companyId: "",
  address: "",
  meterNumber: "",
  waterSource: "",
  waterSourceSupplier: "",
  startDate: "",
  endDate: "",
  waterConsumptionAmount: 0,
  waterConsumptionUnit: "",
  remarks: "",
  minDate: new Date(),
  maxDate: new Date(),
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<WaterConsumptionRecordRow, string> = {
  waterConsumptionAmount: "tips.water.water-consumption-amount",
  meterNumber: "tips.water.meter-number",
};

export enum WaterConsumptionUnit {
  M3 = "m3",
  Litre = "Litre",
  Gallon = "gallon",
}

export const waterConsumptionUnitOptions: OptionType[] = [
  { label: "m3 立方米", value: WaterConsumptionUnit.M3 },
  { label: "Litre 升", value: WaterConsumptionUnit.Litre },
  { label: "Gallon 加仑", value: WaterConsumptionUnit.Gallon },
];

export enum WaterSource {
  TapWater = "tapWater",
  UndergroundWater = "undergroundWater",
  SpringWater = "springWater",
  WellWater = "wellWater",
  Reclaimed = "reclaimed",
}

export const waterSourceOptions: OptionType[] = [
  { label: "Tap water 自来水", value: WaterSource.TapWater },
  { label: "Underground water 地下水", value: WaterSource.UndergroundWater },
  { label: "Spring water 山泉水", value: WaterSource.SpringWater },
  { label: "Well water 井水", value: WaterSource.WellWater },
  { label: "reclaimed 中水", value: WaterSource.Reclaimed },
];
