import React, { useState } from "react";

import { ExpandLess as CollapseIcon, ExpandMore as ExpandIcon } from "@mui/icons-material";
import { Collapse, Unstable_Grid2 as Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  detailsRow: {
    cursor: "pointer",
  },
  detailsContainer: {
    height: 120,
    padding: 6,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "black",
    whiteSpace: "pre-wrap",
    overflow: "scroll",
  },
}));

export type ErrorMessageWithDetailsProps = {
  message: string;
  details: string;
};

export const ErrorMessageWithDetails: React.FC<ErrorMessageWithDetailsProps> = ({ message, details }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Grid container>
      <Grid xs={12}>{message}</Grid>
      <Grid xs={12}>
        <br />
      </Grid>
      <Grid xs={12} onClick={() => setIsExpanded(!isExpanded)} className={classes.detailsRow}>
        <Grid container gap={1}>
          <Grid>{intl.formatMessage({ id: "error.submit.data.details" })}</Grid>
          <Grid>{isExpanded ? <CollapseIcon /> : <ExpandIcon />}</Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Grid xs={12} className={classes.detailsContainer}>
            {details}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
