import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  CompanyType,
  createSelectSingleFormatter,
  DataGridContainer,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { Applicability, indexTypeColumnRef, newCompanyRow, tips } from "./consts";
import { useActions } from "./hook/action";
import { CompanyRecordRow } from "./types";

const source = CompanyType.Company;

export const CompanyPage: React.FC = () => {
  const [segment, setSegment] = useState([] as string[]);
  const [initRows, setInitRows] = useState<CompanyRecordRow[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const { getData, submitData } = useActions();
  const { openDialog, Dialog, DialogType } = useDialog();

  const segmentOptions = useMemo(() => {
    const result = [{ label: "Head Office", value: "Head Office" }];
    return result.concat(segment.map((s) => ({ label: s, value: s })));
  }, [segment]);

  const yesNoOptions = useMemo(
    () => [
      { label: intl.formatMessage({ id: "excel.company.option.applicable" }), value: Applicability.Applicable },
      { label: intl.formatMessage({ id: "excel.company.option.not-applicable" }), value: Applicability.Unapplicable },
    ],
    [intl]
  );

  const columns = useMemo(() => {
    const columns: IColumnItem[] = [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "fullName",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: InputEditor,
        formatter: getFormatter(Formatter, "fullName"),
        resizable: true,
        width: 150,
        frozen: true,
      },
      {
        key: "shortName",
        name: intl.formatMessage({ id: "excel.common.company-short-name" }),
        editor: InputEditor,
        formatter: getFormatter(Formatter, "shortName"),
        width: 150,
        resizable: true,
      },
      {
        key: "segment",
        name: intl.formatMessage({ id: "excel.company.segment" }),
        editor: SelectEditor,
        options: segmentOptions,
        formatter: getFormatter(createSelectSingleFormatter(segmentOptions), "segment"),
        width: 150,
        resizable: true,
      },
    ];

    Object.entries(indexTypeColumnRef).forEach(([index, { key, name }]) => {
      switch (index as IndexType) {
        // Energy Consumption & Water Consumption are not controllable becuase always available
        case IndexType.EnergyConsumption:
        case IndexType.WaterConsumption:
          break;
        default:
          const column: IColumnItem = {
            key,
            name: intl.formatMessage({ id: name }),
            editor: SelectEditor,
            options: yesNoOptions,
            formatter: getFormatter(createSelectSingleFormatter(yesNoOptions), key),
            width: 160,
            resizable: true,
            isDisable: ({ rowData }: { rowData: CompanyRecordRow }) =>
              index === IndexType.Others && rowData.isDisableOthersColumn,
          };

          columns.push(column);
          break;
      }
    });

    return columns;
  }, [intl, segmentOptions, yesNoOptions]);

  const initData = useCallback(async () => {
    setLoading(true);

    const { segment, rows } = await getData();

    setSegment(segment);
    setInitRows([...rows]);

    setLoading(false);
  }, [setLoading, getData]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<CompanyRecordRow>[]>((values) =>
    Yup.array(
      Yup.object<Partial<CompanyRecordRow>>({
        fullName: Yup.string<string>()
          .required("errors.common.require")
          .unique("the company is existed", "fullName", values),
        segment: Yup.string().required("errors.common.require"),
        nf1: Yup.string().required(),
      }).required()
    ).required()
  );

  const introContent = intl.formatMessage({ id: "excel.company.guide" });

  const handleUpdateRows: OnUpdateDataGridRows<CompanyRecordRow> = (rows, updatedItem) => {
    const social = [
      "workforce",
      "turnover",
      "injuryAndFatalities",
      "developmentAndTraining",
      "trainingRatio",
      "supplyChainManagement",
      "productResponsibility",
      "communityInvestment",
      "productResponsibilityPolicy",
      "labourResponsibilityPolicy",
    ];
    let isOpenAlert = false;
    for (const key of Object.keys(updatedItem.updated)) {
      if (social.indexOf(key) !== -1 && updatedItem.updated[key] === Applicability.Unapplicable) {
        isOpenAlert = true;
        break;
      }
    }

    if (isOpenAlert)
      openDialog({ content: intl.formatMessage({ id: "error.projectCompany.notAvailable" }), type: DialogType.ALERT });

    return rows;
  };

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={newCompanyRow}
        source={source}
        fullSchema={validationSchema}
        canSave={false}
        guide={{ introContent }}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
        disableFile
      />
      <Dialog />
    </DatasheetPaper>
  );
};
