import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Property } from "csstype";
import { makeStyles } from "tss-react/mui";

import { DashboardColor } from "pages/dashboard/components/consts";

const useStyles = makeStyles()(({ spacing }) => ({
  colorIndicator: {
    width: 10,
    height: 10,
  },
  boxContainer: {
    paddingTop: spacing(0.5),
  },
  label: {
    paddingBottom: spacing(0.5),
    color: DashboardColor.text.grey,
    lineHeight: 1,
    whiteSpace: "pre-wrap",
    fontWeight: "bold",
  },
  description: {
    fontStyle: "italic",
  },
}));

export type ChartLegendItem = {
  color: Property.Fill;
  name: string;
  description?: string;
};

export type ChartLegendProps = {
  data: ChartLegendItem[];
  column?: number;
  gap?: number;
};

export const ChartLegend: React.FC<ChartLegendProps> = (props) => {
  const { data, column, gap } = props;

  const { classes } = useStyles();

  const gridColumn = column ? Math.min(column, data.length) : 1;

  return (
    <Box
      sx={{ display: "grid", gap: gap ?? 1, alignItems: "center", gridTemplateColumns: `repeat(${gridColumn}, auto)` }}
    >
      {data.map((d, index) => (
        <Grid key={index} padding={0}>
          <Grid container spacing={0} gap={1} alignItems="flex-start" wrap="nowrap">
            <Grid className={classes.boxContainer}>
              <Box
                className={classes.colorIndicator}
                style={{ backgroundColor: d.color ? d.color : DashboardColor.background.grey }}
              />
            </Grid>
            <Grid>
              <Grid container direction="column" spacing={0}>
                <Grid>
                  <Typography variant="body1" className={classes.label}>
                    {d.name}
                  </Typography>
                </Grid>
                {d.description && (
                  <Grid>
                    <Typography variant="body2" className={classes.description}>
                      {d.description}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
