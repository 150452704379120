import React from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid, { Grid2Props as GridProps } from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { UPPER_BOUNDARY_FACTOR } from "pages/dashboard/components/charts/bar-chart/consts";
import { formatNumberToPercentage, formatNumberWithComma } from "utils/format";
import { BarChart, BarChartProps as BasicBarChartProps } from "../charts/bar-chart/bar-chart";
import { getBarChartBoundary } from "../charts/bar-chart/utils";
import { ChartLegend, ChartLegendItem, ChartLegendProps } from "../charts/chart-legend";
import {
  PieChartWithLargestValueLabel,
  PieChartWithLargestValueLabelProps,
} from "../charts/pie-chart/pie-chart-with-largest-value-label";
import { DashboardColor, DASHBOARD_BLOCK_HEIGHT_100, TabPosition } from "../consts";
import { TabSwapContainer } from "../containers/tab-swap-container";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    height: "100%",
    padding: spacing(2, 2),
    borderRadius: 16,
    display: "flex",
    overflow: "visible",
  },
  titleContainer: {
    padding: spacing(0.5, 0),
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  greyText: {
    color: DashboardColor.text.grey,
  },
}));

export type MultiColumnBreakdownPieChartProps = PieChartWithLargestValueLabelProps & {
  title: string;
  legendColumn?: number;
};

export type MultiColumnBreakdownBarChartProps = Pick<BasicBarChartProps, "data" | "chartProps"> & {
  title: string;
};

type BlockProps = {
  title?: string;
  tabName: string;
} & (
  | {
      chartType?: "pie";
      charts: MultiColumnBreakdownPieChartProps[];
    }
  | {
      chartType: "bar";
      charts: MultiColumnBreakdownBarChartProps[];
    }
);

export type MultiColumnBreakdownBlockProps = {
  tabPosition?: TabPosition;
  mains: BlockProps;
  subs?: BlockProps;
  legendProps?: Omit<ChartLegendProps, "data"> & {
    position?: "right" | "bottom";
    showValue?: boolean;
    showPercentage?: boolean;
  };
};

export const MultiColumnBreakdownBlock: React.FC<MultiColumnBreakdownBlockProps> = (props) => {
  const { tabPosition, mains, subs, legendProps } = props;

  const { classes, cx } = useStyles();
  const intl = useIntl();

  const renderBarChart = (chart: MultiColumnBreakdownBarChartProps) => {
    const { data, chartProps } = chart;

    const lowerBoundary = 0;
    const upperBoundary = Math.max(...data.map((d) => d.value)) * UPPER_BOUNDARY_FACTOR;
    const bound = getBarChartBoundary({
      lower: lowerBoundary,
      upper: upperBoundary,
      ticker: 3,
    });

    return (
      <Grid container height="inherit" spacing={0} alignItems="center" direction="column" wrap="nowrap">
        {chart.title && (
          <Grid className={classes.titleContainer}>
            <Typography variant={"h4"} className={cx(classes.greyText, classes.bold)}>
              {chart.title}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} padding={1} flexGrow={3}>
          <BarChart
            height={DASHBOARD_BLOCK_HEIGHT_100 * (0.3 + 0.06 * data.length)}
            names={data.map((d) => d.name)}
            data={data}
            bound={bound}
            chartProps={chartProps}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPieChart = (charts: MultiColumnBreakdownPieChartProps) => {
    const { data, unit, legendColumn } = charts;

    let layoutProps: {
      pieChartGridXs: number;
      legendGap: number;
    };

    switch (legendProps?.position) {
      case "right":
      default:
        layoutProps = {
          pieChartGridXs: 8,
          legendGap: 3,
        };
        break;
      case "bottom":
        layoutProps = {
          pieChartGridXs: 7,
          legendGap: 1,
        };
        break;
    }

    const getPieChartGridContainerProps = (): Pick<GridProps, "flexDirection" | "height"> => {
      switch (legendProps?.position) {
        case "right":
        default:
          return {
            flexDirection: "row",
            height: "100%",
          };
        case "bottom":
          return {
            flexDirection: "column",
          };
      }
    };

    const valueSum = data.reduce((acc, d) => acc + d.value, 0);
    const legendData = data
      .filter((d) => d.value > 0)
      .map<ChartLegendItem>((d) => ({
        name: legendProps?.showPercentage
          ? intl.formatMessage(
              { id: "dashboard.common.legend.label" },
              { name: d.name, percentage: formatNumberToPercentage(d.value / valueSum) }
            )
          : d.name,
        color: d.color,
        description: legendProps?.showValue
          ? intl.formatMessage(
              { id: "dashboard.common.legend.description" },
              { value: formatNumberWithComma(d.value), unit }
            )
          : undefined,
      }));

    return (
      <Grid container height="100%" spacing={0} alignItems="center" direction="column" wrap="nowrap">
        {charts.title && (
          <Grid className={classes.titleContainer}>
            <Typography variant={"h4"} className={cx(classes.greyText, classes.bold)}>
              {charts.title}
            </Typography>
          </Grid>
        )}

        <Grid xs={12} flexGrow={3}>
          <Grid container alignItems="center" wrap="nowrap" {...getPieChartGridContainerProps()}>
            <Grid xs={layoutProps.pieChartGridXs} minWidth={200}>
              <PieChartWithLargestValueLabel data={data} unit={unit} labelType={charts.labelType ?? "percentage"} />
            </Grid>
            <Grid>
              <ChartLegend data={legendData} column={legendColumn} gap={layoutProps.legendGap} {...legendProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderBlockContent = ({ title, charts, chartType = "pie" }: BlockProps) => (
    <Grid padding={0} height="inherit">
      <Grid container height="100%" direction="column" wrap="nowrap">
        {title && (
          <Grid className={classes.titleContainer}>
            <Typography variant={"h4"} className={cx(classes.greyText, classes.bold)}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} flexGrow={3}>
          <Grid container height="100%" wrap="nowrap">
            {charts.map((chart, i: number) => (
              <React.Fragment key={i}>
                <Grid xs={12 / charts.length}>
                  {chartType === "pie"
                    ? renderPieChart(chart as MultiColumnBreakdownPieChartProps)
                    : renderBarChart(chart as MultiColumnBreakdownBarChartProps)}
                </Grid>
                {i !== charts.length - 1 && <Divider orientation="vertical" flexItem />}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <TabSwapContainer
      main={{
        tabName: mains.tabName,
        content: renderBlockContent(mains),
      }}
      sub={
        subs
          ? {
              tabName: subs.tabName,
              content: renderBlockContent(subs),
            }
          : undefined
      }
      tabPosition={tabPosition ?? TabPosition.Right}
    />
  );
};
