import React from "react";

import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel, { StepLabelProps } from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

type StepperStyleListProps = {
  items: Array<{
    key?: React.Key;
    labelProps: StepLabelProps;
    labelContent: JSX.Element;
    content: JSX.Element;
  }>;
};

export const StepperStyleList: React.FC<StepperStyleListProps> = ({ items }) => (
  <Stepper orientation="vertical">
    {items.map((item, index) => (
      <Step key={item.key ?? index}>
        <StepLabel {...item.labelProps}>{item.labelContent}</StepLabel>
        <StepContent TransitionProps={{ in: true }}>{item.content}</StepContent>
      </Step>
    ))}
  </Stepper>
);
