import { DateFormat, toDateString } from "utils/date-time";
import { ProjectFormData } from "./types";
import { CloneProjectMode, ProjectStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";

export const newProjectFormData: ProjectFormData = {
  id: "",
  code: "",
  startDate: toDateString(new Date(), DateFormat.Date),
  endDate: toDateString(new Date(), DateFormat.Date),
  status: ProjectStatus.Open,
  clientGroupId: "",
  clientGroupName: "",
  clientGroupCode: "",
  creater: "",
  cloneProjectId: "",
  cloneProjectCode: "",
  cloneProjectMode: "",
  segment: [],
  tailorMadeIndexFields: [],
  profileId: "",
  permission: [{ user: { firstName: "", lastName: "", loginId: "" } }],
  scope3Permission: [],
  errors: {},
};

export const cloneProjectModeOptions: OptionType[] = [
  { label: "", value: "" },
  { label: "Address & Floor Area", value: CloneProjectMode.Nf1 },
  { label: "All", value: CloneProjectMode.All },
];
