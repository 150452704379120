import React, { useCallback, useContext, useState } from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "tss-react/mui";

import { Language } from "i18n";
import { LanguageContext } from "provider/language";
import EN from "static/images/en.png";
import CN from "static/images/zh-cn.png";
import TW from "static/images/zh-tw.png";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  flag: {
    margin: spacing(),
  },
  text: {
    color: palette.primary.contrastText,
  },
}));

const options: Record<Language, { name: string; flag: string }> = {
  [Language.Eng]: {
    name: "English",
    flag: EN,
  },
  [Language.SimChi]: {
    name: "简体中文",
    flag: CN,
  },
  [Language.TradChi]: {
    name: "繁體中文",
    flag: TW,
  },
};

export const LangSelect: React.FC = () => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { locale, setLocale } = useContext(LanguageContext);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = useCallback(
    (lang: Language) => {
      setLocale(lang);
      setAnchorEl(null);
    },
    [setLocale]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItemButton aria-haspopup="true" onClick={handleClickListItem}>
          <ListItemText>
            <span className={classes.text}>{options[locale].name}</span>
          </ListItemText>
          <ExpandMore />
        </ListItemButton>
      </List>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.entries(options).map(([key, { name, flag }]) => (
          <MenuItem key={key} selected={key === locale} onClick={() => handleMenuItemClick(key as Language)}>
            <img src={flag} alt={name} className={classes.flag} />
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
