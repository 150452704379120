import { RecordStatus } from "generated/graphql";
import { InjuryAndFatalitiesRecordRow } from "./types";

export const newInjuryAndFatalitiesRow: InjuryAndFatalitiesRecordRow = {
  recordId: "",
  companyId: "",
  remarks: "",
  occurrenceDate: "",
  numberOfInjuries: 0,
  daysOfInjury: 0,
  numberOfFatalities: 0,
  incidentDescription: "",
  status: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};
