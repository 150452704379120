import React, { useState } from "react";

import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Switch from "@mui/material/Switch/Switch";
import { TransitionProps } from "@mui/material/transitions";
import { ProgressBlock } from "components/loading/progress-block";
import { makeStyles } from "tss-react/mui";
import { useIntl } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  dialogTitle: {
    padding: theme.spacing(3, 4, 2),
  },
  dialogContent: {
    padding: theme.spacing(2, 4),
  },
  dialogSwitchLabel: {
    padding: theme.spacing(0, 2),
  },
}));

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  );
});

export type SocialKpiReportOptions = {
  isIncludeResign: boolean;
  endDate: Date;
};

export type SocialKpiReportDialogProps = {
  isOpen: boolean;
  onSubmit: (isIncludeResign: boolean) => void;
  onClose: () => void;
};

export const SocialKpiReportDialog: React.FC<SocialKpiReportDialogProps> = (props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const { isOpen, onSubmit, onClose } = props;
  const [isIncludeResign, setIsIncludeResign] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitch = (event) => {
    setIsIncludeResign(event.target.checked);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmit(isIncludeResign);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} fullWidth maxWidth="xs">
      <DialogTitle variant="h4" className={classes.dialogTitle}>
        {intl.formatMessage({ id: "dialog.social-kpi.title" })}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isIncludeResign} onChange={handleSwitch} disabled={isLoading} />}
            label={
              <Typography variant="h5" className={classes.dialogSwitchLabel}>
                {intl.formatMessage({ id: "dialog.social-kpi.include-resign" })}
              </Typography>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <ProgressBlock width={50} height={50} />
        ) : (
          <>
            <Button onClick={handleSubmit}>{intl.formatMessage({ id: "button.submit" })}</Button>
            <Button onClick={onClose}>{intl.formatMessage({ id: "button.close" })}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
