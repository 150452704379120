import { LanguageContext } from "provider/language";
import React, { useContext } from "react";

import { VictoryAxis, VictoryBar, VictoryLabelProps, VictoryStack } from "victory";

import { DashboardColor } from "../../consts";
import { BarChartContainer } from "../bar-chart/bar-chart-container";
import { getBarChartXAxisProps, getBarChartYAxisProps } from "../bar-chart/utils";
import { ChartInput } from "../types";
import { StackedBarChartBarDef, StackedBarChartCategoryDef } from "./types";

export type StackedBarChartProps = {
  bound: number[];
  barDef: StackedBarChartBarDef[];
  categoryDef: StackedBarChartCategoryDef[];
};

export const StackedBarChart: React.FC<StackedBarChartProps> = (props) => {
  const { bound, barDef, categoryDef } = props;

  const { locale } = useContext(LanguageContext);

  const sortByBarName = (chartData: ChartInput[]) => {
    // Horizontal Victory chart displays bar from bottom to top by default
    // To order them as expected (top to bottom), reverse the order of input
    const orderedBarName = barDef.map(({ barName }) => barName).reverse();

    return chartData
      .sort((a, b) => orderedBarName.indexOf(a.name) - orderedBarName.indexOf(b.name))
      .map(({ name, value }) => ({ x: name, y: value }));
  };

  const getColorByXName = (datum?: { xName?: string }) => {
    if (datum && datum.xName) {
      const targetDef = barDef.find((b) => b.barName === datum.xName);
      if (targetDef && targetDef.labelColor) return targetDef.labelColor;
    }

    return DashboardColor.chart.grey1;
  };

  const getLabelByXName = (datum?: { xName?: string }) => {
    if (datum && datum.xName) {
      const targetDef = barDef.find((b) => b.barName === datum.xName);
      if (targetDef && targetDef.label) return targetDef.label;
    }

    return "";
  };

  return (
    <BarChartContainer barCount={barDef.length} bound={bound}>
      <VictoryAxis {...getBarChartXAxisProps()} />
      <VictoryAxis {...getBarChartYAxisProps(bound, locale)} />
      <VictoryStack
        horizontal
        colorScale={categoryDef.map(({ color }) => color)}
        labels={({ datum }: VictoryLabelProps) => getLabelByXName(datum)}
        style={{
          labels: {
            fill: ({ datum }: VictoryLabelProps) => getColorByXName(datum),
            fontSize: 28,
            fontWeight: "bold",
          },
        }}
      >
        {categoryDef.map(({ data }, i) => (
          <VictoryBar
            key={i}
            horizontal
            barWidth={36}
            data={sortByBarName(data)}
            style={{
              labels: {
                fontSize: 28,
                fontWeight: "bold",
              },
            }}
          />
        ))}
      </VictoryStack>
    </BarChartContainer>
  );
};
