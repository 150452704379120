import { useEffect, useRef, useState } from "react";

const useStringState = () => useState<string>("");

export const useDebounceSearch = (
  callback: (value: string) => void,
  debounceTime = 500
): ReturnType<typeof useStringState> => {
  const [searchValue, setSearchValue] = useState("");
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      const timer = setTimeout(() => {
        callback(searchValue);
      }, debounceTime);

      return () => clearTimeout(timer);
    } else {
      isInitialized.current = true;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchValue, debounceTime]);

  return [searchValue, setSearchValue];
};
