import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { KeyRecord } from "utils/types";
import { HazardousWasteRecordRow } from "./types";

export const newHazardousWasteRow: HazardousWasteRecordRow = {
  recordId: "",
  companyId: "",
  hazardousWasteName: "",
  hazardousWasteSource: "",
  hazardousWasteClassificationReference: "",
  hazardousWasteTypes: "",
  handlingDate: "",
  quantityHandled: 0,
  quantityRemaining: 0,
  previousQuantityRemaining: 0,
  quantityGenerated: 0,
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export const tips: KeyRecord<HazardousWasteRecordRow, string> = {
  hazardousWasteTypes: "tips.hazardous-waste.types",
};

export enum HazardousWasteType {
  HW01 = "HW01 Clinical waste 医疗废物",
  HW02 = "HW02 Pharmaceutical waste 医药废物",
  HW03 = "HW03 Waste drugs, medicine 废药物, 药品",
  HW04 = "HW04 Pesticide waste 农药废物",
  HW05 = "HW05 Timber preservative agent waste 木材防腐剂废物",
  HW06 = "HW06 Waste organic solvents and organic solvents-containing waste 废有机溶剂与含有机溶剂废物",
  HW07 = "HW07 Heat treated cyanide-containing waste 热处理含氰废物",
  HW08 = "HW08 Waste mineral oil and mineral oil-containing waste 废矿物油与含矿物油废物",
  HW09 = "HW09 Oil-water, hydrocarbon-water mixture or emulsion 油/水, 烃/水混合物或乳化液",
  HW10 = "HW10 Polychlorinated (brominated) biphenyl waste 多氯(溴)联苯类废物",
  HW11 = "HW11 Refined (steamed) distillation residue 精(蒸) 馏残渣",
  HW12 = "HW12 Dye, coating waste 染料, 涂料废物",
  HW13 = "HW13 Organic resin waste 有机树脂类废物",
  HW14 = "HW14 New chemical substance waste 新化学物质废物",
  HW15 = "HW15 Explosive waste 爆炸性废物",
  HW16 = "HW16 Photosensitive material waste 感光材料废物",
  HW17 = "HW17 Surface treated waste 表面处理废物",
  HW18 = "HW18 Incinerated residue 焚烧处理残渣",
  HW19 = "HW19 Metal-containing carbonyl compound waste 含金属羰基化合物废物",
  HW20 = "HW20 Beryllium-containing waste 含铍废物",
  HW21 = "HW21 Chromium-containing waste 含铬废物",
  HW22 = "HW22 Copper-containing waste 含铜废物",
  HW23 = "HW23 Zinc-containing waste 含锌废物",
  HW24 = "HW24 Arsenic-containing waste 含砷废物",
  HW25 = "HW25 Selenium containing waste 含硒废物",
  HW26 = "HW26 Cadmium-containing waste 含镉废物",
  HW27 = "HW27 Antimony-containing waste 含锑废物",
  HW28 = "HW28 Tellurium-containing waste 含碲废物",
  HW29 = "HW29 Mercury-containing waste 含汞废物",
  HW30 = "HW30 Thallium-containing waste 含铊废物",
  HW31 = "HW31 Lead-containing waste 含铅废物",
  HW32 = "HW32 Inorganic fluoride waste 无机氟化物废物",
  HW33 = "HW33 Inorganic cyanide waste 无机氰化物废物",
  HW34 = "HW34 Waste acid 废酸",
  HW35 = "HW35 Waste alkali 废碱",
  HW36 = "HW36 Asbestos waste 石棉废物",
  HW37 = "HW37 Organic phosphorus compound waste 有机磷化合物废物",
  HW38 = "HW38 Organic cyanide compound waste 有机氰化合物废物",
  HW39 = "HW39 Phenol-containing waste 含酚废物",
  HW40 = "HW40 Ether-containing waste 含醚废物",
  HW45 = "HW45 Organic halide-containing waste 含有机卤化物废物",
  HW46 = "HW46 Nickel-containing waste 含镍废物",
  HW47 = "HW47 Barium-containing waste 含钡废物",
  HW48 = "HW48 Non-ferrous metal smelting waste 有色金属冶炼废物",
  HW49 = "HW49 Other waste 其他废物",
  HW50 = "HW50 Waste catalyst 废催化剂",
}

export const hazardousWasteTypeOptions: OptionType[] = [
  { label: "HW01 Clinical waste 医疗废物", value: HazardousWasteType.HW01 },
  { label: "HW02 Pharmaceutical waste 医药废物", value: HazardousWasteType.HW02 },
  { label: "HW03 Waste drugs, medicine 废药物, 药品", value: HazardousWasteType.HW03 },
  { label: "HW04 Pesticide waste 农药废物", value: HazardousWasteType.HW04 },
  { label: "HW05 Timber preservative agent waste 木材防腐剂废物", value: HazardousWasteType.HW05 },
  {
    label: "HW06 Waste organic solvents and organic solvents-containing waste 废有机溶剂与含有机溶剂废物",
    value: HazardousWasteType.HW06,
  },
  { label: "HW07 Heat treated cyanide-containing waste 热处理含氰废物", value: HazardousWasteType.HW07 },
  {
    label: "HW08 Waste mineral oil and mineral oil-containing waste 废矿物油与含矿物油废物",
    value: HazardousWasteType.HW08,
  },
  {
    label: "HW09 Oil-water, hydrocarbon-water mixture or emulsion 油/水, 烃/水混合物或乳化液",
    value: HazardousWasteType.HW09,
  },
  { label: "HW10 Polychlorinated (brominated) biphenyl waste 多氯(溴)联苯类废物", value: HazardousWasteType.HW10 },
  { label: "HW11 Refined (steamed) distillation residue 精(蒸) 馏残渣", value: HazardousWasteType.HW11 },
  { label: "HW12 Dye, coating waste 染料, 涂料废物", value: HazardousWasteType.HW12 },
  { label: "HW13 Organic resin waste 有机树脂类废物", value: HazardousWasteType.HW13 },
  { label: "HW14 New chemical substance waste 新化学物质废物", value: HazardousWasteType.HW14 },
  { label: "HW15 Explosive waste 爆炸性废物", value: HazardousWasteType.HW15 },
  { label: "HW16 Photosensitive material waste 感光材料废物", value: HazardousWasteType.HW16 },
  { label: "HW17 Surface treated waste 表面处理废物", value: HazardousWasteType.HW17 },
  { label: "HW18 Incinerated residue 焚烧处理残渣", value: HazardousWasteType.HW18 },
  { label: "HW19 Metal-containing carbonyl compound waste 含金属羰基化合物废物", value: HazardousWasteType.HW19 },
  { label: "HW20 Beryllium-containing waste 含铍废物", value: HazardousWasteType.HW20 },
  { label: "HW21 Chromium-containing waste 含铬废物", value: HazardousWasteType.HW21 },
  { label: "HW22 Copper-containing waste 含铜废物", value: HazardousWasteType.HW22 },
  { label: "HW23 Zinc-containing waste 含锌废物", value: HazardousWasteType.HW23 },
  { label: "HW24 Arsenic-containing waste 含砷废物", value: HazardousWasteType.HW24 },
  { label: "HW25 Selenium containing waste 含硒废物", value: HazardousWasteType.HW25 },
  { label: "HW26 Cadmium-containing waste 含镉废物", value: HazardousWasteType.HW26 },
  { label: "HW27 Antimony-containing waste 含锑废物", value: HazardousWasteType.HW27 },
  { label: "HW28 Tellurium-containing waste 含碲废物", value: HazardousWasteType.HW28 },
  { label: "HW29 Mercury-containing waste 含汞废物", value: HazardousWasteType.HW29 },
  { label: "HW30 Thallium-containing waste 含铊废物", value: HazardousWasteType.HW30 },
  { label: "HW31 Lead-containing waste 含铅废物", value: HazardousWasteType.HW31 },
  { label: "HW32 Inorganic fluoride waste 无机氟化物废物", value: HazardousWasteType.HW32 },
  { label: "HW33 Inorganic cyanide waste 无机氰化物废物", value: HazardousWasteType.HW33 },
  { label: "HW34 Waste acid 废酸", value: HazardousWasteType.HW34 },
  { label: "HW35 Waste alkali 废碱", value: HazardousWasteType.HW35 },
  { label: "HW36 Asbestos waste 石棉废物", value: HazardousWasteType.HW36 },
  { label: "HW37 Organic phosphorus compound waste 有机磷化合物废物", value: HazardousWasteType.HW37 },
  { label: "HW38 Organic cyanide compound waste 有机氰化合物废物", value: HazardousWasteType.HW38 },
  { label: "HW39 Phenol-containing waste 含酚废物", value: HazardousWasteType.HW39 },
  { label: "HW40 Ether-containing waste 含醚废物", value: HazardousWasteType.HW40 },
  { label: "HW45 Organic halide-containing waste 含有机卤化物废物", value: HazardousWasteType.HW45 },
  { label: "HW46 Nickel-containing waste 含镍废物", value: HazardousWasteType.HW46 },
  { label: "HW47 Barium-containing waste 含钡废物", value: HazardousWasteType.HW47 },
  { label: "HW48 Non-ferrous metal smelting waste 有色金属冶炼废物", value: HazardousWasteType.HW48 },
  { label: "HW49 Other waste 其他废物", value: HazardousWasteType.HW49 },
  { label: "HW50 Waste catalyst 废催化剂", value: HazardousWasteType.HW50 },
];
