import React, { ElementRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { ICellEditorParams } from "ag-grid-community";
import { ICellEditorReactComp } from "ag-grid-react";
import Select from "react-select";

import { OptionType } from "utils/interfaces";

type UniqueSelectEditorProps = {
  options: OptionType[];
  usedValue: string[];
};

export const UniqueSelectEditor = React.forwardRef((props: ICellEditorParams & UniqueSelectEditorProps, ref) => {
  const [value, setValue] = useState<OptionType | undefined>(undefined);

  const filteredOptions = useMemo(() => {
    return props.options.filter((option) => props.value === option.value || !props.usedValue.includes(option.value));
  }, [props.options, props.value, props.usedValue]);

  useEffect(() => {
    if (props.value) {
      setValue(filteredOptions.find((option) => option.value === props.value));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filteredOptions]);

  useImperativeHandle<ElementRef<typeof UniqueSelectEditor>, ICellEditorReactComp>(ref, () => ({
    getValue() {
      return value ? value.value : "";
    },
  }));

  return (
    <Grid style={{ width: props.eGridCell.style.width }}>
      <Select
        value={value}
        options={filteredOptions}
        onChange={(option) => setValue(option as OptionType)}
        menuPlacement="auto"
      />
    </Grid>
  );
});
