import React from "react";

import DocumentIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/GetApp";

import { ReportType } from "generated/graphql";
import { DownloadButton, DownloadButtonProps } from "./download-button";

const DownloadDataReportButton: React.FC<DownloadButtonProps> = (props) => (
  <DownloadButton {...props}>
    <DownloadIcon />
  </DownloadButton>
);

const DownloadKpiReportButton: React.FC<DownloadButtonProps> = (props) => (
  <DownloadButton {...props}>
    <DocumentIcon />
  </DownloadButton>
);

export type DownloadButtonGroupProps = {
  isProjectHasEmissionFactorProfile: boolean;
  downloadDataSummaryReport: (reportType: ReportType) => void;
  downloadEnvironmentalKpiReport: () => void;
  showSocialKpiReportDialog: () => void;
};

export const DownloadButtonGroup: React.FC<DownloadButtonGroupProps> = (props) => {
  const {
    isProjectHasEmissionFactorProfile,
    downloadDataSummaryReport,
    downloadEnvironmentalKpiReport,
    showSocialKpiReportDialog,
  } = props;

  return (
    <>
      <DownloadDataReportButton
        tooltip="Data Report(Environmental)"
        ariaLabel="download environmental data report"
        onClick={() => downloadDataSummaryReport(ReportType.Environmental)}
      />

      <DownloadDataReportButton
        tooltip="Data Report (Social)"
        ariaLabel="download social data report"
        onClick={() => downloadDataSummaryReport(ReportType.Social)}
      />

      <DownloadKpiReportButton
        tooltip="Environmental KPI Report"
        ariaLabel="download Environmental KPI report"
        disabled={!isProjectHasEmissionFactorProfile}
        disabledTooltip="Please assign an emission factor profile to the project to download KPI report."
        onClick={downloadEnvironmentalKpiReport}
      />

      <DownloadKpiReportButton
        tooltip="Social KPI Report"
        ariaLabel="download Social KPI report"
        disabled={!isProjectHasEmissionFactorProfile}
        disabledTooltip="Please assign an emission factor profile to the project to download KPI report."
        onClick={showSocialKpiReportDialog}
      />
    </>
  );
};
