import React, { useMemo } from "react";

import { Scope3Permission } from "generated/graphql";
import { usePermission } from "hook/permission";
import { DrawerContainer } from "../components/drawer-container";
import { Header } from "../components/header";
import { Sidebar } from "../components/sidebar";
import { menu, menuWithoutDashboard } from "./menu";

export type AdminLayoutProps = {
  scope3Permission?: Scope3Permission;
};

export const AdminLayout: React.FC<AdminLayoutProps> = ({ scope3Permission }) => {
  const isPermitted = usePermission();

  const filteredMenu = useMemo(() => {
    // TODO
    // FIXME: Resume to this code when the dashboard issue is fixed
    // if (scope3Permission && scope3Permission.categories.length > 0) {
    //   return menu;
    // } else {
    //   // if no scope 3 permission, hide scope 3 menu
    //   return menu.filter((m) => m.name !== "layout.menu.admin.scope3");
    // }

    const result = isPermitted ? menu : menuWithoutDashboard;

    if (scope3Permission && scope3Permission.categories.length > 0) {
      return result;
    } else {
      // if no scope 3 permission, hide scope 3 menu
      return result.filter((m) => m.name !== "layout.menu.admin.scope3");
    }
  }, [scope3Permission, isPermitted]);

  return (
    <>
      <Header />
      <DrawerContainer>
        <Sidebar menu={filteredMenu} />
      </DrawerContainer>
    </>
  );
};
