import React from "react";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { StyledTableCell } from "./styled-table-cell";

export type TableHeaderProps = {
  headerGroups: any[];
  HeaderActionComponent: React.ReactNode | null;
};

export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const { HeaderActionComponent, headerGroups } = props;
  return (
    <>
      {headerGroups.map((headerGroup, idx) => (
        <TableHead key={idx} {...headerGroup.getRowProps()}>
          <TableRow>
            {HeaderActionComponent && <TableCell>{HeaderActionComponent}</TableCell>}
            {headerGroup.headers.map((column, headerGroupIdx) => (
              <StyledTableCell key={headerGroupIdx} {...column.getHeaderProps()} sorted={column.sorted}>
                <TableSortLabel active={column.sorted !== undefined} direction={!column.sortedDesc ? "asc" : "desc"}>
                  <div>
                    <span {...column.getSortByToggleProps()}>{column.render("Header")}</span>
                  </div>
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
      ))}
    </>
  );
};
