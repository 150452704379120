import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DashboardSocial } from "generated/graphql";
import { formatNumberToPercentage, formatNumberWithComma } from "utils/format";
import { TitleValueLayout } from "../blocks/title-value-layout";
import { DashboardColor } from "../consts";
import { StyledContainer } from "../containers/styled-container";

const useStyles = makeStyles()(() => ({
  percentage: { display: "inline", fontWeight: "bold" },
}));

export type TrainingTotalEmployeeBlockProps = {
  current: Omit<DashboardSocial["training"]["totalEmployees"], "name">;
  previous?: Omit<DashboardSocial["training"]["totalEmployees"], "name">;
  isIncludeResign: boolean;
};

export const TrainingTotalEmployeeBlock: React.FC<TrainingTotalEmployeeBlockProps> = (props) => {
  const { isIncludeResign, previous } = props;
  const { value, percentageWithResign, percentageWithoutResign } = props.current;

  const { classes } = useStyles();
  const intl = useIntl();

  const getPercentageColor = () => {
    if (!previous) return DashboardColor.text.grey;

    const compareAndGetColor = (cur: number, pre: number) => {
      if (cur > pre) return DashboardColor.chart.green;
      else if (cur < pre) return DashboardColor.chart.red;
      else return DashboardColor.text.grey;
    };

    return isIncludeResign
      ? compareAndGetColor(percentageWithResign, previous.percentageWithResign)
      : compareAndGetColor(percentageWithoutResign, previous.percentageWithoutResign);
  };

  return (
    <StyledContainer>
      <Grid container height="100%" spacing={1} direction="column" alignItems="center">
        <Grid xs={12} flexGrow={3}>
          <TitleValueLayout
            title={intl.formatMessage({ id: "dashboard.social.training.totalEmployeesTrained.title" })}
            value={formatNumberWithComma(value)}
            style={{
              align: "center",
              title: { color: DashboardColor.text.grey, bold: true },
              value: { color: DashboardColor.text.grey, bold: true },
            }}
          />
        </Grid>
        <Grid xs={12} textAlign="center">
          <Typography color={DashboardColor.text.grey}>
            {intl.formatMessage(
              { id: "dashboard.social.training.totalEmployeesTrained.description" },
              {
                percentage: (
                  <Typography color={getPercentageColor()} className={classes.percentage}>
                    {formatNumberToPercentage(isIncludeResign ? percentageWithResign : percentageWithoutResign, 1)}
                  </Typography>
                ),
              }
            )}
          </Typography>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
