import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  checkIsSameMinDate,
  createSelectSingleFormatter,
  DataGridContainer,
  DateRangeEditor,
  Formatter,
  getFormatter,
  InputEditor,
  OnUpdateDataGridRows,
  SelectEditor,
  StringFormatter,
  useProjectCompany,
} from "components/data-grid";
import { IndexType } from "generated/graphql";
import { useDialog } from "hook";
import { usePermission } from "hook/permission";
import { DatasheetPaper } from "pages/excel/datasheet-paper";
import { LoadingContext } from "provider/loading";
import { getDateDiffInDays, toDate } from "utils/date-time";
import { IColumnItem } from "utils/interfaces";
import { Yup } from "utils/yup";
import { isAnyNotNullOrUndefined } from "../utils/data-type";
import { ExcludedResultItem, getExcludedResultItems, getExcludeResultItem } from "../utils/date-range";
import { getSteps } from "../utils/step-message";
import { basicValidationSchema } from "../utils/validation";
import { newWaterConsumptionRow, tips, waterConsumptionUnitOptions, WaterSource, waterSourceOptions } from "./consts";
import { useActions } from "./hook/action";
import { WaterConsumptionRecordRow } from "./types";

const source = IndexType.WaterConsumption;

export const WaterConsumptionPage: React.FC = () => {
  const [initRows, setInitRows] = useState<WaterConsumptionRecordRow[]>([]);
  const [excludeDays, setExcludeDays] = useState<ExcludedResultItem[]>([]);

  const intl = useIntl();
  const { setLoading } = useContext(LoadingContext);
  const {
    companyOptions,
    addressOptions,
    addressPeriod,
    minDate,
    maxDate,
    isValidAllCompanies,
    isValidAllCompaniesAddress,
  } = useProjectCompany(source);
  const { openDialog, Dialog, DialogType } = useDialog();
  const { getData, submitData } = useActions();
  const editable = usePermission();

  const companies = useMemo(
    () => companyOptions.filter((c) => addressOptions[c.value].length !== 0),
    [addressOptions, companyOptions]
  );

  const getNewRow = () => ({ ...newWaterConsumptionRow, minDate, maxDate });

  const getAddressPeriod = useCallback(
    (company: string, address: string) =>
      addressPeriod[company] ? addressPeriod[company].find((d) => d.address === address) : null,
    [addressPeriod]
  );

  const getExcludeDays = useCallback(
    (row: WaterConsumptionRecordRow) =>
      getExcludeResultItem(excludeDays, { ...row, source: row.waterSource })?.excludeDays ?? [],
    [excludeDays]
  );

  const calculateExcludeDays = useCallback((rows: WaterConsumptionRecordRow[]) => {
    const items = getExcludedResultItems(rows.map(({ waterSource, ...rest }) => ({ ...rest, source: waterSource })));
    setExcludeDays(items);
  }, []);

  const handleUpdateRows: OnUpdateDataGridRows<WaterConsumptionRecordRow> = (rows, updatedItem) => {
    const { address, endDate, startDate, waterSource, meterNumber } = updatedItem.updated;
    if (isAnyNotNullOrUndefined(address)) {
      for (let i = updatedItem.fromRow; i <= updatedItem.toRow; i++) {
        const period = getAddressPeriod(rows[i].companyId, rows[i].address);
        if (!period) continue;
        rows[i].maxDate = toDate(period.maxDate);
        rows[i].minDate = toDate(period.minDate);
      }
    }

    if (isAnyNotNullOrUndefined(endDate, startDate, waterSource, meterNumber, address)) calculateExcludeDays(rows);

    if (waterSource === WaterSource.WellWater) {
      openDialog({ content: intl.formatMessage({ id: "errors.water.well-water" }), type: DialogType.ALERT });
    }

    return rows;
  };

  const columns = useMemo<IColumnItem[]>(
    () => [
      { key: "count", name: "", width: 80, frozen: true, isAction: true, formatter: Formatter },
      {
        key: "companyId",
        name: intl.formatMessage({ id: "excel.common.company-name" }),
        editor: SelectEditor,
        options: companies,
        resizable: true,
        width: 200,
        frozen: true,
        formatter: getFormatter(createSelectSingleFormatter(companies), "companyId"),
      },
      {
        key: "address",
        name: intl.formatMessage({ id: "excel.water.address" }),
        width: 250,
        resizable: true,
        editor: SelectEditor,
        dynamicOptionsHandler: (row: { rowData: WaterConsumptionRecordRow }) =>
          row.rowData.companyId === "" || !addressOptions[row.rowData.companyId]
            ? []
            : addressOptions[row.rowData.companyId],
        formatter: getFormatter(createSelectSingleFormatter([]), "address"),
        // pass empty array to formatter because formatter cannot map will directly show value , maybe suitable for dynamic case
      },
      {
        key: "meterNumber",
        name: intl.formatMessage({ id: "excel.water.meter-number" }),
        width: 100,
        resizable: true,
        editor: InputEditor,
        formatter: getFormatter(StringFormatter, "meterNumber"),
      },
      {
        key: "startDate",
        name: intl.formatMessage({ id: "excel.energy.start-date" }),
        width: 100,
        resizable: true,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: true,
          selectsEnd: false,
          handleExcludeDays: (row: { rowData: WaterConsumptionRecordRow }) => getExcludeDays(row.rowData),
        },
        formatter: getFormatter(Formatter, "startDate"),
        isDisable: () => !editable,
        editable,
      },
      {
        key: "endDate",
        name: intl.formatMessage({ id: "excel.energy.end-date" }),
        width: 100,
        resizable: true,
        editor: editable ? DateRangeEditor : undefined,
        dateRangeMetaData: {
          minDate,
          maxDate,
          startDateProperty: "startDate",
          endDateProperty: "endDate",
          selectsStart: false,
          selectsEnd: true,
          handleExcludeDays: (row: { rowData: WaterConsumptionRecordRow }) => getExcludeDays(row.rowData),
        },
        formatter: getFormatter(Formatter, "endDate"),
        isDisable: () => !editable,
        editable,
      },
      {
        key: "waterSource",
        name: intl.formatMessage({ id: "excel.water.water-source" }),
        editor: SelectEditor,
        options: waterSourceOptions,
        resizable: true,
        width: 200,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(waterSourceOptions), "waterSource"),
      },
      {
        key: "waterConsumptionAmount",
        name: intl.formatMessage({ id: "excel.water.water-consumption" }),
        width: 200,
        resizable: true,
        editor: InputEditor,
        type: "number",
        formatter: getFormatter(Formatter, "waterConsumptionAmount"),
      },
      {
        key: "waterConsumptionUnit",
        name: intl.formatMessage({ id: "excel.water.consumption-unit" }),
        editor: SelectEditor,
        options: waterConsumptionUnitOptions,
        resizable: true,
        width: 200,
        isCreatable: true,
        formatter: getFormatter(createSelectSingleFormatter(waterConsumptionUnitOptions), "waterConsumptionUnit"),
      },
    ],
    [addressOptions, companies, getExcludeDays, intl, maxDate, minDate]
  );

  const initData = useCallback(async () => {
    if (companies.length === 0 || checkIsSameMinDate(minDate, maxDate)) return;

    setLoading(true);
    const { rows } = await getData(companies.map((i) => i.value));

    const rowsWithMinMaxDate = rows.map((r) => {
      const period = getAddressPeriod(r.originalCompanyId!, r.address!);
      return {
        ...r,
        minDate: period ? toDate(period.minDate) : minDate,
        maxDate: period ? toDate(period.maxDate) : maxDate,
      };
    });

    setLoading(false);
    calculateExcludeDays(rowsWithMinMaxDate);
    setInitRows([...rowsWithMinMaxDate]);
  }, [companies, setLoading, getAddressPeriod, getData, calculateExcludeDays, maxDate, minDate]);

  useEffect(() => {
    initData();
  }, [initData]);

  const validationSchema = Yup.lazy<Partial<WaterConsumptionRecordRow>[]>((values) => {
    const result = isValidAllCompanies(values as WaterConsumptionRecordRow[]);

    const dateCheck = Yup.string()
      .required("errors.common.require")
      .test("365", "errors.common.in-rent-period", function () {
        const row: WaterConsumptionRecordRow = this.parent;
        if (row.companyId === "" || row.address === "" || row.waterSource === "" || row.meterNumber === "") {
          return true;
        }

        // + 1 to include endDate
        const numberOfPeriodDays = getDateDiffInDays(row.minDate, row.maxDate) + 1;
        const days = getExcludeDays(this.parent as WaterConsumptionRecordRow);
        return days.length === numberOfPeriodDays;
      });

    return Yup.array(
      Yup.object<Partial<WaterConsumptionRecordRow>>({
        companyId: Yup.string()
          .required("errors.common.require")
          .allCompaniesValid("errors.common.validate-all-companies", result),
        address: Yup.string()
          .required("errors.common.require")
          .test("isValidAllCompaniesAddress", "errors.common.validate-all-address", function () {
            const validateCompaniesAddressResult = isValidAllCompaniesAddress(
              values as WaterConsumptionRecordRow[],
              this.parent as WaterConsumptionRecordRow
            );
            return validateCompaniesAddressResult.isValid
              ? true
              : this.createError({ message: validateCompaniesAddressResult.error });
          }),
        meterNumber: Yup.string().required("errors.common.require"),
        waterSource: Yup.string().required("errors.common.require"),
        waterConsumptionAmount: Yup.number().required("errors.common.require").min(0, "errors.common.more-zero"),
        waterConsumptionUnit: Yup.string().required("errors.common.require"),
        startDate: dateCheck,
        endDate: dateCheck,
      }).required()
    ).defined();
  });

  const introContent = intl.formatMessage({ id: "excel.water.guide" });
  const steps = getSteps(intl);

  return (
    <DatasheetPaper>
      <DataGridContainer
        columns={columns}
        tips={tips}
        initRows={initRows}
        newRowData={getNewRow()}
        source={source}
        basicSchema={basicValidationSchema}
        fullSchema={validationSchema}
        canSave={true}
        guide={{ introContent, steps }}
        excludeClone={[
          { property: "startDate", value: "" },
          { property: "endDate", value: "" },
          { property: "waterConsumptionAmount", value: 0 },
        ]}
        onInitData={initData}
        submitData={submitData}
        onUpdateRows={handleUpdateRows}
      />
      <Dialog />
    </DatasheetPaper>
  );
};
