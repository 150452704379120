import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  Mutation,
  MutationUploadScope3DataArgs,
  Query,
  QueryScope3DataArgs,
  Scope3DataDocument as queryScope3Data,
  UploadScope3DataDocument as mutateUploadScope3Data,
} from "generated/graphql";

type APIs = {
  getScope3Data: (variables: QueryScope3DataArgs) => Promise<Pick<Query, "scope3Data">["scope3Data"]>;
  uploadScope3Data: (
    variables: MutationUploadScope3DataArgs
  ) => Promise<Pick<Mutation, "uploadScope3Data">["uploadScope3Data"]>;
};

export const useAPI = (): APIs => {
  const client = useApolloClient();

  const getScope3Data = useCallback(
    async (variables: QueryScope3DataArgs) => {
      const response = await client.query<Pick<Query, "scope3Data">>({
        query: queryScope3Data,
        variables,
      });

      return response.data.scope3Data;
    },
    [client]
  );

  const uploadScope3Data = useCallback(
    async (variables: MutationUploadScope3DataArgs) => {
      const response = await client.mutate<Pick<Mutation, "uploadScope3Data">>({
        mutation: mutateUploadScope3Data,
        variables,
      });

      return response.data!.uploadScope3Data;
    },
    [client]
  );
  return { getScope3Data, uploadScope3Data };
};
