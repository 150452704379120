import { IndexType, RecordStatus } from "generated/graphql";
import { KeyRecord } from "utils/types";
import { CompanyRecordRow } from "./types";

export const newCompanyRow: CompanyRecordRow = {
  recordId: "",
  companyId: "",
  fullName: "",
  shortName: "",
  segment: "",
  nf1: "true",
  nf2: "true",
  nf3: "true",
  energy: "true",
  water: "true",
  paper: "true",
  nonHazardousWaste: "true",
  hazardousWaste: "true",
  fuelMobile: "true",
  fuelStationary: "true",
  otherMobileMachinery: "true",
  packagingMaterial: "true",
  refrigerantAndFire: "true",
  tree: "true",
  others: "true",
  workforce: "true",
  turnover: "true",
  injuryAndFatalities: "true",
  developmentAndTraining: "true",
  trainingRatio: "true",
  supplyChainManagement: "true",
  productResponsibility: "true",
  communityInvestment: "true",
  productResponsibilityPolicy: "true",
  labourResponsibilityPolicy: "true",
  remarks: "",
  errors: {},
  recordStatus: RecordStatus.Failure,
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  isDisableOthersColumn: true,
};

export const tips: KeyRecord<CompanyRecordRow, string> = {
  fullName: "tips.company.fullName",
  shortName: "tips.company.shortName",
  nf1: "tips.company.nf1",
};

export const indexTypeColumnRef: Record<IndexType, { key: string; name: string }> = {
  [IndexType.NormalizationFactor1]: { key: "nf1", name: "excel.title.nf1" },
  [IndexType.NormalizationFactor2]: { key: "nf2", name: "excel.title.nf2" },
  [IndexType.NormalizationFactor3]: { key: "nf3", name: "excel.title.nf3" },
  [IndexType.EnergyConsumption]: { key: "energy", name: "excel.title.energy" },
  [IndexType.MobileFuel]: { key: "fuelMobile", name: "excel.title.fuel-mobile" },
  [IndexType.StationaryFuel]: { key: "fuelStationary", name: "excel.title.fuel-stationary" },
  [IndexType.OtherMobileMachinery]: { key: "otherMobileMachinery", name: "excel.title.other-mobile-machinery" },
  [IndexType.WaterConsumption]: { key: "water", name: "excel.title.water" },
  [IndexType.OfficePaperUsage]: { key: "paper", name: "excel.title.paper" },
  [IndexType.NonHazardousWaste]: { key: "nonHazardousWaste", name: "excel.title.non-hazardous" },
  [IndexType.HazardousWaste]: { key: "hazardousWaste", name: "excel.title.hazardous-waste" },
  [IndexType.PackagingMaterial]: { key: "packagingMaterial", name: "excel.title.packaging-material" },
  [IndexType.RefrigerantAndFireEx]: { key: "refrigerantAndFire", name: "excel.title.refrigerant-and-fire" },
  [IndexType.Others]: { key: "others", name: "excel.title.others" },
  [IndexType.Tree]: { key: "tree", name: "excel.title.tree" },
  [IndexType.Workforce]: { key: "workforce", name: "excel.title.workforce" },
  [IndexType.Turnover]: { key: "turnover", name: "excel.title.turnover" },
  [IndexType.InjuryAndFatalities]: { key: "injuryAndFatalities", name: "excel.title.injuryAndFatalities" },
  [IndexType.DevelopmentAndTraining]: { key: "developmentAndTraining", name: "excel.title.developmentAndTraining" },
  [IndexType.TrainingRatio]: { key: "trainingRatio", name: "excel.title.trainingRatio" },
  [IndexType.LabourResponsibilityPolicy]: {
    key: "labourResponsibilityPolicy",
    name: "excel.title.labourResponsibilityPolicy",
  },
  [IndexType.SupplyChainManagement]: { key: "supplyChainManagement", name: "excel.title.supplyChainManagement" },
  [IndexType.ProductResponsibility]: { key: "productResponsibility", name: "excel.title.productResponsibility" },
  [IndexType.ProductResponsibilityPolicy]: {
    key: "productResponsibilityPolicy",
    name: "excel.title.productResponsibilityPolicy",
  },
  [IndexType.CommunityInvestment]: { key: "communityInvestment", name: "excel.title.communityInvestment" },
};

export enum Applicability {
  Applicable = "true",
  Unapplicable = "false",
}
