import { CommonReportRecordRow } from "utils/interfaces";
import { DataGridState } from ".";

export const filterRows = <T extends CommonReportRecordRow>(
  params: Pick<DataGridState<T>, "rowsBaseMap" | "rowsWithDisplayValue" | "filterString">
): DataGridState<T>["rowsDisplay"] => {
  const { rowsBaseMap, rowsWithDisplayValue, filterString } = params;

  if (rowsWithDisplayValue.length === 0 || !filterString) return Object.values(rowsBaseMap);

  const filteredRows = rowsWithDisplayValue
    /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
    .filter(({ rowId, ...r }) =>
      Object.values(r).some((value) => value.toLowerCase().includes(filterString.toLowerCase()))
    )
    .map((r) => rowsBaseMap[r.rowId]);

  return filteredRows;
};
