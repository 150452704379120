import { RecordStatus } from "generated/graphql";
import { OptionType } from "utils/interfaces";
import { TreeRecordRow } from "./types";

export const newTreeRow: TreeRecordRow = {
  recordId: "",
  companyId: "",
  numberOfTree: 0,
  isPlantedWithinOrAfter: "",
  isWithinCompanySiteBoundary: "",
  isHigherThan5: "",
  remarks: "",
  errors: {},
  fileInfo: [],
  fileToUpload: [],
  fileToDelete: [],
  recordStatus: RecordStatus.Failure,
};

export enum YesNo {
  Yes = "true",
  No = "false",
}

export const yesNoOptions: OptionType[] = [
  { label: "是 yes", value: YesNo.Yes },
  { label: "否 No", value: YesNo.No },
];
