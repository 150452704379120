import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetOtherMobileMachineryDataDocument,
  GetOtherMobileMachineryDataQuery,
  GetOtherMobileMachineryDataQueryVariables,
  SubmitOtherMobileMachineryDocument,
  SubmitOtherMobileMachineryMutation,
  SubmitOtherMobileMachineryMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getOtherMobileMachineryData: (
    variables: GetOtherMobileMachineryDataQueryVariables
  ) => Promise<GetOtherMobileMachineryDataQuery["project"]["otherMobileMachinery"]>;
  submitOtherMobileMachinery: (
    variables: SubmitOtherMobileMachineryMutationVariables
  ) => Promise<SubmitOtherMobileMachineryMutation["submitOtherMobileMachinery"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getOtherMobileMachineryData = useCallback(
    async (variables: GetOtherMobileMachineryDataQueryVariables) => {
      const response = await client.query<GetOtherMobileMachineryDataQuery, GetOtherMobileMachineryDataQueryVariables>({
        query: GetOtherMobileMachineryDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.otherMobileMachinery;
    },
    [client]
  );

  const submitOtherMobileMachinery = useCallback(
    async (variables: SubmitOtherMobileMachineryMutationVariables) => {
      const response = await client.mutate<
        SubmitOtherMobileMachineryMutation,
        SubmitOtherMobileMachineryMutationVariables
      >({
        mutation: SubmitOtherMobileMachineryDocument,
        variables,
      });

      return response.data?.submitOtherMobileMachinery ?? false;
    },
    [client]
  );

  return {
    getOtherMobileMachineryData,
    submitOtherMobileMachinery,
  };
};
