import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetWaterConsumptionDataDocument,
  GetWaterConsumptionDataQuery,
  GetWaterConsumptionDataQueryVariables,
  SubmitWaterConsumptionDocument,
  SubmitWaterConsumptionMutation,
  SubmitWaterConsumptionMutationVariables,
} from "generated/graphql";

type UseAPIReturns = {
  getWaterConsumptionData: (
    variables: GetWaterConsumptionDataQueryVariables
  ) => Promise<GetWaterConsumptionDataQuery["project"]["waterConsumption"]>;
  submitWaterConsumption: (
    variables: SubmitWaterConsumptionMutationVariables
  ) => Promise<SubmitWaterConsumptionMutation["submitWaterConsumption"]>;
};

export const useAPI = (): UseAPIReturns => {
  const client = useApolloClient();

  const getWaterConsumptionData = useCallback(
    async (variables: GetWaterConsumptionDataQueryVariables) => {
      const response = await client.query<GetWaterConsumptionDataQuery, GetWaterConsumptionDataQueryVariables>({
        query: GetWaterConsumptionDataDocument,
        variables,
        fetchPolicy: "network-only",
      });

      return response.data.project.waterConsumption;
    },
    [client]
  );

  const submitWaterConsumption = useCallback(
    async (variables: SubmitWaterConsumptionMutationVariables) => {
      const response = await client.mutate<SubmitWaterConsumptionMutation, SubmitWaterConsumptionMutationVariables>({
        mutation: SubmitWaterConsumptionDocument,
        variables,
      });

      return response.data?.submitWaterConsumption ?? false;
    },
    [client]
  );

  return {
    getWaterConsumptionData,
    submitWaterConsumption,
  };
};
